const PriceShow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
      <g>
        <path
          d="M8.05764 11.8881H9.83549H10.3047L10.3345 12.3564C10.3379 12.4103 10.3396 12.4644 10.3396 12.5186C10.3396 13.5055 9.73871 14.4247 8.83676 14.8231L8.82079 14.8302L8.80437 14.8361C8.36071 14.9961 7.95843 15.2634 7.64116 15.6085L7.61274 15.6394L8.15344 16.1364L8.18186 16.1055L8.1819 16.1055C8.38009 15.8898 8.62318 15.7147 8.89002 15.5935L8.90375 15.5872L8.91783 15.5818L8.91783 15.5818L8.91789 15.5818L8.91813 15.5817L8.91913 15.5813L8.92309 15.5798L8.93766 15.5742L8.98444 15.5561C9.02291 15.5412 9.05777 15.5276 9.06984 15.5225L9.08643 15.5156L9.10348 15.5099C9.2371 15.4653 9.37468 15.4333 9.51319 15.4152L8.05764 11.8881ZM8.05764 11.8881V11.1537H9.06781H9.82452L9.52775 10.4576C9.37403 10.097 9.29356 9.70534 9.29356 9.30711C9.29356 7.69187 10.6085 6.37695 12.2237 6.37695C12.8587 6.37695 13.4606 6.57584 13.9688 6.953C14.3306 7.22158 14.62 7.56127 14.8232 7.95211L14.1096 8.17981C13.72 7.52577 13.0112 7.11129 12.2237 7.11129C11.0125 7.11129 10.028 8.09587 10.028 9.30704C10.028 9.81426 10.2011 10.3296 10.6917 10.9605L10.8419 11.1536H11.0864H13.3454V11.8881H11.5718H11.0541L11.0721 12.4054C11.0734 12.4434 11.0741 12.4811 11.0741 12.5185C11.0741 13.0999 10.9224 13.6592 10.6338 14.1538L10.3407 14.656L10.8812 14.8705C11.1564 14.9797 11.4142 15.1304 11.6461 15.3189L11.6589 15.3293L11.6724 15.3389C12.1291 15.6623 12.7007 15.8099 13.2521 15.7378C13.865 15.6578 14.418 15.321 14.7709 14.8135L14.7948 14.779L15.3978 15.1983L15.3738 15.2327L15.3738 15.2328C14.9032 15.9096 14.1641 16.3593 13.3472 16.4661L13.3472 16.4661C13.2263 16.4819 13.1021 16.49 12.9783 16.49C12.3546 16.49 11.7301 16.2874 11.223 15.9203L11.1923 15.8965C10.7244 15.512 10.1139 15.3368 9.51324 15.4152L8.05764 11.8881Z"
          fill="#848484"
          fillOpacity="0.333333"
          stroke="#848484"
        />
      </g>
      <path
        d="M21.3438 11.127C21.3438 5.6904 16.9366 1.2832 11.5 1.2832C6.06345 1.2832 1.65625 5.6904 1.65625 11.127C1.65625 16.5635 6.06345 20.9707 11.5 20.9707C16.9366 20.9707 21.3438 16.5635 21.3438 11.127Z"
        stroke="#848484"
        strokeWidth="2"
      />
    </svg>
  );
};

export default PriceShow;
