import React, { useCallback, useEffect, useState } from "react";
import { Grid, Card, Select, MenuItem, TableRow, TableCell } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import DatePickerUi from "components/SuiDateRangePicker/commonSingleDatePicker";
import { useThreeCX_requestMutation } from "app/features/api/AuthApi";
import { Link, useNavigate } from "react-router-dom";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import { formatDate } from "helper";
import { useFormik } from "formik";

import ApprovalStatus from "components/SuiDropDownList/commonDropdown";
import { useUpdateCustomerStatusValMutation } from "app/features/api/AuthApi";
import RequestStatusSelector from "components/StatusSelector/RequestStatusSelector";
import SuiTypography from "components/SuiTypography";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useGetLienceValueMutation } from "app/features/api/AuthApi";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import { useGetAllAdminsMutation } from "app/features/api/AuthApi";
import { debounce } from "lodash";

const ThreeCXRequestApproval = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigateSearch = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [requestData, setRequestData] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      cus_company_name: "",
      cus_phone_number: "",
      license_3cx_id: "",
      Requsted_date: "",
      Approval_status: "",
      admin_id: "",
      payment_date: null,
    },

    enableReinitialize: true,
    onSubmit: () => {
      ThreeCXDetails(perPage, 1);
    },
    onReset: () => {
      formik.values.first_name = "";
      formik.values.last_name = "";
      formik.values.cus_company_name = "";
      formik.values.cus_phone_number = "";
      formik.values.license_3cx_id = "";
      formik.values.payment_date = null;
      formik.values.Approval_status = "";
      formik.values.admin_id = "";

      ThreeCXDetails(perPage, 1);
    },
  });

  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "First Name" },
    { id: "2", align: "left", width: "auto", label: "Last Name" },
    { id: "3", align: "left", width: "auto", label: "Company Name" },
    { id: "4", align: "left", width: "auto", label: "Mobile Number" },
    { id: "5", align: "left", width: "auto", label: "Requested on" },
    { id: "6", align: "left", width: "auto", label: "Licence Edition" },
    { id: "7", align: "left", width: "auto", label: "Simultaneous calls" },
    { id: "8", align: "left", width: "auto", label: "Price" },
    { id: "9", align: "left", width: "auto", label: "Request Status" },
    { id: "10", align: "left", width: "auto", label: "Updated By" },
  ];
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];

  const rows =
    requestData &&
    requestData?.map((item) => {
      return (
        <>
          <TableRow key={item.id}>
            <TableCell>{`${item.customers.cus_firstname}`}</TableCell>
            <TableCell>{`${item.customers.cus_lastname}`}</TableCell>
            <TableCell>{`${item.customers.cus_company_name}`}</TableCell>
            <TableCell>{`${item.customers.cus_phone_number}`}</TableCell>

            <TableCell>{formatDate(item.createdAt)}</TableCell>
            <TableCell>{`${item.three_cx_licenses.license_name}`}</TableCell>
            <TableCell>{`${item.request_sim_calls}`}</TableCell>

            <TableCell>{`£ ${item.request_price}`}</TableCell>

            <TableCell>
              {item.request_status !== "Pending" ? (
                <SuiTypography
                  textColor={item.request_status === "Rejected" ? "error" : "primary"}
                  fontSize={14}
                >
                  {item.request_status}
                </SuiTypography>
              ) : permissionInfo?.threecx_licences_request?.UPDATE ? (
                <RequestStatusSelector
                  currentState={item.request_status}
                  onClick={(getVal) => updateStatus(item.request_id, getVal.val)}
                />
              ) : (
                <SuiTypography sx={{ color: "#848484 !important" }} fontSize={14}>
                  Pending
                </SuiTypography>
              )}
            </TableCell>
            <TableCell>
              {" "}
              {item.admins == null
                ? "-"
                : `${item.admins.admin_name} ${item.admins.admin_last_name}`}
            </TableCell>
          </TableRow>
        </>
      );
    });

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/3cx-request-approval", { page: page });
  };
  const [ThreeCX_request, { isLoading: isLoading }] = useThreeCX_requestMutation();
  const ThreeCXDetails = async (perPage, currentPage) => {
    const params = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.first_name) {
      params.cus_firstname = formik.values.first_name?.trim();
    }
    if (formik.values.last_name) {
      params.cus_lastname = formik.values.last_name?.trim();
    }
    if (formik.values.cus_company_name) {
      params.cus_company_name = formik.values.cus_company_name?.trim();
    }
    if (formik.values.Approval_status) {
      params.request_status = formik.values.Approval_status;
    }
    if (formik.values.payment_date) {
      params.request_date = formik.values.payment_date;
    }
    if (formik.values.cus_phone_number) {
      params.cus_phone_number = formik.values.cus_phone_number?.trim();
    }
    if (formik.values.license_3cx_id) {
      params.license_id = formik.values.license_3cx_id.id;
    }
    if (formik.values.admin_id) {
      params.admin_id = formik.values.admin_id.id;
    }
    try {
      const resposne = await ThreeCX_request(params).unwrap();
      setRequestData(resposne.data.rows);
      setCount(resposne.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/3cx-request-approval", { page: 1 });
  };
  useEffect(() => {
    ThreeCXDetails(perPage, currentPage);
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);

  const [updateCustomerStatus] = useUpdateCustomerStatusValMutation();
  const suiSnackbar = useSuiSnackbar();

  const updateStatus = async (id, i) => {
    const body = {
      status: i,
      id,
    };
    try {
      const resposne = await updateCustomerStatus(body).unwrap();
      suiSnackbar(resposne.message, "success");
      ThreeCXDetails(perPage, currentPage);
    } catch (error) {
      console.log(error);
    }
  };
  const [GetLienceValue] = useGetLienceValueMutation();
  const [getAllAdmins] = useGetAllAdminsMutation();
  const [searchText, setSearchText] = useState("");
  const [dropvals, setDropVal] = useState([]);
  const [adminvals, setAdminVal] = useState([]);
  const dropdownValues = async () => {
    try {
      const response = await GetLienceValue().unwrap();
      setDropVal(response.data.licences);
    } catch (error) {
      console.error("Error fetching license values:", error);
    }
  };

  const licenseEdition = dropvals.map((dropval) => ({
    id: dropval.license_id,
    name: dropval.license_name,
  }));
  const dropdownAdmins = async () => {
    try {
      const response = await getAllAdmins().unwrap();
      setAdminVal(response.data);
    } catch (error) {
      console.error("Error fetching license values:", error);
    }
  };
  const debouncedGetCustomers = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");

      try {
        const response = await getAllAdmins(params).unwrap();
        setAdminVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (searchText.length < 0) {
      dropdownAdmins();
    } else {
      setAdminVal([]);
    }
  }, [searchText]);

  const allAdminVals = adminvals.map((dropval) => ({
    id: dropval.admin_id,
    name: dropval.admin_name + " " + dropval.admin_last_name,
    lastname: dropval.admin_last_name,
  }));
  useEffect(() => {
    dropdownValues();
    dropdownAdmins();
  }, []);
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2} onSubmit={handleFormSubmit}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="First Name"
                  id="first_name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Last Name"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Company Name"
                  id="cus_company_name"
                  name="cus_company_name"
                  value={formik.values.cus_company_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Mobile Number"
                  id="cus_phone_number"
                  name="cus_phone_number"
                  value={formik.values.cus_phone_number}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="license_3cx_id"
                  value={formik.values.license_3cx_id}
                  setValue={(value) => {
                    formik.setFieldValue("license_3cx_id", value);
                    formik.setFieldValue("license_3cx_name", value.name);
                  }}
                  placeholder="Select Licence Edition"
                  isLoading={false}
                  disabled={false}
                  options={licenseEdition}
                  formik={formik}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <ApprovalStatus
                  name="Approval_status"
                  value={formik.values.Approval_status}
                  setValue={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <DatePickerUi
                  placeholder="Date"
                  value={formik.values.payment_date}
                  color="primary"
                  formik={formik}
                  getValue={(value) => formik.setFieldValue("payment_date", value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="admin_id"
                  value={formik.values.admin_id}
                  setValue={(value) => {
                    formik.setFieldValue("admin_id", value);
                    formik.setFieldValue("admin_name", value.name);
                    formik.setFieldValue("admin_last_name", value.name);
                  }}
                  placeholder="Updated By"
                  isLoading={false}
                  disabled={false}
                  onInputChange={debouncedGetCustomers}
                  options={allAdminVals}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  onClick={formik.handleSubmit}
                  sx={{ px: "1rem", marginRight: "14px" }}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    formik.handleReset();
                    setPerPage(10);
                  }}
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of 3CX Request Approvals">
            {permissionInfo?.threecx_licences_request?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"assign-licence"}
              >
                Assign Licence
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={pagesTableHeader}
            isLoading={isLoading}
            rowsData={rows}
          />
          {!isLoading && requestData.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      // onClick={() => getCustomerMultipleRecords(perPage, currentPage)}
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default ThreeCXRequestApproval;
