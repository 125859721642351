import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAuthenticated: localStorage.getItem("auth_token_admin"),
  auth_token: localStorage.getItem("auth_token_admin"),
  searchParams: {},
  globalInfo: "",
  permissions: null,
  remember: false,
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    setCredentials: (state, action) => {
      state.auth_token = action.payload.auth_token;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setRememberMe: (state, action) => {
      const data = action.payload;
      state.remember = data;
    },
    getSorting: (state, action) => {
      const data = action.payload;
      state.searchParams = { ...state.searchParams, ordering: `${data.order}${data.orderBy}` };
    },
    setSearchParams: (state, action) => {
      const data = action.payload;
      state.searchParams = data;
    },
    setGlobalInfo: (state, action) => {
      const data = action.payload;
      state.globalInfo = data;
    },
    setPermission: (state, action) => {
      const data = action.payload;
      state.permissions = data;
    },
    Logout: (state) => {
      state.isAuthenticated = null;
      state.auth_token = null;
      localStorage.removeItem("loggedInTime_admin");
      localStorage.removeItem("tokenExpiryTime_admin");
      localStorage.removeItem("auth_token_admin");
      localStorage.removeItem("refresh_token_admin");
      localStorage.removeItem("isRemember_admin");
    },
  },
});

export const {
  setRememberMe,
  setCredentials,
  setGlobalInfo,
  getSorting,
  Logout,
  setSearchParams,
  setPermission,
} = authSlice.actions;

export default authSlice.reducer;
