import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  // Step,
  // StepLabel,
  // Stepper,
  Switch,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useGeoCodeListMutation } from "app/features/api/AuthApi";
import { useRangeHolderDropListMutation } from "app/features/api/AuthApi";
import { useAddDidsNumbersMutation } from "app/features/api/AuthApi";
import { useGetAssignSipTrunkMutation } from "app/features/api/AuthApi";
import { useGetCustomerAccountNoMutation } from "app/features/api/AuthApi";
import { useSupplierDropListMutation } from "app/features/api/AuthApi";
import { useAddDidsMutation } from "app/features/api/AuthApi";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";
import SuiButton from "components/SuiButton";
import DatePickerUi from "components/SuiDateRangePicker/CommonFeatureDate";
import SuiDropDownList from "components/SuiDropDownList";
import NumberInput from "components/SuiInput/InventoryNumber";
import SuiTextArea from "components/SuiInput/textArea";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import SuiTypography from "components/SuiTypography";
import DeleteIcon from "Container/Icons/DeleteIcon";
import dayjs from "dayjs";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AddDidsSchema } from "Validation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetAllCountryMutation } from "app/features/api/AuthApi";
const steps = ["Step 1", "Step 2"];
const AddDid = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const [flag, setFlag] = useState();
  const [AddDids] = useAddDidsMutation();
  const [errors, setErrors] = useState({});
  const isStepComplete = (step) => {
    const isComplete = completedSteps[step] === true;
    return isComplete;
  };

  const CustomStepIcon = (props) => {
    const { completed } = props;
    return completed ? <CheckCircleIcon color="primary" /> : <span>{props.icon}</span>;
  };
  const handleNext = () => {
    setCompletedSteps((prevCompletedSteps) => ({
      ...prevCompletedSteps,
      [activeStep]: true,
    }));
    const newErrors = {};

    didNumbers.forEach((row, index) => {
      if (row.did_area_code == null) {
        newErrors[index] = true;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    if (activeStep === steps.length - 1) {
      navigate("/did");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newActiveStep = prevActiveStep - 1;

      setCompletedSteps((prevCompletedSteps) => {
        const updatedCompletedSteps = { ...prevCompletedSteps };
        delete updatedCompletedSteps[newActiveStep];
        return updatedCompletedSteps;
      });

      return newActiveStep;
    });
  };

  const didTableHeader = [
    { id: "1", align: "left", width: "auto", label: "DID Numbers" },
    { id: "7", align: "left", width: "auto", label: "Country" },
    { id: "2", align: "left", width: "auto", label: "Area Code" },
    { id: "3", align: "left", width: "auto", label: "Range Info" },
    { id: "4", align: "left", width: "auto", label: "Is Golden Number" },
    { id: "5", align: "left", width: "auto", label: "Is Fuse 2 Allocation" },
    { id: "6", align: "right", width: "auto", label: "Action" },
  ];

  const [input, setInput] = useState("");
  const [didNumbers, setDidNumbers] = useState("");
  const [rows, setRows] = useState([]);
  const [checkDidNumbers, setCheckDidNumbers] = useState();
  const [error, setError] = useState("");
  const [getCustomerAccountNo] = useGetCustomerAccountNoMutation();
  const [allNumbers, setAllNumbers] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [supplierDropList] = useSupplierDropListMutation();
  const [rangeHolderDropList] = useRangeHolderDropListMutation();
  const [supplierListVal, setSupplierListVal] = useState();
  const [rangeListVal, setRangeListVal] = useState();
  const [customerListVal, setCustomerListVal] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchTextCust, setSearchTextCust] = useState("");
  const [searchTextRange, setSearchTextRange] = useState("");
  const [searchTextGeo, setSearchTextGeo] = useState("");
  const parseInput = (text) => {
    const lines = text.split("\n");
    const numbers = new Set();
    let errorMsg = "";

    lines.forEach((line) => {
      const trimmed = line.trim();
      if (trimmed) {
        const ranges = trimmed.split(",").map((part) => part.trim());

        ranges.forEach((range) => {
          if (range.includes("-")) {
            const [start, end] = range.split("-").map((num) => num.trim());
            const startNum = parseInt(start, 10);
            const endNum = parseInt(end, 10);

            if (!isNaN(startNum) && !isNaN(endNum) && startNum <= endNum) {
              // Check if the range is too large
              const rangeSize = endNum - startNum + 1;
              if (rangeSize > 500) {
                errorMsg = "The range exceeds the maximum size limit of 500 numbers.";
                return; // Skip adding this range
              }

              for (let i = startNum; i <= endNum; i++) {
                if (i.toString().length < 6 || i.toString().length > 15) {
                  errorMsg = "Number is not between 6 and 15 digits long.";
                } else {
                  numbers.add(i);
                }
              }
            } else {
              errorMsg = "Invalid range format or values.";
            }
          } else {
            const num = parseInt(range, 10);
            if (!isNaN(num)) {
              if (num.toString().length < 6 || num.toString().length > 15) {
                errorMsg = "Number is not between 6 and 15 digits long.";
              } else {
                numbers.add(num);
              }
            } else {
              errorMsg = "Invalid number format.";
            }
          }
        });
      }
    });

    return { parsedNumbers: Array.from(numbers), errorMsg };
  };

  const findRanges = (numbers) => {
    const sortedNumbers = Array.from(new Set(numbers)).sort((a, b) => a - b);
    const result = [];
    if (sortedNumbers.length === 0) return result;

    let rangeStart = sortedNumbers[0];
    let rangeEnd = sortedNumbers[0];
    const rangeMap = new Map();

    for (let i = 1; i < sortedNumbers.length; i++) {
      const currentNumber = sortedNumbers[i];
      if (currentNumber === rangeEnd + 1) {
        rangeEnd = currentNumber;
      } else {
        for (let num = rangeStart; num <= rangeEnd; num++) {
          rangeMap.set(num, { start: rangeStart, end: rangeEnd });
        }
        rangeStart = currentNumber;
        rangeEnd = currentNumber;
      }
    }

    for (let num = rangeStart; num <= rangeEnd; num++) {
      rangeMap.set(num, { start: rangeStart, end: rangeEnd });
    }

    sortedNumbers.forEach((num) => {
      if (rangeMap.has(num)) {
        const { start, end } = rangeMap.get(num);
        result.push({
          did_inventory_number: num.toString(),
          did_inventory_range_start: start === end ? "-" : start.toString(),
          did_inventory_range_end: start === end ? "-" : end.toString(),
        });
      }
    });
    return result;
  };
  const suiSnackbar = useSuiSnackbar();
  const [GeoCodeList] = useGeoCodeListMutation();
  const [getListData, setGetListData] = useState();
  const [getAssignSipTrunk] = useGetAssignSipTrunkMutation();
  const [SipDetails, setSipDetails] = useState([]);
  const [countryList, setCountryList] = useState();
  const [GetAllCountry] = useGetAllCountryMutation();
  const getCountry = async () => {
    try {
      const response = await GetAllCountry().unwrap();
      setCountryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const optionsCountry = countryList?.map((item) => ({
    id: item.country_id,
    name: item.country_name,
  }));
  const getCustomerNo = async (id) => {
    try {
      const response = await getAssignSipTrunk(id).unwrap();
      setSipDetails(response?.data);
    } catch (error) {
      setSipDetails([]);
      console.log(error);
    }
  };

  useEffect(() => {
    getGeoList();
    SupplierLists();
    RangeList();
    CustomersLists();
    getCountry();
  }, []);

  const handleChange = (event) => {
    const newInput = event.target.value;
    const isValidFormat = /^[0-9\s,-]*$/.test(newInput);
    const hasInvalidStartingZero = /\b0\d+/.test(newInput);
    const isOnlyZero = /^0$/.test(newInput.trim());
    if (isValidFormat && !hasInvalidStartingZero && !isOnlyZero) {
      setInput(newInput);
      setErrors("");
    } else if (!isValidFormat) {
      setErrors("Invalid input: Only numbers and ranges are allowed.");
    } else if (hasInvalidStartingZero) {
      setErrors("Invalid input: Numbers cannot start with 0.");
    } else if (isOnlyZero) {
      setErrors("Invalid input: '0' is not allowed.");
    }
  };
  const customerTableHeader = [
    { id: "1", align: "left", width: "auto", label: "DID Numbers" },
    { id: "9", align: "left", width: "auto", label: "Country" },
    { id: "2", align: "left", width: "auto", label: "Area Code" },
    { id: "3", align: "left", width: "auto", label: "Provision to SRE" },
    { id: "4", align: "left", width: "auto", label: "Is Ported" },
    { id: "5", align: "left", width: "auto", label: "Effective Date" },
    { id: "6", align: "left", width: "auto", label: "Rate Override" },
    { id: "7", align: "left", width: "auto", label: "Provision to SIP Server" },
    { id: "8", align: "left", width: "auto", label: "Porting Fees" },
  ];

  const handleAreaCodeChange = (inventoryNumber, newValue) => {
    const updatedTable = didNumbers.map((row) => {
      if (row.did_inventory_number == inventoryNumber) {
        const matchingAreaCode = Array.isArray(getListData.geo)
          ? getListData.geo.find((option) => option.did_area_code_caption === newValue) ||
            (Array.isArray(getListData.nongeo)
              ? getListData.nongeo.find((option) => option.did_area_code_caption === newValue)
              : null)
          : null;

        return {
          ...row,
          did_area_code: newValue,
          did_area_code_id: matchingAreaCode ? matchingAreaCode.did_area_code_id : null,
          did_inventory_number_sub_type: matchingAreaCode
            ? matchingAreaCode.did_area_code_type
            : null,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };

  const CheckChange = (newValue) => {
    const updatedTable = didNumbers.map((row) => ({
      ...row,
      did_inventory_sip_server_name: newValue,
    }));

    setDidNumbers(updatedTable);
  };

  const changeCountry = (newValue, index) => {
    const updatedTable = didNumbers.map((row, idx) => ({
      ...row,

      country_id: idx === index ? newValue?.id : row.country_id,
    }));

    setDidNumbers(updatedTable);
  };
  const CheckSingleChange = (newValue, index) => {
    const updatedTable = didNumbers.map((row, idx) => ({
      ...row,
      did_inventory_sip_server_name: idx === index ? newValue : row.did_inventory_sip_server_name,
    }));

    setDidNumbers(updatedTable);
  };
  const handlePriceChange = (event, index) => {
    const newValue = Number(event.target.value);
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_porting_fee_amount: newValue,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const handlePorted = (event, index) => {
    const newValue = Number(event.target.value);
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_is_ported_in: newValue,
          did_inventory_porting_fee_amount:
            newValue == 0 ? 0 : row.did_inventory_porting_fee_amount,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const handleOverride = (event, index) => {
    const newValue = Number(event.target.value);
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_rate_override: newValue,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };

  const handleDate = (event, index) => {
    const newValue = event;
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_effective_date: newValue,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const [AddDidsNumbers] = useAddDidsNumbersMutation();
  const formik = useFormik({
    initialValues: {
      did_supplier_status: "",
      range_holder_name: "",
      customer_name: "",
      checkboxAssign: true,
    },
    validationSchema: AddDidsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        did_supplier_id: values.did_supplier_status,
        did_range_holder_id: values.range_holder_name,
        numbers: updatedData,
        ...(values.checkboxAssign && { cus_id: values.customer_name }),
      };
      const isSipServerBlank =
        formik.touched.customer_name &&
        formik.values.checkboxAssign &&
        formik.values.customer_name == "" &&
        setFlag(isSipServerBlank);

      if (!isSipServerBlank && !flag) {
        try {
          const resp = await AddDidsNumbers(body).unwrap();
          suiSnackbar(resp.message, "success");
          navigate("/did-inventory");
        } catch (error) {
          suiSnackbar(error.data.message, "error");
          console.log(error);
        }
      }
    },
  });

  const customerId = formik.values.customer_name;
  const matchedCustomer = customerListVal?.filter((customer) => customer.cus_id == customerId);
  const accountNumber = matchedCustomer ? matchedCustomer[0]?.cus_id : null;
  const accountNumberType = matchedCustomer ? matchedCustomer[0]?.cus_account_type : null;
  const [status, setStatus] = useState(false);
  const customerRows = Array.isArray(didNumbers)
    ? [
        <TableRow key="header-row">
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>

          <TableCell>
            <SuiDropDownList
              data={[
                { id: "1", label: "Yes" },
                { id: "0", label: "No" },
              ]}
              disabled
              select={{
                key: "id",
                label: "Select for all",

                valueKey: "id",
                labelName: "label",
                fn: () => {
                  // const newValue = Number(event.target.value);
                  setDidNumbers((prevNumbers) =>
                    prevNumbers.map((row) => ({
                      ...row,
                      did_inventory_is_ported_in: 1,
                    }))
                  );
                },
              }}
            />
          </TableCell>
          <TableCell>
            <DatePickerUi
              placeholder="Pick date for all"
              value={formik.values.payment_date}
              color="primary"
              disabled={accountNumberType == 1 || accountNumberType == undefined}
              formik={formik}
              getValue={(newValue) => {
                setDidNumbers((prevNumbers) =>
                  prevNumbers.map((row) => ({
                    ...row,
                    did_inventory_effective_date: newValue,
                  }))
                );
                setStatus(true);
              }}
            />
          </TableCell>
          <TableCell>
            <NumberInput
              placeholder="Enter Rate for all"
              step={1}
              setValue={formik.setFieldValue}
              max={100}
              required
              onChange={(event) => {
                const newValue = Number(event.target.value);
                setDidNumbers((prevNumbers) =>
                  prevNumbers.map((row) => ({
                    ...row,
                    did_inventory_rate_override: newValue,
                  }))
                );
              }}
            />
          </TableCell>
          <TableCell>
            <Autocomplete
              freeSolo={false}
              options={
                Array.isArray(SipDetails) ? SipDetails.map((option) => option.sip_username) : []
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Select SIP Server Name for all" />
              )}
              onChange={(event, newValue) => {
                CheckChange(newValue);
              }}
              noOptionsText="No options"
              sx={{
                "& .MuiAutocomplete-input:focus": {
                  border: "none !important",
                },
              }}
            />
          </TableCell>
          <TableCell>
            <NumberInput
              placeholder="Enter Fees for all"
              step={1}
              max={100}
              required
              setValue={formik.setFieldValue}
              onChange={(event) => {
                const newValue = Number(event.target.value);

                setDidNumbers((prevNumbers) =>
                  prevNumbers.map((row) => ({
                    ...row,
                    did_inventory_porting_fee_amount:
                      row.did_inventory_is_ported_in == 0 ? 0 : newValue,
                  }))
                );
              }}
            />
          </TableCell>
        </TableRow>,
        ...didNumbers.map((row, index) => (
          <TableRow key={row.did_inventory_number}>
            <TableCell>{row.did_inventory_number}</TableCell>
            <TableCell>
              {countryList?.find((option) => option?.country_id === row?.country_id)
                ?.country_name || "N/A"}
            </TableCell>

            <TableCell>{row.did_area_code?.did_area_code_caption || row.did_area_code}</TableCell>
            <TableCell>
              <FormControlLabel
                sx={{ ml: "20px" }}
                id={`cus_2fa${row.did_inventory_number}`}
                name="cus_2fa"
                control={
                  <Switch
                    checked={row.did_inventory_provision_to_sre === "1"}
                    color="primary"
                    sx={{ ml: 2 }}
                    disabled
                  />
                }
              />
            </TableCell>
            <TableCell>
              <SuiDropDownList
                data={[
                  { id: "1", label: "Yes" },
                  { id: "0", label: "No" },
                ]}
                disabled
                select={{
                  key: "id",
                  valueKey: "id",
                  labelName: "label",
                  name: "did_range_holder_status",
                  val: row.did_inventory_is_ported_in,
                  // value={row.did_inventory_porting_fee_amount || 0}
                  fn: (event) => handlePorted(event, index),
                }}
              />
            </TableCell>
            <TableCell>
              <DatePickerUi
                placeholder="Pick Date"
                value={
                  accountNumberType == 0 && status == false
                    ? dayjs()
                    : row.did_inventory_effective_date
                    ? dayjs(row.did_inventory_effective_date)
                    : null
                }
                color="primary"
                disabled={accountNumberType == 1 || accountNumberType == undefined}
                formik={formik}
                getValue={(value) => {
                  handleDate(value, index);
                  setStatus(true);
                }}
              />
            </TableCell>
            <TableCell>
              <NumberInput
                placeholder="Enter Rate"
                setValue={formik.setFieldValue}
                required
                value={row.did_inventory_rate_override || 0}
                onChange={(event) => handleOverride(event, index)}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                freeSolo={false}
                options={
                  Array.isArray(SipDetails) ? SipDetails.map((option) => option.sip_username) : []
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select SIP Server Name" />
                )}
                onChange={(event, newValue) => {
                  const valueToSet =
                    typeof newValue === "string" ? newValue : newValue?.username || "";
                  CheckSingleChange(valueToSet, index);
                }}
                sx={{
                  "& .MuiAutocomplete-input:focus": {
                    border: "none !important",
                  },
                }}
                value={row.did_inventory_sip_server_name}
              />
            </TableCell>
            <TableCell>
              <NumberInput
                placeholder="Enter Fees"
                step={1}
                setValue={formik.setFieldValue}
                max={100}
                disabled={row.did_inventory_is_ported_in == 0}
                required
                value={row.did_inventory_porting_fee_amount || 0}
                onChange={(event) => handlePriceChange(event, index)}
              />
            </TableCell>
          </TableRow>
        )),
      ]
    : null;
  useEffect(() => {
    if (Array.isArray(didNumbers)) {
      const headerRow = (
        <TableRow key="header-row">
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          {didNumbers?.length > 0 && (
            <TableCell>
              <Checkbox
                color="secondary"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setDidNumbers((prevNumbers) =>
                    prevNumbers.map((row) => ({
                      ...row,
                      did_inventory_is_golden_number: checked ? "1" : "0",
                    }))
                  );
                }}
              />
            </TableCell>
          )}
          {didNumbers?.length > 0 && (
            <TableCell>
              <Checkbox
                color="secondary"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setDidNumbers((prevNumbers) =>
                    prevNumbers.map((row) => ({
                      ...row,
                      did_inventory_is_fuse2_number: checked ? "1" : "0",
                    }))
                  );
                }}
              />
            </TableCell>
          )}
          <TableCell colSpan={3}></TableCell>
        </TableRow>
      );
      setRows([
        headerRow,
        ...didNumbers.map((row, index) => (
          <>
            <TableRow key={row.did_inventory_number}>
              <TableCell>{row.did_inventory_number}</TableCell>
              <TableCell>
                <Autocomplete
                  freeSolo={false}
                  options={optionsCountry}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} placeholder="Select Country" />}
                  renderOption={(props, option) => {
                    return <li {...props}>{option.name}</li>;
                  }}
                  onChange={(event, newValue) => {
                    changeCountry(newValue, index);
                  }}
                  sx={{
                    "& .MuiAutocomplete-input:focus": {
                      border: "none !important",
                    },
                  }}
                  value={optionsCountry?.find((option) => option?.id === row?.country_id) || null}
                  // value={row.countries.country_name ? row.countries.country_name : "N/A"}
                  isOptionEqualToValue={(option, value) => option.name === value}
                />
              </TableCell>

              <TableCell>
                {row.did_area_code &&
                checkDidNumbers.find((cd) => cd.did_inventory_number == row.did_inventory_number)
                  ?.did_area_code != null ? (
                  row.did_area_code.did_area_code_caption || row.did_area_code
                ) : (
                  <Autocomplete
                    freeSolo={false}
                    options={[
                      ...(getListData?.geo?.map((option) => option.did_area_code_caption) || []),
                      ...(getListData?.nongeo?.map((option) => option.did_area_code_caption) || []),
                    ]}
                    groupBy={(option) => {
                      const isGeo = getListData?.geo?.some(
                        (item) => item.did_area_code_caption === option
                      );
                      return isGeo ? "Geo" : "Non-Geo";
                    }}
                    onInputChange={getGeoList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select area code"
                        error={!!errors[index]}
                        helperText={errors[index] ? "Area code cannot be blank." : ""}
                      />
                    )}
                    renderOption={(props, option) => {
                      return <li {...props}>{option}</li>;
                    }}
                    onChange={(event, newValue) => {
                      handleAreaCodeChange(row.did_inventory_number, newValue);
                    }}
                    sx={{
                      "& .MuiAutocomplete-input:focus": {
                        border: "none !important",
                      },
                    }}
                    value={row.did_area_code ? row.did_area_code : "N/A"}
                    getOptionLabel={(option) => option}
                  />
                )}
              </TableCell>

              <TableCell>
                {row.did_inventory_range_start == "-" ? "" : row.did_inventory_range_start} -{" "}
                {row.did_inventory_range_end == "-" ? "" : row.did_inventory_range_end}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={row.did_inventory_is_golden_number === "1"}
                  color="secondary"
                  onChange={(e) =>
                    handleCheckboxChanges(
                      row.did_inventory_number,
                      "did_inventory_is_golden_number",
                      e.target.checked
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={row.did_inventory_is_fuse2_number === "1"}
                  color="secondary"
                  onChange={(e) =>
                    handleCheckboxChanges(
                      row.did_inventory_number,
                      "did_inventory_is_fuse2_number",
                      e.target.checked
                    )
                  }
                />
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleDeletes(row.did_inventory_number)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </>
        )),
      ]);
    }
  }, [didNumbers, errors]);

  const handleCheckboxChanges = (id, field, checked) => {
    const updatedTable = didNumbers.map((row) =>
      row.did_inventory_number === id ? { ...row, [field]: checked ? "1" : "0" } : row
    );
    setDidNumbers(updatedTable);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [inventoryNumberToDelete, setInventoryNumberToDelete] = useState(null);

  const [deleteMessage, setDeleteMessage] = useState("");

  const handleDeletes = (inventoryNumber) => {
    const numberToDelete = Number(inventoryNumber);

    const rangeToDelete = didNumbers.find(
      (row) =>
        row.did_inventory_range_start <= numberToDelete &&
        row.did_inventory_range_end >= numberToDelete
    );

    if (rangeToDelete) {
      const { did_inventory_range_start, did_inventory_range_end } = rangeToDelete;
      setDeleteMessage(
        `Are you sure you want to delete this full range ${did_inventory_range_start}-${did_inventory_range_end}?`
      );
    } else {
      setDeleteMessage(`Are you sure you want to delete this ${numberToDelete}?`);
    }

    setInventoryNumberToDelete(inventoryNumber);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    const numberToDelete = Number(inventoryNumberToDelete);

    const rangeToDelete = didNumbers.find(
      (row) =>
        row.did_inventory_range_start <= numberToDelete &&
        row.did_inventory_range_end >= numberToDelete
    );

    let updatedTable;

    if (rangeToDelete && rangeToDelete != undefined) {
      updatedTable = didNumbers.filter(
        (row) =>
          !(
            row.did_inventory_range_start <= numberToDelete &&
            row.did_inventory_range_end >= numberToDelete
          )
      );
      const { did_inventory_range_start, did_inventory_range_end } = rangeToDelete;
      setAllNumbers((prevNumbers) => {
        const newNumbers = prevNumbers.filter((number) => {
          const isInRange =
            number >= did_inventory_range_start && number <= did_inventory_range_end;
          return !isInRange; // Exclude numbers within the range
        });
        return newNumbers;
      });
    } else {
      updatedTable = didNumbers.filter((row) => {
        const isMatching = row.did_inventory_number == numberToDelete;
        return !isMatching;
      });
      setAllNumbers((prevNumbers) => {
        const newNumbers = prevNumbers.filter((number) => {
          const isDeleting = number == numberToDelete;
          return !isDeleting;
        });
        return newNumbers;
      });
    }
    setDidNumbers(updatedTable);
    setOpenDialog(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { parsedNumbers, errorMsg } = parseInput(input);

    if (errorMsg) {
      setError(errorMsg);
      return;
    }

    setError("");
    setLoading(true);

    const updatedNumbers = new Set([...allNumbers, ...parsedNumbers]);
    const resultss = findRanges(Array.from(updatedNumbers));

    const filteredResults = resultss.filter((result) => {
      return !didNumbers?.some(
        (did) =>
          did.did_inventory_number === result.did_inventory_number &&
          did.did_inventory_range_start === result.did_inventory_range_start &&
          did.did_inventory_range_end === result.did_inventory_range_end
      );
    });
    if (resultss.length >= 500) {
      setError("The number of results exceeds the maximum limit of 500.");
      setLoading(false);
      return;
    }

    try {
      const body = { did_inventory_number: filteredResults };
      const response = await AddDids(body).unwrap();
      setDidNumbers([...didNumbers, ...response.data.success]);
      setCheckDidNumbers([...didNumbers, ...response.data.success]);
      suiSnackbar(response.message, "success");
      const duplicates = response.data.duplicate;
      if (duplicates && duplicates.length > 0) {
        duplicates.forEach((error) => {
          suiSnackbar(error, "error");
        });

        // Remove duplicate numbers from the updated set
        duplicates.forEach((duplicate) => {
          const duplicateNumber = duplicate.match(/\d+/)[0]; // Extract the number
          updatedNumbers.delete(Number(duplicateNumber));
        });
      }
      setInput("");
    } catch (error) {
      suiSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }

    // Update the state for all numbers
    setAllNumbers(Array.from(updatedNumbers));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [showDropdown, setShowDropdown] = useState(true);

  const handleCheckboxChange = (event) => {
    setShowDropdown(event.target.checked);
    formik.setFieldValue("checkboxAssign", event.target.checked);
  };

  const SupplierLists = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");

      try {
        const response = await supplierDropList(params).unwrap();
        setSupplierListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );

  const CustomersLists = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchTextCust(value?.target?.value || "");

      try {
        const response = await getCustomerAccountNo(params).unwrap();
        setCustomerListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  const getGeoList = useCallback(
    debounce(async (value) => {
      const params = value ? { did_area_code_code: value?.target?.value } : {};
      setSearchTextGeo(value?.target?.value || "");

      try {
        const response = await GeoCodeList(params).unwrap();
        setGetListData(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (searchTextGeo.length > 0) {
      getGeoList(searchTextGeo);
    } else {
      setGetListData([]);
    }
  }, [searchTextGeo, getGeoList]);
  useEffect(() => {
    if (searchText.length > 0) {
      SupplierLists(searchText);
    } else {
      setSupplierListVal([]);
    }
  }, [searchText, SupplierLists]);
  useEffect(() => {
    if (searchTextCust.length > 0) {
      CustomersLists(searchTextCust);
    } else {
      setCustomerListVal([]);
    }
  }, [searchTextCust, CustomersLists]);

  const RangeList = useCallback(
    debounce(async (value) => {
      setSearchTextRange(value?.target?.value || "");

      try {
        const response = await rangeHolderDropList().unwrap();
        setRangeListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  useEffect(() => {
    if (searchTextRange.length > 0) {
      RangeList();
    } else {
      setRangeListVal([]);
    }
  }, [searchTextRange, RangeList]);
  const supplierData = supplierListVal?.map((dropval) => ({
    id: dropval.did_supplier_id,
    label: dropval.did_supplier_name,
  }));
  const rangeData = rangeListVal?.map((dropval) => ({
    id: dropval.did_range_holder_id,
    label: dropval.did_range_holder_name,
  }));

  const CustomerData = customerListVal?.map((dropval) => ({
    id: dropval.cus_id,
    label: `(${dropval.cus_account_number}) ${dropval.cus_company_name}`,
  }));
  useEffect(() => {
    if (accountNumber != null || accountNumber != undefined) {
      getCustomerNo(accountNumber);
    } else {
      setSipDetails([]);
    }
    if (accountNumberType !== 0) {
      setDidNumbers((prevNumbers) => {
        const numbersArray = Array.isArray(prevNumbers) ? prevNumbers : [];
        return numbersArray.map((row) => ({
          ...row,
          did_inventory_effective_date: null,
        }));
      });
    }
    if (accountNumberType == 0) {
      setDidNumbers((prevNumbers) => {
        const numbersArray = Array.isArray(prevNumbers) ? prevNumbers : [];
        return numbersArray.map((row) => ({
          ...row,
          did_inventory_effective_date: dayjs().format("YYYY-MM-DD"),
        }));
      });
    }
  }, [accountNumber]);
  const updatedData = Array.isArray(didNumbers)
    ? didNumbers.map((item) => {
        const newItem = { ...item };
        delete newItem.did_area_code;
        if (item?.did_area_code?.did_area_code_type) {
          newItem.did_area_code_id = item.did_area_code.did_area_code_id;
          newItem.did_inventory_number_sub_type = item.did_area_code.did_area_code_type;
        }
        if (typeof newItem.did_inventory_is_ported_in == "number") {
          newItem.did_inventory_is_ported_in = String(newItem.did_inventory_is_ported_in);
        }
        if (newItem.did_inventory_range_start === "-" && newItem.did_inventory_range_end === "-") {
          delete newItem.did_inventory_range_start;
          delete newItem.did_inventory_range_end;
        }
        return newItem;
      })
    : [];
  // console.log(didNumbers, "didNumbers");
  useEffect(() => {
    if (activeStep == 0) {
      formik.resetForm();
      setShowDropdown(true);
      const updatedTable = Array.isArray(didNumbers)
        ? didNumbers.map((row) => {
            if (row) {
              return {
                ...row,
                did_inventory_porting_fee_amount: 0,
                did_inventory_sip_server_name: null,
                did_inventory_is_ported_in: 1,
              };
            }
            return row;
          })
        : [];

      setDidNumbers(updatedTable);
    }
    if (activeStep == 1) {
      formik.resetForm();
      setShowDropdown(true);
      const updatedTable = Array.isArray(didNumbers)
        ? didNumbers.map((row) => {
            if (row) {
              return {
                ...row,
                did_inventory_is_ported_in: 1,
              };
            }
            return row;
          })
        : [];

      setDidNumbers(updatedTable);
    }
  }, [activeStep]);

  console.log(didNumbers, "didNUmbers");
  return (
    <Card sx={{ my: 3, p: 2 }}>
      <Grid component={"form"} container spacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <div>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={index} completed={isStepComplete(index)}>
                    <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <DialogContentText>{deleteMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <SuiButton
                    buttonColor="dark"
                    variant="outlined"
                    onClick={handleCloseDialog}
                    size="small"
                    sx={{ px: "1rem" }}
                  >
                    Cancel
                  </SuiButton>
                  <SuiButton
                    onClick={confirmDelete}
                    size="small"
                    sx={{ px: "1rem", maxWidth: "70px", border: "1px solid rgb(54, 79, 179)" }}
                    buttonColor="info"
                    fullWidth
                  >
                    Delete
                  </SuiButton>
                </DialogActions>
              </Dialog>
              {activeStep === steps.length ? (
                <SuiTypography variant="h5">All steps completed!</SuiTypography>
              ) : (
                <div>
                  {activeStep === 0 && (
                    <Grid container spacing={2} mt={2} alignItems="end">
                      <Grid item xs={12} pb={1}>
                        <SuiTypography vhariant="h6" fontWeight="bold">
                          Basic Details
                        </SuiTypography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <SuiTypography fontSize={14} textColor="text2" fontWeight="regular" mb={1}>
                          DID Number{" "}
                          <SuiTypography textColor="error" variant="span">
                            *
                          </SuiTypography>
                        </SuiTypography>
                        <SuiTextArea
                          inputSettungs={{ autoComplete: "off" }}
                          type="textarea"
                          placeholder="Enter Numbers and ranges"
                          id="didnumber"
                          rows={5}
                          name="removal_request_remark"
                          value={input}
                          onChange={handleChange}
                          // onChange={formik.handleChange}
                        />
                        {error && <div style={{ color: "red" }}>{error}</div>}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <SuiButton
                          size="small"
                          type="submit"
                          disabled={input?.length === 0}
                          onClick={handleSubmit}
                          buttonColor="info"
                        >
                          {" Add"}
                        </SuiButton>
                      </Grid>

                      <Grid item xs={12} mt={2}>
                        {loading ? ( // Show loader when loading
                          <CommonSpinner size="100px" color={colors.primary.main} />
                        ) : // <CircularProgress size={24} />
                        didNumbers?.length === 0 ? ( // Check if didNumbers is empty
                          ""
                        ) : (
                          <Box
                            className="didTableSet"
                            sx={{
                              border: `1px solid ${colors.accous.main}`,
                              borderRadius: "20px",
                            }}
                          >
                            <EnhancedTable
                              tableMinWidth={900}
                              tablehead={didTableHeader}
                              rowsData={rows}
                            />
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 1 && (
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2} mt={2} alignItems="end">
                        <Grid item xs={12} pb={1}>
                          <h6 style={{ fontWeight: "bold" }}>Other Configurations</h6>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormLabel htmlFor="did_supplier_status" required>
                            Supplier Name
                          </FormLabel>
                          <Autocomplete
                            sx={{
                              "& .MuiAutocomplete-input:focus": {
                                border: "none !important",
                              },
                            }}
                            options={supplierData}
                            getOptionLabel={(option) => option.label}
                            // onInputChange={SupplierLists}
                            // value={
                            //   supplierData.find(
                            //     (option) => option.id === formik.values.did_supplier_status
                            //   ) || null
                            // }
                            onChange={(event, newValue) => {
                              formik.setFieldValue(
                                "did_supplier_status",
                                newValue ? newValue.id : ""
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Select Supplier Name" />
                            )}
                          />
                          {formik.errors.did_supplier_status &&
                            formik.touched.did_supplier_status && (
                              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                {formik.errors.did_supplier_status}
                              </div>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormLabel htmlFor="range_holder_name" required>
                            Range Holder Name
                          </FormLabel>
                          <Autocomplete
                            options={rangeData}
                            onInputChange={RangeList}
                            getOptionLabel={(option) => option.label}
                            sx={{
                              "& .MuiAutocomplete-input:focus": {
                                border: "none !important",
                              },
                            }}
                            // value={
                            //   rangeData.find(
                            //     (option) => option.id === formik.values.range_holder_name
                            //   ) || null
                            // }
                            onChange={(event, newValue) => {
                              formik.setFieldValue(
                                "range_holder_name",
                                newValue ? newValue.id : ""
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Select Range Holder Name" />
                            )}
                          />
                          {formik.errors.range_holder_name && formik.touched.range_holder_name && (
                            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                              {formik.errors.range_holder_name}
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12} mt={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.checkboxAssign}
                                sx={{
                                  opacity: 0.5,
                                }}
                                onChange={handleCheckboxChange}
                                name="checkboxAssign"
                                color="secondary"
                                disabled
                              />
                            }
                            label="Assign Numbers to customers"
                          />
                        </Grid>

                        {showDropdown && (
                          <Grid item xs={12} md={6} mb={2}>
                            <FormLabel htmlFor="customer_name" required>
                              Company Name
                            </FormLabel>
                            <Autocomplete
                              options={CustomerData}
                              sx={{
                                "& .MuiAutocomplete-input:focus": {
                                  border: "none !important",
                                },
                              }}
                              getOptionLabel={(option) => option.label}
                              onInputChange={CustomersLists}
                              // value={
                              //   CustomerData.find(
                              //     (option) => option.id === formik.values.customer_name
                              //   ) || null
                              // }
                              onChange={(event, newValue) => {
                                formik.setFieldValue("customer_name", newValue ? newValue.id : "");
                                const updatedTable = didNumbers.map((row) => ({
                                  ...row,
                                  did_inventory_sip_server_name: null,
                                }));

                                setDidNumbers(updatedTable);
                                setStatus(false);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select Company Name" />
                              )}
                            />
                            {formik.touched.customer_name &&
                            formik.values.checkboxAssign == true &&
                            formik.values.customer_name == "" ? (
                              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                Company Name cannot be blank.
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                        )}

                        {showDropdown && (
                          <Grid item xs={12} mt={2}>
                            <Box
                              className="didTableSet"
                              sx={{
                                border: `1px solid ${colors.accous.main}`,
                                borderRadius: "20px",
                              }}
                            >
                              <EnhancedTable
                                tableMinWidth={1300}
                                tablehead={customerTableHeader}
                                rowsData={customerRows}
                              />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  )}

                  <div style={{ marginTop: 20 }}>
                    {activeStep === 1 && (
                      <SuiButton
                        buttonColor="primary"
                        sx={{ marginRight: "10px" }}
                        type="button"
                        size="small"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Previous
                      </SuiButton>
                    )}
                    <SuiButton
                      size="small"
                      variant="outlined"
                      buttonColor="dark"
                      onClick={handleBack}
                      component={Link}
                      to={"/did-inventory"}
                    >
                      Cancel
                    </SuiButton>
                    <SuiButton
                      buttonColor="primary"
                      type="button"
                      size="small"
                      disabled={didNumbers?.length == 0}
                      onClick={activeStep === steps.length - 1 ? formik.handleSubmit : handleNext}
                      style={{ marginLeft: 10 }}
                    >
                      {activeStep === steps.length - 1 ? "Add" : "Next"}
                    </SuiButton>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AddDid;
