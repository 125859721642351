import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { discountType } from "./dropdownArrays";

const DiscountTypeDropdown = ({ name, value, setValue, disabled }) => {
  return (
    <SuiDropDownList
      disabled={disabled}
      data={discountType}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Select",
        name: name,
        val: value,
        fn: setValue,
      }}
    />
  );
};

DiscountTypeDropdown.defaultProps = {
  name: "discount_type",
};

DiscountTypeDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default DiscountTypeDropdown;
