import pxToRem from "assets/theme/functions/pxToRem";

const DidIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height={pxToRem(size)} viewBox="0 0 22 22">
      <path
        clipRule="evenodd"
        d="M21.5159 15.9286L19.0962 13.6019C18.5378 12.9505 17.514 12.9505 16.8626 13.6019L14.629 15.8355C12.8607 15.5563 10.9063 14.5326 9.2311 12.8574C7.46283 11.0891 6.4391 9.13472 6.1599 7.36645L8.3935 5.13285C9.04497 4.57445 9.04497 3.55072 8.3935 2.89925L6.06683 0.572585C5.41536 -0.0788824 4.39163 -0.0788824 3.83323 0.572585C2.99563 1.41019 2.25109 2.15472 1.41349 2.89925C-0.913174 5.31899 1.59963 10.9961 5.78763 15.3702C10.2548 19.9305 16.5834 23.0947 19.0962 20.5819C19.8407 19.7443 20.6783 18.9998 21.5159 18.1622C22.0743 17.5107 22.0743 16.5801 21.5159 15.9286ZM19.9338 9.60006C19.9338 9.97232 20.306 10.3446 20.7714 10.3446C21.1436 10.3446 21.5159 9.97232 21.5159 9.60006C21.5159 8.39019 21.2367 7.18032 20.7714 6.15659C20.3991 5.03979 19.6546 4.01605 18.817 3.17845C17.9794 2.34085 16.9556 1.68939 15.9319 1.22405C14.8151 0.758718 13.6052 0.479518 12.3954 0.479518C12.0231 0.479518 11.6508 0.851785 11.6508 1.31712C11.6508 1.68939 12.0231 2.06165 12.3954 2.06165C13.4191 2.06165 14.4428 2.24779 15.2804 2.62005C16.2111 2.99232 17.0487 3.55072 17.7932 4.29525C18.4447 4.94672 19.0031 5.78432 19.3754 6.71499C19.7476 7.64566 19.9338 8.57632 19.9338 9.60006ZM16.9556 9.78619C16.9556 10.2515 17.3279 10.6238 17.7002 10.6238C18.1655 10.6238 18.5378 10.2515 18.5378 9.78619C18.5378 8.94859 18.3516 8.20405 17.9794 7.36645C17.7002 6.62192 17.2348 5.97045 16.6764 5.31899C16.118 4.76059 15.3735 4.29525 14.629 4.01605C13.8844 3.64379 13.0468 3.55072 12.2092 3.55072C11.7439 3.55072 11.4647 3.82992 11.4647 4.29525C11.4647 4.66752 11.7439 5.03979 12.2092 5.03979C12.8607 5.03979 13.4191 5.13285 14.0706 5.41205C14.629 5.59819 15.0943 5.97045 15.5596 6.43579C16.025 6.90112 16.3972 7.45952 16.5834 8.01792C16.8626 8.57632 16.9556 9.13472 16.9556 9.78619Z"
      />
    </svg>
  );
};

export default DidIcon;
