import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import colors from "assets/theme/base/colors";
import SuiInput from "components/SuiInput";
import EyeOffIcon from "Container/Icons/EyeOffIcon";
import EyeIcon from "Container/Icons/EyeIcon";

const { inputColors, white } = colors;

const useStyles = makeStyles(() => ({
  inputBase: {
    borderRadius: "8px",
    border: `0.125rem solid ${inputColors.borderColor.main}`,
    backgroundColor: `${inputColors.borderColor.main}`,
    overflow: "hidden",
    "&:focus-within": {
      border: `0.125rem solid ${inputColors.borderColor.focus}`,
      backgroundColor: `${white.main}`,
      "& input": {
        backgroundColor: `${white.main} !important`,
        "&:-webkit-autofill": {
          WebkitBoxShadow: "inset 0rem 0rem 0rem 1.875rem #fff !important",
        },
      },
    },
  },
}));

function SuiPasswordInput({ attr, getValue, value, onBlur, disabled }) {
  const classes = useStyles();
  const [showPass, setShowPass] = useState(false);

  return useMemo(
    () => (
      <SuiInput
        customClass={classes.inputBase}
        sx={{ flexGrow: 1 }}
        disabled={disabled}
        inputProps={{ style: { border: "none", borderRadius: 0 } }}
        inputSettungs={{ autoComplete: "off", required: false }}
        withIcon={{
          baseClass: "outlined",
          color: "info",
          svg: showPass ? <EyeOffIcon /> : <EyeIcon />,
          direction: "right",
          onClickIcon: () => setShowPass(!showPass),
        }}
        value={value}
        onChange={getValue}
        id={attr.name}
        name={attr.name}
        onBlur={onBlur}
        type={showPass ? "text" : "password"}
        placeholder={attr.placeholder}
      />
    ),
    [value, showPass, getValue]
  );
}
SuiPasswordInput.defaultProps = {
  attr: {
    placeholder: "Password",
    name: "password",
  },
};

SuiPasswordInput.propTypes = {
  attr: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
  }),
  value: PropTypes.string,
  getValue: PropTypes.func,
};

export default SuiPasswordInput;
