import { Avatar, Box, Grid, Skeleton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import boxShadow from "assets/theme/functions/boxShadow";
import { useNavigate } from "react-router-dom";

import EmailIcon from "../../assets/images/email.png";
import CallIcon from "../../assets/images/call.png";
import { useViewPartnerInfoMutation } from "app/features/api/AuthApi";
import { useEffect, useState } from "react";
import { useProductListInfoMutation } from "app/features/api/AuthApi";
import { stringAvatar } from "helper";
import { useProviderGetByIdMutation } from "app/features/api/AuthApi";
import colors from "assets/theme/base/colors";
import SuiButton from "components/SuiButton";
import { getFileExtension } from "helper";

const ViewProvider = () => {
  const id = localStorage.getItem("partner_id");
  const [ViewPartnerInfo, { isLoading: isLoading }] = useViewPartnerInfoMutation();
  const [partnerInfo, setPartnerInfo] = useState();
  const partnerDetails = async (id) => {
    try {
      const response = await ViewPartnerInfo(id).unwrap();
      setPartnerInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    tabInfo(id);

    if (id == "1#") {
      partnerDetails(id);
      GetProductListData();
    }
  }, []);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/providers");
  };

  const [ProductListInfo] = useProductListInfoMutation();

  const [ProviderGetById] = useProviderGetByIdMutation();

  const tabInfo = async (id) => {
    try {
      const response = await ProviderGetById(id).unwrap();
      setPartnerInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDownload = async (path) => {
    try {
      const response = await fetch(path, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const filePath = partnerInfo?.provider_company_logo_img_path;
      const fileExtension = getFileExtension(filePath);
      link.setAttribute("download", `provider_logo.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const GetProductListData = async () => {
    const params = {
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      console.log(response);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  const imgVal =
    partnerInfo?.provider_company_logo_img_path === "" ||
    partnerInfo?.provider_company_logo_img_path === null ||
    partnerInfo?.provider_company_logo_img_path === "null";

  console.log(partnerInfo, "partnerInfo?.partner_name");
  return (
    <>
      <SuiBox>
        <Grid container pt={{ xs: 2, md: 3, xl: 4 }} rowSpacing={{ xs: 3, lg: 5 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Detail Section */}
              <Grid item xs={12} mb={2}>
                <Box
                  mx="auto"
                  borderRadius={5}
                  bgcolor="#fff"
                  boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                >
                  <Box py={{ xs: 3, lg: 3 }} px={{ xs: 4, lg: 4 }}>
                    <Box
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <SuiTypography
                        sx={{
                          fontSize: "18px",
                          color: "#2B2B2B",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <ArrowBackIcon
                          onClick={handleBackClick}
                          sx={{
                            color: "#55DED0",
                            fontSize: "20px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                          }}
                        />
                        View Provider Details
                        {isLoading || !partnerInfo ? (
                          <Skeleton variant="text" width={120} height={50} />
                        ) : (
                          <SuiTypography
                            fontSize="12px"
                            width="auto"
                            px={1.5}
                            py={0.8}
                            borderRadius={2}
                            fontWeight="semiBold"
                            textColor={
                              partnerInfo?.provider_status == "1"
                                ? "text"
                                : partnerInfo?.provider_status == "2"
                                ? "white"
                                : partnerInfo?.provider_status == "3"
                                ? "white"
                                : "white"
                            }
                            bgcolor={
                              partnerInfo?.provider_status == "1"
                                ? `${colors.primary.main}`
                                : partnerInfo?.provider_status == "2"
                                ? `${colors.dark.main}`
                                : partnerInfo?.provider_status == "3"
                                ? `${colors.error.main}`
                                : `${colors.text2.main}`
                            }
                          >
                            {partnerInfo?.provider_status == "1"
                              ? "Active"
                              : partnerInfo?.provider_status == "2"
                              ? "Restricted"
                              : partnerInfo?.provider_status == "3"
                              ? "Blocked"
                              : "Inactive"}
                            {/* {partnerInfo.partner_status} */}
                          </SuiTypography>
                        )}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        gap: "20px",
                      }}
                    >
                      {isLoading ? (
                        <Skeleton variant="circular" width={100} height={100} />
                      ) : imgVal ? (
                        <Avatar
                          sx={{ width: "100px", height: "100px" }}
                          alt="Partner Avatar"
                          {...stringAvatar(partnerInfo?.provider_name, "", "100px", "40px")}
                        />
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              background: "#F2F4FF",
                              borderRadius: "50%",
                              padding: "5px",
                              width: "100px",
                              height: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                partnerInfo?.provider_company_logo_img_path
                              }
                              alt="IMG"
                              width="90"
                              height="90"
                              className="radius-set"
                            />
                          </Box>
                          {isLoading || !partnerInfo ? (
                            <Skeleton variant="text" width={120} height={30} />
                          ) : (
                            partnerInfo?.provider_company_logo_img_path?.length > 1 && (
                              <SuiButton
                                variant="outlined"
                                buttonColor="info"
                                customClass="linkSet"
                                sx={{
                                  margin: "10px auto 0",
                                }}
                                onClick={() =>
                                  handleDownload(
                                    `${process.env.REACT_APP_BASE_URL}${partnerInfo?.provider_company_logo_img_path}`
                                  )
                                }
                              >
                                Download
                              </SuiButton>
                            )
                          )}
                        </Box>
                      )}

                      <Box>
                        <SuiTypography
                          varient="h2"
                          fontWeight="medium"
                          fontSize={{ xs: 18, lg: 22, xl: 22, xxl: 28 }}
                          sx={{
                            marginTop: "15px",
                          }}
                        >
                          {partnerInfo?.provider_name}
                        </SuiTypography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "8px",
                          }}
                        >
                          {isLoading ? (
                            <Skeleton variant="text" width={150} height={20} />
                          ) : (
                            partnerInfo?.provider_email && (
                              <a
                                href={`mailto:${partnerInfo?.provider_email}`}
                                style={{ textDecoration: "none", color: "#2B2B2B" }}
                              >
                                <img src={EmailIcon} alt="Email Icon" width="15" />{" "}
                                {partnerInfo?.provider_email}
                              </a>
                            )
                          )}
                          {isLoading ? (
                            <Skeleton variant="text" width={150} height={20} />
                          ) : (
                            partnerInfo?.provider_phone_number && (
                              <>
                                {" | "}
                                <a
                                  href={`tel:${partnerInfo?.provider_phone_number}`}
                                  style={{ textDecoration: "none", color: "#2B2B2B" }}
                                >
                                  <img src={CallIcon} alt="Phone Icon" width="15" />{" "}
                                  {partnerInfo?.provider_phone_number}
                                </a>
                              </>
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                    sx={{ borderTop: "3px solid #F2F4FF" }}
                  >
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Rate Scheme:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.rate_schemes === "" || partnerInfo?.rate_schemes === null
                          ? "-"
                          : partnerInfo?.rate_schemes?.rate_scheme_name}
                      </SuiTypography>
                    </Box>
                  </Box>
                  {/* address details :: BEGIN */}
                  <Box
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                    sx={{ borderTop: "3px solid #F2F4FF" }}
                  >
                    <SuiTypography
                      varient="h3"
                      mb={1.5}
                      fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 22 }}
                      fontWeight="medium"
                    >
                      Address Details
                    </SuiTypography>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Address Line 1:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_address_line_1 !== ""
                          ? partnerInfo?.provider_address_line_1
                          : "-"}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Address Line 2:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_address_line_2 === "" ||
                        partnerInfo?.provider_address_line_2 === null
                          ? "-"
                          : partnerInfo?.provider_address_line_2}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Address Line 3:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_address_line_3 === "" ||
                        partnerInfo?.provider_address_line_3 === null
                          ? "-"
                          : partnerInfo?.provider_address_line_3}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Country:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.countries && partnerInfo?.countries != ""
                          ? partnerInfo?.countries?.country_name
                          : "-"}
                      </SuiTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Postal Code:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_postal_code !== ""
                          ? partnerInfo?.provider_postal_code
                          : "-"}
                      </SuiTypography>
                    </Box>
                  </Box>
                  {/* address details :: END */}
                  {/* email details :: BEGIN */}
                  <Box
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                    sx={{ borderTop: "3px solid #F2F4FF" }}
                  >
                    <SuiTypography
                      varient="h3"
                      mb={1.5}
                      fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 22 }}
                      fontWeight="medium"
                    >
                      Company Details
                    </SuiTypography>
                    <SuiTypography
                      variant="h3"
                      fontWeight="normal"
                      sx={{ color: "#848484 !important" }}
                      fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                    >
                      Website Link:{" "}
                      {partnerInfo?.provider_website_link ? (
                        <a
                          href={partnerInfo?.provider_website_link}
                          target="_blank"
                          rel="noopener noreferrer external"
                        >
                          <u>{partnerInfo?.provider_website_link}</u>
                        </a>
                      ) : (
                        "-"
                      )}
                    </SuiTypography>
                    <Box
                      mt={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="medium"
                        sx={{ color: "#848484 !important" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        VAT Number:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_vat_number == null ||
                        partnerInfo?.provider_vat_number == ""
                          ? "-"
                          : partnerInfo?.provider_vat_number}
                      </SuiTypography>
                    </Box>
                  </Box>
                  <Box
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                    sx={{ borderTop: "3px solid #F2F4FF" }}
                  >
                    <SuiTypography
                      varient="h3"
                      mb={1.5}
                      fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 22 }}
                      fontWeight="medium"
                    >
                      Bank Details
                    </SuiTypography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-bank"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Bank Name:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_bank_name == null ||
                        partnerInfo?.provider_bank_name == ""
                          ? "-"
                          : partnerInfo?.provider_bank_name}
                      </SuiTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-bank"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Account Holder Name:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_bank_account_holder_name == null ||
                        partnerInfo?.provider_bank_account_holder_name == ""
                          ? "-"
                          : partnerInfo?.provider_bank_account_holder_name}
                      </SuiTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-bank"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Account Number:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_bank_account_number == null ||
                        partnerInfo?.provider_bank_account_number == ""
                          ? "-"
                          : partnerInfo?.provider_bank_account_number}
                      </SuiTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-bank"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Bank Sort Code:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.provider_bank_sort_code == null ||
                        partnerInfo?.provider_bank_sort_code == ""
                          ? "-"
                          : partnerInfo?.provider_bank_sort_code}
                      </SuiTypography>
                    </Box>
                  </Box>
                  {/* email details :: END */}
                  {/* Product Price Details :: BEGIN */}

                  {/* Product Price Details :: END */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

export default ViewProvider;
