import { apiController } from "layouts/customer/CustomerTable";
import { baseAPIService } from ".";

const AuthApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    loginWithPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/login",
          method: "POST",
          body: data,
        };
      },
    }),
    loginWithOtp: build.mutation({
      query: (data) => {
        return {
          url: "admin/2fa/login/verify",
          method: "POST",
          body: data,
        };
      },
    }),
    downloadpaymentInvoiceCDRReport: build.mutation({
      query: (body) => {
        return {
          url: `admin/customer/invoice/cdr/download/${body.id}/${body.No}`,
          method: "GET",
        };
      },
    }),
    paymentInvoiceReport: build.mutation({
      query: (values) => {
        return {
          url: `admin/customer/invoice/${values.id}`,
          method: "GET",
          params: values.params,
        };
      },
    }),
    downloadpaymentInvoicePDFReport: build.mutation({
      query: (body) => {
        return {
          url: `admin/customer/invoice/download/${body.id}/${body.No}`,
          method: "GET",
        };
      },
    }),
    forgotPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/forgotpassword",
          method: "POST",
          body: data,
        };
      },
    }),
    refreshTokens: build.mutation({
      query: (data) => {
        return {
          url: "admin/refreshtoken",
          method: "POST",
          body: data,
        };
      },
    }),
    verifyOtp: build.mutation({
      query: ({ data, authToken }) => {
        return {
          url: "admin/verifyOtp",
          method: "POST",
          headers: {
            authtoken: authToken,
          },
          body: data,
        };
      },
    }),
    resetPassword: build.mutation({
      query: (data) => {
        return {
          url: "admin/resetpassword",
          method: "POST",
          body: data,
        };
      },
    }),
    logout: build.mutation({
      query: (headers) => {
        return {
          url: "admin/logout",
          method: "POST",
          headers: headers,
          // body: null,
        };
      },
    }),
    getThreeCX: build.mutation({
      query: (params) => {
        return {
          url: "admin/three-cx",
          method: "GET",
          params: params,
        };
      },
    }),
    getAdminInfo: build.mutation({
      query: () => {
        return {
          url: "admin",
          method: "GET",
        };
      },
    }),
    getCustomerInfo: build.mutation({
      query: (params) => {
        return {
          url: "admin/customer",
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    getLienceValue: build.mutation({
      query: () => {
        return {
          url: "admin/three-cx/licence_calls",
          method: "GET",
        };
      },
    }),
    getThreeCXOrder: build.mutation({
      query: (params) => {
        return {
          url: `admin/customer/three-cx/${params.cus_id}`,
          method: "GET",
          params: params,
          signal: apiController.signal,
        };
      },
    }),
    downloadData: build.mutation({
      query: (params) => {
        return {
          url: "admin/customer/download",
          method: "GET",
          params: params,
        };
      },
    }),
    updateCustomer: build.mutation({
      query: (data) => {
        return {
          url: "admin/customer",
          method: "PUT",
          body: data,
        };
      },
    }),
    GetRole: build.mutation({
      query: (params) => {
        return {
          url: "admin/roles",
          method: "GET",
          params: params,
        };
      },
    }),
    GetRoleModule: build.mutation({
      query: () => {
        return {
          url: "admin/roles/modules",
          method: "GET",
        };
      },
    }),
    deleteRole: build.mutation({
      query: (id) => {
        return {
          url: `admin/roles/${id}`,
          method: "DELETE",
        };
      },
    }),
    addRole: build.mutation({
      query: (data) => {
        return {
          url: `admin/roles`,
          method: "POST",
          body: data,
        };
      },
    }),
    UpdateRole: build.mutation({
      query: (data) => {
        return {
          url: `admin/roles/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    adminRoleByID: build.mutation({
      query: (id) => {
        return {
          url: `admin/roles/${id}`,
          method: "GET",
        };
      },
    }),
    GetRoleModulePermission: build.mutation({
      query: () => {
        return {
          url: "admin/roles/permissions",
          method: "GET",
        };
      },
    }),
    Get2fa: build.mutation({
      query: () => {
        return {
          url: "admin/2fa",
          method: "GET",
        };
      },
    }),
    Post2fa: build.mutation({
      query: () => {
        return {
          url: "admin/2fa",
          method: "POST",
        };
      },
    }),
    Verify2fa: build.mutation({
      query: (data) => {
        return {
          url: `admin/2fa/verify`,
          method: "POST",
          body: data,
        };
      },
    }),
    Reset2fa: build.mutation({
      query: () => {
        return {
          url: "admin/2fa/reset",
          method: "PUT",
        };
      },
    }),
    portInNumbers: build.mutation({
      query: (values) => {
        return {
          url: `admin/customer/number-port-in/${values.id}`,
          method: "GET",
          params: values.params,
        };
      },
    }),
    pricing: build.mutation({
      query: () => {
        return {
          url: "admin/pricing",
          method: "GET",
        };
      },
    }),
    updatePricing: build.mutation({
      query: (data) => {
        return {
          url: "admin/pricing",
          method: "PATCH",
          body: data,
        };
      },
    }),
    deleteNumberPort: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/number-port-in/${id}`,
          method: "DELETE",
        };
      },
    }),
    customerGetById: build.mutation({
      query: (cus_id) => {
        return {
          url: `admin/customer/${cus_id}`,
          method: "GET",
        };
      },
    }),
    getAlldid: build.mutation({
      query: (params) => {
        return {
          url: "admin/did/purchased",
          method: "GET",
          params: params,
        };
      },
    }),
    getPurchasedDidExport: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/purchased/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    getAlldidById: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/purchased/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    AllocateSIPDid: build.mutation({
      query: (body) => {
        return {
          url: "admin/did/purchased/allocate-sip-trunk",
          method: "PATCH",
          body: body,
        };
      },
    }),
    getInventoryDidList: build.mutation({
      query: (params) => {
        return {
          url: "admin/did/inventory",
          method: "GET",
          params: params,
        };
      },
    }),
    deleteInventoryDid: build.mutation({
      query: (id) => {
        return {
          url: `admin/did/inventory/${id}`,
          method: "DELETE",
        };
      },
    }),
    inventoryExport: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/inventory/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    getDidCountry: build.mutation({
      query: () => {
        return {
          url: "admin/did/filter/countries",
          method: "GET",
        };
      },
    }),
    getCustomerAccountNo: build.mutation({
      query: () => {
        return {
          url: "admin/did/filter/customers",
          method: "GET",
        };
      },
    }),
    downloadDIDnumbers: build.mutation({
      query: (params) => {
        return {
          url: "admin/did/download",
          method: "GET",
          params: params,
        };
      },
    }),
    getAssignDID: build.mutation({
      query: (params) => {
        return {
          url: "admin/assign-did",
          method: "GET",
          params: params,
        };
      },
    }),
    getAssignSipTrunk: build.mutation({
      query: (id) => {
        return {
          url: `admin/did/inventory/siptrunk/${id}`,
          method: "GET",
        };
      },
    }),
    AssignDID: build.mutation({
      query: (data) => {
        return {
          url: `admin/assign-did`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteAssignDID: build.mutation({
      query: (id) => {
        return {
          url: `admin/assign-did/${id}`,
          method: "DELETE",
        };
      },
    }),
    assignDIDCSV: build.mutation({
      query: () => {
        return {
          url: "admin/assign-did/sample",
          method: "GET",
        };
      },
    }),
    importDIDCSV: build.mutation({
      query: (data) => {
        return {
          url: "admin/assign-did/import",
          method: "POST",
          body: data,
        };
      },
    }),
    statusUpdate: build.mutation({
      query: (data) => {
        return {
          url: `admin/assign-did/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    getRequestApproval: build.mutation({
      query: (params) => {
        return {
          url: `admin/did-request-approval`,
          method: "GET",
          params: params,
        };
      },
    }),
    updateRequestApproval: build.mutation({
      query: (data, params) => {
        return {
          url: `admin/did-request-approval/${data.id}`,
          method: "PATCH",
          body: data,
          params: params,
        };
      },
    }),
    termsConditions: build.mutation({
      query: (params) => {
        return {
          url: `admin/pages`,
          method: "GET",
          params: params,
        };
      },
    }),
    postPages: build.mutation({
      query: (data) => {
        return {
          url: `admin/pages/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    getPagesById: build.mutation({
      query: (id) => {
        return {
          url: `admin/pages/${id}`,
          method: "GET",
        };
      },
    }),
    deletePages: build.mutation({
      query: (id) => {
        return {
          url: `admin/pages/${id}`,
          method: "DELETE",
        };
      },
    }),
    changePassword: build.mutation({
      query: (data) => {
        return {
          url: `admin/changepassword`,
          method: "POST",
          body: data,
        };
      },
    }),
    resetPasswordSipTrunk: build.mutation({
      query: (data) => {
        return {
          url: `admin/customer/sip-trunk/password`,
          method: "PUT",
          body: data,
        };
      },
    }),
    dayOfPlan: build.mutation({
      query: (params) => {
        return {
          url: `admin/time_of_day/category`,
          method: "GET",
          params: params,
        };
      },
    }),
    dayOfPlanCategory: build.mutation({
      query: (id) => {
        return {
          url: `admin/time_of_day/category/${id}`,
          method: "GET",
        };
      },
    }),
    callRecordReport: build.mutation({
      query: (params) => {
        return {
          url: `admin/customer/cdrreport`,
          method: "GET",
          params: params,
        };
      },
    }),
    activeSubscription: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/active_subscription/${id}`,
          method: "GET",
        };
      },
    }),
    createCustomer: build.mutation({
      query: (data) => {
        return {
          url: `admin/customer`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSpendLimitData: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/spend-limit/${id}`,
          method: "GET",
        };
      },
    }),
    SpendLimitData: build.mutation({
      query: (data) => {
        return {
          url: `admin/customer/spend-limit/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    cronDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/report/cron`,
          method: "GET",
          params: params,
        };
      },
    }),
    cronExport: build.mutation({
      query: () => {
        return {
          url: `admin/report/cron/export`,
          method: "GET",
        };
      },
    }),
    getRolesDropdown: build.mutation({
      query: () => {
        return {
          url: `admin/roles/active`,
          method: "GET",
        };
      },
    }),
    getSupplierDropdown: build.mutation({
      query: () => {
        return {
          url: `admin/did/inventory/supplier`,
          method: "GET",
        };
      },
    }),
    createAdmins: build.mutation({
      query: (data) => {
        return {
          url: `admin`,
          method: "POST",
          body: data,
        };
      },
    }),
    GetAdminByID: build.mutation({
      query: (id) => {
        return {
          url: `admin/${id}`,
          method: "GET",
        };
      },
    }),
    UpdateAdmins: build.mutation({
      query: (data, params) => {
        return {
          url: `admin/${data.id}`,
          method: "PUT",
          body: data,
          params: params,
        };
      },
    }),
    GetAllAdmin: build.mutation({
      query: (params) => {
        return {
          url: `admin/all`,
          method: "GET",
          params: params,
        };
      },
    }),
    downloadAdminDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    deleteAdmin: build.mutation({
      query: (id) => {
        return {
          url: `admin/${id}`,
          method: "DELETE",
        };
      },
    }),
    importAdmin: build.mutation({
      query: () => {
        return {
          url: `admin/sample`,
          method: "GET",
        };
      },
    }),
    importFormAdmin: build.mutation({
      query: (data) => {
        return {
          url: `admin/import`,
          method: "POST",
          body: data,
        };
      },
    }),
    ThreeCX_request: build.mutation({
      query: (params) => {
        return {
          url: `admin/three-cx/requests`,
          method: "GET",
          params: params,
        };
      },
    }),
    getCustomerDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/three-cx/customers`,
          method: "GET",
          params: params,
        };
      },
    }),

    updateCustomerStatusVal: build.mutation({
      query: (data) => {
        return {
          url: `admin/three-cx/updatestatus/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    viewCustomerDetailsByID: build.mutation({
      query: (params) => {
        return {
          url: `admin/three-cx/customers/details/${params}`,
          method: "GET",
        };
      },
    }),
    requestCreate: build.mutation({
      query: (data) => {
        return {
          url: `admin/three-cx/request`,
          method: "POST",
          body: data,
        };
      },
    }),
    getAllAdmins: build.mutation({
      query: (params) => {
        return {
          url: `admin/three-cx/admins`,
          method: "GET",
          params: params,
        };
      },
    }),
    NotificationUnread: build.mutation({
      query: () => {
        return {
          url: `admin/notification/unread`,
          method: "GET",
        };
      },
    }),
    NotificationCheck: build.mutation({
      query: (data) => {
        return {
          url: `admin/notification`,
          method: "PUT",
          body: data,
        };
      },
    }),
    AllNotification: build.mutation({
      query: (params) => {
        return {
          url: `admin/notification`,
          method: "GET",
          params: params,
        };
      },
    }),
    getUnreadCount: build.mutation({
      query: () => {
        return {
          url: `admin/notification/unread/count`,
          method: "GET",
        };
      },
    }),
    MandateUpdate: build.mutation({
      query: (params) => {
        return {
          url: `admin/customer/mandate/${params}`,
          method: "PATCH",
        };
      },
    }),
    LastOrder: build.mutation({
      query: () => {
        return {
          url: `admin/dashboard/recent/orders`,
          method: "GET",
        };
      },
    }),
    LastCustomers: build.mutation({
      query: () => {
        return {
          url: `admin/dashboard/recent/customers`,
          method: "GET",
        };
      },
    }),
    LastDids: build.mutation({
      query: () => {
        return {
          url: `admin/dashboard/recent/dids`,
          method: "GET",
        };
      },
    }),
    three3cxCounts: build.mutation({
      query: () => {
        return {
          url: `admin/dashboard/three-cx/count`,
          method: "GET",
        };
      },
    }),
    subscriptionList: build.mutation({
      query: (params) => {
        return {
          url: `admin/subscription`,
          method: "GET",
          params: params,
        };
      },
    }),
    subscriptionCounts: build.mutation({
      query: () => {
        return {
          url: `admin/dashboard/subscription/count`,
          method: "GET",
        };
      },
    }),
    subscriptionExport: build.mutation({
      query: (params) => {
        return {
          url: `admin/subscription/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    CustRegistrationCounts: build.mutation({
      query: (params) => {
        return {
          url: `admin/dashboard/customer/registration/graph`,
          method: "GET",
          params: params,
        };
      },
    }),
    CustStatusCounts: build.mutation({
      query: (params) => {
        return {
          url: `admin/dashboard/customer/status/overview`,
          method: "GET",
          params: params,
        };
      },
    }),
    SubGetById: build.mutation({
      query: (params) => {
        return {
          url: `admin/subscription/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    ProductDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/subscription/products/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    RequestDetails: build.mutation({
      query: (params) => {
        return {
          url: `admin/removal-request/`,
          method: "GET",
          params: params,
        };
      },
    }),
    ViewRequestDetails: build.mutation({
      query: (id) => {
        return {
          url: `admin/removal-request/${id}`,
          method: "GET",
        };
      },
    }),
    SupplierList: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/supplier`,
          method: "GET",
          params: params,
        };
      },
    }),
    AddSupplier: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/supplier`,
          method: "POST",
          body: data,
        };
      },
    }),
    DeleteSupplier: build.mutation({
      query: (id) => {
        return {
          url: `admin/did/master/supplier/${id}`,
          method: "DELETE",
        };
      },
    }),
    GetSupplierById: build.mutation({
      query: (id) => {
        return {
          url: `admin/did/master/supplier/${id}`,
          method: "GET",
        };
      },
    }),
    UpdateSupplier: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/supplier/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    RangeholderList: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/rangeholder`,
          method: "GET",
          params: params,
        };
      },
    }),
    AddRangeholder: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/rangeholder`,
          method: "POST",
          body: data,
        };
      },
    }),
    DeleteRangeholder: build.mutation({
      query: (id) => {
        return {
          url: `admin/did/master/rangeholder/${id}`,
          method: "DELETE",
        };
      },
    }),
    GetRangeholderById: build.mutation({
      query: (id) => {
        return {
          url: `admin/did/master/rangeholder/${id}`,
          method: "GET",
        };
      },
    }),
    UpdateRangeholder: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/rangeholder/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    RemarksRequestDetails: build.mutation({
      query: ({ params, id }) => {
        return {
          url: `admin/removal-request/${id}`,
          method: "PUT",
          body: params,
        };
      },
    }),
    DeleteProduct: build.mutation({
      query: ({ cancelType, productId }) => {
        return {
          url: `admin/subscription/products/${productId}`,
          method: "DELETE",
          params: { cancelType },
        };
      },
    }),
    cancelsub: build.mutation({
      query: ({ cancelType, productId }) => {
        return {
          url: `admin/subscription/${productId}`,
          method: "DELETE",
          params: { cancelType },
        };
      },
    }),
    DownloadInvoice: build.mutation({
      query: (params) => {
        return {
          url: `admin/subscription/invoices/download/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    GetAllGlobal: build.mutation({
      query: () => {
        return {
          url: `admin/global-config`,
          method: "GET",
        };
      },
    }),
    GetByIdGlobal: build.mutation({
      query: (params) => {
        return {
          url: `admin/global-config/${params}`,
          method: "GET",
        };
      },
    }),
    PostGlobal: build.mutation({
      query: ({ data, id }) => {
        return {
          url: `admin/global-config/${id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    DeleteDID: build.mutation({
      query: (params) => {
        return {
          url: `admin/customer/did/${params}`,
          method: "DELETE",
          params: params,
        };
      },
    }),
    DeleteSips: build.mutation({
      query: (params) => {
        return {
          url: `admin/customer/sip-trunk/${params}`,
          method: "DELETE",
          params: params,
        };
      },
    }),
    GetAllCountry: build.mutation({
      query: () => {
        return {
          url: `customer/countries`,
          method: "GET",
        };
      },
    }),
    getAllGeoArea: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/geoareacode`,
          method: "GET",
          params: params,
        };
      },
    }),
    sampleGeoArea: build.mutation({
      query: () => {
        return {
          url: `admin/did/master/geoareacode/download`,
          method: "GET",
        };
      },
    }),
    getByGeoArea: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/geoareacode/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    DeleteGeoArea: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/geoareacode/${params}`,
          method: "DELETE",
          params: params,
        };
      },
    }),
    AddGeoArea: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/geoareacode`,
          method: "POST",
          body: data,
        };
      },
    }),
    UpdateGeoArea: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/geoareacode/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),

    ImportGeoArea: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/geoareacode/import`,
          method: "POST",
          body: data,
        };
      },
    }),
    getAllNonGeoArea: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/nongeoareacode`,
          method: "GET",
          params: params,
        };
      },
    }),
    sampleNonGeoArea: build.mutation({
      query: () => {
        return {
          url: `admin/did/master/nongeoareacode/download`,
          method: "GET",
        };
      },
    }),
    getByNonGeoArea: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/nongeoareacode/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),

    GeoCodeList: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/inventory/areacodes`,
          method: "GET",
          params: params,
        };
      },
    }),
    DeleteNonGeoArea: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/master/nongeoareacode/${params}`,
          method: "DELETE",
          params: params,
        };
      },
    }),
    AddNonGeoArea: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/nongeoareacode`,
          method: "POST",
          body: data,
        };
      },
    }),
    UpdateNonGeoArea: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/nongeoareacode/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    ImportNonGeoArea: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/master/nongeoareacode/import`,
          method: "POST",
          body: data,
        };
      },
    }),
    AddDids: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/inventory/areacodes`,
          method: "POST",
          body: data,
        };
      },
    }),
    supplierDropList: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/inventory/supplier`,
          method: "GET",
          params: params,
        };
      },
    }),
    rangeHolderDropList: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/inventory/rangeholder`,
          method: "GET",
          params: params,
        };
      },
    }),
    AddDidsNumbers: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/inventory`,
          method: "POST",
          body: data,
        };
      },
    }),
    getByIdDIds: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/inventory/${data}`,
          method: "GET",
          params: data,
        };
      },
    }),
    AddIdDIds: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/inventory/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    exportMark: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/purchased/exported`,
          method: "PUT",
          body: data,
        };
      },
    }),
    ExportPurchasedDid: build.mutation({
      query: (params) => {
        return {
          url: `admin/did/purchased/download?`,
          method: "GET",
          params: params,
        };
      },
    }),
    AssignDIDs: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/inventory/assign`,
          method: "PUT",
          body: data,
        };
      },
    }),
    DIDinventoryKIPs: build.mutation({
      query: () => {
        return {
          url: `admin/dashboard/did-inventory/status`,
          method: "GET",
        };
      },
    }),
    PurchasedUpdate: build.mutation({
      query: (data) => {
        return {
          url: `admin/did/purchased/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    ProductCategoryList: build.mutation({
      query: (params) => {
        return {
          url: `admin/product/category`,
          method: "GET",
          params: params,
        };
      },
    }),
    AddProductCategory: build.mutation({
      query: (data) => {
        return {
          url: `admin/product/category`,
          method: "POST",
          body: data,
        };
      },
    }),
    UpdateProductCategory: build.mutation({
      query: (data) => {
        return {
          url: `admin/product/category/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    GetProductCategoryById: build.mutation({
      query: (id) => {
        return {
          url: `admin/product/category/${id}`,
          method: "GET",
        };
      },
    }),
    deleteProductCategory: build.mutation({
      query: (id) => {
        return {
          url: `admin/product/category/${id}`,
          method: "DELETE",
        };
      },
    }),
    ProductMasterList: build.mutation({
      query: (params) => {
        return {
          url: `admin/product/master`,
          method: "GET",
          params: params,
        };
      },
    }),
    AddProductMaster: build.mutation({
      query: (data) => {
        return {
          url: `admin/product/master`,
          method: "POST",
          body: data,
        };
      },
    }),
    UpdateProductMaster: build.mutation({
      query: (data) => {
        return {
          url: `admin/product/master/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    GetProductMasterById: build.mutation({
      query: (params) => {
        return {
          url: `admin/product/master/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    ActiveCategoryList: build.mutation({
      query: (params) => {
        return {
          url: `admin/product/category/active`,
          method: "GET",
          params: params,
        };
      },
    }),
    GetPartner: build.mutation({
      query: (params) => {
        return {
          url: `admin/partner`,
          method: "GET",
          params: params,
        };
      },
    }),
    AddPartner: build.mutation({
      query: (data) => {
        return {
          url: `admin/partner`,
          method: "POST",
          body: data,
        };
      },
    }),
    EditPartner: build.mutation({
      query: (data) => {
        return {
          url: `admin/partner/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    AddProvider: build.mutation({
      query: (data) => {
        return {
          url: `admin/provider`,
          method: "POST",
          body: data,
        };
      },
    }),
    EditProvider: build.mutation({
      query: (data) => {
        return {
          url: `admin/provider/${data.id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    ProviderGetById: build.mutation({
      query: (data) => {
        return {
          url: `admin/provider/${data}`,
          method: "GET",
          params: data,
        };
      },
    }),
    ProductListInfo: build.mutation({
      query: (params) => {
        return {
          url: `admin/partner/pricing`,
          method: "GET",
          params: params,
        };
      },
    }),
    ViewPartnerInfo: build.mutation({
      query: (params) => {
        return {
          url: `admin/partner/${params}`,
          method: "GET",
          params: params,
        };
      },
    }),
    ActivePartnerList: build.mutation({
      query: (params) => {
        return {
          url: `admin/partner/active`,
          method: "GET",
          params: params,
        };
      },
    }),
    GetPartnerDetails: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/partner/${id}`,
          method: "GET",
        };
      },
    }),
    UpdateCustomerPartnerDeatails: build.mutation({
      query: ({ body, id }) => {
        return {
          url: `admin/customer/partner/${id}`,
          method: "PATCH",
          body: body,
        };
      },
    }),
    PartnerDataExport: build.mutation({
      query: (params) => {
        return {
          url: `admin/partner/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    AssignCustomerPartner: build.mutation({
      query: (param) => {
        return {
          url: `admin/partner/customers/${param.id}`,
          method: "GET",
          params: param,
        };
      },
    }),
    DeletePartner: build.mutation({
      query: (id) => {
        return {
          url: `admin/partner/${id}`,
          method: "DELETE",
        };
      },
    }),
    AssignPartner: build.mutation({
      query: (data) => {
        return {
          url: `admin/partner/assign/${data.deleteByID}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    SyncPrice: build.mutation({
      query: () => {
        return {
          url: `admin/product/master/sync_default_price`,
          method: "PUT",
        };
      },
    }),
    getCustomerId: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/get-by-account-number/${id}`,
          method: "GET",
        };
      },
    }),
    GetRateScheme: build.mutation({
      query: (params) => {
        return {
          url: `admin/product/rate-scheme`,
          method: "GET",
          params: params,
        };
      },
    }),
    SyncRateScheme: build.mutation({
      query: () => {
        return {
          url: `admin/product/rate-scheme/sync`,
          method: "POST",
        };
      },
    }),
    ProviderList: build.mutation({
      query: (params) => {
        return {
          url: `admin/provider`,
          method: "GET",
          params: params,
        };
      },
    }),
    downloadProvider: build.mutation({
      query: (params) => {
        return {
          url: `admin/provider/download`,
          method: "GET",
          params: params,
        };
      },
    }),
    getActiveProviders: build.mutation({
      query: (params) => {
        return {
          url: `admin/product/rate-scheme/active`,
          method: "GET",
          params: params,
        };
      },
    }),
    UpdateRateScheme: build.mutation({
      query: (data) => {
        return {
          url: `admin/product/rate-scheme/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    GetActiveRateScheme: build.mutation({
      query: (data) => {
        return {
          url: `admin/product/rate-scheme/active`,
          method: "GET",
          params: data,
        };
      },
    }),
    GetActiveProvider: build.mutation({
      query: () => {
        return {
          url: `admin/provider/active`,
          method: "GET",
        };
      },
    }),
    GetGlobalProvider: build.mutation({
      query: () => {
        return {
          url: `admin/global-config/provider_settings`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useSyncPriceMutation,
  useGetGlobalProviderMutation,
  useGetActiveRateSchemeMutation,
  useProviderGetByIdMutation,
  useGetActiveProvidersMutation,
  useGetActiveProviderMutation,
  useAddProviderMutation,
  useEditProviderMutation,
  useDownloadProviderMutation,
  useProviderListMutation,
  useAssignCustomerPartnerMutation,
  useGetRateSchemeMutation,
  useSyncRateSchemeMutation,
  useUpdateRateSchemeMutation,
  useEditPartnerMutation,
  useGetCustomerIdMutation,
  useViewPartnerInfoMutation,
  useDeletePartnerMutation,
  useAssignPartnerMutation,
  useProductListInfoMutation,
  usePartnerDataExportMutation,
  useGetPartnerMutation,
  useAddPartnerMutation,
  useActiveCategoryListMutation,
  useActivePartnerListMutation,
  useUpdateCustomerPartnerDeatailsMutation,
  useGetPartnerDetailsMutation,
  useProductCategoryListMutation,
  useAddProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useGetProductCategoryByIdMutation,
  useDeleteProductCategoryMutation,
  useProductMasterListMutation,
  useAddProductMasterMutation,
  useUpdateProductMasterMutation,
  useGetProductMasterByIdMutation,
  usePurchasedUpdateMutation,
  useAssignDIDsMutation,
  useExportMarkMutation,
  useDIDinventoryKIPsMutation,
  useAddIdDIdsMutation,
  useGetByIdDIdsMutation,
  useAddDidsNumbersMutation,
  useSupplierDropListMutation,
  useRangeHolderDropListMutation,
  useGeoCodeListMutation,
  useAddDidsMutation,
  useAddNonGeoAreaMutation,
  useDeleteNonGeoAreaMutation,
  useGetAllNonGeoAreaMutation,
  useGetByNonGeoAreaMutation,
  useImportNonGeoAreaMutation,
  useSampleNonGeoAreaMutation,
  useUpdateNonGeoAreaMutation,
  useImportGeoAreaMutation,
  useUpdateGeoAreaMutation,
  useAddGeoAreaMutation,
  useDeleteGeoAreaMutation,
  useGetByGeoAreaMutation,
  useSampleGeoAreaMutation,
  useGetAllGeoAreaMutation,
  useDeleteDIDMutation,
  useGetAllCountryMutation,
  useDeleteSipsMutation,
  usePostGlobalMutation,
  useGetByIdGlobalMutation,
  useGetAlldidByIdMutation,
  useGetAllGlobalMutation,
  useSubGetByIdMutation,
  useCancelsubMutation,
  useDeleteProductMutation,
  useDownloadInvoiceMutation,
  useProductDetailsMutation,
  useLastCustomersMutation,
  useLastDidsMutation,
  useLastOrderMutation,
  useThree3cxCountsMutation,
  useSubscriptionCountsMutation,
  useCustRegistrationCountsMutation,
  useCustStatusCountsMutation,
  useImportAdminMutation,
  useGetUnreadCountMutation,
  useSubscriptionListMutation,
  useSubscriptionExportMutation,
  useNotificationUnreadMutation,
  useAllNotificationMutation,
  useNotificationCheckMutation,
  useRequestCreateMutation,
  useMandateUpdateMutation,
  useUpdateCustomerStatusValMutation,
  useViewCustomerDetailsByIDMutation,
  useGetCustomerDetailsMutation,
  useThreeCX_requestMutation,
  useImportFormAdminMutation,
  useDeleteAdminMutation,
  useDownloadAdminDetailsMutation,
  useGetAdminByIDMutation,
  useGetAllAdminMutation,
  useCreateAdminsMutation,
  useUpdateAdminsMutation,
  useGetRolesDropdownMutation,
  useGetSpendLimitDataMutation,
  useCronExportMutation,
  useSpendLimitDataMutation,
  useLoginWithPasswordMutation,
  useCallRecordReportMutation,
  useForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useCreateCustomerMutation,
  useLogoutMutation,
  useDayOfPlanCategoryMutation,
  useGetAdminInfoMutation,
  useRefreshTokensMutation,
  useGetCustomerInfoMutation,
  useCronDetailsMutation,
  useDownloadDataMutation,
  useCustomerGetByIdMutation,
  useUpdateCustomerMutation,
  usePortInNumbersMutation,
  usePricingMutation,
  useUpdatePricingMutation,
  useDeleteNumberPortMutation,
  useGetAlldidMutation,
  useGetDidCountryMutation,
  useGetCustomerAccountNoMutation,
  useDownloadDIDnumbersMutation,
  useGetAssignDIDMutation,
  useGetAssignSipTrunkMutation,
  useResetPasswordSipTrunkMutation,
  useAssignDIDMutation,
  useDeleteAssignDIDMutation,
  useAssignDIDCSVMutation,
  useImportDIDCSVMutation,
  useStatusUpdateMutation,
  useGetRequestApprovalMutation,
  useUpdateRequestApprovalMutation,
  useTermsConditionsMutation,
  usePostPagesMutation,
  useGetPagesByIdMutation,
  useDeletePagesMutation,
  useChangePasswordMutation,
  useDayOfPlanMutation,
  useActiveSubscriptionMutation,
  useDownloadpaymentInvoicePDFReportMutation,
  useDownloadpaymentInvoiceCDRReportMutation,
  usePaymentInvoiceReportMutation,
  useGetRoleMutation,
  useGetRoleModuleMutation,
  useGetRoleModulePermissionMutation,
  useAddRoleMutation,
  useDeleteRoleMutation,
  useAdminRoleByIDMutation,
  useUpdateRoleMutation,
  useGet2faMutation,
  usePost2faMutation,
  useVerify2faMutation,
  useReset2faMutation,
  useLoginWithOtpMutation,
  useGetThreeCXMutation,
  useGetLienceValueMutation,
  useGetThreeCXOrderMutation,
  useGetAllAdminsMutation,
  useRequestDetailsMutation,
  useViewRequestDetailsMutation,
  useRemarksRequestDetailsMutation,
  useSupplierListMutation,
  useAddSupplierMutation,
  useDeleteSupplierMutation,
  useGetSupplierByIdMutation,
  useUpdateSupplierMutation,
  useRangeholderListMutation,
  useAddRangeholderMutation,
  useDeleteRangeholderMutation,
  useGetRangeholderByIdMutation,
  useUpdateRangeholderMutation,
  useGetInventoryDidListMutation,
  useDeleteInventoryDidMutation,
  useInventoryExportMutation,
  useGetSupplierDropdownMutation,
  useExportPurchasedDidMutation,
  useAllocateSIPDidMutation,
} = AuthApi;
