import {
  Checkbox,
  Grid,
  TableCell,
  TableRow,
  Card,
  MenuItem,
  Select,
  IconButton,
  Box,
  DialogTitle,
  FormLabel,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import dialogImg from "../../assets/images/logout-dialog-image.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import { PurchasedDidHeader } from "./data";
import SuiDropDownList from "components/SuiDropDownList";
import { useGetAlldidMutation } from "app/features/api/AuthApi";
import { useCallback, useEffect, useState } from "react";
import AutoCompleteAccountNo from "components/SuiAutocomplete/Autocompleteaccountno";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import EditIcon from "Container/Icons/EditIcon";
import { formattedDates } from "helper";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useSupplierDropListMutation } from "app/features/api/AuthApi";
import { debounce } from "lodash";
import { useExportMarkMutation } from "app/features/api/AuthApi";
import SuiTypography from "components/SuiTypography";
import SuiDialog from "components/SuiDialog";
import colors from "assets/theme/base/colors";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useSelector } from "react-redux";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { HighlightOff } from "@mui/icons-material";
import { useExportPurchasedDidMutation } from "app/features/api/AuthApi";
import { useGetAssignSipTrunkMutation } from "app/features/api/AuthApi";
import { useAllocateSIPDidMutation } from "app/features/api/AuthApi";
import { purchasedSIPAllocate } from "Validation";
import EyeIcon from "Container/Icons/EyeIcon";

const PurchasedTable = () => {
  const [getAlldid, { isLoading: isLoading }] = useGetAlldidMutation();
  const [customerInfo, setCustomerInfo] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItemsSip, setCheckedItemsSip] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [supplierDropList] = useSupplierDropListMutation();
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [supplierListVal, setSupplierListVal] = useState();
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failer, setfailer] = useState(false);
  const [markDisabled, setMArkDisabled] = useState(true);
  const suiSnackbar = useSuiSnackbar();
  const [getAssignSipTrunk] = useGetAssignSipTrunkMutation();
  const [alloacteSIPDidAdd] = useAllocateSIPDidMutation();

  // if (page === null) {
  //   page = 1;
  // }
  const navigateSearch = useNavigateSearch();
  const formik = useFormik({
    initialValues: {
      did_num: "",
      number_type: "",
      is_diverted: "",
      country: "",
      cus_account_number: "",
      is_fuse2: "",
      Supplier1: "",
      Supplier: "",
      account_no: "",
      purchased_from: "",

      // page: 1,
    },
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmitForm(perPage, 1);
      setCurrentPage(1);
    },
  });
  const AllocateSipDid = useFormik({
    initialValues: {
      sip_id: "",
      sipid1: "",
    },
    validationSchema: purchasedSIPAllocate,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const transformedData = checkedItemsSip.map((item) => ({
        sip_id: String(item.sip_id),
        number: item.number,
        cus_account_number: item.account_no,
        cus_id: String(item.cus_id),
      }));
      const body = {
        sip_username: values.sip_id.name,
        numbers: transformedData,
      };
      try {
        const resposne = await alloacteSIPDidAdd(body).unwrap();
        setOpenModal(false);
        AllocateSipDid.setFieldValue("sipid1", "");
        AllocateSipDid.setFieldValue("sip_id", "");
        AllocateSipDid.setFieldValue("sip_username", "");
        setCheckedItems([]);
        setCheckedItemsSip([]);
        suiSnackbar(resposne.message, "success");
      } catch (error) {
        setOpenModal(false);
        AllocateSipDid.setFieldValue("sipid1", "");
        AllocateSipDid.setFieldValue("sip_id", "");
        AllocateSipDid.setFieldValue("sip_username", "");
        setCheckedItems([]);
        setCheckedItemsSip([]);
        console.log(error);
        if (error.status === 400) {
          suiSnackbar(error?.data?.message, "error");
        }
        const issues = error.data.errorMessages.issues;
        if (issues) {
          issues.map((item) => {
            console.log("error", item);
            suiSnackber(item.message, "error");
          });
        }
      }
    },
  });
  const closeAlloacteModal = () => {
    setCheckedItems([]);
    setCheckedItemsSip([]);
    AllocateSipDid.setFieldValue("sipid1", "");
    AllocateSipDid.setFieldValue("sip_id", "");
    AllocateSipDid.setFieldValue("sip_username", "");
    setOpenModal(false);
  };
  const [accMatch, setAccMatch] = useState(false);
  const handleMatchEdge = (items) => {
    if (items && items.length > 0) {
      let hasEdge = false;
      for (let i = 0; i < items.length; i++) {
        if (items[i].did_purchased_from === "Edge" || items[i].did_purchased_from === "DIDWW") {
          hasEdge = true;
          break;
        }
      }
      if (hasEdge) {
        suiSnackbar(
          "To use Mark as Exported feature, you need to select DIDs purchased from DID Inventory only.",
          "error"
        );
      } else {
        hasEdge = false;
      }
      // Update the disabled state based on whether we found an "Edge"
      setMArkDisabled(hasEdge);
    }
  };
  const handleMatchAccNumber = () => {
    if (checkedItemsSip.length > 20) {
      suiSnackbar("At a time max 20 DIDs can be selected at once", "error");
    } else {
      if (checkedItemsSip.length > 0) {
        let firstAccountNo = checkedItemsSip[0].account_no;
        let allMatch = true;
        for (let i = 1; i < checkedItemsSip.length; i++) {
          if (checkedItemsSip[i].account_no !== firstAccountNo) {
            allMatch = false;
            break;
          }
        }
        if (allMatch) {
          // console.log("allMatch:::::::", allMatch);
        } else {
          suiSnackbar(
            "To use the Allocate SIP Trunk feature, you need to select DID belonging to the same customer.",
            "error"
          );
        }
        setAccMatch(allMatch);
      } else {
        setAccMatch(false);
      }
    }
  };
  const handleCheckSingle = (
    cus_id,
    did_id,
    did_inventory_id,
    sip_id,
    number,
    account_no,
    company_name,
    did_purchased_from
  ) => {
    const itemToCheck = { cus_id, did_id, did_inventory_id, did_purchased_from };
    const newCheckedItems = [...checkedItems];
    const currentIndex = newCheckedItems.findIndex(
      (item) =>
        item.cus_id === cus_id &&
        item.did_id === did_id &&
        item.did_inventory_id === did_inventory_id
    );
    if (currentIndex === -1) {
      newCheckedItems.push(itemToCheck);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    const newCheckedItemss = [...checkedItemsSip];
    const currentIndexs = newCheckedItemss.findIndex(
      (item) => item.sip_id === sip_id && item.number === number && item.account_no === account_no
    );
    const itemToChecks = { sip_id, number, account_no, company_name, cus_id };
    if (currentIndex === -1) {
      newCheckedItemss.push(itemToChecks);
    } else {
      newCheckedItemss.splice(currentIndexs, 1);
    }
    setCheckedItems(newCheckedItems);
    handleMatchEdge(newCheckedItems);
    setCheckedItemsSip(newCheckedItemss);
    handleMatchAccNumber();
  };

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCheckedItems([]);
    setCheckedItemsSip([]);

    setCurrentPage(1);
    navigateSearch("/purchased-dids", { page: 1 });
  };
  const [exportMark, { isLoading: isLoadingExport }] = useExportMarkMutation();
  const suiSnackber = useSuiSnackbar();
  const exportHandle = async () => {
    const getTransformData = checkedItems.map((item) => ({
      cus_id: item.cus_id,
      did_id: item.did_id,
      did_inventory_id: item.did_inventory_id,
    }));
    const body = getTransformData;
    try {
      const resp = await exportMark(body).unwrap();
      handleDialogClose(false);
      suiSnackber(resp.message, "success");
      handleSubmitForm(perPage, currentPage);
      setCheckedItems([]);
      setCheckedItemsSip([]);
    } catch (error) {
      console.log(error, "error");
      if (error.status == 400) {
        if (error.data.message) {
          if (error.data.errorMessages) {
            const issues = error.data.errorMessages.issues;
            if (error.data.errorMessages.issues) {
              issues.map((item) => {
                suiSnackber(item.message, "error");
                handleDialogClose(false);
                setCheckedItems([]);
                setCheckedItemsSip([]);
              });
            }
          } else {
            suiSnackber(error.data.message, "error");
            handleDialogClose(false);
            setCheckedItems([]);
            setCheckedItemsSip([]);
          }
        }
      } else {
        const issues = error.data.errorMessages.issues;
        if (error.data.errorMessages.issues) {
          issues.map((item) => {
            suiSnackber(item.message, "error");
            handleDialogClose(false);
            setCheckedItems([]);
            setCheckedItemsSip([]);
          });
        }
      }
    }
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/purchased-dids", { page: page });
    setCheckedItems([]);
    setCheckedItemsSip([]);
  };
  const handleSubmitForm = async (perPage, currentPage) => {
    navigateSearch("/purchased-dids", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.did_num) {
      urlParamss.did_number = formik.values.did_num;
    }
    if (formik.values.purchased_from) {
      urlParamss.did_purchased_from = formik.values.purchased_from;
    }
    if (formik.values.number_type == "0" || formik.values.number_type == "1") {
      if (formik.values.number_type == "0") {
        urlParamss.did_inventory_is_golden_number = "0";
      } else {
        urlParamss.did_inventory_is_golden_number = "1";
      }
    }
    if (formik.values.Supplier) {
      urlParamss.did_supplier_id = formik.values.Supplier.id;
    }
    if (formik.values.is_fuse2 == "0" || formik.values.is_fuse2 == "1") {
      if (formik.values.is_fuse2 == "0") {
        urlParamss.did_inventory_is_fuse2_number = "0";
      } else {
        urlParamss.did_inventory_is_fuse2_number = "1";
      }
    }
    if (formik.values.account_no) {
      urlParamss.cus_account_number = formik.values.account_no;
    }

    if (formik.values.country) {
      urlParamss.did_country = formik.values.country;
    }
    if (formik.values.is_diverted == "1" || formik.values.is_diverted == "0") {
      if (formik.values.is_diverted == "1") {
        urlParamss.cfu_active = 1;
      } else {
        urlParamss.cfu_active = "0";
      }
    }

    try {
      const response = await getAlldid(urlParamss).unwrap();
      navigateSearch("/purchased-dids", { page: currentPage });
      setCustomerInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setCustomerInfo("");
      setCount("");
    }
  };
  const handleResetForm = async (pageNo) => {
    setCurrentPage(pageNo);
    navigateSearch("/purchased-dids", { page: pageNo });
    try {
      let urlParamss = {
        results_per_page: 10,
        page: pageNo,
      };
      setPerPage(10);
      const response = await getAlldid(urlParamss).unwrap();
      navigateSearch("/purchased-dids", { page: pageNo });
      setCustomerInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setCustomerInfo("");
      setCount("");
    }
  };
  const handleCheckAll = () => {
    if (!rows || rows.length === 0) {
      return;
    }
    if (checkedItems.length === rows.length) {
      setCheckedItems([]);
      setCheckedItemsSip([]);
      setMArkDisabled(false);
    } else {
      const allCheckedItems = customerInfo.map((row) => ({
        cus_id: row.customers.cus_id,
        did_id: row.did_id,
        did_inventory_id: row.did_inventory_id,
        did_purchased_from: row.did_purchased_from,
      }));
      const allCheckedItemsSip = customerInfo.map((row) => ({
        sip_id: row.main_services.sip_id,
        number: row.did_number,
        account_no: row.customers.cus_account_number,
        company_name: row.customers.cus_company_name,
        cus_id: row.customers.cus_id,
      }));
      setCheckedItems(allCheckedItems);
      handleMatchEdge(allCheckedItems);
      setCheckedItemsSip(allCheckedItemsSip);
      handleMatchAccNumber();
    }
  };
  const [downloadPurchasedDid] = useExportPurchasedDidMutation();
  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.did_num) {
      urlParamss.did_number = formik.values.did_num;
    }
    if (formik.values.number_type === "0" || formik.values.number_type === "1") {
      urlParamss.did_inventory_is_golden_number = formik.values.number_type === "0" ? "0" : "1";
    }
    if (formik.values.Supplier) {
      urlParamss.did_supplier_id = formik.values.Supplier.id;
    }
    if (formik.values.is_fuse2 === "0" || formik.values.is_fuse2 === "1") {
      urlParamss.did_inventory_is_fuse2_number = formik.values.is_fuse2 === "0" ? "0" : "1";
    }
    if (formik.values.account_no) {
      urlParamss.cus_account_number = formik.values.account_no;
    }

    if (formik.values.country) {
      urlParamss.did_country = formik.values.country;
    }
    if (formik.values.is_diverted === "1" || formik.values.is_diverted === "0") {
      urlParamss.cfu_active = formik.values.is_diverted === "1" ? 1 : "0";
    }
    try {
      setLoading(true);
      setSuccess(false);
      const response =
        Object.keys(urlParamss)?.length > 0
          ? await downloadPurchasedDid(urlParamss).unwrap()
          : await downloadPurchasedDid().unwrap();
      const message = response?.message || "Operation successful";
      if (response.status === 200) {
        setTimeout(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1000);
        }, 1000);
        suiSnackbar(message, "success");
      } else if (response.status === 404) {
        setTimeout(() => {
          setLoading(false);
          setfailer(true);
          setTimeout(() => {
            setfailer(false);
          }, 1000);
        }, 1000);
        suiSnackbar(message, "error");
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error?.data?.message || "Operation Failed.";
      setTimeout(() => {
        setLoading(false);
        setfailer(true);
        setTimeout(() => {
          setfailer(false);
        }, 1000);
      }, 1000);
      suiSnackbar(errorMessage, "error");
    }
  };
  useEffect(() => {
    handleSubmitForm(perPage, currentPage);
    SupplierLists();
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    localStorage.removeItem("sub_id");

    // dispatch(resetCustomerDetails());
  }, [perPage, currentPage]);
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [SipDetails, setSipDetails] = useState([]);
  const allocateSIP = async (id) => {
    try {
      const response = await getAssignSipTrunk(id).unwrap();
      setSipDetails(response?.data);
    } catch (error) {
      setSipDetails([]);
      console.log(error);
    }
    setOpenModal(true);
  };
  const optionSIP = SipDetails?.map((option) => ({
    id: option.sip_id,
    name: option.sip_username,
  }));
  const SupplierLists = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");

      try {
        const response = await supplierDropList(params).unwrap();
        setSupplierListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  const supplierData = supplierListVal?.map((dropval) => ({
    id: dropval.did_supplier_id,
    name: dropval.did_supplier_name,
  }));

  useEffect(() => {
    handleMatchAccNumber();
    handleMatchEdge();
    if (searchText.length > 0) {
      SupplierLists(searchText);
    } else {
      setSupplierListVal([]);
    }
  }, [searchText, SupplierLists, checkedItemsSip, accMatch, checkedItems, markDisabled]);
  const isChecked = (cus_id, did_id, did_inventory_id) => {
    return checkedItems.some(
      (item) =>
        item.cus_id === cus_id &&
        item.did_id === did_id &&
        item.did_inventory_id === did_inventory_id
    );
  };
  const editRedirect = (item, type) => {
    localStorage.setItem("did_id", item);
    {
      type === "view" ? navigateSearch("view-purchased-did") : navigateSearch("edit-purchased-did");
    }
  };
  const rows =
    customerInfo &&
    customerInfo?.map((item, i) => {
      const labelId = `item.did_id_${i}`;
      const did_id = item.did_id;
      const cus_id = item.customers.cus_id;
      const did_inventory_id = item.did_inventory_id;
      const sip_id = item.main_services.sip_id;
      const number = item.did_number;
      const account_no = item.customers.cus_account_number;
      const company_name = item.customers.cus_company_name;
      const did_purchased_from = item.did_purchased_from;
      return (
        <>
          <TableRow key={labelId}>
            {permissionInfo?.purchasedDid?.UPDATE || permissionInfo?.purchasedDid?.DELETE ? (
              <TableCell align="center">
                <Checkbox
                  color="secondary"
                  checked={isChecked(cus_id, did_id, did_inventory_id)}
                  onChange={() =>
                    handleCheckSingle(
                      cus_id,
                      did_id,
                      did_inventory_id,
                      sip_id,
                      number,
                      account_no,
                      company_name,
                      did_purchased_from
                    )
                  }
                />
              </TableCell>
            ) : null}
            <TableCell>{item.did_number}</TableCell>
            <TableCell>{item.did_number_type}</TableCell>
            <TableCell>{item.customers.cus_account_number}</TableCell>
            <TableCell>{item.did_purchased_from}</TableCell>
            <TableCell>
              {item.did_inventorys === null
                ? item.did_country
                : item.did_inventorys?.did_area_codes?.countries?.country_name}
            </TableCell>
            <TableCell>{item.did_price === null ? "-" : `£${item.did_price}`}</TableCell>
            <TableCell>
              {item?.did_inventorys?.did_inventory_is_golden_number == "0"
                ? "No"
                : item?.did_inventorys?.did_inventory_is_golden_number == null
                ? "-"
                : "Yes"}
            </TableCell>
            <TableCell>
              {" "}
              {item?.did_inventorys?.did_inventory_is_fuse2_number == "0"
                ? "No"
                : item?.did_inventorys?.did_inventory_is_fuse2_number == null
                ? "-"
                : "Yes"}
            </TableCell>
            <TableCell>
              {" "}
              {item?.did_inventorys?.did_inventory_is_ported_in == "0"
                ? "No"
                : item?.did_inventorys?.did_inventory_is_ported_in == null
                ? "-"
                : "Yes"}
            </TableCell>
            <TableCell>
              {item?.did_inventorys?.did_inventory_effective_date == null
                ? "-"
                : formattedDates(item?.did_inventorys?.did_inventory_effective_date)}
            </TableCell>
            <TableCell>
              {item?.did_inventorys?.did_suppliers?.did_supplier_name == null
                ? "-"
                : item?.did_inventorys?.did_suppliers?.did_supplier_name}
            </TableCell>
            <TableCell>
              {item.did_inventorys?.did_range_holders?.did_range_holder_name == null
                ? "-"
                : item.did_inventorys?.did_range_holders?.did_range_holder_name}
            </TableCell>
            <TableCell>{item.main_services?.description}</TableCell>
            <TableCell>{formattedDates(item.createdAt)}</TableCell>
            {!permissionInfo.purchasedDid.UPDATE && permissionInfo.purchasedDid.VIEW && (
              <TableCell align="right">
                <IconButton className="viewIcon" onClick={() => editRedirect(item.did_id, "view")}>
                  {<EyeIcon />}
                </IconButton>
              </TableCell>
            )}
            {permissionInfo.purchasedDid.UPDATE && permissionInfo.purchasedDid.VIEW && (
              <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                <IconButton className="viewIcon" onClick={() => editRedirect(item.did_id, "view")}>
                  {<EyeIcon />}
                </IconButton>
                <IconButton onClick={() => editRedirect(item.did_id, "edit")}>
                  {<EditIcon />}
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        </>
      );
    });
  const handleDialogClose = (val) => {
    setStatusDialogOpen(val);
  };
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="Number"
                  id="did_num"
                  name="did_num"
                  value={formik.values.did_num}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Yes" },
                    { id: "0", label: "No" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Golden Number",
                    name: "number_type",
                    val: formik.values.number_type,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Yes" },
                    { id: "0", label: "No" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Fuse2 Allocation",
                    name: "is_fuse2",
                    val: formik.values.is_fuse2,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <AutoCompleteAccountNo
                  type="accountNo"
                  placeholder="Account Number"
                  formik={formik}
                  name="cus_account_number"
                  id="cus_account_number"
                  value={formik.values.cus_account_number}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="Supplier"
                  value={formik.values.Supplier}
                  setValue={(value) => {
                    formik.setFieldValue("Supplier", value);
                    formik.setFieldValue("Supplier1", value.name);
                  }}
                  noOptionsText="No options"
                  onInputChange={SupplierLists}
                  placeholder="Supplier Name"
                  isLoading={false}
                  disabled={false}
                  options={supplierData}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "Edge", label: "Edge" },
                    { id: "DID Inventory", label: "DID Inventory" },
                    { id: "DIDWW", label: "DIDWW" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Purchased From",
                    name: "purchased_from",
                    val: formik.values.purchased_from,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    navigateSearch("/purchased-dids", { page: 1 });
                    handleResetForm(1);
                    setCheckedItems([]);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Purchased DIDs"}>
            {permissionInfo?.purchasedDid?.DELETE && (
              <SuiButton
                size="small"
                buttonColor="primary"
                onClick={() => handleDialogClose(true)}
                sx={{
                  fontWeight: "500",
                  px: 2,
                  "&:disabled": {
                    backgroundColor: "#E9E9E9 !important",
                    color: "#848484 !important",
                  },
                }}
                disabled={checkedItems.length === 0 || checkedItems.length >= 20 || markDisabled}
              >
                Mark as Exported
              </SuiButton>
            )}
            <SuiButton
              size="small"
              buttonColor={success ? "primary" : failer ? "error" : "accous"}
              sx={{
                fontWeight: "500",
                px: "12px",
                ml: 2,
                color: "#848484 !important",
              }}
              onClick={downloadDetails}
            >
              {loading ? (
                <CircularProgress size={20} color="info" />
              ) : success ? (
                <CheckCircleIcon size={20} color="white" />
              ) : failer ? (
                <HighlightOff size={20} color="white" />
              ) : (
                "Export"
              )}
            </SuiButton>
            {permissionInfo?.purchasedDid?.UPDATE && (
              <SuiButton
                size="small"
                buttonColor="info"
                disabled={checkedItems.length === 0 || !accMatch}
                onClick={() => allocateSIP(checkedItemsSip[0].cus_id)}
                sx={{
                  fontWeight: "500",
                  px: 2,
                  ml: 2,
                  "&:disabled": {
                    backgroundColor: "#E9E9E9 !important",
                    color: "#848484 !important",
                  },
                }}
              >
                Allocate SIP Trunk
              </SuiButton>
            )}
          </TableToolbar>

          <EnhancedTable
            tableMinWidth={1200}
            checkAll={
              permissionInfo?.purchasedDid?.UPDATE || permissionInfo?.purchasedDid?.DELETE ? (
                <Checkbox
                  checked={checkedItems?.length === rows.length && rows.length > 0}
                  onChange={handleCheckAll}
                  color="secondary"
                />
              ) : null
            }
            tablehead={PurchasedDidHeader}
            rowsData={rows}
            isLoading={isLoading}
          />

          <SuiDialog open={statusDialogOpen} onClose={() => handleDialogClose(false)} haveCloseIcon>
            <DialogContent
              sx={{
                textAlign: "center",
              }}
            >
              <img src={dialogImg} />
              <SuiTypography fontWeight="semiBold" sx={{ mt: 2, fontSize: "16px !important" }}>
                Are you sure you want to mark the DID(s) as exported?
              </SuiTypography>
              <SuiTypography
                fontWeight="regular"
                sx={{
                  mt: 2,
                  fontSize: "12px !important",
                  color: `${colors.secondary.focus} !important`,
                }}
              >
                This action cannot be undone.
              </SuiTypography>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        onClick={() => handleDialogClose(false)}
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                      >
                        No
                      </SuiButton>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        buttonColor="primary"
                        disabled={isLoadingExport}
                        fullWidth
                        onClick={() => exportHandle()}
                      >
                        Yes
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </SuiDialog>
          {!isLoading && customerInfo?.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
      <SuiDialog open={openModal} onClose={closeAlloacteModal} haveCloseIcon maxWidth="md">
        <DialogTitle borderBottom={1} mb={3} sx={{ borderColor: "#E9E9E9" }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <SuiTypography fontWeight="semiBold">Allocate SIP Trunk</SuiTypography>
            <SuiTypography textColor="info" fontWeight="bold" fontSize="16px">
              Total DIDs selected: {checkedItemsSip.length}
            </SuiTypography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          <Grid
            component={"form"}
            container
            spacing={2}
            rowSpacing={3}
            onSubmit={AllocateSipDid.handleSubmit}
          >
            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="role_name">Company Name</FormLabel>
              <SuiInput
                inputSettungs={{ autoComplete: "off", required: true }}
                type="text"
                placeholder="Enter Company Name"
                id="account_no"
                name="account_no"
                value={
                  checkedItemsSip.length > 0
                    ? `(${checkedItemsSip[0].account_no}) ${checkedItemsSip[0].company_name}`
                    : ""
                }
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="role_name" required>
                SIP Server Name
              </FormLabel>
              <AutocompleteSingleSelect
                id="sip_id"
                value={AllocateSipDid.values.sip_id}
                setValue={(value) => {
                  AllocateSipDid.setFieldValue("sipid1", value.id);
                  AllocateSipDid.setFieldValue("sip_id", value);
                  AllocateSipDid.setFieldValue("sip_username", value.username);
                }}
                placeholder="Select"
                isLoading={false}
                disabled={false}
                options={optionSIP}
                formik={AllocateSipDid}
              />
              {AllocateSipDid.errors.sipid1 && AllocateSipDid.touched.sip_id && (
                <div style={{ color: "#fc3c3c", fontWeight: 400 }}>
                  {AllocateSipDid.errors.sipid1}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"start"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    // onClick={() => setOpenModal(false)}
                    onClick={closeAlloacteModal}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={AllocateSipDid.handleSubmit}
                  >
                    Allocate
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
    </>
  );
};

export default PurchasedTable;
