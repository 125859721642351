import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormLabel,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetByIdGlobalMutation } from "app/features/api/AuthApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiButton from "components/SuiButton";
import { usePostGlobalMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import CommonSpinner from "components/CommonSpinner";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import { useGetAllGlobalMutation } from "app/features/api/AuthApi";
import DisNumberInput from "components/SuiInput/DiscountNumberInput";

const GlobalConfig = () => {
  const [GetByIdGlobal, { isLoading: isLoading }] = useGetByIdGlobalMutation();
  const [globalInfo, setGlobalInfo] = useState({});
  const suiSnackbar = useSuiSnackbar();
  const { id } = useParams();
  const replaceId = id?.replace(/-/g, "_");
  const [GetAllGlobal] = useGetAllGlobalMutation();
  const [globalFullInfo, setGlobalFullInfo] = useState({});
  console.log(globalFullInfo, "globalFu");
  const [PostGlobal] = usePostGlobalMutation();
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const [errorVal, setErrorVal] = useState("");
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const fetchGlobalInfo = async () => {
      try {
        const response = await GetByIdGlobal(replaceId).unwrap();
        setGlobalInfo(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchGlobalInfo();
  }, [replaceId, GetByIdGlobal]);

  const createInitialValues = () => {
    const initialValues = {};
    if (Array.isArray(globalInfo?.global_config_fields)) {
      globalInfo.global_config_fields.forEach((field) => {
        const [fieldName] = Object.entries(field)[0];
        initialValues[fieldName] =
          fieldName in globalInfo
            ? globalInfo[fieldName]
            : fieldName in globalInfo.global_config_json_value
            ? globalInfo.global_config_json_value[fieldName]
            : "";
      });
    }
    // if (initialValues?.default_postpay_provider !== undefined) {
    //   initialValues.default_postpay_provider;
    // }
    return initialValues;
  };

  const path = `global-configuration/${id}`;
  const pathSegments = path.split("/");
  const navigate = useNavigate();
  const Globalid = pathSegments[1];
  console.log(globalFullInfo, "globalInfo");
  const handleRedirect = () => {
    if (!Array.isArray(globalFullInfo)) {
      console.error("globalFullInfo is not an array:", globalFullInfo);
      return;
    }

    const slugToMatch = Globalid.replace(/-/g, "_");
    console.log("Slug to match:", slugToMatch);
    const index = globalFullInfo.findIndex((c) => c.global_config_slug === slugToMatch);
    console.log("Current index:", index);

    if (index > 0) {
      const baseSlug = globalFullInfo[0]?.global_config_slug?.replace(/_/g, "-");
      const path = `/global-configuration/${baseSlug}`;
      navigate(path);
    } else {
      navigate("/dashboard");
    }
  };
  const createValidationSchema = () => {
    const schema = {};
    if (Array.isArray(globalInfo?.global_config_fields)) {
      globalInfo.global_config_fields.forEach((field) => {
        const [fieldName, fieldConfig] = Object.entries(field)[0];
        let fieldSchema = Yup.string();

        if (fieldConfig.required) {
          fieldSchema = fieldSchema.required(`${fieldConfig.label} cannot be blank.`);
        }

        if (fieldConfig.regex) {
          try {
            const pattern = fieldConfig.regex.slice(1, -1);
            const regexPattern = pattern;
            const errorMessage =
              fieldName === "password"
                ? `${fieldConfig.label} must contain at least 8 characters with at least one uppercase letter and one number.`
                : `${fieldConfig.label} is not valid.`;

            fieldSchema = fieldSchema.matches(regexPattern, errorMessage);
          } catch (error) {
            console.error(`Invalid regex pattern for field ${fieldName}: ${fieldConfig.regex}`);
          }
        }

        schema[fieldName] = fieldSchema;
      });
    }

    return Yup.object().shape(schema);
  };

  // Formik setup
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createValidationSchema(),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await PostGlobal({
          id: replaceId,
          data: { global_config_json_value: formik.values },
        }).unwrap();
        suiSnackbar(response.message, "success");
        setErrorVal("");
        resetForm({ values: formik.values });
      } catch (error) {
        setErrorVal(error);

        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                // setErrorVal(errorMessage);
                // suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
        console.log(error);
      }
    },
  });

  useEffect(() => {
    setInitialValues(createInitialValues());
  }, [globalInfo]);

  useEffect(() => {
    if (errorVal?.data?.errorMessages?.fieldErrors) {
      const fieldErrors = {};
      const errors = errorVal.data.errorMessages.fieldErrors;
      for (const field in errors) {
        if (Object.hasOwn(errors, field)) {
          fieldErrors[field] = errors[field].join(" ");
        }
      }
      formik.setErrors(fieldErrors);
    } else {
      setErrorVal("");
    }
  }, [errorVal]);
  const isArray = Array.isArray(globalInfo?.global_config_fields);
  useEffect(() => {
    const fetchGlobalInfo = async () => {
      try {
        const response = await GetAllGlobal().unwrap();

        setGlobalFullInfo(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchGlobalInfo();
  }, []);
  useEffect(() => {
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, []);
  console.log(initialValues?.default_postpay_provider, "initialval");
  return (
    <>
      {isLoading ? (
        <CommonSpinner size="100px" color="#55DED0" />
      ) : (
        <Grid container component="form" spacing={2} onSubmit={formik.handleSubmit}>
          {isArray
            ? globalInfo.global_config_fields.map((field, index) => {
                const [fieldName, fieldConfig] = Object.entries(field)[0];
                if (fieldConfig.type === "dropdown") {
                  return (
                    <Grid item xs={12} lg={6} key={index} className="focusRemoveInput">
                      <FormLabel htmlFor={fieldName} required={fieldConfig.required}>
                        {fieldConfig.label}
                      </FormLabel>
                      {globalInfo.global_config_slug === "3cx_info" ? (
                        <Autocomplete
                          options={fieldConfig.values}
                          getOptionLabel={(option) => option.value}
                          value={
                            fieldConfig.values.find(
                              (option) => option.value === formik.values[fieldName]
                            ) || null
                          }
                          onChange={(event, newValue) =>
                            formik.setFieldValue(fieldName, newValue ? newValue.value : "")
                          }
                          sx={{
                            "&:focus": {
                              border: 0,
                            },
                            border: 0,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={fieldName}
                              name={fieldName}
                              placeholder={fieldConfig.label}
                              sx={{
                                "&:focus": {
                                  border: 0,
                                },
                                border: 0,
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          options={fieldConfig.values}
                          getOptionLabel={(option) => option.value}
                          value={
                            fieldConfig.values.find(
                              (option) => option.id === formik.values[fieldName]
                            ) || null
                          }
                          onChange={(event, newValue) =>
                            formik.setFieldValue(fieldName, newValue ? newValue.id : "")
                          }
                          sx={{
                            "&:focus": {
                              border: 0,
                            },
                            border: 0,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={fieldName}
                              name={fieldName}
                              placeholder={fieldConfig.label}
                              sx={{
                                "&:focus": {
                                  border: 0,
                                },
                                border: 0,
                              }}
                            />
                          )}
                        />
                      )}
                      <ErrorMessageComponent
                        touched={formik.touched[fieldName]}
                        error={formik.errors[fieldName]}
                      />
                    </Grid>
                  );
                }

                if (fieldConfig.type === "radio") {
                  return (
                    <Grid item xs={12} lg={6} key={index}>
                      <FormLabel component="legend" required={fieldConfig.required}>
                        {fieldConfig.label}
                      </FormLabel>
                      <FormControl
                        sx={{ p: 1 }}
                        component="fieldset"
                        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                      >
                        <RadioGroup
                          sx={{ display: "flex", flexDirection: "row" }}
                          name={fieldName}
                          value={formik.values[fieldName]}
                          onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}
                        >
                          {fieldConfig.values.map((option) => (
                            <FormControlLabel
                              key={option.id}
                              value={option.id}
                              control={<Radio color="primary" />}
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                        <ErrorMessageComponent
                          touched={formik.touched[fieldName]}
                          error={formik.errors[fieldName]}
                        />
                      </FormControl>
                    </Grid>
                  );
                }

                if (fieldConfig.type === "password") {
                  return (
                    <Grid item xs={12} lg={6} key={index}>
                      <FormLabel htmlFor={fieldName} required={fieldConfig.required}>
                        {fieldConfig.label}
                      </FormLabel>
                      <SuiPasswordInput
                        index={1}
                        attr={{ name: fieldName, placeholder: "Password" }}
                        value={formik.values[fieldName]}
                        onBlur={formik.handleBlur}
                        getValue={formik.handleChange}
                      />
                      <ErrorMessageComponent
                        touched={formik.touched[fieldName]}
                        error={formik.errors[fieldName]}
                      />
                    </Grid>
                  );
                }

                if (fieldConfig.type === "number") {
                  console.log(fieldName, "fieldName");
                  return (
                    <Grid item xs={12} lg={6} key={index}>
                      <FormLabel htmlFor={fieldName} required={fieldConfig.required}>
                        {fieldConfig.label}
                      </FormLabel>
                      <DisNumberInput
                        id={fieldName}
                        name={fieldName}
                        placeholder={fieldConfig.label}
                        value={formik.values[fieldName]}
                        setValue={formik.setFieldValue}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          formik.setFieldValue(fieldName, isNaN(numericValue) ? "" : numericValue);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <ErrorMessageComponent
                        touched={formik.touched[fieldName]}
                        error={formik.errors[fieldName]}
                      />
                    </Grid>
                  );
                }

                if (fieldConfig.type === "checkbox") {
                  return (
                    <Grid item xs={12} lg={6} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values[fieldName]}
                            onChange={(e) => formik.setFieldValue(fieldName, e.target.checked)}
                            name={fieldName}
                            color="secondary"
                          />
                        }
                        label={fieldConfig.label}
                      />
                      <ErrorMessageComponent
                        touched={formik.touched[fieldName]}
                        error={formik.errors[fieldName]}
                      />
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={12} lg={6} key={index}>
                    <FormLabel htmlFor={fieldName} required={fieldConfig.required}>
                      {fieldConfig.label}
                    </FormLabel>
                    <TextField
                      fullWidth
                      id={fieldName}
                      name={fieldName}
                      placeholder={fieldConfig.label}
                      type={fieldConfig.type}
                      value={formik.values[fieldName]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessageComponent
                      touched={formik.touched[fieldName]}
                      error={formik.errors[fieldName]}
                    />
                  </Grid>
                );
              })
            : []}
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component="button"
                  onClick={handleRedirect}
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              {permissionInfo?.global_config?.UPDATE && (
                <Grid item>
                  <SuiButton
                    type="submit"
                    size="small"
                    buttonColor="primary"
                    sx={{ px: "1rem" }}
                    onClick={formik.handleSubmit}
                    disabled={!formik.dirty}
                  >
                    Update
                  </SuiButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default GlobalConfig;
