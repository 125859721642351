import { lazy } from "react";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import RootLayout from "layouts/RootLayout";
import RootOutlet from "layouts/RootLayout/RootOutlet";

import Dashboard from "layouts/dashboard";

const Profile = lazy(() => import("layouts/profile"));
const ProfileDetails = lazy(() => import("layouts/profile/components/ProfileDetails"));
const ChangePassword = lazy(() => import("layouts/profile/components/ChangePassword"));

const CustomerTable = lazy(() => import("layouts/customer/CustomerTable"));
import CommonCustomerModules from "layouts/customer/CommonCustomerModules";
const EditCustomer = lazy(() => import("layouts/customer/components/EditCustomer"));
const PartnerDetails = lazy(() => import("layouts/customer/components/PartnerDetails"));
const DiscountDetails = lazy(() => import("layouts/customer/components/DiscountDetails"));
const DIDDetails = lazy(() => import("layouts/customer/components/DIDDetails"));
const SIPTrunkDetails = lazy(() => import("layouts/customer/components/SIPTrunkDetails"));
const NumberPortinDetails = lazy(() => import("layouts/customer/components/NumberPortinDetails"));
const TransactionDetails = lazy(() => import("layouts/customer/components/TransactionDetails"));
const CdrDetails = lazy(() => import("layouts/customer/components/CDRDetails"));
const DIDTable = lazy(() => import("layouts/DID/DIDTable"));
const RequestApprovalTable = lazy(() => import("layouts/DID/RequestApprovalTable"));
const AssignDIDsTable = lazy(() => import("layouts/assign-dids/AssignDIDsTable"));
const AssignNewDID = lazy(() => import("layouts/assign-dids/AssignNewDID"));
const Pricing = lazy(() => import("layouts/pricing"));

import LogIn from "layouts/authentication/log-in";
import LoginWithPass from "layouts/authentication/log-in/Forms/LoginWithPass";
import EnterEmail from "layouts/authentication/log-in/Forms/EnterEmail";
import ForgetPassword from "layouts/authentication/log-in/Forms/ForgetPassword";
const LogOut = lazy(() => import("layouts/authentication/log-out"));
import LoginAuth from "layouts/authentication/log-in/LoginAuth";
import NotFound from "layouts/NotFound";
import Terms from "layouts/terms-conditions";
import NewPages from "layouts/terms-conditions/Pages";
import CreateCustomer from "layouts/create-customer/CreateCustomer";
import PaymentDetails from "layouts/customer/components/PaymentInvoice";
import SpendLimit from "layouts/customer/components/spendLimit";
import OrderDetails from "layouts/customer/components/OrderDetails";
import CronStatusDetails from "layouts/cronStatus/cronStatusDetails";
import AdminTable from "layouts/Admin/AdminTable";
import CreateAdmin from "layouts/Admin/Add-admin";
import Roles from "layouts/Roles/Roles";
import AddRole from "layouts/Roles/addRole";
import EditAdmin from "layouts/Admin/Edit-admin";
import ProtectedRoute from "Validation/ProtectedRoutes";
import OtpVerify from "layouts/authentication/log-in/Forms/2faauth";
import TwoFactorAuthntication from "layouts/profile/components/twoFactorAuthntication";
import ThreeCXRequestApproval from "layouts/licences/request-approval";
import ThreeCXDetails from "layouts/licences/liecence-detail";
import ThreeCXView from "layouts/licences/liecence-view";
import BuyLiecence from "layouts/licences/buy-liecence";
import ThreeCXCustmoerDetails from "layouts/customer/components/ThreeCXCustmoerDetails";
import Notification from "layouts/notification";
import GlobalConfig from "layouts/globalConfiguration/index";
import ThreeCxLicencesConfig from "layouts/globalConfiguration/threeCxLicencesConfig";
import Subscriptions from "layouts/Subscriptions/SubscriptionList";
import SubscriptionDetails from "layouts/Subscriptions/SubscriptionDetails";
import GeoCodeList from "layouts/GeoCode/GeoCodeList";
import SupplierList from "layouts/supplier/SupplierList";
import GeoCodeAction from "layouts/GeoCode/GeoCodeAction";
import AddRangeHolder from "layouts/rangeHolder/addRangeHolder";
import RangeHolderList from "layouts/rangeHolder/RangeHolderList";
import NonGeoAreaList from "layouts/nonGeoArea/NonGeoArea";
import NonGeoCodeAction from "layouts/nonGeoArea/NonGeoAreaAction";
import AddDid from "layouts/DID/AddDid";
import AddSupplier from "layouts/supplier/addSupplier";
import DidInventoryDetails from "layouts/DID/DidInventoryDetails";
import PurchasedTable from "layouts/DID/PurchasedDIDs";
import AssignDID from "layouts/DID/AssignDID";
import ViewPurchase from "layouts/DID/ViewPurchase";
import ProductCatagory from "layouts/Products/ProductCatagory";
import AddProductCategory from "layouts/Products/AddProductCategory";
import ProductMaster from "layouts/Products/ProductMaster";
import AddProductMaster from "layouts/Products/AddProductMaster";
import PartnerManagement from "layouts/partner/PartnerManagement";
import AddPartner from "layouts/partner/CreatePartner";
import ViewPartner from "layouts/partner/viewPartnerManagement";
import RateScheme from "layouts/Products/RateScheme";
import Provider from "layouts/Provider/Provider";
import ProviderAction from "layouts/Provider/ProviderAction";
import ViewProvider from "layouts/Provider/ViewProvider";

const useRoutes = () => {
  const { permissions } = useSelector((state) => state.auth);
  const userPermissions = matchPermissions(permissions, Permission);

  const perMissionsroutes = [
    {
      element: <LoginAuth />,
      errorElement: <LoginWithPass />,
      children: [
        {
          path: "/",
          element: <LogIn />,
          children: [
            {
              index: true,
              element: <LoginWithPass />,
            },
            {
              element: <OtpVerify />,
              path: "OtpVerify/:id",
            },
            {
              element: <EnterEmail />,
              path: "forgot-password",
            },
            {
              element: <ForgetPassword />,
              path: "reset-password",
            },
          ],
        },
      ],
    },

    {
      path: "",
      element: <RootLayout />,
      errorElement: <NotFound />,
      protected: true,
      children: [
        {
          element: <CreateCustomer />,
          path: "/add-customer",
          index: true,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
          index: true,
        },
        {
          element: <Notification />,
          path: "notification",
        },
        {
          path: "profile",
          element: <Profile />,
          children: [
            {
              element: <ProfileDetails />,
              index: true,
            },
            {
              element: <ChangePassword />,
              path: "change-password",
            },
            {
              element: <TwoFactorAuthntication />,
              path: "two-factor-authentication",
            },
          ],
        },
        {
          path: "/log-out",
          element: <LogOut />,
        },
      ],
    },
  ];

  const rootChildrens = perMissionsroutes[1].children;

  const rootChildrenRoutes = {
    price: {
      path: "/pricing",
      element: <Pricing />,
      index: true,
    },
    partner: {
      path: "/partner-management",
      element: (
        <ProtectedRoute permission="partner" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="partner" action="VIEW">
              <PartnerManagement />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="partner" action="CREATE">
              <AddPartner />
            </ProtectedRoute>
          ),
          path: "create-partner",
        },
        {
          element: (
            <ProtectedRoute permission="partner" action="UPDATE">
              <AddPartner />
            </ProtectedRoute>
          ),
          path: "edit-partner",
        },
        {
          element: (
            <ProtectedRoute permission="partner" action="VIEW">
              <ViewPartner />
            </ProtectedRoute>
          ),
          path: "view-partner",
        },
      ],
    },
    Assigndid: {
      path: "/assign-dids",
      element: (
        <ProtectedRoute permission="Assigndid" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="Assigndid" action="VIEW">
              <AssignDIDsTable />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="Assigndid" action="CREATE">
              <AssignNewDID />
            </ProtectedRoute>
          ),
          path: "assign-new-did",
        },
      ],
    },
    threecx_licences: {
      path: "/3cx-details",
      element: (
        <ProtectedRoute permission="threecx_licences" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="threecx_licences" action="VIEW">
              <ThreeCXDetails />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: <ThreeCXView />,
          path: "view-3cx-details",
        },
      ],
    },
    provider: {
      path: "/providers",
      element: (
        <ProtectedRoute permission="provider" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="provider" action="VIEW">
              <Provider />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="provider" action="CREATE">
              <ProviderAction />
            </ProtectedRoute>
          ),
          path: "add-provider",
        },
        {
          element: (
            <ProtectedRoute permission="provider" action="UPDATE">
              <ProviderAction />
            </ProtectedRoute>
          ),
          path: "edit-provider",
        },
        {
          element: (
            <ProtectedRoute permission="provider" action="VIEW">
              <ViewProvider />
            </ProtectedRoute>
          ),
          path: "view-provider",
        },
      ],
    },
    products: {
      path: "/product-category",
      element: (
        <ProtectedRoute permission="products" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="products" action="VIEW">
              <ProductCatagory />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="products" action="CREATE">
              <AddProductCategory />
            </ProtectedRoute>
          ),
          path: "add-product-category",
        },
        {
          element: (
            <ProtectedRoute permission="products" action="UPDATE">
              <AddProductCategory />
            </ProtectedRoute>
          ),
          path: "edit-product-category",
        },
      ],
    },
    product_master: {
      path: "/product-master",
      element: (
        <ProtectedRoute permission="product_master" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="product_master" action="VIEW">
              <ProductMaster />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="product_master" action="CREATE">
              <AddProductMaster />
            </ProtectedRoute>
          ),
          path: "add-product-master",
        },
        {
          element: (
            <ProtectedRoute permission="product_master" action="UPDATE">
              <AddProductMaster />
            </ProtectedRoute>
          ),
          path: "edit-product-master",
        },
      ],
    },
    rate_scheme: {
      path: "/rate-scheme",
      element: (
        <ProtectedRoute permission="rate_scheme" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="rate_scheme" action="VIEW">
              <RateScheme />
            </ProtectedRoute>
          ),
          index: true,
        },
      ],
    },
    geo_area: {
      path: "/geo-area-code",
      element: (
        <ProtectedRoute permission="geo_area" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="geo_area" action="VIEW">
              <GeoCodeList />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="geo_area" action="CREATE">
              <GeoCodeAction />
            </ProtectedRoute>
          ),
          path: "add-geo-area-code",
        },
        {
          element: (
            <ProtectedRoute permission="geo_area" action="UPDATE">
              <GeoCodeAction />
            </ProtectedRoute>
          ),
          path: "edit-geo-area-code",
        },
      ],
    },
    non_geo_area: {
      path: "/non-geo-area-code",
      element: (
        <ProtectedRoute permission="non_geo_area" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="non_geo_area" action="VIEW">
              <NonGeoAreaList />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="non_geo_area" action="CREATE">
              <NonGeoCodeAction />
            </ProtectedRoute>
          ),
          path: "add-non-geo-area-code",
        },
        {
          element: (
            <ProtectedRoute permission="non_geo_area" action="UPDATE">
              <NonGeoCodeAction />
            </ProtectedRoute>
          ),
          path: "edit-non-geo-area-code",
        },
      ],
    },
    global_config: {
      path: "global-configuration",
      element: (
        <ProtectedRoute permission="global_config" action="VIEW">
          <GlobalConfig />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="global_config" action="VIEW">
              <GlobalConfig />
            </ProtectedRoute>
          ),
        },
        {
          element: <ThreeCxLicencesConfig />,
          path: ":id",
          index: true,
        },
      ],
      // children: [
      //   {
      //     element: <ThreeCxLicencesConfig />,
      //     path: ":id",
      //     index: true,
      //   },
      // ],
    },
    threecx_licences_request: {
      path: "3cx-request-approval",
      element: (
        <ProtectedRoute permission="threecx_licences_request" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),

      children: [
        {
          element: (
            <ProtectedRoute permission="threecx_licences_request" action="VIEW">
              <ThreeCXRequestApproval />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="threecx_licences_request" action="CREATE">
              <BuyLiecence />
            </ProtectedRoute>
          ),
          path: "assign-licence",
        },
      ],
    },
    supplier: {
      path: "/supplier",
      element: (
        <ProtectedRoute permission="supplier" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="supplier" action="VIEW">
              <SupplierList />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="supplier" action="CREATE">
              <AddSupplier />
            </ProtectedRoute>
          ),
          path: "add-supplier",
        },
        {
          element: (
            <ProtectedRoute permission="supplier" action="UPDATE">
              <AddSupplier />
            </ProtectedRoute>
          ),
          path: "edit-supplier",
        },
      ],
    },
    rangeHolder: {
      path: "/range-holder",
      element: (
        <ProtectedRoute permission="rangeHolder" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="rangeHolder" action="VIEW">
              <RangeHolderList />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="rangeHolder" action="CREATE">
              <AddRangeHolder />
            </ProtectedRoute>
          ),
          path: "add-range-holder",
        },
        {
          element: (
            <ProtectedRoute permission="rangeHolder" action="UPDATE">
              <AddRangeHolder />
            </ProtectedRoute>
          ),
          path: "edit-range-holder",
        },
      ],
    },
    cron: {
      path: "/cron-history",
      element: (
        <ProtectedRoute permission="cron" action="VIEW">
          <CronStatusDetails />
        </ProtectedRoute>
      ),
      index: true,
    },
    customer: {
      path: "/customer",
      element: (
        <ProtectedRoute permission="customer" action="VIEW">
          <CommonCustomerModules />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <CustomerTable />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="CREATE">
              <CreateCustomer />
            </ProtectedRoute>
          ),
          path: "add-customer",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="UPDATE">
              <EditCustomer />
            </ProtectedRoute>
          ),
          path: "edit-customer/account-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <PartnerDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/partner-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <DiscountDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/discount-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <DIDDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/did-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <SIPTrunkDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/sip-trunk-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <NumberPortinDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/number-port-in-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <ThreeCXCustmoerDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/3cx-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <TransactionDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/transaction-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <OrderDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/order-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <CdrDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/cdr-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <PaymentDetails />
            </ProtectedRoute>
          ),
          path: "edit-customer/invoice-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="UPDATE">
              <SpendLimit />
            </ProtectedRoute>
          ),
          path: "edit-customer/spend-limit-details/:id/:accnt_num",
        },

        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <EditCustomer />
            </ProtectedRoute>
          ),
          path: "view-customer/account-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <PartnerDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/partner-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <DiscountDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/discount-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <DIDDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/did-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <SIPTrunkDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/sip-trunk-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <NumberPortinDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/number-port-in-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <ThreeCXCustmoerDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/3cx-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <TransactionDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/transaction-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <OrderDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/order-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <CdrDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/cdr-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <PaymentDetails />
            </ProtectedRoute>
          ),
          path: "view-customer/invoice-details/:id/:accnt_num",
        },
        {
          element: (
            <ProtectedRoute permission="customer" action="VIEW">
              <SpendLimit />
            </ProtectedRoute>
          ),
          path: "view-customer/spend-limit-details/:id/:accnt_num",
        },
      ],
    },
    didinventory: {
      path: "/did-inventory",
      element: (
        <ProtectedRoute permission="didinventory" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="didinventory" action="VIEW">
              <DIDTable />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="didinventory" action="CREATE">
              <AddDid />
            </ProtectedRoute>
          ),
          path: "add-new-did",
        },
        {
          element: (
            <ProtectedRoute permission="didinventory" action="UPDATE">
              <DidInventoryDetails />
            </ProtectedRoute>
          ),
          path: "edit-did-inventory",
        },
        {
          element: (
            <ProtectedRoute permission="didinventory" action="VIEW">
              <DidInventoryDetails />
            </ProtectedRoute>
          ),
          path: "view-did-details",
        },
        {
          element: (
            <ProtectedRoute permission="didinventory" action="UPDATE">
              <AssignDID />
            </ProtectedRoute>
          ),
          path: "assign-did",
        },
      ],
    },
    purchasedDid: {
      path: "/purchased-dids",
      element: (
        <ProtectedRoute permission="purchasedDid" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="purchasedDid" action="VIEW">
              <PurchasedTable />
            </ProtectedRoute>
          ),
          index: true,
        },
        // {
        //   element: (
        //     <ProtectedRoute permission="purchasedDid" action="CREATE">
        //       <AddDid />
        //     </ProtectedRoute>
        //   ),
        //   path: "add-new-did",
        // },
        {
          element: (
            <ProtectedRoute permission="purchasedDid" action="UPDATE">
              <ViewPurchase />
            </ProtectedRoute>
          ),
          path: "view-purchased-did",
        },
        {
          element: (
            <ProtectedRoute permission="purchasedDid" action="UPDATE">
              <ViewPurchase />
            </ProtectedRoute>
          ),
          path: "edit-purchased-did",
        },
        {
          element: (
            <ProtectedRoute permission="purchasedDid" action="VIEW">
              <DidInventoryDetails />
            </ProtectedRoute>
          ),
          path: "view-did-details",
        },
        {
          element: (
            <ProtectedRoute permission="Assigndid" action="UPDATE">
              <DIDTable />
            </ProtectedRoute>
          ),
          path: "assign-did",
        },
      ],
    },
    removal_request: {
      path: "/removal-requests",
      element: (
        <ProtectedRoute permission="removal_request" action="VIEW">
          <RequestApprovalTable />
        </ProtectedRoute>
      ),
      index: true,
    },
    roles: {
      path: "/roles-&-permission",
      element: (
        <ProtectedRoute permission="roles" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="roles" action="VIEW">
              <Roles />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="roles" action="CREATE">
              <AddRole />
            </ProtectedRoute>
          ),
          path: "add-role",
        },
        {
          element: (
            <ProtectedRoute permission="roles" action="UPDATE">
              <AddRole />
            </ProtectedRoute>
          ),
          path: "update-role",
        },
        {
          element: (
            <ProtectedRoute permission="roles" action="UPDATE">
              <AddRole />
            </ProtectedRoute>
          ),
          path: "edit-role",
        },
      ],
    },
    subscription: {
      path: "subscriptions",
      element: (
        <ProtectedRoute permission="subscription" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="subscription" action="VIEW">
              <Subscriptions />{" "}
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="subscription" action="VIEW">
              <SubscriptionDetails />
            </ProtectedRoute>
          ),
          path: "view-subscription",
        },
        {
          element: (
            <ProtectedRoute permission="subscription" action="UPDATE">
              <SubscriptionDetails />
            </ProtectedRoute>
          ),
          path: "edit-subscription",
        },
      ],
    },
    sites_pages: {
      path: "/pages",
      element: (
        <ProtectedRoute permission="sites_pages" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="sites_pages" action="VIEW">
              <Terms />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="sites_pages" action="UPDATE">
              <NewPages />
            </ProtectedRoute>
          ),
          path: "edit-pages/:id",
        },
      ],
    },
    user_management: {
      path: "/user-management",
      element: (
        <ProtectedRoute permission="user_management" action="VIEW">
          <RootOutlet />
        </ProtectedRoute>
      ),
      children: [
        {
          element: (
            <ProtectedRoute permission="user_management" action="VIEW">
              <AdminTable />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          element: (
            <ProtectedRoute permission="user_management" action="CREATE">
              <CreateAdmin />
            </ProtectedRoute>
          ),
          path: "add-user",
        },
        {
          element: (
            <ProtectedRoute permission="user_management" action="UPDATE">
              <EditAdmin />
            </ProtectedRoute>
          ),
          path: "edit-user",
        },
      ],
    },
  };

  // Define your routes as usual

  const dashboardChildrens = [
    "price",
    "partner",
    "provider",
    "Assigndid",
    "cron",
    "customer",
    "supplier",
    "didinventory",
    "rangeHolder",
    "removal_request",
    "roles",
    "ThreeCXRequestApproval",
    "threecx_licences_request",
    "sites_pages",
    "user_management",
    "geo_area",
    "non_geo_area",
    "purchasedDid",
    "threecx_licences",
    "subscription",
    "global_config",
    "products",
    "product_master",
    "rate_scheme",
  ];

  const viewOnlyRoutes = [
    "roles",
    "ThreeCXRequestApproval",
    "threecx_licences_request",
    "provider",
    "sites_pages",
    "customer",
    "supplier",
    "rangeHolder",
    "geo_area",
    "non_geo_area",
    "purchasedDid",
    "Assigndid",
    "user_management",
    "dashboard",
    "price",
    "didinventory",
    "removal_request",
    "threecx_licences",
    "subscription",
    "global_config",
    "products",
    "product_master",
    "partner",
    "rate_scheme",
  ];

  dashboardChildrens.forEach((el) => {
    if (viewOnlyRoutes.includes(el)) {
      if (userPermissions[el]?.VIEW) {
        rootChildrens.push(rootChildrenRoutes[el]);
      }
    } else {
      if (userPermissions[el]?.VIEW && userPermissions[el]?.CREATE) {
        rootChildrens.push(rootChildrenRoutes[el]);
      }
    }
  });

  return {
    userPermissions,
    perMissionsroutes,
  };
};

export default useRoutes;
