import React, { useEffect, useState } from "react";
import { Card, Grid, FormLabel, Box } from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDropDownList from "components/SuiDropDownList";
import colors from "assets/theme/base/colors";
import { AddProviderVal } from "Validation";
import { useProductListInfoMutation } from "app/features/api/AuthApi";
import { useGetAllCountryMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { importImg } from "Validation";
import ProfileUploadUI from "components/SuiFileUploadInput/ProfileUploadUI";
import companyLogo from "../../assets/images/dashboard/company-logo.svg";
import { useAddProviderMutation } from "app/features/api/AuthApi";
import { useEditProviderMutation } from "app/features/api/AuthApi";
import { useGetActiveProvidersMutation } from "app/features/api/AuthApi";
import { useProviderGetByIdMutation } from "app/features/api/AuthApi";
import { getFileExtension } from "helper";

const ProviderAction = () => {
  const id = localStorage.getItem("partner_id");
  const [error, setError] = useState("");
  const [AddProvider] = useAddProviderMutation();
  const [setProductList] = useState();
  const suiSnackbar = useSuiSnackbar();
  const [EditProvider] = useEditProviderMutation();
  const navigate = useNavigate();

  const priceFormik = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: importImg,
    onSubmit: () => {},
  });
  const [ProductListInfo] = useProductListInfoMutation();

  const [getActiveProviders] = useGetActiveProvidersMutation();

  const formik = useFormik({
    initialValues: {
      provider_name: "",
      email_id: "",
      phone_no: "",
      status: 1,
      rate_scheme: "",
      add_line_1: "",
      add_line_2: "",
      add_line_3: "",
      passcode: "",
      website_link: "",
      vat_link: "",
      id: "",
      file: "",
      Country: "",
      country_id: "",
      ActivePro: "",
      ActivePro_id: "",
      img: "",
      bank_name: "",
      accnt_holder: "",
      accnt_number: "",
      accnt_sort: "",
    },
    validationSchema: AddProviderVal,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      if (id) {
        if (values.provider_name != tabInfoVal.provider_name) {
          formData.append("provider_name", values.provider_name);
        }
        if (values.email_id != tabInfoVal.provider_email) {
          formData.append("provider_email", values.email_id);
        }
        if (values.phone_no != tabInfoVal.provider_phone_number) {
          formData.append("provider_phone_number", values.phone_no);
        }
        if (values.ActivePro?.id != tabInfoVal.rate_scheme_id) {
          formData.append("rate_scheme_id", values.ActivePro?.id);
        }
        if (values.add_line_1 != tabInfoVal.provider_address_line_1) {
          formData.append("provider_address_line_1", values.add_line_1);
        }
        if (values.add_line_2 != tabInfoVal.provider_address_line_2) {
          formData.append("provider_address_line_2", values.add_line_2);
        }
        if (values.add_line_3 != tabInfoVal.provider_address_line_3) {
          formData.append("provider_address_line_3", values.add_line_3);
        }
        if (values.country_id != tabInfoVal.country_id) {
          formData.append("country_id", values.Country.id);
        }
        if (values.passcode != tabInfoVal.provider_postal_code) {
          formData.append("provider_postal_code", values.passcode);
        }
        if (values.website_link != tabInfoVal.provider_website_link) {
          formData.append("provider_website_link", values.website_link);
        }
        if (values.vat_link != tabInfoVal.provider_vat_number) {
          formData.append("provider_vat_number", values.vat_link);
        }
        if (values.status != tabInfoVal.provider_status) {
          formData.append("provider_status", String(values.status));
        }
        if (values.bank_name != tabInfoVal.provider_bank_name) {
          formData.append("provider_bank_name", String(values.bank_name));
        }
        if (values.accnt_holder != tabInfoVal.provider_bank_account_holder_name) {
          formData.append("provider_bank_account_holder_name", String(values.accnt_holder));
        }
        if (values.accnt_number != tabInfoVal.provider_bank_account_number) {
          formData.append("provider_bank_account_number", String(values.accnt_number));
        }
        if (values.accnt_sort != tabInfoVal.provider_bank_sort_code) {
          formData.append("provider_bank_sort_code", String(values.accnt_sort));
        }
      } else {
        formData.append("provider_name", values.provider_name);
        formData.append("provider_email", values.email_id);
        formData.append("provider_phone_number", values.phone_no);
        formData.append("rate_scheme_id", values.ActivePro?.id);
        formData.append("provider_address_line_1", values.add_line_1);
        formData.append("provider_address_line_2", values.add_line_2);
        formData.append("provider_address_line_3", values.add_line_3);
        formData.append("country_id", values.Country.id);
        formData.append("provider_postal_code", values.passcode);
        formData.append("provider_website_link", values.website_link);
        formData.append("provider_vat_number", values.vat_link);
        formData.append("provider_status", String(values.status));
        formData.append("provider_bank_name", String(values.bank_name));
        formData.append("provider_bank_account_holder_name", String(values.accnt_holder));
        formData.append("provider_bank_account_number", String(values.accnt_number));
        formData.append("provider_bank_sort_code", String(values.accnt_sort));
      }

      if (values.file) {
        formData.append("image", values.file);
      }
      if (!values.file && previewUrl == null) {
        formData.append("provider_company_logo_img_path", "");
      }
      if (previewUrl != null) {
        formData.append("image", previewUrl);
        formData.append("provider_company_logo_img_path", previewUrl);
      }

      try {
        if (id) {
          formData.id = id;
          let response = await EditProvider(formData).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/providers");
            setError("");
          }
        } else {
          let response = await AddProvider(formData).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            navigate("/providers");
            suiSnackbar(response.message, "success");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  const [uploadedFile, setUploadedFile] = useState(null);

  const [previewUrl, setPreviewUrl] = useState(null);
  const handleFileChange = (file) => {
    setUploadedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    formik.setFieldValue("file", file);
  };
  const removeFileNamefn = () => {
    setUploadedFile(null);
    setPreviewUrl(null);
    formik.setFieldValue("file", "");
    formik.setFieldValue("partner_logo_img_path", "");
  };
  //   const handleFileChange = (file) => {
  //     console.log(file);
  //     priceFormik.setFieldValue("file", file);
  //   };
  //   const removeFileName = () => {
  //     return;
  //   };
  const [countryList, setCountryList] = useState();
  const [ActiveProvider, setActiveProvider] = useState();
  const [GetAllCountry] = useGetAllCountryMutation();
  const getCountry = async () => {
    try {
      const response = await GetAllCountry().unwrap();
      setCountryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getActive = async () => {
    const params = {
      rate_scheme_type: "partner",
    };
    try {
      const response = await getActiveProviders(params).unwrap();
      const val = response.data.find((item) => item?.rate_scheme_is_default_partner == 1);
      setActiveProvider(response.data);
      if (!id) {
        formik.setFieldValue("ActivePro", { id: val?.rate_scheme_id, name: val?.rate_scheme_name });
        formik.setFieldValue("ActivePro_id", val?.rate_scheme_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const options = countryList?.map((item) => ({
    id: item.country_id,
    name: item.country_name,
  }));
  const ActiveProviderOptions = ActiveProvider?.map((item) => ({
    id: item.rate_scheme_id,
    name: item.rate_scheme_name,
  }));
  const [ProviderGetById] = useProviderGetByIdMutation();
  const [tabInfoVal, setTabInfoVal] = useState();
  const handleDownload = async (path) => {
    try {
      const response = await fetch(path, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const filePath = tabInfoVal?.provider_company_logo_img_path;
      const fileExtension = getFileExtension(filePath);
      link.setAttribute("download", `provider_logo.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  console.log(formik.values, "Formik value");
  console.log(formik.errors, "Formik error");

  const tabInfo = async (id) => {
    try {
      const response = await ProviderGetById(id).unwrap();
      setTabInfoVal(response.data);
      setPreviewUrl(
        response.data.provider_company_logo_img_path === "" ||
          response.data.provider_company_logo_img_path === null ||
          response.data.provider_company_logo_img_path === "null"
          ? null
          : process.env.REACT_APP_BASE_URL + response.data.provider_company_logo_img_path
      );
      // formik.setFieldValue(
      //   "file",
      //   response.data.provider_company_logo_img_path === "" ||
      //     response.data.provider_company_logo_img_path === null
      //     ? null
      //     : response.data.provider_company_logo_img_path
      // );
      formik.setFieldValue("provider_name", response.data.provider_name);
      formik.setFieldValue("bank_name", response.data.provider_bank_name);
      formik.setFieldValue("accnt_holder", response.data.provider_bank_account_holder_name);
      formik.setFieldValue("accnt_number", response.data.provider_bank_account_number);
      formik.setFieldValue("accnt_sort", response.data.provider_bank_sort_code);
      formik.setFieldValue("email_id", response.data.provider_email);
      formik.setFieldValue("status", response.data.provider_status);
      formik.setFieldValue("phone_no", response.data.provider_phone_number);
      formik.setFieldValue("ActivePro_id", response.data.rate_schemes.rate_scheme_id);
      formik.setFieldValue("add_line_1", response.data.provider_address_line_1);
      formik.setFieldValue("add_line_2", response.data.provider_address_line_2);
      formik.setFieldValue("add_line_3", response.data.provider_address_line_3);
      formik.setFieldValue("passcode", response.data.provider_postal_code);
      formik.setFieldValue("website_link", response.data.provider_website_link);
      formik.setFieldValue("country_id", response.data.countries.country_id);
      formik.setFieldValue("vat_link", response.data.provider_vat_number);
      formik.setFieldValue("img", response.data.provider_company_logo_img_path);

      const transformedCountry = {
        id: response.data.countries.country_id,
        name: response.data.countries.country_name,
      };
      const transformedRate = {
        id: response.data.rate_schemes.rate_scheme_id,
        name: response.data.rate_schemes.rate_scheme_name,
      };
      formik.setFieldValue("Country", transformedCountry);
      formik.setFieldValue("ActivePro", transformedRate);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const GetProductListData = async () => {
    const params = {
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  const GetProductListDataById = async () => {
    const params = {
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  useEffect(() => {
    if (id) {
      GetProductListDataById();
      tabInfo(id);
    }
    if (!id == "1%") {
      GetProductListData();
    }
    getCountry();
    getActive();
  }, [id]);
  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Basic Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Provider Name
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Provider Name"
              id="provider_name"
              name="provider_name"
              value={formik.values.provider_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.provider_name}
              error={formik.errors.provider_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Email Id
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Email Id"
              id="email_id"
              name="email_id"
              value={formik.values.email_id}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.email_id}
              error={formik.errors.email_id}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Phone Number
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Phone Number"
              id="phone_no"
              name="phone_no"
              value={formik.values.phone_no}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.phone_no}
              error={formik.errors.phone_no}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="status" required>
              Status
            </FormLabel>
            <SuiDropDownList
              data={[
                { id: "1", label: "Active" },
                { id: "0", label: "Inactive" },
              ]}
              select={{
                key: "id",
                valueKey: "id",
                labelName: "label",
                label: "Status",
                name: "status",
                val: formik.values.status,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent touched={formik.touched.status} error={formik.errors.status} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="company_address" required>
              Rate Scheme
            </FormLabel>
            <AutocompleteSingleSelect
              id="rate_scheme"
              value={formik.values.ActivePro}
              setValue={(value) => {
                formik.setFieldValue("ActivePro", value);
                formik.setFieldValue("ActivePro_id", value.name);
              }}
              noOptionsText="No options"
              placeholder="Rate Scheme"
              isLoading={false}
              disabled={false}
              options={ActiveProviderOptions}
              formik={formik}
            />
            {formik.errors.ActivePro_id && formik.touched.ActivePro && (
              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                {formik.errors.ActivePro_id}
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Address Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Address Line 1
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Address Line 1"
              id="add_line_1"
              name="add_line_1"
              value={formik.values.add_line_1}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.add_line_1}
              error={formik.errors.add_line_1}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Address Line 2</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Address Line 2"
              id="add_line_2"
              name="add_line_2"
              value={formik.values.add_line_2}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.add_line_2}
              error={formik.errors.add_line_2}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Address Line 3</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Address Line 3"
              id="add_line_3"
              name="add_line_3"
              value={formik.values.add_line_3}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.add_line_3}
              error={formik.errors.add_line_3}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="company_address" required>
              Country
            </FormLabel>
            <AutocompleteSingleSelect
              id="Country"
              value={formik.values.Country}
              setValue={(value) => {
                formik.setFieldValue("Country", value);
                formik.setFieldValue("country_id", value.name);
              }}
              noOptionsText="No options"
              placeholder="Select Country"
              isLoading={false}
              disabled={false}
              options={options}
              formik={formik}
            />
            {formik.errors.country_id && formik.touched.Country && (
              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>{formik.errors.country_id}</div>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Postal Code
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Postal Code"
              id="passcode"
              name="passcode"
              value={formik.values.passcode}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.passcode}
              error={formik.errors.passcode}
            />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Company Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Website Link</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Website  Link"
              id="website_link"
              name="website_link"
              value={formik.values.website_link}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.website_link}
              error={formik.errors.website_link}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">VAT Number</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter VAT Number"
              id="vat_link"
              name="vat_link"
              value={formik.values.vat_link}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.vat_link}
              error={formik.errors.vat_link}
            />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography
              fontWeight="normal"
              fontSize={16}
              sx={{
                color: `${colors.text.focus} !important`,
              }}
            >
              {"Company Logo"}
            </SuiTypography>
          </Grid>
          <Grid ml={2} item xs={12} lg={6}>
            <Box
              className="company-image-upload"
              mt={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#E9E9E9",
                  width: "70px",
                  height: "70px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={previewUrl === null ? companyLogo : previewUrl}
                  //   src={previewUrl}
                  className={previewUrl === null ? "company-preview-set" : "comapny-image-set"}
                  alt="Company Logo"
                />
              </Box>
              <Box>
                <ProfileUploadUI
                  name="file"
                  id="file"
                  label={!uploadedFile ? "Upload Logo" : "Remove"}
                  accept=".png, .jpg, .jpeg, .svg"
                  getFile={handleFileChange}
                  removeFileName={removeFileNamefn}
                  type="false"
                  tabInfoVal={tabInfoVal?.provider_company_logo_img_path}
                  formik={formik}
                />
              </Box>
            </Box>
            {id && previewUrl !== null && (
              <SuiButton
                variant="outlined"
                buttonColor="info"
                customClass="linkSet"
                onClick={() =>
                  handleDownload(
                    `${process.env.REACT_APP_BASE_URL}${tabInfoVal?.provider_company_logo_img_path}`
                  )
                }
              >
                Download
              </SuiButton>
            )}
            <ErrorMessageComponent touched={formik.touched.file} error={formik.errors.file} />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Bank Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Bank Name</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Bank Name"
              id="bank_name"
              name="bank_name"
              value={formik.values.bank_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.bank_name}
              error={formik.errors.bank_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Account Holder Name</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Account Holder Name"
              id="accnt_holder"
              name="accnt_holder"
              value={formik.values.accnt_holder}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.accnt_holder}
              error={formik.errors.accnt_holder}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Account Number</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Account Number"
              id="accnt_number"
              name="accnt_number"
              value={formik.values.accnt_number}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.accnt_number}
              error={formik.errors.accnt_number}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Bank Sort Code</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Bank Sort Code"
              id="accnt_sort"
              name="accnt_sort"
              value={formik.values.accnt_sort}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.accnt_sort}
              error={formik.errors.accnt_sort}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/providers"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  onClick={async () => {
                    if (formik.values.check_white) {
                      const isPriceFormValid = await priceFormik.validateForm();
                      if (isPriceFormValid && priceFormik.dirty) {
                        priceFormik.handleSubmit();
                        formik.handleSubmit();
                      }
                    }
                  }}
                  disabled={!formik.dirty}
                >
                  {id ? "Update" : "Save"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProviderAction;
