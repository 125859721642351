import { CircularProgress } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useDayOfPlanMutation } from "app/features/api/AuthApi";
import { setCheckTimeOfdayValue } from "app/features/DashboardSlice";

const DayOfPlan = ({ isLoading, name, setValue, formik, type, disabled }) => {
  const [dayOfPlan] = useDayOfPlanMutation();
  const [timeOfDay, setTimeOfDay] = useState([]);
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    name: "",
  });
  const timeout = useRef();
  const { dayOfplans } = useSelector((state) => state.dashboard);
  const updatedCategories = timeOfDay.map((category) => {
    return {
      id: category.id,
      name: category.category_name,
    };
  });
  useEffect(() => {
    const checkTimeofdayValue = selectedCountry;

    dispatch(setCheckTimeOfdayValue(checkTimeofdayValue));
  }, [selectedCountry]);
  const fetchData = async () => {
    try {
      const response = await dayOfPlan().unwrap();
      setTimeOfDay(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (type == "editProfile") {
      if (dayOfplans?.id == null && dayOfplans?.name == undefined) {
        setSelectedCountry({ id: "", name: "" });
      } else {
        setSelectedCountry({ id: dayOfplans?.id, name: dayOfplans?.name });
      }
    } else {
      setSelectedCountry({ id: "", name: "" });
    }
    if (formik && formik.values) {
      if (selectedCountry.name?.length <= 0) {
        formik.setFieldValue("cus_day_of_plan", dayOfplans?.id);
      }
    }
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [dayOfplans]);

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
      setValue(newValue.id);
    } else {
      setSelectedCountry({
        id: "",
        name: "",
      });
      setValue("");
    }
  };

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, fill: "#A0B5BE" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "none",
          bgcolor: isLoading ? "#E5EBED !important" : "#FFF",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
      }}
      name={name}
      value={selectedCountry}
      onChange={handleAutocompleteChange}
      options={updatedCategories}
      getOptionLabel={(option) => option?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Time of day Category"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="info" size={18} /> : null}
                {params.InputProps.endAdornment}
                {React.cloneElement(params.InputProps.endAdornment, {
                  onClick: () => {
                    formik.setFieldValue("cus_time_of_day_category", "");
                  },
                })}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default DayOfPlan;
