import pxToRem from "assets/theme/functions/pxToRem";

const ProductIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height={pxToRem(size)} viewBox="0 0 24 24">
      <path d="M21.93 7.65038V7.49038L19.51 3.49038C19.2419 3.02997 18.856 2.64926 18.392 2.38739C17.9281 2.12552 17.4027 1.99194 16.87 2.00038H7.13C6.61203 2.00117 6.10309 2.13605 5.65272 2.3919C5.20235 2.64776 4.82589 3.01586 4.56 3.46038L2.14 7.46038V7.62038C2.06791 7.73617 2.02026 7.86549 2 8.00038V19.0004C2 19.796 2.31607 20.5591 2.87868 21.1217C3.44129 21.6843 4.20435 22.0004 5 22.0004H19C19.7956 22.0004 20.5587 21.6843 21.1213 21.1217C21.6839 20.5591 22 19.796 22 19.0004V8.00038C21.9978 7.88046 21.9741 7.7619 21.93 7.65038ZM17.73 4.48038L19.23 7.00038H13V4.00038H16.87C17.0435 4.00041 17.2141 4.0456 17.3649 4.13151C17.5157 4.21742 17.6415 4.3411 17.73 4.49038V4.48038ZM6.28 4.48038C6.36886 4.33441 6.49365 4.21364 6.64245 4.12961C6.79126 4.04558 6.95911 4.00109 7.13 4.00038H11V7.00038H4.77L6.28 4.48038ZM20 19.0004C20 19.2656 19.8946 19.5199 19.7071 19.7075C19.5196 19.895 19.2652 20.0004 19 20.0004H5C4.73478 20.0004 4.48043 19.895 4.29289 19.7075C4.10536 19.5199 4 19.2656 4 19.0004V9.00038H20V19.0004Z" />
      <path d="M9 12C9 12.2652 9.10536 12.5196 9.29289 12.7071C9.48043 12.8946 9.73478 13 10 13H14C14.2652 13 14.5196 12.8946 14.7071 12.7071C14.8946 12.5196 15 12.2652 15 12C15 11.7348 14.8946 11.4804 14.7071 11.2929C14.5196 11.1054 14.2652 11 14 11H10C9.73478 11 9.48043 11.1054 9.29289 11.2929C9.10536 11.4804 9 11.7348 9 12Z" />
    </svg>
  );
};

export default ProductIcon;
