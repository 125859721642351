import React from "react";
import {
  Grid,
  Card,
  TableCell,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  DialogContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useEffect } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useDeleteProductCategoryMutation } from "app/features/api/AuthApi";
import SuiDialog from "components/SuiDialog";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import DeleteIcon from "Container/Icons/DeleteIcon";
import EditIcon from "Container/Icons/EditIcon";
import SuiDropDownList from "components/SuiDropDownList";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { useProductCategoryListMutation } from "app/features/api/AuthApi";

const ProductCatagory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [roleInfo, setRoleInfo] = useState("");
  const [getProductCategoryList, { isLoading }] = useProductCategoryListMutation();
  const navigateSearch = useNavigateSearch();
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [deleteProductCategory] = useDeleteProductCategoryMutation();
  const [deleteID, setDeleteID] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/product-category", { page: page });
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/product-category", { page: 1 });
  };
  const deleteModal = (id) => {
    console.log("Delete id is:::", id);
    setDeleteID(id);
    setOpenModal(true);
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await deleteProductCategory(id).unwrap();
      if (response.status === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
        showData(perPage, currentPage);
      }
    } catch (error) {
      suiSnackbar(error?.data?.message || "Failed to delete category", "error");
      setOpenModal(false);
    }
  };
  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "Category name" },
    { id: "2", align: "left", width: "auto", label: "Status" },
  ];
  const isActions =
    permissionInfo?.products?.UPDATE || permissionInfo?.products?.DELETE
      ? {
          id: "3",
          align: "right",
          width: "auto",
          label: "Action",
        }
      : null;
  // Edit category
  const editRedirect = (item) => {
    localStorage.setItem("prod_category_id", item);
    navigateSearch(`edit-product-category`);
  };
  const formik = useFormik({
    initialValues: {
      prod_category_name: "",
      prod_category_status: "",
    },

    // enableReinitialize: true,
    onSubmit: () => {
      setCurrentPage(1);
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.prod_category_name = "";
      formik.values.prod_category_status = "";
      setCurrentPage(1);
      showData(perPage, 1); // Call showData with initial page
    },
  });
  let showData = async (perPage, currentPage) => {
    navigateSearch("/product-category", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.prod_category_name) {
      urlParamss.prod_category_name = formik.values.prod_category_name;
    }
    if (formik.values.prod_category_status) {
      urlParamss.prod_category_status = formik.values.prod_category_status;
    }
    try {
      const response = await getProductCategoryList(urlParamss).unwrap();
      setRoleInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setRoleInfo(null);
      setCount(null);
    }
  };
  useEffect(() => {
    showData(perPage, currentPage);
    localStorage.removeItem("prod_category_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);
  const rows =
    roleInfo &&
    roleInfo?.map((item) => {
      return (
        <>
          <TableRow key={item.id}>
            <TableCell sx={{ maxWidth: "500px" }}>{`${item.prod_category_name}`}</TableCell>
            <TableCell>
              {item.prod_category_status === "1" ? (
                <SuiTypography fontSize={14} sx={{ color: "#55DED0 !important" }}>
                  Active
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            {permissionInfo.products.UPDATE && !permissionInfo.products.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => editRedirect(item.prod_category_id)}>
                  {<EditIcon />}
                </IconButton>
              </TableCell>
            )}
            {!permissionInfo.products.UPDATE && permissionInfo.products.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => deleteModal(item.prod_category_id)}>
                  {<DeleteIcon />}
                </IconButton>
              </TableCell>
            )}
            {permissionInfo.products.UPDATE && permissionInfo.products.DELETE && (
              <TableCell align="right">
                {item.prod_category_name !== "Super Admin" && (
                  <>
                    <IconButton onClick={() => editRedirect(item.prod_category_id)}>
                      {<EditIcon />}
                    </IconButton>
                    <IconButton onClick={() => deleteModal(item.prod_category_id)}>
                      {<DeleteIcon />}
                    </IconButton>
                  </>
                )}
              </TableCell>
            )}
          </TableRow>
        </>
      );
    });
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          Are you sure you want to delete this Product category?
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpenModal(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => handleDeleteIconClick(deleteID)}
                  >
                    Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Category Name"
                  id="prod_category_name"
                  name="prod_category_name"
                  value={formik.values.prod_category_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Active" },
                    { id: "0", label: "Inactive" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "prod_category_status",
                    val: formik.values.prod_category_status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                    formik.values.prod_category_name = "";
                    formik.values.prod_category_status = "";
                    setPerPage(10);
                    navigateSearch("/product-category", { page: 1 });
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>

      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Product Categories">
            {permissionInfo?.products?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"add-product-category"}
              >
                Add Category
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={isActions ? [...pagesTableHeader, isActions] : [...pagesTableHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default ProductCatagory;
