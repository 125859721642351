import React from "react";
import {
  Grid,
  Card,
  TableCell,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  DialogContent,
  Box,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useProductMasterListMutation } from "app/features/api/AuthApi";
import { useEffect } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import setImg from "../../assets/images/logout-dialog-image.png";
import EditIcon from "Container/Icons/EditIcon";
import SuiDropDownList from "components/SuiDropDownList";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { useActiveCategoryListMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import SuiDialog from "components/SuiDialog";
import { useSyncPriceMutation } from "app/features/api/AuthApi";

const ProductMaster = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [roleInfo, setRoleInfo] = useState("");
  const [GetProductMasterList, { isLoading }] = useProductMasterListMutation();
  const navigateSearch = useNavigateSearch();
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/product-master", { page: page });
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/product-master", { page: 1 });
  };
  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "Product Name" },
    { id: "2", align: "left", width: "auto", label: "SKU" },
    { id: "3", align: "left", width: "auto", label: "Category" },
    { id: "4", align: "left", width: "auto", label: "Billing Period" },
    { id: "5", align: "left", width: "auto", label: "Default Price" },
    { id: "6", align: "left", width: "auto", label: "Status" },
  ];
  const isActions = permissionInfo?.product_master?.UPDATE
    ? {
        id: "7",
        align: "right",
        width: "auto",
        label: "Action",
      }
    : null;
  // Edit category
  const editRedirect = (item) => {
    localStorage.setItem("prod_master_id", item);
    navigateSearch(`edit-product-master`);
  };
  const formik = useFormik({
    initialValues: {
      prod_master_name: "",
      prod_master_sku: "",
      prod_category_id: "",
      prod_master_billing_period: "",
      prod_master_status: "",
    },

    // enableReinitialize: true,
    onSubmit: () => {
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.prod_master_name = "";
      formik.values.prod_master_sku = "";
      formik.values.prod_category_id = "";
      formik.values.prod_master_billing_period = "";
      formik.values.prod_master_status = "";
      showData(perPage, 1); // Call showData with initial page
    },
  });
  const [ActiveCategoryList] = useActiveCategoryListMutation();
  const [CategoryData, setCategoryData] = useState();
  const ActiveCategory = async () => {
    try {
      const response = await ActiveCategoryList().unwrap();
      setCategoryData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CateData = CategoryData?.map((dropval) => ({
    id: dropval.prod_category_id,
    name: dropval.prod_category_name,
  }));
  let showData = async (perPage, currentPage) => {
    navigateSearch("/product-master", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.prod_master_name) {
      urlParamss.prod_master_name = formik.values.prod_master_name;
    }
    if (formik.values.prod_master_sku) {
      urlParamss.prod_master_sku = formik.values.prod_master_sku;
    }
    if (formik.values.prod_category_id) {
      urlParamss.prod_category_id = formik.values.prod_category_id.id;
    }
    if (formik.values.prod_master_name) {
      urlParamss.prod_master_name = formik.values.prod_master_name;
    }
    if (formik.values.prod_master_billing_period) {
      urlParamss.prod_master_billing_period = formik.values.prod_master_billing_period;
    }
    if (formik.values.prod_master_status) {
      urlParamss.prod_master_status = formik.values.prod_master_status;
    }
    try {
      const response = await GetProductMasterList(urlParamss).unwrap();
      setRoleInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setRoleInfo(null);
      setCount(null);
    }
  };
  const [SyncPrices, { isLoading: isSyncPriceLoading }] = useSyncPriceMutation();
  const SyncNewPrice = async () => {
    try {
      const response = await SyncPrices().unwrap();
      suiSnackbar(response.message, "success");
      setConfirmDeleteModal(false);
    } catch (error) {
      suiSnackbar(error.data.message, "error");
    }
  };
  useEffect(() => {
    showData(perPage, currentPage);
    localStorage.removeItem("prod_master_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    ActiveCategory();
  }, [perPage, currentPage]);
  const rows =
    roleInfo &&
    roleInfo?.map((item) => {
      return (
        <>
          <TableRow key={item.prod_master_id}>
            <TableCell>{`${item.prod_master_name}`}</TableCell>
            <TableCell>{`${item.prod_master_sku}`}</TableCell>
            <TableCell>{`${item?.product_categories?.prod_category_name}`}</TableCell>
            <TableCell>{`${item.prod_master_billing_period}`}</TableCell>
            <TableCell>£{`${item.prod_master_price}`}</TableCell>
            <TableCell>
              {item.prod_master_status === "1" ? (
                <SuiTypography fontSize={14} sx={{ color: "#55DED0 !important" }}>
                  Active
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            {permissionInfo.product_master.UPDATE && (
              <TableCell align="right">
                <IconButton onClick={() => editRedirect(item.prod_master_id)}>
                  {<EditIcon />}
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        </>
      );
    });
  const [confiemDeleteModal, setConfirmDeleteModal] = useState(false);

  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];

  const syncData = () => {
    setConfirmDeleteModal(true);
  };
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Product name"
                  id="prod_master_name"
                  name="prod_master_name"
                  value={formik.values.prod_master_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="SKU"
                  id="prod_master_sku"
                  name="prod_master_sku"
                  value={formik.values.prod_master_sku}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="Supplier"
                  value={formik.values.prod_category_id}
                  setValue={(value) => {
                    formik.setFieldValue("prod_category_id", value);
                  }}
                  noOptionsText="No options"
                  placeholder="Category"
                  isLoading={false}
                  disabled={false}
                  options={CateData}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Active" },
                    { id: "0", label: "Inactive" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "prod_master_status",
                    val: formik.values.prod_master_status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "One Time", label: "One Time" },
                    { id: "Monthly", label: "Monthly" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Billing Period",
                    name: "prod_master_billing_period",
                    val: formik.values.prod_master_billing_period,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                    navigateSearch("/product-master", { page: 1 });
                    showData(perPage, 1);
                    setPerPage(10);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiDialog
        open={confiemDeleteModal}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        haveCloseIcon
      >
        <DialogContent
          sx={{
            fontWeight: "semiBold",
            textAlign: "center",
          }}
        >
          <Box mb={4} textAlign="center">
            <img src={setImg} alt="Image" width="100" />
          </Box>
          <>
            <SuiTypography fontWeight="bold" textColor="text">
              Are you sure you want to sync
              <br />
              the default prices?
            </SuiTypography>
            <SuiTypography
              fontWeight="regular"
              mt={1}
              mb={2}
              sx={{ fontSize: "14px", color: "#848484 !important" }}
            >
              Default prices will be synced with the PRD portal.
            </SuiTypography>
          </>

          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setConfirmDeleteModal(false);
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    disabled={isSyncPriceLoading}
                    onClick={() => {
                      SyncNewPrice();
                    }}
                    // onClick={() => {
                    //   deleteID?.partner_no_of_cus == 0
                    //     ? DeletePartnerInfo()
                    //     : DeleteDID.submitForm();
                    // }}
                  >
                    {isSyncPriceLoading ? (
                      <CircularProgress size="25px" sx={{ color: "blue" }} />
                    ) : (
                      "Proceed to Sync"
                    )}
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Products">
            {permissionInfo?.product_master?.UPDATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                onClick={syncData}
              >
                Sync Default Price
              </SuiButton>
            ) : null}
            {permissionInfo?.product_master?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"add-product-master"}
              >
                Add Product Master
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={isActions ? [...pagesTableHeader, isActions] : [...pagesTableHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default ProductMaster;
