import { CircularProgress, Grid, Icon } from "@mui/material";

import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiButton from "components/SuiButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useState } from "react";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useResetPasswordMutation } from "app/features/api/AuthApi";
import colors from "assets/theme/base/colors";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const token = new URLSearchParams(window.location.search).get("token");
  const snackbarFn = useSuiSnackbar();
  const [resetPassword] = useResetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      const body = {
        password: values.password,
        confirm_password: values.confirm_password,
        token: token,
      };
      try {
        const response = await resetPassword(body).unwrap();
        if (response.status === 200) {
          formik.resetForm();
          setError("");
          navigate("/");
          snackbarFn(response?.message, "success");
        }
      } catch (error) {
        formik.resetForm();
        // console.log(error);
        setError(error?.data.message);
      }
    },
  });

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Reset Password
      </SuiTypography>
      <SuiTypography component="p" textColor="text2" variant="formSubHeading">
        Enter New Password
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={2.5}>
          <SuiTypography
            component="p"
            variant="formSubHeading"
            mb={1.5}
            sx={{
              color: `${colors.text.focus} !important`,
            }}
          >
            New Password
          </SuiTypography>
          <SuiPasswordInput
            index={1}
            attr={{ name: "password", placeholder: "Enter New Password" }}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
          <SuiBox display="flex" alignItems="flex-start" pt={0.5}>
            <Icon
              fontSize="small"
              sx={{ fontSize: "0.85rem !important", marginTop: "2px", color: "#6E828A" }}
            >
              info_outlined
            </Icon>
            <SuiTypography
              fontWeight="medium"
              textColor="text2"
              sx={{ fontSize: "0.7rem" }}
              px={0.5}
              py={0}
            >
              Note: Password required at least 8 characters, 1 number, 1 Alphabet, 1 special
              character.
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox mb={2.5}>
          <SuiTypography
            component="p"
            variant="formSubHeading"
            mb={1.5}
            sx={{
              color: `${colors.text.focus} !important`,
            }}
          >
            Confirm New Password
          </SuiTypography>
          <SuiPasswordInput
            index={2}
            attr={{ name: "confirm_password", placeholder: "Confirm New Password" }}
            value={formik.values.confirm_password}
            onBlur={formik.handleBlur}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.confirm_password}
            error={formik.errors.confirm_password}
          />
        </SuiBox>
      </SuiBox>
      <Grid item sx={{ pb: 1 }}>
        {/* {Object.keys(formik.errors)?.length === 0 && error && ( */}
        <ErrorMessageComponent touched={error} error={error} />
        {/* )} */}
      </Grid>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={3}
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty}
          variant="contained"
          buttonColor="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" sx={{ color: "blue" }} />
          ) : (
            "Reset Password"
          )}
        </SuiButton>
      </SuiBox>
      <SuiTypography
        textAlign="center"
        component="p"
        pt={2}
        textColor="text2"
        variant="formSubHeading"
      >
        Back to
        <SuiTypography
          component={Link}
          to="/"
          pl={1}
          textColor="info"
          fontWeight="semiBold"
          variant="formSubHeading"
          sx={{ "&.MuiTypography-root": { textDecoration: "underline !important" } }}
        >
          Sign in
        </SuiTypography>
      </SuiTypography>
    </>
  );
};
export default ForgetPassword;
