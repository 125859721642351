import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import HistoryIcon from "@mui/icons-material/History";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SidenavLink from "Container/Sidenav/SidenavLink";
import SidenavCollapse from "Container/Sidenav/SidenavCollapse";
import styles from "Container/Sidenav/styles/sidenav";
import Logo from "assets/images/Logo.png";
import { updateUI } from "app/features/UIControllerSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import PricingIcon from "Container/Icons/PricingIcon";
import DidIcon from "Container/Icons/DidIcon";
import DashboardIcon from "Container/Icons/DashboardIcon";
import RoleIcon from "Container/Icons/RoleIcon";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomerIcon from "Container/Icons/CustomerIcon";
import ThreeCXIcon from "Container/Icons/ThreeCXIcon";
import SubscriptionIcon from "Container/Icons/SubscriptionIcon";
import RequestIcon from "Container/Icons/RequestIcon";
import ProductIcon from "Container/Icons/ProductIcon";
import PartnerIcon from "Container/Icons/PartnerIcon";

const Sidenav = ({ ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const tabView = useMediaQuery(theme.breakpoints.down("xl"));
  const { uiControl } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav } = uiControl;
  const classes = styles({ miniSidenav, transparentSidenav });
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const closeSidenav = () => dispatch(updateUI({ type: "miniSidenav", value: true }));

  const { permissions } = useSelector((state) => state.auth);
  const userPermissions = matchPermissions(permissions, Permission);

  useEffect(() => {
    dispatch(updateUI({ type: "miniSidenav", value: tabView }));
  }, [dispatch, location, tabView]);

  const rootChildrens = [];

  const rootChildrenRoutes = {
    dashboard: {
      id: 1,
      type: "link",
      label: "Dashboard",
      link: "/dashboard",
      icon: <DashboardIcon size={14} />,
    },
    roles: {
      id: 2,
      type: "link",
      label: "Roles & Permission",
      link: "/roles-&-permission",
      icon: <RoleIcon size={16} />,
    },
    user_management: {
      id: 3,
      type: "link",
      label: "User Management",
      link: "/user-management",
      icon: <PersonOutlineOutlinedIcon sx={{ fontSize: 21, marginLeft: "-5px" }} fontSize="20" />,
    },
    customer: {
      id: 4,
      type: "link",
      label: "Customer",
      link: "/customer",
      icon: <CustomerIcon size={18} />,
    },
    partner: {
      id: 5,
      type: "collapse",
      label: "Partner Management",
      icon: <PartnerIcon size={18} sx={{ marginLeft: "-5px" }} />,
      sub_menu: [
        {
          id: 6.1,
          order: 1,
          label: " Providers",
          link: "/providers",
          permission: "provider",
        },
        {
          id: 6.2,
          order: 2,
          label: "Partners",
          link: "/partner-management",
          permission: "partner",
        },
      ],
    },
    products: {
      id: 6,
      type: "collapse",
      label: "Products",
      icon: <ProductIcon size={18} />,
      sub_menu: [
        {
          id: 6.1,
          order: 1,
          label: "Product Category",
          link: "/product-category",
          permission: "products",
        },
        {
          id: 6.2,
          order: 2,
          label: "Product Master",
          link: "/product-master",
          permission: "product_master",
        },
        {
          id: 6.3,
          order: 3,
          label: "Rate Scheme",
          link: "/rate-scheme",
          permission: "rate_scheme",
        },
      ],
    },
    master_setup: {
      id: 7,
      type: "collapse",
      label: "DID",
      icon: <DidIcon size={15} />,
      sub_menu: [
        {
          id: 7.1,
          order: 1,
          // icon: <DidIcon size={18} />,
          label: "Master Setup",
          className: "childSubmenu",
          sub_menu: [
            {
              order: 1,
              label: "Geo Area Code",
              link: "/geo-area-code",
              permission: "geo_area",
            },
            {
              order: 2,
              label: "Non Geo Area Code",
              link: "/non-geo-area-code",
              permission: "non_geo_area",
            },
            {
              order: 3,
              label: "Supplier",
              link: "/supplier",
              permission: "supplier",
            },
            {
              order: 3,
              label: "Range Holder",
              link: "/range-holder",
              permission: "rangeHolder",
            },
          ],
        },
        {
          id: 7.2,
          order: 2,
          label: "DID Inventory",
          link: "/did-inventory",
          permission: "didinventory",
        },
        {
          id: 7.3,
          order: 2,
          label: "Purchased DIDs",
          link: "/purchased-dids",
          permission: "purchasedDid",
        },
      ],
    },
    threecx_licences: {
      id: 8,
      type: "collapse",
      label: "3CX Licences",
      icon: <ThreeCXIcon size={18} />,
      sub_menu: [
        {
          id: 8.1,
          order: 1,
          label: "3CX Details",
          link: "/3cx-details",
          permission: "threecx_licences",
        },
        {
          id: 8.2,
          order: 2,
          label: "3CX Request Approval",
          link: "/3cx-request-approval",
          permission: "threecx_licences_request",
        },
      ],
    },
    removal_request: {
      id: 9,
      type: "link",
      label: "Removal Requests",
      link: "/removal-requests",
      permission: "removal_request",
      icon: <RequestIcon size={16} />,
    },
    subscription: {
      id: 10,
      type: "link",
      label: "Subscriptions",
      link: "/subscriptions",
      icon: <SubscriptionIcon size={14} />,
      sub_menu: [
        {
          id: 10.1,
          order: 1,
          link: "/view-subscription",
        },
      ],
    },
    price: {
      id: 11,
      type: "link",
      label: "Pricing",
      link: "/pricing",
      icon: <PricingIcon size={18} />,
    },
    sites_pages: {
      id: 12,
      type: "link",
      label: "Sites & pages",
      link: "/pages",
      icon: <DescriptionIcon size={18} />,
    },
    cron: {
      id: 13,
      type: "link",
      label: "Cron History",
      link: "/cron-history",
      icon: <HistoryIcon sx={{ fontSize: 18 }} fontSize="18" />,
    },
  };

  rootChildrenRoutes.threecx_licences.sub_menu =
    rootChildrenRoutes.threecx_licences.sub_menu.filter(
      (item) => userPermissions?.[item.permission]?.VIEW
    );

  if (rootChildrenRoutes.threecx_licences.sub_menu.length > 0) {
    rootChildrens.push(rootChildrenRoutes.threecx_licences);
  }

  rootChildrenRoutes.products.sub_menu = rootChildrenRoutes.products.sub_menu.filter(
    (item) => userPermissions?.[item.permission]?.VIEW
  );

  if (rootChildrenRoutes.products.sub_menu.length > 0) {
    rootChildrens.push(rootChildrenRoutes.products);
  }
  rootChildrenRoutes.partner.sub_menu = rootChildrenRoutes.partner.sub_menu.filter(
    (item) => userPermissions?.[item.permission]?.VIEW
  );

  if (rootChildrenRoutes.partner.sub_menu.length > 0) {
    rootChildrens.push(rootChildrenRoutes.partner);
  }

  rootChildrenRoutes.master_setup.sub_menu = rootChildrenRoutes.master_setup.sub_menu
    .map((menuItem) => {
      if (menuItem.sub_menu) {
        menuItem.sub_menu = menuItem.sub_menu.filter(
          (item) => userPermissions?.[item.permission]?.VIEW
        );
      }
      return menuItem;
    })
    .filter((menuItem) => {
      return (
        (menuItem.sub_menu && menuItem.sub_menu.length > 0) ||
        (menuItem.permission && userPermissions?.[menuItem.permission]?.VIEW)
      );
    });
  if (
    userPermissions?.[rootChildrenRoutes.master_setup.permission]?.VIEW ||
    rootChildrenRoutes.master_setup.sub_menu.length > 0
  ) {
    if (!rootChildrens.find((route) => route === rootChildrenRoutes.master_setup)) {
      rootChildrens.push(rootChildrenRoutes.master_setup);
    }
  } else {
    const index = rootChildrens.findIndex((route) => route === rootChildrenRoutes.master_setup);
    if (index !== -1) {
      rootChildrens.splice(index, 1);
    }
  }
  [
    "price",
    "dashboard",
    "roles",
    "user_management",
    "subscription",
    "sites_pages",
    "removal_request",
    "cron",
    "customer",
  ].forEach((el) => {
    if (userPermissions?.[el]?.VIEW) {
      rootChildrens.push(rootChildrenRoutes?.[el]);
    }
  });

  useEffect(() => {}, [rootChildrens]);

  const renderRoutes = rootChildrens.map(({ id, icon, link, label, sub_menu, type }) => {
    let returnValue;
    if (type === "link") {
      returnValue = (
        <SidenavLink to={link} name={label} icon={icon} active={link === `/${collapseName}`} />
      );
    } else if (type === "collapse") {
      returnValue = <SidenavCollapse name={label} icon={icon} subMenu={sub_menu} />;
    } else if (type === "title") {
      returnValue = (
        <SuiTypography
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          customClass={classes.sidenav_title}
        >
          {label}
        </SuiTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider />;
    }
    return (
      <ListItem component="li" key={`${id}`} sx={{ order: id }}>
        {returnValue}
      </ListItem>
    );
  });

  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSidenav}
        >
          <SuiTypography variant="h6" textColor="dark" sx={{ cursor: "pointer" }}>
            <Icon className="font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={Link} to="/dashboard">
          <SuiBox component="img" src={Logo} alt="Logo" customClass={classes.sidenav_logo} />
        </SuiBox>
      </SuiBox>
      <Divider sx={{ display: { xs: "block", xl: "none" } }} />
      <List className={classes.sidenav_list}>{renderRoutes}</List>
    </Drawer>
  );
};

export default Sidenav;
