import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TableCell,
  TableRow,
  // Tooltip,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import AccountStatusDropdown from "components/SuiDropDownList/AccountStatusDropdown";
import AccountTypeDropdown from "components/SuiDropDownList/AccountTypeDropdown";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import { CustomerTableHeader } from "./data";
import Edit from "Container/Icons/EditIcon";
import AccountStatusSelector from "components/StatusSelector/AccountStatusSelector";
// import SuiTablePagination from "components/SuiTablePagination";
import { Link } from "react-router-dom";
import SuiDialog from "components/SuiDialog";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import DiscountTypeDropdown from "components/SuiDropDownList/DiscountTypeDropdown";
import { useGetCustomerInfoMutation } from "app/features/api/AuthApi";
import useNavigateSearch from "hooks/useNavigateSearch";
import { useDownloadDataMutation } from "app/features/api/AuthApi";
import { appendZero } from "helper";
import { format } from "date-fns";
import { useUpdateCustomerStatusMutation } from "app/features/api/CustomerApi";
import { useUpdateCustomerNetworkStatusMutation } from "app/features/api/CustomerStatusApi";
import { setID } from "app/features/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetCustomerDetails } from "app/features/CustomeDetailsSlice";
import { useCustomerBulkDiscountMutation } from "app/features/api/CustomerApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import * as Yup from "yup";
import ErrorMessageComponent from "components/FormikErrorMessage";
import CustomerNo from "components/SuiAutocomplete/AutocompleteMultipleSelect";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import AlarmIcon from "Container/Icons/Alarm";
import { useMandateUpdateMutation } from "app/features/api/AuthApi";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { styled } from "@mui/styles";
import EyeIcon from "Container/Icons/EyeIcon";
export let apiController = new AbortController();

const CustomerTable = () => {
  const [count, setCount] = useState(0);
  const suiSnackbar = useSuiSnackbar();
  const [checkedItems, setCheckedItems] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [customerInfo, setCustomerInfo] = useState("");

  let page = new URLSearchParams(window.location.search).get("page");
  if (page === null) {
    page = 1;
  }

  const navigateSearch = useNavigateSearch();
  const [open, setOpen] = useState(false);
  const [getCustomerInfo, { isLoading }] = useGetCustomerInfoMutation();
  const [downloadData, { isLoading: isExport }] = useDownloadDataMutation();
  const [updateCustomerStatus] = useUpdateCustomerStatusMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [mandateUpdate] = useMandateUpdateMutation();
  const [customerBulkDiscount] = useCustomerBulkDiscountMutation();

  const [updateCustomerNetworkStatus] = useUpdateCustomerNetworkStatusMutation();
  // const [updateCustomerTwoFactorAuth] = useUpdateCustomerTwoFactorAuthMutation();
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const doCancel = () => {
    apiController.abort();
    apiController = new AbortController();
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/customer", { page: 1 });
  };
  const dispatch = useDispatch();
  const updateCustomerStatusFunc = async (id, status) => {
    const body = {
      id: id,
      status: status,
    };
    try {
      const response = await updateCustomerStatus(body).unwrap();
      suiSnackbar(response.message, "success");
      getCustomerOnload();
    } catch (error) {
      console.log(error);
    }
  };
  const updateCustomerNetorkStatusFunc = async (id, status) => {
    const body = {
      id: id,
      status: status,
    };
    try {
      const response = await updateCustomerNetworkStatus(body).unwrap();
      suiSnackbar(response.message, "success");
      getCustomerOnload();
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      cus_account_number: "",
      first_name: "",
      partner_account_number: "",
      last_name: "",
      // mobile_num: "",
      email: "",
      account_type: "",
      account_status: "active",
      cus_company_name: "",
    },

    enableReinitialize: true,
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  const discountFormik = useFormik({
    initialValues: {
      sip_trunk: "",
      sip_trunk_margin: 0,
      international_did: "",
      international_did_margin: 0,
      national_did: "",
      national_did_margin: 0,
    },
    // enableReinitialize: true,/
    validationSchema: Yup.object().shape({
      sip_trunk: Yup.string().required("Please select the discount type"),
      international_did: Yup.string().required("Please select the discount type"),
      national_did: Yup.string().required("Please select the discount type"),
      sip_trunk_margin: Yup.number().when("sip_trunk", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "SIP Trunk value should be at least 1%.")
          .max(100, "SIP Trunk value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "SIP Trunk value must be at least 1"),
      }),
      international_did_margin: Yup.number().when("international_did", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "International DID value should be at least 1%")
          .max(100, "International DID value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "International DID value must be at least 1"),
      }),
      national_did_margin: Yup.number().when("national_did", {
        is: (value) => value && value.toLowerCase() === "percentage",
        then: Yup.number()
          .typeError("Please enter a number")
          .min(1, "National DID value should be at least 1%")
          .max(100, "National DID value should not exceed 100%."),
        otherwise: Yup.number()
          .typeError("Please enter a number")
          .min(1, "National DID value must be at least 1"),
      }),
    }),
    onSubmit: (values) => {
      handleDiscount(values);
    },
  });
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/customer", { page: page });

    // getCustomerMultipleRecords(perPage, page);
  };
  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.partner_account_number) {
      urlParamss.partner_account_number = formik.values.partner_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    // if (formik.values.mobile_num) {
    //   urlParamss.cus_phone_number = formik.values.mobile_num;
    // }
    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else if (formik.values.account_type == "post_paid") {
        urlParamss.cus_account_type = 0;
      }
    }
    if (formik.values.account_status === "active") {
      urlParamss.cus_status = 1;
    }
    if (formik.values.account_status === "inactive") {
      urlParamss.cus_status = 0;
    }
    if (formik.values.account_status === "blocked") {
      urlParamss.cus_status = 3;
    }
    if (formik.values.account_status === "restricted") {
      urlParamss.cus_status = 2;
    }
    const response =
      Object.keys(urlParamss)?.length > 0
        ? await downloadData(urlParamss).unwrap()
        : await downloadData().unwrap();
    return response?.data;
  };
  const onDownloadFn = async () => {
    try {
      const response = await downloadDetails();
      const downloadData = response?.map((item, i) => {
        let cusAccntStatus;
        if (item.cus_network_status === "1") {
          cusAccntStatus = "ACTIVE";
        } else if (item.cus_network_status === "0") {
          cusAccntStatus = "INACTIVE";
        } else if (item.cus_network_status === "3") {
          cusAccntStatus = "BLOCKED";
        } else if (item.cus_network_status === "2") {
          cusAccntStatus = "RESTRICTED";
        }
        return {
          "Sr.no": appendZero(i + 1),
          "ACCOUNT NUMBER": item?.cus_account_number,
          "PARTNER ID":
            item?.partners?.partner_account_number !== null
              ? item?.partners?.partner_account_number
              : "-",
          "FIRST NAME": item?.cus_firstname.charAt(0).toUpperCase() + item?.cus_firstname.slice(1),
          "LAST NAME": item?.cus_lastname.charAt(0).toUpperCase() + item?.cus_lastname.slice(1),
          "ACCOUNT TYPE": item?.cus_account_type === "1" ? "PRE PAID" : " POST PAID",
          "COMPANY NAME": item.cus_company_name,
          EMAIL: item?.cus_email,
          "RATE SCHEME":
            item.rate_schemes === null ? "FusionM395" : item.rate_schemes.rate_scheme_name,
          "ACCOUNT STATUS":
            item?.cus_status == 0
              ? "INACTIVE"
              : item?.cus_status == 1
              ? "ACTIVE"
              : item?.cus_status == 3
              ? "BLOCKED"
              : item?.cus_status == 2
              ? "RESTRICTED"
              : "unknown",
          "NETWORK BLOCKED": cusAccntStatus,
          DIDS: item?.cus_did_count,
          "SIP TRUNKS": item?.cus_sip_count,
          "ACCOUNT BALANCE": `£ ${item?.cus_account_balance}`,
        };
      });

      const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
      const csvFormat = [
        csvHeaders && csvHeaders.join(","),
        ...(downloadData || []).map(
          (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
        ),
      ].join("\n");

      const blob = new Blob([csvFormat], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Customer List(${format(new Date(), "dd/MM/yyyy | hh:mm a")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      if (error.status === 400) {
        suiSnackbar(error?.message, "error");
      } else {
        suiSnackbar(error?.data?.message, "error");
      }
    }
  };

  const handleSubmitForm = async () => {
    navigateSearch("/customer", { page: currentPage });
    let urlParamss = {
      results_per_page: 10,
      page: 1,
    };
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.partner_account_number) {
      urlParamss.partner_account_number = formik.values.partner_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    // if (formik.values.mobile_num) {
    //   urlParamss.cus_phone_number = formik.values.mobile_num;
    // }

    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else {
        urlParamss.cus_account_type = 0;
      }
    }
    if (
      formik.values.account_status == "active" ||
      formik.values.account_status == "inactive" ||
      formik.values.account_status == "blocked" ||
      formik.values.account_status == "restricted"
    ) {
      if (formik.values.account_status == "active") {
        urlParamss.cus_status = 1;
      }
      if (formik.values.account_status == "inactive") {
        urlParamss.cus_status = 0;
      }
      if (formik.values.account_status == "blocked") {
        urlParamss.cus_status = 3;
      }
      if (formik.values.account_status == "restricted") {
        urlParamss.cus_status = 2;
      }
    }

    try {
      const response = await getCustomerInfo(urlParamss).unwrap();
      navigateSearch("/customer", { page: currentPage });
      setCustomerInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        console.error("Network error:", error.message);
      } else {
        console.error("Request failed with status:", error.response.status);
      }
      setCustomerInfo("");
      setCount("");
    }
  };
  const updateMandate = async (item) => {
    setOpenModal(false);
    try {
      const response = await mandateUpdate(item).unwrap();
      suiSnackbar(response.message, "success");
      handleSubmitForm();
    } catch (error) {
      suiSnackbar(error.data.message, "error");

      console.log(error);
    }
  };
  useEffect(() => {
    // handleSubmitForm();
    if (localStorage.getItem("cus_id")) {
      localStorage.removeItem("cus_id");
    }
    if (localStorage.getItem("customer_id")) {
      localStorage.removeItem("customer_id");
    }
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    dispatch(resetCustomerDetails());
  }, []);
  const isActions =
    permissionInfo?.customer?.UPDATE || permissionInfo?.customer?.VIEW
      ? {
          id: "11",
          align: "right",
          width: "auto",
          label: "ACTION",
        }
      : null;

  // useEffect(() => {
  //   handleSubmitForm(1);
  //   dispatch(resetCustomerDetails());
  // }, []);
  const [openModal, setOpenModal] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const rows =
    customerInfo &&
    customerInfo?.map((item) => {
      const isNetworkStatus = item.cus_network_status == 1;
      // const isTwoFactorAuth = item.cus_2fa_auth === "0" ? false : true;
      let accountStatus = "";
      if (item.cus_status == 1) {
        accountStatus = "Active";
      }
      if (item.cus_status == 0) {
        accountStatus = "Inactive";
      }
      if (item.cus_status == 3) {
        accountStatus = "Blocked";
      }
      if (item.cus_status == 2) {
        accountStatus = "restricted";
      }

      const handleCheckSingle = (cus_id) => {
        const newCheckedItems = [...checkedItems];
        const currentIndex = newCheckedItems.indexOf(cus_id);

        if (currentIndex === -1) {
          newCheckedItems.push(cus_id);
        } else {
          newCheckedItems.splice(currentIndex, 1);
        }

        setCheckedItems(newCheckedItems);
      };

      const cus_id = item.cus_id;
      const updateNetwork = () => {
        if (item?.cus_network_status == 1) {
          return 0;
        } else if (item?.cus_network_status == 0) {
          return 1;
        }
      };

      const editRedirect = (item) => {
        localStorage.setItem("cus_id", item.cus_account_type);
        localStorage.setItem("customer_id", item.cus_id);
        navigateSearch(`edit-customer/account-details/${cus_id}/${item.cus_account_number}`);
      };
      const ViewByIdRedirect = (item) => {
        localStorage.setItem("cus_id", item.cus_account_type);
        localStorage.setItem("customer_id", item.cus_id);
        navigateSearch(`view-customer/account-details/${cus_id}/${item.cus_account_number}`);
      };

      const viewRedirect = (item) => {
        localStorage.setItem("partner_id", item);
        navigateSearch("/partner-management/view-partner");
      };
      dispatch(setID(cus_id));
      const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 150,
          textAlign: "right",
          backgroundColor: "#2B2B2B",
          color: "#F2F4FF",
        },
      });
      return (
        <>
          <TableRow key={cus_id}>
            {permissionInfo.customer.UPDATE || permissionInfo.customer.CREATE ? (
              <TableCell align="center">
                <Checkbox
                  color="secondary"
                  checked={checkedItems.includes(cus_id)}
                  onChange={() => handleCheckSingle(cus_id)}
                />
              </TableCell>
            ) : null}
            <TableCell onClick={() => editRedirect(item)}>
              {permissionInfo?.customer?.UPDATE ? (
                <SuiTypography
                  textColor="info"
                  sx={{
                    textDecoration: "underline !important",
                    fontSize: "14px !important",
                    cursor: "pointer",
                  }}
                >
                  {item?.cus_account_number}
                </SuiTypography>
              ) : (
                item?.cus_account_number
              )}
            </TableCell>

            <TableCell sx={{ textTransform: "capitalize" }}>{`${item.cus_firstname}`}</TableCell>
            <TableCell sx={{ textTransform: "capitalize" }}>{` ${item.cus_lastname}`}</TableCell>
            <TableCell>
              {item?.partners ? (
                permissionInfo?.partner?.VIEW ? (
                  <SuiTypography
                    onClick={() => viewRedirect(item?.partners?.partner_id)}
                    textColor="info"
                    sx={{
                      textDecoration: "underline !important",
                      fontSize: "14px !important",
                      cursor: "pointer",
                    }}
                  >
                    {item?.partners?.partner_account_number}
                  </SuiTypography>
                ) : (
                  item?.partners?.partner_account_number
                )
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell> {item.cus_account_type == 1 ? "Pre Paid" : "Post Paid"} </TableCell>
            <TableCell> {item.cus_company_name} </TableCell>
            {/* <TableCell>
              <SuiTypography
                customClass="width-number"
                fontWeight="medium"
                fontSize={{ xs: 14, xxl: 16 }}
              >
                {item.cus_phone_country_code} {item.cus_phone_number}
              </SuiTypography>
            </TableCell> */}
            {/* <TableCell>
              <SuiTypography
                customClass="width-number"
                fontWeight="medium"
                fontSize={{ xs: 14, xxl: 16 }}
              >
                {item.cus_business_number !== "" || item.cus_business_country_code !== ""
                  ? `${item.cus_business_country_code} ${item.cus_business_number}`
                  : "-"}
              </SuiTypography>
            </TableCell> */}
            <TableCell>{item.cus_email}</TableCell>
            <TableCell>
              {item.rate_schemes === null ? "FusionM395" : item.rate_schemes.rate_scheme_name}
            </TableCell>
            <TableCell className="acc_status">
              {permissionInfo.customer.UPDATE ? (
                <AccountStatusSelector
                  id={cus_id}
                  currentState={accountStatus}
                  onClick={(getValue) => {
                    updateCustomerStatusFunc(cus_id, getValue.val);
                  }}
                />
              ) : item.cus_status === "1" ? (
                <SuiTypography fontSize={14} textColor="primary">
                  Active
                </SuiTypography>
              ) : item.cus_status === "2" ? (
                <SuiTypography fontSize={14} textColor="dark">
                  Restricted
                </SuiTypography>
              ) : item.cus_status === "3" ? (
                <SuiTypography fontSize={14} textColor="error">
                  Blocked
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} textColor="text2">
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            <TableCell>
              <Switch
                checked={isNetworkStatus}
                onClick={() => updateCustomerNetorkStatusFunc(cus_id, updateNetwork())}
              />
            </TableCell>
            {/* <TableCell>
              <Switch
                checked={isTwoFactorAuth}
                onClick={() => updateCustomerTwoFactorAuthFunc(cus_id, updateTwoFactorAuth())}
              />
            </TableCell> */}
            <TableCell>{item.cus_did_count}</TableCell>
            <TableCell>{item.cus_sip_count}</TableCell>
            {item?.cus_account_type == "0" ? (
              <TableCell>-</TableCell>
            ) : (
              <TableCell>£{item.cus_account_balance}</TableCell>
            )}
            {permissionInfo.customer.VIEW && (
              <TableCell align="right">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <IconButton
                    component={Link}
                    className="viewIcon"
                    onClick={() => ViewByIdRedirect(item)}
                  >
                    <EyeIcon />
                  </IconButton>
                  {permissionInfo.customer.UPDATE && (
                    <IconButton
                      component={Link}
                      onClick={() => editRedirect(item)}
                      // to={`edit-customer/account-details/${cus_id}/${item.cus_account_number}`}
                    >
                      <Edit cus_id={cus_id} />
                    </IconButton>
                  )}
                  {item.cus_account_type == 0 &&
                  (permissionInfo.customer.UPDATE || permissionInfo.customer.CREATE) ? (
                    <CustomWidthTooltip
                      arrow
                      title={
                        item.cus_ask_for_mandate == 1
                          ? "Direct mandate has been sent"
                          : "Reminder for direct debit mandate"
                      }
                      placement="bottom-end"
                    >
                      <span>
                        <IconButton
                          disabled={item.cus_ask_for_mandate == 1}
                          sx={{
                            opacity: item.cus_ask_for_mandate == 1 ? 0.5 : 1,
                          }}
                          onClick={() => {
                            setOpenModal(true);
                            setCustomerId(item);
                          }}
                          // onClick={() => updateMandate(item.cus_id)}
                        >
                          <AlarmIcon />
                        </IconButton>
                      </span>
                    </CustomWidthTooltip>
                  ) : null}
                </Box>
              </TableCell>
            )}
          </TableRow>
        </>
      );
    });
  const getCustomerOnload = async () => {
    try {
      const response = await getCustomerInfo({
        results_per_page: 10,
        page: 1,
        cus_status: 1,
      }).unwrap();
      setPerPage(10);
      setCurrentPage(1);
      setCount(response?.data?.count);
      setCustomerInfo(response.data.rows);
      if (checkedItems.length > 0) {
        setCheckedItems([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCustomerMultipleRecords = async (perPage, currentPage) => {
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.partner_account_number) {
      urlParamss.partner_account_number = formik.values.partner_account_number;
    }
    if (formik.values.first_name) {
      urlParamss.cus_firstname = formik.values.first_name;
    }
    if (formik.values.last_name) {
      urlParamss.cus_lastname = formik.values.last_name;
    }
    // if (formik.values.mobile_num) {
    //   urlParamss.cus_phone_number = formik.values.mobile_num;
    // }
    if (formik.values.email) {
      urlParamss.cus_email = formik.values.email;
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name;
    }
    if (formik.values.account_type == "pre_paid" || formik.values.account_type == "post_paid") {
      if (formik.values.account_type == "pre_paid") {
        urlParamss.cus_account_type = 1;
      } else {
        urlParamss.cus_account_type = 0;
      }
    }
    if (
      formik.values.account_status == "active" ||
      formik.values.account_status == "inactive" ||
      formik.values.account_status == "blocked" ||
      formik.values.account_status == "restricted"
    ) {
      if (formik.values.account_status == "active") {
        urlParamss.cus_status = 1;
      }
      if (formik.values.account_status == "inactive") {
        urlParamss.cus_status = 0;
      }
      if (formik.values.account_status == "blocked") {
        urlParamss.cus_status = 3;
      }
      if (formik.values.account_status == "restricted") {
        urlParamss.cus_status = 2;
      }
    }
    try {
      const response = await getCustomerInfo(urlParamss).unwrap();
      setCount(response?.data?.count);
      setCustomerInfo(response.data.rows);
      if (checkedItems.length > 0) {
        setCheckedItems([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckAll = () => {
    if (checkedItems.length === rows.length) {
      setCheckedItems([]);
    } else {
      const allCusIds = customerInfo.map((item) => item.cus_id);
      setCheckedItems(allCusIds);
    }
  };

  const handleDiscount = async (values) => {
    const body = {
      customer_ids: checkedItems,
      discount: [
        {
          discount_product_name: "SIP Trunk",
          discount_type: values.sip_trunk ? values.sip_trunk : "Fixed",
          discount_value: Number(values.sip_trunk_margin),
        },
        {
          discount_product_name: "International DID",
          discount_type: values.international_did ? values.international_did : "Fixed",
          discount_value: Number(values.international_did_margin),
        },
        {
          discount_product_name: "National DID",
          discount_type: values.national_did ? values.national_did : "Fixed",
          discount_value: Number(values.national_did_margin),
        },
      ],
    };
    try {
      const response = await customerBulkDiscount(body).unwrap();
      suiSnackbar(response.message, "success");
      setOpen(false);
      setCheckedItems([]);
      discountFormik.handleReset();
    } catch (error) {
      console.log(error);
      suiSnackbar(error.data.message, "error");
    }
  };
  const handlePostPaidSelection = () => {};
  useEffect(() => {
    getCustomerMultipleRecords(perPage, currentPage);
  }, [perPage, currentPage]);
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <CustomerNo
                  type="customerTable"
                  formik={formik}
                  placeholder="Account Number"
                  name="cus_account_number"
                  id="cus_account_number"
                  value={formik.values.cus_account_number}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Partner ID"
                  id="partner_account_number"
                  name="partner_account_number"
                  value={formik.values.partner_account_number}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="First Name"
                  id="first_name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Last Name"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="number"
                  placeholder="Mobile Number"
                  id="mobile_num"
                  name="mobile_num"
                  value={formik.values.mobile_num}
                  onChange={(e) => {
                    const newValue = e.target.value.trim() === "" ? "" : e.target.value;
                    formik.setFieldValue("mobile_num", newValue);
                  }}
                />
                {formik.touched.mobile_num && formik.errors.mobile_num ? (
                  <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                    {formik.errors.mobile_num}
                  </div>
                ) : null}
              </Grid> */}
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="email"
                  placeholder="Email ID"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <AccountTypeDropdown
                  name="account_type"
                  value={formik.values.account_type}
                  setValue={formik.handleChange}
                  handlePostPaidSelection={handlePostPaidSelection}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <AccountStatusDropdown
                  name="account_status"
                  value={formik.values.account_status}
                  setValue={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="email"
                  placeholder="Company Name"
                  id="cus_company_name"
                  name="cus_company_name"
                  value={formik.values.cus_company_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    navigateSearch("/customer", { page: 1 });
                    getCustomerOnload();
                    setPerPage(10);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <SuiDialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="lg"
          haveCloseIcon
          closeByBackdrop
        >
          <SuiBox px={{ xs: 1.5, lg: 2 }} py={2}>
            <SuiTypography fontWeight="semiBold" variant="h6">
              {`Add Discount for ${checkedItems.length} Customers `}
            </SuiTypography>
          </SuiBox>
          <hr style={{ borderColor: "#E9E9E966", margin: 0 }} />
          <SuiBox sx={{ overflow: "clip overlay" }}>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={1} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Product</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  inputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  placeholder="Product"
                  name="discount_type"
                  value={"SIP Trunk"}
                />
              </Grid>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Discount type</FormLabel>
                <DiscountTypeDropdown
                  name="sip_trunk"
                  value={discountFormik.values.sip_trunk}
                  setValue={discountFormik.handleChange}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.sip_trunk}
                  error={discountFormik.errors.sip_trunk}
                />
              </Grid>
              <Grid item xs={12} sm={3} xl={2}>
                <FormLabel>Value</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="0"
                  name="sip_trunk_margin"
                  value={discountFormik.values.sip_trunk_margin}
                  onChange={discountFormik.handleChange}
                  onBlur={discountFormik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.sip_trunk_margin}
                  error={discountFormik.errors.sip_trunk_margin}
                />
              </Grid>
            </Grid>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={1} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Product</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  inputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  placeholder="Product"
                  name="discount_type"
                  value={"International DID"}
                />
              </Grid>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Discount type</FormLabel>
                <DiscountTypeDropdown
                  name="international_did"
                  value={discountFormik.values.international_did}
                  setValue={discountFormik.handleChange}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.international_did}
                  error={discountFormik.errors.international_did}
                />
              </Grid>
              <Grid item xs={12} sm={3} xl={2}>
                <FormLabel>Value</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="0"
                  name="international_did_margin"
                  value={discountFormik.values.international_did_margin}
                  onChange={discountFormik.handleChange}
                  onBlur={discountFormik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.international_did_margin}
                  error={discountFormik.errors.international_did_margin}
                />
              </Grid>
            </Grid>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={1} columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Product</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  inputProps={{
                    readOnly: true,
                  }}
                  type="text"
                  placeholder="Product"
                  name="discount_type"
                  value={"National DID"}
                />
              </Grid>
              <Grid item xs={12} sm={4.5} xl={5}>
                <FormLabel>Discount type</FormLabel>
                <DiscountTypeDropdown
                  name="national_did"
                  value={discountFormik.values.national_did}
                  setValue={discountFormik.handleChange}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.national_did}
                  error={discountFormik.errors.national_did}
                />
              </Grid>
              <Grid item xs={12} sm={3} xl={2}>
                <FormLabel>Value</FormLabel>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="0"
                  name="national_did_margin"
                  value={discountFormik.values.national_did_margin}
                  onChange={discountFormik.handleChange}
                  onBlur={discountFormik.handleBlur}
                />
                <ErrorMessageComponent
                  touched={discountFormik.touched.national_did_margin}
                  error={discountFormik.errors.national_did_margin}
                />
              </Grid>
            </Grid>
            <Grid container px={{ xs: 1.5, lg: 2 }} py={2} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </SuiButton>
              </Grid>

              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  onClick={discountFormik.handleSubmit}
                >
                  Add
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiDialog>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Customers"}>
            {permissionInfo?.customer?.UPDATE || permissionInfo?.customer?.CREATE ? (
              <SuiButton
                size="small"
                buttonColor="primary"
                sx={{
                  fontWeight: "500",
                  px: 2,
                  "&:disabled": {
                    backgroundColor: "#E9E9E9 !important",
                    color: "#848484 !important",
                  },
                }}
                disabled={checkedItems.length === 0}
                onClick={() => setOpen(true)}
              >
                Add Discount
              </SuiButton>
            ) : null}
            <SuiButton
              size="small"
              buttonColor="accous"
              sx={{ fontWeight: "500", px: "12px", ml: 2, color: "#848484 !important" }}
              onClick={onDownloadFn}
              disabled={isExport}
            >
              {isExport ? <CircularProgress size={20} color="secondary" /> : "Export"}{" "}
            </SuiButton>
            {permissionInfo?.customer?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"/customer/add-customer"}
                onClick={doCancel}
              >
                Add Customer
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={1200}
            checkAll={
              (permissionInfo?.customer?.UPDATE || permissionInfo?.customer?.CREATE) && (
                <Checkbox
                  color="secondary"
                  checked={checkedItems.length === rows.length && rows.length > 0}
                  onChange={handleCheckAll}
                />
              )
            }
            tablehead={isActions ? [...CustomerTableHeader, isActions] : [...CustomerTableHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {/* {count > 0 && (
            <SuiTablePagination
              totalData={count}
              currentPage={Number(page)}
              onPageChange={(_, value) => {
                handleSubmitForm(value);
              }}
            />
          )} */}
          {!isLoading && customerInfo.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      // onClick={() => getCustomerMultipleRecords(perPage, currentPage)}
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
      <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          Are you sure you want to send mandate this customer {customerId?.cus_firstname}{" "}
          {customerId?.cus_lastname} {`(${customerId?.cus_account_number})`} ?
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpenModal(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => updateMandate(customerId?.cus_id)}
                  >
                    Send
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
    </>
  );
};

export default CustomerTable;
