import React from "react";
import { Grid, Card, TableCell, TableRow, Select, MenuItem, Switch } from "@mui/material";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useEffect } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import { useFormik } from "formik";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import SuiDropDownList from "components/SuiDropDownList";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import AccountStatusSelector from "components/StatusSelector/AccountStatusSelector";
import { useGetRateSchemeMutation } from "app/features/api/AuthApi";
import { useSyncRateSchemeMutation } from "app/features/api/AuthApi";
import { useUpdateRateSchemeMutation } from "app/features/api/AuthApi";
import SuiTypography from "components/SuiTypography";

const RateScheme = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rateSchemeList, setRateSchemeList] = useState("");
  const [GetRateSchemeList, { isLoading }] = useGetRateSchemeMutation();
  const [UpdateRateScheme] = useUpdateRateSchemeMutation();
  const [GetSyncDataList] = useSyncRateSchemeMutation();
  const navigateSearch = useNavigateSearch();
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [permissionInfo, setPermissionInfo] = useState();
  const suiSnackbar = useSuiSnackbar();
  const { permissions } = useSelector((state) => state.auth);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/rate-scheme", { page: page });
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/rate-scheme", { page: 1 });
  };
  const pagesTableHeader = [
    { id: "1", align: "left", width: "200", label: "Rate Scheme" },
    { id: "2", align: "left", width: "250", label: "Description" },
    {
      id: "3",
      align: "center",
      width: "200",
      label: (
        <>
          Is Public <br />
          (Direct Customer)
        </>
      ),
    },
    {
      id: "4",
      align: "center",
      width: "280",
      label: (
        <>
          Is Default <br />
          (Direct Customer)
        </>
      ),
    },
    {
      id: "5",
      align: "center",
      width: "auto",
      label: (
        <>
          Is Public <br />
          (Partner)
        </>
      ),
    },
    {
      id: "6",
      align: "center",
      width: "150",
      label: (
        <>
          Is Default <br />
          (Partner)
        </>
      ),
    },
    { id: "7", align: "left", width: "auto", label: "Status" },
  ];
  const formik = useFormik({
    initialValues: {
      rate_scheme_name: "",
      rate_scheme_status: "",
    },
    onSubmit: () => {
      setCurrentPage(1);
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.rate_scheme_name = "";
      formik.values.rate_scheme_status = "";
      setPerPage(10);
      setCurrentPage(1);
      showData(perPage, 1);
    },
  });
  const syncdata = async () => {
    try {
      const syncResponse = await GetSyncDataList().unwrap();
      suiSnackbar(syncResponse.message, "success");
    } catch (error) {
      suiSnackbar(error.message, "error");
    }
  };
  let showData = async (perPage, currentPage) => {
    navigateSearch("/rate-scheme", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.rate_scheme_name) {
      urlParamss.rate_scheme_name = formik.values.rate_scheme_name.trim();
    }
    if (formik.values.rate_scheme_status) {
      urlParamss.rate_scheme_status = formik.values.rate_scheme_status;
    }
    try {
      const response = await GetRateSchemeList(urlParamss).unwrap();
      setRateSchemeList(response.data.rows);
      setCount(response?.data?.count);
      const initialStatus = {};
      response.data.rows.forEach((item) => {
        initialStatus[item.rate_scheme_id] = {
          isPublicCustomer: item.rate_scheme_is_public_cus === "1", // Convert to boolean
          isDefaultCustomer: item.rate_scheme_is_default_cus === "1",
          isPublicPartner: item.rate_scheme_is_public_partner === "1",
          isDefaultPartner: item.rate_scheme_is_default_partner === "1",
        };
      });
      setStatusVal(initialStatus);
      setStatus(initialStatus);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setRateSchemeList(null);
      setCount(null);
    }
  };
  const toggleStatus = (id, type) => {
    setStatusVal((prevStatus) => ({
      ...prevStatus,
      [id]: {
        ...prevStatus[id],
        [type]: !prevStatus[id][type],
      },
    }));
    const newStatus = statusVal[id][type] ? "0" : "1";
    updateCustomerNetorkStatusFunc(id, newStatus, type);
  };

  const updateCustomerStatusFunc = async (id, status) => {
    const body = {
      id: id,
      rate_scheme_status: status,
    };
    try {
      const response = await UpdateRateScheme(body).unwrap();
      suiSnackbar(response.message, "success");
      showData(perPage, currentPage);
    } catch (error) {
      if (error?.data?.errorMessages?.issues) {
        const fieldErrors = error.data.errorMessages.issues;
        if (fieldErrors) {
          fieldErrors.map((item) => {
            suiSnackbar(item.message, "error");
          });
        }
      } else {
        suiSnackbar(error?.data?.message || "An error occurred", "error");
      }
    }
  };
  const [statusVal, setStatusVal] = useState({});
  const updateCustomerNetorkStatusFunc = async (id, status, type) => {
    const body = {
      id: id,
      ...(type === "isPublicCustomer" && { rate_scheme_is_public_cus: String(status) }),
      ...(type === "isDefaultCustomer" && { rate_scheme_is_default_cus: String(status) }),
      ...(type === "isPublicPartner" && { rate_scheme_is_public_partner: String(status) }),
      ...(type === "isDefaultPartner" && { rate_scheme_is_default_partner: String(status) }),
    };

    try {
      const response = await UpdateRateScheme(body).unwrap();
      suiSnackbar(response.message, "success");
      // showData(perPage, currentPage);
    } catch (error) {
      if (error?.data?.errorMessages?.issues) {
        const fieldErrors = error.data.errorMessages.issues;
        if (fieldErrors) {
          fieldErrors.map((item) => {
            suiSnackbar(item.message, "error");
          });
        }
      } else {
        suiSnackbar(error?.data?.message || "An error occurred", "error");
      }
    }
  };
  useEffect(() => {
    showData(perPage, currentPage);
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);
  useEffect(() => {}, [statusVal]);
  const [status, setStatus] = useState({});
  const toggleStatusVal = (id, type) => {
    setStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      if (newStatus[id][type]) {
        updateCustomerNetorkStatusFunc(id, 0, type);
        return newStatus;
      }
      for (let key in newStatus) {
        newStatus[key] = { ...newStatus[key], [type]: false };
      }
      newStatus[id] = { ...newStatus[id], [type]: true };
      updateCustomerNetorkStatusFunc(id, 1, type);
      return newStatus;
    });
  };

  const rows =
    rateSchemeList &&
    rateSchemeList?.map((item) => {
      let accountStatus = "";
      if (item.rate_scheme_status == "1") {
        accountStatus = "Active";
      }
      if (item.rate_scheme_status == "0") {
        accountStatus = "Inactive";
      }
      return (
        <>
          <TableRow key={item.rate_scheme_id}>
            <TableCell>{`${item.rate_scheme_name}`}</TableCell>
            <TableCell>{`${item.rate_scheme_description}`}</TableCell>
            <TableCell align="center">
              {permissionInfo?.rate_scheme?.UPDATE ? (
                <Switch
                  checked={statusVal[item.rate_scheme_id]?.isPublicCustomer || false}
                  onClick={() => toggleStatus(item.rate_scheme_id, "isPublicCustomer")}
                  disabled={item.rate_scheme_status == "0"}
                />
              ) : (
                <Switch
                  checked={statusVal[item.rate_scheme_id]?.isPublicCustomer || false}
                  disabled
                />
              )}
            </TableCell>
            <TableCell align="center">
              {permissionInfo?.rate_scheme?.UPDATE ? (
                <Switch
                  checked={status[item.rate_scheme_id]?.isDefaultCustomer || false}
                  onClick={() => toggleStatusVal(item.rate_scheme_id, "isDefaultCustomer")}
                  disabled={item.rate_scheme_status == "0"}
                />
              ) : (
                <Switch
                  checked={status[item.rate_scheme_id]?.isDefaultCustomer || false}
                  disabled
                />
              )}
            </TableCell>
            <TableCell align="center">
              {permissionInfo?.rate_scheme?.UPDATE ? (
                <Switch
                  checked={statusVal[item.rate_scheme_id]?.isPublicPartner || false}
                  onClick={() => toggleStatus(item.rate_scheme_id, "isPublicPartner")}
                  disabled={item.rate_scheme_status == "0"}
                />
              ) : (
                <Switch
                  checked={statusVal[item.rate_scheme_id]?.isPublicPartner || false}
                  disabled
                />
              )}
            </TableCell>
            <TableCell align="center">
              {permissionInfo?.rate_scheme?.UPDATE ? (
                <Switch
                  checked={status[item.rate_scheme_id]?.isDefaultPartner || false}
                  onClick={() => toggleStatusVal(item.rate_scheme_id, "isDefaultPartner")}
                  disabled={item.rate_scheme_status == "0"}
                />
              ) : (
                <Switch checked={status[item.rate_scheme_id]?.isDefaultPartner || false} disabled />
              )}
            </TableCell>
            <TableCell className="acc_status">
              {permissionInfo.rate_scheme.UPDATE ? (
                <AccountStatusSelector
                  id={item.rate_scheme_id}
                  currentState={accountStatus}
                  type="rateSchemeStatus"
                  onClick={(getValue) => {
                    updateCustomerStatusFunc(item.rate_scheme_id, getValue.val);
                  }}
                />
              ) : item.rate_scheme_status === "1" ? (
                <SuiTypography fontSize={14} textColor="primary">
                  Active
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} textColor="text2">
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
          </TableRow>
        </>
      );
    });
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Rate Scheme"
                  id="rate_scheme_name"
                  name="rate_scheme_name"
                  value={formik.values.rate_scheme_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Active" },
                    { id: "0", label: "Inactive" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "rate_scheme_status",
                    val: formik.values.rate_scheme_status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>

      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Rate Scheme">
            {permissionInfo?.rate_scheme?.CREATE ? (
              <SuiButton
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                onClick={syncdata}
              >
                Sync
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={pagesTableHeader}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default RateScheme;
