import {
  Grid,
  TableCell,
  TableRow,
  Card,
  IconButton,
  DialogContent,
  MenuItem,
  Select,
  fabClasses,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import DeleteIcon from "Container/Icons/DeleteIcon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDialog from "components/SuiDialog";
import { useStatusUpdateMutation } from "app/features/api/AuthApi";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import SuiDropDownList from "components/SuiDropDownList";
import EditIcon from "Container/Icons/EditIcon";
import SuiTypography from "components/SuiTypography";
import { useSupplierListMutation } from "app/features/api/AuthApi";
import { useDeleteSupplierMutation } from "app/features/api/AuthApi";

const SupplierList = () => {
  let page = new URLSearchParams(window.location.search).get("page");
  const [count, setCount] = useState(0);
  const [getSupplier, setGetSupplier] = useState("");
  const [getSupplierList, { isLoading: isLoading }] = useSupplierListMutation();
  const [statusUpdate] = useStatusUpdateMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [deleteSupplier] = useDeleteSupplierMutation();
  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();
  const formik = useFormik({
    initialValues: {
      did_supplier_name: "",
      did_supplier_status: "",
    },
    enableReinitialize: true,
    onSubmit: () => {
      setCurrentPage(1);
      handleSubmitForm(perPage, currentPage);
    },
  });
  const handleSubmitForm = async (perPage, currentPage) => {
    navigateSearch("/supplier", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.did_supplier_name) {
      urlParamss.did_supplier_name = formik.values.did_supplier_name.trim();
    }
    if (formik.values.did_supplier_status) {
      urlParamss.did_supplier_status = formik.values.did_supplier_status;
    }
    try {
      const response = await getSupplierList(urlParamss).unwrap();
      navigateSearch("/supplier", { page: currentPage });
      setGetSupplier(response.data?.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setGetSupplier("");
      setCount("");
    }
  };
  const handleRestForm = async () => {
    navigateSearch("/supplier", { page: 1 });
    let urlParamss = {
      results_per_page: 10,
      page: 1,
    };
    formik.setFieldValue("did_supplier_name", "");
    formik.setFieldValue("did_supplier_status", "");
    try {
      const response = await getSupplierList(urlParamss).unwrap();
      navigateSearch("/supplier", { page: 1 });
      setGetSupplier(response.data?.rows);
      setCount(response?.data?.count);
      formik.setFieldValue("did_supplier_name", "");
      formik.setFieldValue("did_supplier_status", "");
    } catch (error) {
      setGetSupplier("");
      setCount("");
    }
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await deleteSupplier(id).unwrap();
      console.log("showError", response);
      if (response.status === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
      }
    } catch (error) {
      console.log("handleDeleteIconClick:::::::::", error?.data?.message);
      suiSnackbar(error?.data?.message, "error");
      setOpenModal(false);
    }
    handleSubmitForm(perPage, currentPage);
  };
  const updateStatusFunc = async (id, status) => {
    const body = {
      id: id,
      did_supplier_status: status,
    };
    try {
      const response = await statusUpdate(body).unwrap();
      suiSnackbar(response.message, "success");
      handleSubmitForm(perPage, currentPage);
    } catch (error) {
      console.log(error);
    }
  };
  const [permissionInfo, setPermissionInfo] = useState();
  const checkval = fabClasses;
  const { permissions } = useSelector((state) => state.auth);
  useEffect(() => {
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    if (checkval) {
      updateStatusFunc();
    }
    localStorage.removeItem("supplier_id");
    handleSubmitForm(perPage, currentPage);
  }, [perPage, currentPage]);

  const [openModal, setOpenModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/supplier", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/supplier", { page: page });
  };
  // Edit Supplier
  const editRedirect = (item) => {
    localStorage.setItem("supplier_id", item);
    navigateSearch("edit-supplier");
  };
  const pagesTableHeader = [
    { id: "1", align: "left", width: "60%", label: "Supplier" },
    { id: "2", align: "left", width: "auto", label: "Status" },
  ];
  const isActions =
    permissionInfo?.supplier?.UPDATE || permissionInfo?.supplier?.DELETE
      ? {
          id: "3",
          align: "right",
          width: "auto",
          label: "Action",
        }
      : null;
  const rows =
    getSupplier && Array.isArray(getSupplier)
      ? getSupplier.map((item, i) => {
          const labelId = `item.did_supplier_id${i}`;
          return (
            <>
              <TableRow key={labelId}>
                <TableCell sx={{ wordbreak: "break-word" }}>{item.did_supplier_name}</TableCell>
                <TableCell>
                  <SuiTypography
                    fontSize="inherit"
                    textColor={item.did_supplier_status === "0" ? "error" : "success"}
                  >
                    {item.did_supplier_status === "0" ? "Inactive" : "Active"}
                  </SuiTypography>
                </TableCell>
                {(permissionInfo?.supplier?.UPDATE || permissionInfo?.supplier?.DELETE) && (
                  <TableCell align="right">
                    {permissionInfo?.supplier?.UPDATE && (
                      <IconButton onClick={() => editRedirect(item.did_supplier_id)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {permissionInfo?.supplier?.DELETE && (
                      <IconButton onClick={() => deleteModal(item.did_supplier_id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </>
          );
        })
      : [];

  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Supplier Name"
                  id="did_supplier_name"
                  name="did_supplier_name"
                  value={formik.values.did_supplier_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Active" },
                    { id: "0", label: "Inactive" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "did_supplier_status",
                    val: formik.values.did_supplier_status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    navigateSearch("/supplier", { page: 1 });
                    handleRestForm();
                    setPerPage(10);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Suppliers"}>
            {/* {permissionInfo?.Assigndid?.CREATE ? ()} */}
            {permissionInfo?.supplier?.CREATE ? (
              <>
                <SuiButton
                  component={Link}
                  size="small"
                  buttonColor="info"
                  sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                  to={"add-supplier"}
                >
                  Add Supplier
                </SuiButton>
              </>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={1000}
            tablehead={isActions ? [...pagesTableHeader, isActions] : [...pagesTableHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && getSupplier.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
      <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          Are you sure you want to delete this supplier?
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpenModal(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => handleDeleteIconClick(deleteID)}
                  >
                    Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
    </>
  );
};

export default SupplierList;
