import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { capitalize } from "helper";
import StatusSelector from ".";
import { accountStatus, rateSchemestatusType } from "components/SuiDropDownList/dropdownArrays";

const AccountStatusSelector = ({ id, currentState, onClick, type }) => {
  let textColor;
  switch (currentState) {
    case "Active":
      textColor = "primary";
      break;
    case "Inactive":
      textColor = "text2";
      break;
    case "Blocked":
      textColor = "error";
      break;
    default:
      textColor = "dark";
      break;
  }
  return (
    <SuiBox
      maxWidth={100}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1.2}
    >
      <SuiTypography fontSize={14} textColor={textColor}>
        {capitalize(currentState)}
      </SuiTypography>
      <StatusSelector
        id={id}
        selectData={type === "rateSchemeStatus" ? rateSchemestatusType : accountStatus}
        onStateChange={onClick}
      />
    </SuiBox>
  );
};

export default AccountStatusSelector;
