import { Card, FormLabel, Grid, MenuItem, Select, TableCell, TableRow } from "@mui/material";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import SuiButton from "components/SuiButton";
import SuiSearchBox from "components/SuiSearchBox";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import dayjs from "dayjs";
import { useFormik } from "formik";
import SuiDateRangePicker from "components/SuiDateRangePicker";
import { useGetThreeCXOrderMutation } from "app/features/api/AuthApi";
import { useEffect, useState } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import { Link } from "react-router-dom";

const ThreeCXCustmoerDetails = () => {
  // const { pathname } = useLocation();
  // const checkVal = pathname.includes("view");
  const [getThreeCXOrder, { isLoading }] = useGetThreeCXOrderMutation();
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [threeCXInfo, setThreeCXInfo] = useState("");
  const navigateSearch = useNavigateSearch();
  const cus_id = localStorage.getItem("customer_id");
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      order_id: "",
      transaction_id: "",
    },
    enableReinitialize: true,
    onSubmit: () => {
      showData(perPage, 1);
      setCurrentPage(1);
    },
    onReset: () => {
      formik.values.startDate = "";
      formik.values.endDate = "";
      formik.values.order_id = "";
      formik.values.transaction_id = "";
      setThreeCXInfo("");
      showData(perPage, 1); // Call showData with initial page
    },
  });
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const handleDateChange = (a) => {
    if (a?.length) {
      formik.setFieldValue("startDate", new Date(a[0]));
      formik.setFieldValue("endDate", new Date(a[1]));
    } else {
      formik.setFieldValue("startDate", null);
      formik.setFieldValue("endDate", null);
    }
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("", { page: page });
  };
  const showData = async (perPage, currentPage) => {
    const urlParamss = {
      results_per_page: perPage,
      page: currentPage,
      cus_id: cus_id,
    };
    if (formik.values.startDate) {
      urlParamss.start_date = dayjs(new Date(formik.values.startDate)).format("YYYY-MM-DD");
    }
    if (formik.values.endDate) {
      urlParamss.end_date = dayjs(new Date(formik.values.endDate)).format("YYYY-MM-DD");
    }
    if (formik.values.order_id) {
      urlParamss.order_id = formik.values.order_id.trim();
    }
    if (formik.values.transaction_id) {
      urlParamss.transaction_id = formik.values.transaction_id.trim();
    }
    try {
      const response = await getThreeCXOrder(urlParamss).unwrap();
      setThreeCXInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        console.error("Network error:", error.message);
      } else {
        console.error("Request failed with status:", error.response.status);
      }
    }
  };
  const maskString = (str) => {
    if (str?.length <= 4) return str;
    const last4 = str?.slice(-4);
    const masked = "*"?.repeat(str?.length - 4) + last4;
    return masked;
  };
  const rows =
    threeCXInfo &&
    threeCXInfo.map((item) => (
      <TableRow key={item.id}>
        <TableCell>{`${item.order_id}`}</TableCell>
        <TableCell>{item.payments === null ? "-" : item.payments.payment_transaction_id}</TableCell>
        <TableCell>{dayjs(new Date(item.createdAt)).format("YYYY-MM-DD")}</TableCell>
        <TableCell>
          {item.order_total_price === null ? "-" : `£ ${item.order_total_price}`}
        </TableCell>
        <TableCell>
          {item.order_logs === null || item.order_logs.order_three_cx_license === null
            ? "-"
            : item.order_logs.order_three_cx_license.Edition}
        </TableCell>
        <TableCell>
          {item.order_logs === null ||
          item.order_logs.order_three_cx_license === null ||
          item.order_status === "Failed"
            ? "-"
            : maskString(item.order_logs.order_three_cx_license.LicenseKey)}
        </TableCell>
        <TableCell>
          {item.order_status === "Failed" ? (
            <SuiTypography fontSize={14} sx={{ color: "#C02B0A !important" }}>
              Failed
            </SuiTypography>
          ) : item.order_status === "Success" ? (
            <SuiTypography fontSize={14} sx={{ color: "#2DC573 !important" }}>
              Completed
            </SuiTypography>
          ) : (
            <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
              Pending
            </SuiTypography>
          )}
        </TableCell>
      </TableRow>
    ));
  useEffect(() => {
    showData(perPage, currentPage);
  }, [perPage, currentPage]);

  const pagesTableHeader = [
    { id: "1", align: "left", width: "300px", label: "Order ID" },
    { id: "2", align: "left", width: "auto", label: "Transaction ID" },
    { id: "3", align: "left", width: "auto", label: "Payment Date" },
    { id: "4", align: "left", width: "auto", label: "Payment Amount" },
    { id: "5", align: "left", width: "auto", label: "Licence Edition" },
    { id: "6", align: "left", width: "auto", label: "Licence Key" },
    { id: "7", align: "left", width: "auto", label: "Status" },
  ];

  return (
    <>
      <SuiSearchBox title="Search">
        <SuiBox mx={1}>
          <Grid container component="form" spacing={2}>
            <Grid item xs={12} md={6} xxl={4}>
              <FormLabel>Date Range</FormLabel>
              <SuiDateRangePicker
                fullWidth
                placeholder="Date Range"
                value={
                  formik.values.startDate === "" && formik.values.endDate === ""
                    ? []
                    : formik.values.startDate === null && formik.values.endDate === null
                    ? []
                    : [new Date(formik.values.startDate), new Date(formik.values.endDate)]
                }
                onDateChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={6} xxl={4}>
              <FormLabel htmlFor="order_id">Order ID</FormLabel>
              <SuiInput
                inputSettungs={{ autoComplete: "off" }}
                type="search"
                placeholder="Enter Order ID"
                id="order_id"
                name="order_id"
                value={formik.values.order_id}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} xxl={4}>
              <FormLabel htmlFor="transaction_id">Transaction ID</FormLabel>
              <SuiInput
                inputSettungs={{ autoComplete: "off" }}
                type="search"
                placeholder="Enter Transaction ID"
                id="transaction_id"
                name="transaction_id"
                value={formik.values.transaction_id}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SuiButton
                type="submit"
                size="small"
                buttonColor="primary"
                sx={{ px: "1rem", marginRight: "14px" }}
                onClick={formik.handleSubmit}
              >
                Search
              </SuiButton>
              <SuiButton
                type="reset"
                size="small"
                variant="outlined"
                buttonColor="dark"
                sx={{ px: "1rem", py: "0.4rem" }}
                disabled={!formik.dirty}
                onClick={formik.handleReset}
              >
                Reset
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiSearchBox>

      <Card
        className="set-width-table"
        sx={{ border: "1px solid #E9E9E9", boxShadow: "none", width: "100%", mt: 3 }}
      >
        <EnhancedTable
          customClass="no-border"
          tableMinWidth={870}
          tablehead={pagesTableHeader}
          rowsData={rows}
          isLoading={isLoading}
        />
        {!isLoading && threeCXInfo.length > 0 && (
          <Grid
            container
            py={3}
            spacing={1.5}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "flex-end" }}
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <span>Show</span>
                </Grid>
                <Grid item>
                  {/* Select dropdown for items per page */}
                  <Select
                    value={perPage}
                    onChange={handlePerPageChange}
                    variant="standard"
                    sx={{ minWidth: 100 }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <span>out of {count} Results</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CommonPagination
                mt={0}
                totalData={count}
                pageSize={perPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>
        )}
      </Card>
      <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
        <Grid item>
          <SuiButton
            size="small"
            component={Link}
            to="/customer"
            variant="outlined"
            buttonColor="dark"
            sx={{ px: "1rem", py: "0.4rem" }}
          >
            Cancel
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ThreeCXCustmoerDetails;
