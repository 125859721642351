import {
  Grid,
  TableCell,
  TableRow,
  Card,
  IconButton,
  DialogContent,
  MenuItem,
  Select,
  fabClasses,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import DeleteIcon from "Container/Icons/DeleteIcon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDialog from "components/SuiDialog";
import { useStatusUpdateMutation } from "app/features/api/AuthApi";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import SuiDropDownList from "components/SuiDropDownList";
import EditIcon from "Container/Icons/EditIcon";
import SuiTypography from "components/SuiTypography";
import { useRangeholderListMutation } from "app/features/api/AuthApi";
import { useDeleteRangeholderMutation } from "app/features/api/AuthApi";

const RangeHolderList = () => {
  let page = new URLSearchParams(window.location.search).get("page");
  const [count, setCount] = useState(0);
  const [getRangeholder, setGetRangeholder] = useState("");
  const [getRangeholderList, { isLoading: isLoading }] = useRangeholderListMutation();
  const [statusUpdate] = useStatusUpdateMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [deleteRangeholder] = useDeleteRangeholderMutation();
  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();
  const formik = useFormik({
    initialValues: {
      did_range_holder_name: "",
      did_range_holder_code: "",
      did_range_holder_status: "",
    },
    enableReinitialize: true,
    onSubmit: () => {
      setCurrentPage(1);
      handleSubmitForm(perPage, currentPage);
    },
  });
  const handleSubmitForm = async (perPage, currentPage) => {
    navigateSearch("/range-holder", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.did_range_holder_name) {
      urlParamss.did_range_holder_name = formik.values.did_range_holder_name.trim();
    }
    if (formik.values.did_range_holder_code) {
      urlParamss.did_range_holder_code = formik.values.did_range_holder_code.trim();
    }
    if (formik.values.did_range_holder_status) {
      urlParamss.did_range_holder_status = formik.values.did_range_holder_status;
    }
    try {
      const response = await getRangeholderList(urlParamss).unwrap();
      navigateSearch("/range-holder", { page: currentPage });
      setGetRangeholder(response.data?.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setGetRangeholder("");
      setCount("");
    }
  };
  const handleRestForm = async () => {
    navigateSearch("/range-holder", { page: 1 });
    let urlParamss = {
      results_per_page: 10,
      page: 1,
    };
    formik.setFieldValue("did_range_holder_name", "");
    formik.setFieldValue("did_range_holder_code", "");
    formik.setFieldValue("did_range_holder_status", "");
    try {
      const response = await getRangeholderList(urlParamss).unwrap();
      navigateSearch("/range-holder", { page: 1 });
      setGetRangeholder(response.data?.rows);
      setCount(response?.data?.count);
      formik.setFieldValue("did_range_holder_name", "");
      formik.setFieldValue("did_range_holder_code", "");
      formik.setFieldValue("did_range_holder_status", "");
    } catch (error) {
      setGetRangeholder("");
      setCount("");
    }
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await deleteRangeholder(id).unwrap();
      console.log("showError", response);
      if (response.status === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
      }
    } catch (error) {
      suiSnackbar(error?.data?.message, "error");
      setOpenModal(false);
    }
    handleSubmitForm(perPage, currentPage);
  };
  const updateStatusFunc = async (id, status) => {
    const body = {
      id: id,
      did_range_holder_status: status,
    };
    try {
      const response = await statusUpdate(body).unwrap();
      suiSnackbar(response.message, "success");
      handleSubmitForm(perPage, currentPage);
    } catch (error) {
      console.log(error);
    }
  };
  const [permissionInfo, setPermissionInfo] = useState();
  const checkval = fabClasses;
  const { permissions } = useSelector((state) => state.auth);
  useEffect(() => {
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    if (checkval) {
      updateStatusFunc();
    }
    localStorage.removeItem("did_range_holder_id");
    handleSubmitForm(perPage, currentPage);
  }, [perPage, currentPage]);

  const [openModal, setOpenModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/range-holder", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/range-holder", { page: page });
  };
  // Edit Range Holder
  const editRedirect = (item) => {
    localStorage.setItem("did_range_holder_id", item);
    navigateSearch("edit-range-holder");
  };
  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "Range Holder Code" },
    { id: "2", align: "left", width: "auto", label: "Range Holder" },
    { id: "3", align: "left", width: "auto", label: "CUPID" },
    { id: "4", align: "left", width: "auto", label: "Status" },
  ];
  const isActions =
    permissionInfo?.rangeHolder?.UPDATE || permissionInfo?.rangeHolder?.DELETE
      ? {
          id: "5",
          align: "right",
          width: "auto",
          label: "Action",
        }
      : null;
  const rows =
    getRangeholder && Array.isArray(getRangeholder)
      ? getRangeholder.map((item, i) => {
          const labelId = `item.did_range_holder_id${i}`;
          return (
            <>
              <TableRow key={labelId}>
                <TableCell>{item.did_range_holder_code}</TableCell>
                <TableCell sx={{ wordbreak: "break-word" }}>{item.did_range_holder_name}</TableCell>
                <TableCell>
                  {item.did_range_holder_cupid === null ? "-" : item.did_range_holder_cupid}
                </TableCell>
                <TableCell>
                  <SuiTypography
                    fontSize="inherit"
                    textColor={item.did_range_holder_status === "0" ? "error" : "success"}
                  >
                    {item.did_range_holder_status === "0" ? "Inactive" : "Active"}
                  </SuiTypography>
                </TableCell>
                {(permissionInfo?.rangeHolder?.UPDATE || permissionInfo?.rangeHolder?.DELETE) && (
                  <TableCell align="right">
                    {permissionInfo?.rangeHolder?.UPDATE && (
                      <IconButton onClick={() => editRedirect(item.did_range_holder_id)}>
                        {<EditIcon />}
                      </IconButton>
                    )}
                    {permissionInfo?.rangeHolder?.DELETE && (
                      <IconButton onClick={() => deleteModal(item.did_range_holder_id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </>
          );
        })
      : [];

  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Range Holder Name"
                  id="did_range_holder_name"
                  name="did_range_holder_name"
                  value={formik.values.did_range_holder_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Range Holder Code"
                  id="did_range_holder_code"
                  name="did_range_holder_code"
                  value={formik.values.did_range_holder_code}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Active" },
                    { id: "0", label: "Inactive" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "did_range_holder_status",
                    val: formik.values.did_range_holder_status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    navigateSearch("/range-holder", { page: 1 });
                    handleRestForm();
                    setPerPage(10);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Range Holders"}>
            {permissionInfo?.rangeHolder?.CREATE ? (
              <>
                <SuiButton
                  component={Link}
                  size="small"
                  buttonColor="info"
                  sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                  to={"add-range-holder"}
                >
                  Add Range Holder
                </SuiButton>
              </>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={1000}
            tablehead={isActions ? [...pagesTableHeader, isActions] : [...pagesTableHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && getRangeholder.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
      <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          Are you sure you want to delete this range holder?
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpenModal(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => handleDeleteIconClick(deleteID)}
                  >
                    Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
    </>
  );
};

export default RangeHolderList;
