export const Permission = {
  didNum: {
    CREATE: "did-create",
    VIEW: "did-view",
    DELETE: "did-delete",
    UPDATE: "did-update",
  },
  price: {
    CREATE: "pricing-create",
    VIEW: "pricing-view",
    DELETE: "pricing-delete",
    UPDATE: "pricing-upate",
  },
  sites_pages: {
    CREATE: "sites_pages-create",
    VIEW: "sites_pages-view",
    DELETE: "sites_pages-delete",
    UPDATE: "sites_pages-update",
  },
  cron: {
    CREATE: "cron_history-view",
    VIEW: "cron_history-view",
    DELETE: "cron_history-delete",
    UPDATE: "cron_history-update",
  },
  Assigndid: {
    CREATE: "assign_dids-create",
    VIEW: "assign_dids-view",
    DELETE: "assign_dids-delete",
    UPDATE: "assign_dids-update",
  },
  dashboard: {
    CREATE: "dashboard-create",
    VIEW: "dashboard-view",
    DELETE: "dashboard-delete",
    UPDATE: "dashboard-update",
  },
  user_management: {
    CREATE: "user_management-create",
    VIEW: "user_management-view",
    DELETE: "user_management-delete",
    UPDATE: "user_management-update",
  },
  customer: {
    CREATE: "customer-create",
    VIEW: "customer-view",
    DELETE: "customer-delete",
    UPDATE: "customer-update",
  },
  dids: {
    CREATE: "did_management-create",
    VIEW: "did_management-view",
    DELETE: "did_management-delete",
    UPDATE: "did_management-update",
  },
  removal_request: {
    VIEW: "removal_request-view",
    UPDATE: "removal_request-update",
  },
  roles: {
    CREATE: "role_permissions-create",
    VIEW: "role_permissions-view",
    DELETE: "role_permissions-delete",
    UPDATE: "role_permissions-update",
  },
  threecx_licences: {
    CREATE: "3cx_licences_details-create",
    VIEW: "3cx_licences_details-view",
    DELETE: "3cx_licences_details-delete",
    UPDATE: "3cx_licences_details-update",
  },
  partner: {
    CREATE: "partner-create",
    VIEW: "partner-view",
    DELETE: "partner-delete",
    UPDATE: "partner-update",
  },
  products: {
    CREATE: "product_category-create",
    VIEW: "product_category-view",
    DELETE: "product_category-delete",
    UPDATE: "product_category-update",
  },
  product_master: {
    CREATE: "product_master-create",
    VIEW: "product_master-view",
    DELETE: "product_master-delete",
    UPDATE: "product_master-update",
  },
  rate_scheme: {
    CREATE: "rate_scheme-create",
    VIEW: "rate_scheme-view",
    DELETE: "rate_scheme-delete",
    UPDATE: "rate_scheme-update",
  },
  threecx_licences_request: {
    CREATE: "3cx_licences_request-create",
    VIEW: "3cx_licences_request-view",
    DELETE: "3cx_licences_request-delete",
    UPDATE: "3cx_licences_request-update",
  },
  supplier: {
    CREATE: "did_supplier-create",
    VIEW: "did_supplier-view",
    DELETE: "did_supplier-delete",
    UPDATE: "did_supplier-update",
  },
  rangeHolder: {
    CREATE: "did_range_holder-create",
    VIEW: "did_range_holder-view",
    DELETE: "did_range_holder-delete",
    UPDATE: "did_range_holder-update",
  },
  subscription: {
    CREATE: "subscription-create",
    VIEW: "subscription-view",
    DELETE: "subscription-delete",
    UPDATE: "subscription-update",
  },
  global_config: {
    CREATE: "global_configuration-create",
    VIEW: "global_configuration-view",
    DELETE: "global_configuration-delete",
    UPDATE: "global_configuration-update",
  },
  geo_area: {
    CREATE: "did_geo_area_code-create",
    VIEW: "did_geo_area_code-view",
    DELETE: "did_geo_area_code-delete",
    UPDATE: "did_geo_area_code-update",
  },
  non_geo_area: {
    CREATE: "did_non_geo_area_code-create",
    VIEW: "did_non_geo_area_code-view",
    DELETE: "did_non_geo_area_code-delete",
    UPDATE: "did_non_geo_area_code-update",
  },
  master_setup: {
    CREATE: "did-create",
    VIEW: "did-view",
    DELETE: "did-delete",
    UPDATE: "did-update",
  },
  didinventory: {
    CREATE: "did_inventory-create",
    VIEW: "did_inventory-view",
    DELETE: "did_inventory-delete",
    UPDATE: "did_inventory-update",
  },
  purchasedDid: {
    CREATE: "purchased_did-create",
    VIEW: "purchased_did-view",
    DELETE: "purchased_did-delete",
    UPDATE: "purchased_did-update",
  },
  provider: {
    CREATE: "provider-create",
    VIEW: "provider-view",
    DELETE: "provider-delete",
    UPDATE: "provider-update",
  },
};
