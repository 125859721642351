import { removeTags } from "helper";
import moment from "moment";
import * as yup from "yup";
const SUPPOERTED_IMAGE = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"];
const numericOnly = /^\d+$/;
const alphaNumeric = /^[a-z\d\-_\s]+$/i;
const onlyAlphabet = /^[a-zA-Z\s-]+$/;
const alphaNumericWithDash = /^[a-zA-Z0-9-]+$/;
const emailPattern = /(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;
const passWordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
const passwordPattern =
  /(?=.*[A-Za-z])(?=.*\d)(?=.*[!-/\\:-@\][-`{}-~])[A-Za-z\d!-/\\:-@\][-`{}-~]{8,}$/g;
const minCr = 3;
// const passwordPatterns = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
const imageWidthAndHeight = (provideFile) => {
  const imgDimensions = { width: null, height: null };

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(provideFile);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result;

      img.onload = function () {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;

        resolve(imgDimensions);
      };
    };
  });
};
export const imageDimensionCheck = yup.addMethod(
  yup.mixed,
  "imageDimensionCheck",
  function (message, requiredWidth, requiredHeight, targetRatio, isDimension) {
    return this.test("image-width-height-check", message, async function (value) {
      const { path, createError } = this;
      if (!value) {
        return true;
      }
      if (typeof value !== "object" && value.split("/")[0] === "https:") {
        return true;
      }
      if (!SUPPOERTED_IMAGE.includes(value.type)) {
        return createError({
          path,
          message,
        });
      }
      if (value.size >= 1048576 * 1) {
        return createError({
          path,
          message: "Max allowed size is less then 1MB",
        });
      }
      const imgDimensions = await imageWidthAndHeight(value);
      if (
        imgDimensions.width <= requiredWidth &&
        imgDimensions.height <= requiredHeight &&
        isDimension
      ) {
        return createError({
          path,
          message: `The image dimension should be ${requiredWidth}px to ${requiredHeight}px!`,
        });
      }

      return true;
    });
  }
);

const password = {
  password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passwordPattern,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("password"), null], "Password does not match."),
};

export const loginWithPasswordSchema = yup.object({
  email: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Invalid Email ID."),
  password: yup.string().required("Password cannot be blank."),
});
export const GeoCodeSechma = yup.object({
  country_id: yup.string().required("Country cannot be blank."),
  did_area_code_caption: yup
    .string()
    .required("Caption cannot be blank.")
    // .matches(alphaNumeric, "Caption will allow only alpha numberic value.")

    .test("len", "Caption cannot exceed more than 150 characters.", (val) =>
      val ? val.length <= 150 : true
    ),
  did_area_code_code: yup
    .string()
    .required("Code cannot be blank.")
    .matches(numericOnly, "Alphabets and special characters are not allowed.")
    .test("len", "Code cannot exceed more than 10 characters.", (val) =>
      val ? val.length <= 10 : true
    ),
});
export const NonGeoCodeSechma = yup.object({
  did_area_code_caption: yup
    .string()
    .required("Caption cannot be blank.")
    // .matches(alphaNumeric, "Caption will allow only alpha numberic value.")

    .test("len", "Caption cannot exceed more than 150 characters.", (val) =>
      val ? val.length <= 150 : true
    ),
  did_area_code_code: yup
    .string()
    .required("Code cannot be blank.")
    .matches(numericOnly, "Alphabets and special characters are not allowed.")
    .test("len", "Code cannot exceed more than 10 characters.", (val) =>
      val ? val.length <= 10 : true
    ),
});
export const EditDidsSchema = yup.object({
  did_range_holder_id1: yup.string().required("Range Holder Name cannot be blank."),
  did_supplier_id1: yup.string().required("Supplier Name cannot be blank."),
});
export const changePasswordSchema = yup.object({
  new_password: yup
    .string()
    .required("New Password cannot be blank.")
    .matches(
      passWordValidation,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("new_password"), null], "Password entries do not match."),
});
export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Enter valid Email ID."),
});
export const AssignDIDschema = yup.object({
  customer_name: yup.string().required("Company Name cannot be blank."),
  did_num: yup
    .string()
    .required("DID Number cannot be blank.")
    .matches(/^[0-9]+$/, " Special characters and alphabets are not allowed.")
    .test("len", "Minimum 7 and maximum 15 numbers are allowed.", (val) =>
      val ? val.length >= 7 && val.length <= 15 : true
    ),

  price: yup.string().required("DID Number Price cannot be blank."),
});
export const pageSchema = yup.object({
  page_content: yup.string().required("Page Content cannot be blank."),
});
export const updateCustomerSchema = yup.object({
  cus_firstname: yup
    .string()
    .required("First Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "Firstname cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),
  cus_day_of_plan: yup.string().required("Time of the day cannot be blank."),
  cus_time_of_day_category: yup.string().required("Category of Time of the day cannot be blank."),
  cus_lastname: yup
    .string()
    .required("Last Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "Lastname cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the last name.", (val) => (val ? val.trim().length > 0 : true)),
  cus_country: yup.string().required("Country cannot be blank."),
  cus_phone_number: yup.string().required("Mobile Number cannot be blank."),
  cus_business_number: yup.string().required("Business Number cannot be blank."),
  provider_id1: yup.string().required("Provider cannot be blank."),
  email_id: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Invalid Email ID."),
  cus_billing_email: yup
    .string()
    .required("Billing Email ID cannot be blank.")
    .matches(emailPattern, "Invalid Billing Email ID."),
  rateScheme_id1: yup.string().required("Rate Scheme cannot be blank."),
  company_name: yup
    .string()
    .required("Company Name cannot be blank.")
    .test("len", "Enter the company name.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Company name cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  cus_address_line_1: yup
    .string()
    .required("Company address line 1 cannot be blank.")
    .test("len", "Enter the company address line 1.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Company address line 1 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  cus_address_line_2: yup
    .string()
    .required("Company address line 2 cannot be blank.")
    .test("len", "Enter the company address line 2.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Company address line 2 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  // cus_city: yup
  //   .string()
  //   .required("City cannot be blank.")
  //   .matches(/^[a-zA-Z -]+$/, { message: "Special characters and numbers are not allowed." })
  //   .min(minCr, "Invalid city.")
  //   .test("len", "City cannot exceed more than 50 characters.", (val) =>
  //     val ? val.length <= 50 : true
  //   )
  //   .test("len", "Enter the city.", (val) => (val ? val.trim().length > 0 : true)),

  cus_postal_code: yup
    .string()
    .required("Postal code cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .min(minCr, `Invalid postal code.`)
    .test("len", "Postal code cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the postal code.", (val) => (val ? val.trim().length > 0 : true)),
});

export const AddDidsSchema = yup.object({
  did_supplier_status: yup.string().required("Supplier Name cannot be blank."),
  range_holder_name: yup.string().required("Range Holder Name cannot be blank."),
});
export const AddProductMasterVal = yup.object({
  prod_master_name: yup
    .string()
    .required("Product Name cannot be blank.")
    .test("len", "Product Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." }),
  prod_master_sku: yup.string().required("SKU cannot be blank."),
  // prod_category_id: "",
  prod_master_billing_period: yup.string().required("Billing Period must be selected."),
  prod_master_status: yup.string().required("Status cannot be blank."),
  prod_master_price: yup.string().required("Default Price cannot be blank."),
  prod_category_id1: yup.string().required("Category cannot be blank."),
});
export const addRemarks = yup.object({
  removal_request_remark: yup
    .string()
    .required("Remark cannot be blank.")
    .test("len", "Remark cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the remark.", (val) => (val ? val.trim().length > 0 : true)),
});
export const addRoleSchema = yup.object({
  admin_role_name: yup
    .string()
    .required("Role Name cannot be blank.")
    .test("len", "Role Name cannot exceed more than 256 characters.", (val) =>
      val ? val.length < 256 : true
    )
    .test("len", "Enter the role name.", (val) => (val ? val.trim().length > 0 : true)),
  admin_role_status: yup.string().required("Please select admin status cannot be blank."),
});
export const purchasedSIPAllocate = yup.object({
  sipid1: yup.string().required("SIP Server Name cannot be blank."),
});
export const addSuppplierSchema = yup.object({
  did_supplier_name: yup
    .string()
    .required("Supplier Name cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .test("len", "Supplier Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the supplier name.", (val) => (val ? val.trim().length > 0 : true)),
  did_supplier_status: yup.string().required("Status cannot be blank."),
});
export const addRangeHolderchema = yup.object({
  did_range_holder_name: yup
    .string()
    .required("Range Holder Name cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .test("len", "Range Holder Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the range holder name.", (val) => (val ? val.trim().length > 0 : true)),
  did_range_holder_code: yup
    .string()
    .required("Range Holder Code cannot be blank.")
    .matches(/^[0-9 -]+$/, { message: "Alphabets and special characters are not allowed." })
    .test("len", "Range Holder Code cannot exceed more than 10 characters.", (val) =>
      val ? val.length < 11 : true
    )
    .test("len", "Enter the range holder code.", (val) => (val ? val.trim().length > 0 : true)),
  did_range_holder_cupid: yup
    .string()
    .nullable()
    .matches(onlyAlphabet, " Special characters and numbers are not allowed."),
  did_range_holder_status: yup.string().required("Status cannot be blank."),
});
export const addProductCategory = yup.object({
  prod_category_name: yup
    .string()
    .required("Category Name cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .test("len", "Category Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the Category Name.", (val) => (val ? val.trim().length > 0 : true)),
  prod_category_status: yup.string().required("Status cannot be blank."),
});
export const AddPartnerVal = yup.object({
  country_id: yup.string().required("Country cannot be blank."),
  rateScheme_id1: yup.string().required("Rate Scheme cannot be blank"),
  partner_name: yup

    .string()
    .required("Partner Name cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .test("len", "Partner Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the Partner Name.", (val) => (val ? val.trim().length > 0 : true)),
  status: yup.string().required("Status cannot be blank."),
  email_id: yup
    .string()
    .test("len", "Enter the Email ID.", (val) => (val ? val.trim().length > 0 : true))
    .matches(emailPattern, "Invalid Email ID.")
    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  phone_no: yup
    .string()
    .required("Phone Number cannot be blank.")
    .matches(numericOnly, "Alphabets and special characters are not allowed.")
    .test("len", "Minimum 6 and maximum 15 numbers are allowed.", (val) =>
      val ? val.length >= 6 && val.length <= 15 : true
    ),
  add_line_1: yup
    .string()
    .required("Address Line 1 cannot be blank.")
    .test("len", "Enter the Address Line 1.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Address Line 1 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  passcode: yup
    .string()
    .required("Postal Code cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .min(minCr, `Invalid Postal Code.`)
    .test("len", "Postal Code cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the Postal Code.", (val) => (val ? val.trim().length > 0 : true)),
  billing_email: yup
    .string()
    .required("Billing Email ID cannot be blank.")
    .test("len", "Enter the Billing Email ID.", (val) => (val ? val.trim().length > 0 : true))
    .matches(emailPattern, "Invalid Billing Email ID."),
  file: yup
    .mixed()
    .test("fileSize", "File size cannot be greater than 25MB.", (value) => {
      if (!value) return true;
      return value.size <= 25 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format.", (value) => {
      if (!value) return true;
      const supportedFormats = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];
      return supportedFormats.includes(value.type);
    }),
  password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passWordValidation,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
  provider_id1: yup.string().required("Provider cannot be blank."),
});
export const AddProviderVal = yup.object({
  file: yup
    .mixed()
    .test("fileSize", "File size cannot be greater than 25MB.", (value) => {
      if (!value) return true;
      return value.size <= 25 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format.", (value) => {
      if (!value) return true;
      const supportedFormats = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];
      return supportedFormats.includes(value.type);
    }),
  country_id: yup.string().required("Country cannot be blank."),
  ActivePro_id: yup.string().required("Rate Scheme cannot be blank."),
  provider_name: yup
    .string()
    .required("Provider Name cannot be blank.")
    // .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .test("len", "Provider Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the Provider Name.", (val) => (val ? val.trim().length > 0 : true)),
  status: yup.string().required("Status cannot be blank."),
  email_id: yup
    .string()
    .test("len", "Enter the Email ID.", (val) => (val ? val.trim().length > 0 : true))
    .matches(emailPattern, "Invalid Email ID.")
    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  phone_no: yup
    .string()
    .required("Phone Number cannot be blank.")
    .matches(numericOnly, "Alphabets and special characters are not allowed.")
    .test("len", "Minimum 6 and maximum 15 numbers are allowed.", (val) =>
      val ? val.length >= 6 && val.length <= 15 : true
    ),
  add_line_1: yup
    .string()
    .required("Address Line 1 cannot be blank.")
    .test("len", "Enter the Address Line 1.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Address Line 1 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  passcode: yup
    .string()
    .required("Postal Code cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .min(minCr, `Invalid Postal Code.`)
    .test("len", "Postal Code cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the Postal Code.", (val) => (val ? val.trim().length > 0 : true)),
  bank_name: yup
    .string()
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Invalid Bank Name." })
    .nullable(),
  accnt_holder: yup
    .string()
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .nullable(),

  accnt_number: yup
    .string()
    .matches(numericOnly, "Alphabets and special characters are not allowed.")
    .nullable(),

  accnt_sort: yup.string().matches(alphaNumericWithDash, "Invalid  Bank Sort Code.").nullable(),
});
export const AddPartnerValEdit = yup.object({
  file: yup
    .mixed()
    .test("fileSize", "File size cannot be greater than 25MB.", (value) => {
      if (!value) return true;
      return value.size <= 25 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format.", (value) => {
      if (!value) return true;
      const supportedFormats = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];
      return supportedFormats.includes(value.type);
    }),
  country_id: yup.string().required("Country cannot be blank."),
  rateScheme_id1: yup.string().required("Rate Scheme cannot be blank"),
  partner_name: yup
    .string()
    .required("Partner Name cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .test("len", "Partner Name cannot exceed more than 150 characters.", (val) =>
      val ? val.length < 151 : true
    )
    .test("len", "Enter the Partner Name.", (val) => (val ? val.trim().length > 0 : true)),
  status: yup.string().required("Status cannot be blank."),
  email_id: yup
    .string()
    .test("len", "Enter the Email ID.", (val) => (val ? val.trim().length > 0 : true))
    .matches(emailPattern, "Invalid Email ID.")
    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  phone_no: yup
    .string()
    .required("Phone Number cannot be blank.")
    .matches(numericOnly, "Alphabets and special characters are not allowed.")
    .test("len", "Minimum 6 and maximum 15 numbers are allowed.", (val) =>
      val ? val.length >= 6 && val.length <= 15 : true
    ),
  add_line_1: yup
    .string()
    .required("Address Line 1 cannot be blank.")
    .test("len", "Enter the Address Line 1.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Address Line 1 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  passcode: yup
    .string()
    .required("Postal Code cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .min(minCr, `Invalid postal Code.`)
    .test("len", "Postal Code cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the Postal Code.", (val) => (val ? val.trim().length > 0 : true)),
  password: yup
    .string()
    .matches(
      passWordValidation,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
  billing_email: yup
    .string()
    .required("Billing Email ID cannot be blank.")
    .test("len", "Enter the Billing Email ID.", (val) => (val ? val.trim().length > 0 : true))
    .matches(emailPattern, "Invalid Billing Email ID."),
  provider_id1: yup.string().required("Provider cannot be blank."),
});
export const addotpSchemass = yup.object({
  prices: yup.array().of(
    yup.object({
      partner_price: yup
        .string()
        .required("Price cannot be blank")
        .matches(/^\d+(\.\d{1,2})?$/, "Invalid price format"),
    })
  ),
});
export const addotpSchema = yup.object({
  otp: yup
    .string()
    .required("Verification Code cannot be blank.")
    .matches(/^[0-9 -]+$/, { message: "Alphabets and Special characters are not allowed." }),
});
export const authverifySchema = yup.object({
  otp: yup
    .string()
    .required("Authentication Code cannot be blank.")
    .matches(/^[0-9 -]+$/, { message: "Alphabets and Special characters are not allowed." }),
});
export const createCustomerSchema = yup.object({
  cus_first_name: yup
    .string()
    .required("First Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "Firstname cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),
  cus_day_of_time: yup.string().required("Category of Time of the day cannot be blank."),
  cus_last_name: yup
    .string()
    .required("Last Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "Lastname cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the last name.", (val) => (val ? val.trim().length > 0 : true)),
  cus_country: yup.string().required("Country cannot be blank."),
  cus_phone_number: yup.string().required("Mobile Number cannot be blank."),
  cus_business_number: yup.string().required("Business Number cannot be blank."),
  provider_id1: yup.string().required("Provider cannot be blank."),
  cus_time_of_day_sub_category: yup.string().required("Time of the day cannot be blank."),
  cus_email: yup
    .string()
    .required("Email ID cannot be blank.")
    .matches(emailPattern, "Invalid Email ID."),
  cus_billing_email: yup
    .string()
    .required("Billing Email ID cannot be blank.")
    .matches(emailPattern, "Invalid Billing Email ID."),
  rateScheme_id1: yup.string().required("Rate Scheme cannot be blank."),
  cus_address_line_1: yup
    .string()
    .required("Company address line 1 cannot be blank.")
    .test("len", "Enter the company address line 1.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Company address line 1 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  cus_address_line_2: yup
    .string()
    .required("Company address line 2 cannot be blank.")
    .test("len", "Enter the company address line 2.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Company address line 2 cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  cus_company_name: yup
    .string()
    .required("Company Name cannot be blank.")
    .test("len", "Enter the company name.", (val) => (val ? val.trim().length > 0 : true))
    .test("len", "Company name cannot exceed more than 256 characters.", (val) =>
      val ? val.trim().length < 256 : true
    ),
  cus_postal_code: yup
    .string()
    .required("Postal code cannot be blank.")
    .matches(/^[a-zA-Z0-9 -]+$/, { message: "Special characters are not allowed." })
    .min(minCr, `Invalid postal code.`)
    .test("len", "Postal code cannot exceed more than 50 characters.", (val) =>
      val ? val.length < 50 : true
    )
    .test("len", "Enter the postal code.", (val) => (val ? val.trim().length > 0 : true)),
  password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least 8 characters with at least one uppercase letter and one number."
    ),
  cus_account_type: yup.string().required("Please select account type."),
  // username: yup
  //   .string()
  //   .required("Username cannot be blank.")
  //   .matches(emailPattern, "Invalid Username."),
  cus_account_status: yup.string().required("Please select account status."),
});
export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("New Password cannot be blank.")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{8,}$/,
      "New Password must contain at least 8 characters with at least one uppercase letter and one number."
    )
    .nullable(),

  confirm_password: yup
    .string()
    .required("Confirm New Password cannot be blank.")
    .oneOf([yup.ref("password"), null], "Password entries do not match.")
    .nullable(),
});
export const importImg = yup.object({
  file: yup
    .mixed()
    .test("fileSize", "File size cannot be greater than 25MB.", (value) => {
      if (!value) return true;
      return value.size <= 25 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format.", (value) => {
      if (!value) return true;
      const supportedFormats = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];
      return supportedFormats.includes(value.type);
    }),
});

export const importCSV = yup.object({
  file: yup
    .mixed()
    .required("File cannot be blank.")
    .test("fileSize", "File size cannot be greater than 15KB.", (value) => {
      if (!value) return true;
      return value.size <= 15 * 1024;
    })
    .test("fileType", "Unsupported file format.", (value) => {
      if (!value) return true;
      const supportedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
      ];
      return supportedFormats.includes(value.type);
    }),
});
export const request3cx = yup.object({
  customer_name1: yup.string().required("Company Name must be selected."),
  licence_type1: yup.string().required("Licence edition must be selected."),
  calls1: yup.string().required("Simultaneous calls must be selected."),
});

export const createAdmin = yup.object({
  admin_role1: yup.string().required("Admin Role cannot be blank."),
  Email_id: yup
    .string()
    .matches(emailPattern, "Invalid Email ID.")
    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  admin_name: yup
    .string()
    .required("First Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "First Name cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),

  Last_Name: yup
    .string()
    .required("Last Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "Last Name cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the last name.", (val) => (val ? val.trim().length > 0 : true)),

  status_type: yup.string().required("Status cannot be blank."),
  password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passwordPattern,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
});
export const partnerAssign = yup.object({
  partner_id1: yup.string().required("Partner Name cannot be blank."),
});
export const UpdateAdmin = yup.object({
  admin_role1: yup.string().required("Admin Role cannot be blank."),
  Email_id: yup
    .string()
    .matches(emailPattern, "Invalid Email ID.")

    .test("len", "Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable()
    .required("Email ID cannot be blank."),
  admin_name: yup
    .string()
    .required("First Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "First Name cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the first name.", (val) => (val ? val.trim().length > 0 : true)),

  Last_Name: yup
    .string()
    .required("Last Name cannot be blank.")
    .matches(onlyAlphabet, " Special characters and numbers are not allowed.")
    .test("len", "Last Name cannot exceed more than 50 characters.", (val) =>
      val ? val.length <= 50 : true
    )
    .test("len", "Enter the last name.", (val) => (val ? val.trim().length > 0 : true)),

  status_type: yup.string().required("Status cannot be blank."),
});
export const spendLimitSchema = yup.object({
  warning_cus_email: yup
    .string()
    .matches(emailPattern, "Invalid Warning Limit Email.")
    .test("len", "Warning Limit Email ID should be maximum 254 character.", (val) =>
      val ? val.length <= 254 : true
    )
    .nullable() // Allow the field to be null
    .required("Warning Limit Email ID cannot be blank."),

  daily_limit: yup
    .number()
    .notOneOf([0], "Daily limit cannot be 0.")
    .required("Daily limit cannot be blank."),
  weekly_limit: yup
    .number()
    .notOneOf([0], "Weekly limit cannot be 0.")
    .required("Weekly limit cannot be blank."),
  monthly_limit: yup
    .number()
    .notOneOf([0], "Monthly limit cannot be 0.")
    .required("Monthly limit cannot be blank."),
  warning_daily_limit: yup.number().notOneOf([0], "Daily warning limit cannot be blank."),
  warning_monthly_limit: yup.number().notOneOf([0], "Monthly warning limit cannot be blank."),
  warning_weekly_limit: yup.number().notOneOf([0], "Weekly warning limit cannot be blank."),
});
export const forgotPasswordSchema = yup.object({
  password: yup
    .string()
    .required("New Password cannot be blank.")
    .matches(
      passwordPattern,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password cannot be blank.")
    .oneOf([yup.ref("password"), null], "Password does not match."),
});
export const updatePriceSchema = yup.object({
  global_national_did_prize: yup.string().required("National DID cannot be blank."),
  global_international_did_prize: yup.string().required("International DID cannot be blank."),
  global_sip_prize: yup.string().required("SIP Trunk cannot be blank."),
});
export const passwordChangeSchema = yup.object({
  old_password: yup
    .string()
    .required("Password cannot be blank.")
    .matches(
      passwordPattern,
      "Password must contains at least 8 characters, one number, one alphabet, and one special character."
    ),
  ...password,
});

export const companySchema = yup.object({
  company_name: yup
    .string()
    .required("Please enter company name.")
    .min(minCr, `Company name must have at least ${minCr} characters.`)
    .max(60, "Company name may not be longer then 60 characters.")
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  company_logo: yup.string().required("Please upload a company logo."),
  url: yup.mixed().when("company_logo", {
    is: (image) => image?.split("/")[0] !== "https:",
    then: yup
      .mixed()
      .imageDimensionCheck(
        "Please upload company logo in jpg, jpeg, svg or png format.",
        120,
        120,
        1,
        true
      ),
    otherwise: yup.mixed().notRequired(),
  }),
  company_industry_type: yup.string().required("Please select an industry type."),
  company_address: yup
    .string()
    .min(minCr, `Company address must have at least ${minCr} characters.`)
    .max(250, "Company address may not be longer then 250 characters.")
    .nullable(),
});

export const skillSchema = yup.object({
  skill_name: yup
    .string()
    .required("Please enter skill name.")
    .min(minCr, `Skill name must have at least ${minCr} characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});

export const industrySchema = yup.object({
  industry_name: yup
    .string()
    .required("Please enter industry name.")
    .min(minCr, `Industry name must have at least ${minCr} characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
});

export const jobSchema = yup.object({
  job_designation: yup
    .string()
    .required("Please enter designation.")
    .min(minCr, `Designation name must have at least ${minCr} characters.`)
    .max(60, `Designation name must have at least 60 characters.`)
    .matches(alphaNumeric, "Only alphabet and numeric are allowed for this field."),
  company_id: yup.string().required("Please select a company."),
  skills: yup
    .array()
    .min(2, "Please add at least two skill.")
    .required("Please select add skills."),
  job_summary: yup
    .string()
    .required("Please enter Summary.")
    .min(minCr, `Summary name must have at least ${minCr} characters.`)
    .max(150, `Summary must be within 150 Characters`),
  job_description: yup
    .string()
    .required("Please enter job description.")
    .test("contentRequired", "Please enter job description.", (item) => {
      return removeTags(item);
    }),
  extra_benefits: yup.string().nullable(),
  currency: yup.string().required("Please select currency type."),
  salary_offered: yup
    .string()
    .required("Please enter offered salary.")
    .matches(numericOnly, "Must be numeric only."),
  job_type: yup.string().required("Please select a job type."),
  job_mode: yup.string().required("Please select a mode type."),
  experience: yup
    .string()
    .required("Please enter experience.")
    .matches(numericOnly, "Must be numeric only."),
});

export const DateValidateSchema = yup.object({
  start_date: yup
    .date()
    .max(new Date(), "Start date should not be greater than today.")
    .typeError("Please enter valid start date.")
    .nullable(),
  end_date: yup
    .date()
    .max(new Date(), "End date should not be greater than today.")
    .when("start_date", {
      is: (start_date) => moment.isDate(start_date),
      then: yup
        .date()
        .required("Please enter end date.")
        .typeError("Please enter valid end date.")
        .nullable(),
    })
    .test("lessthenSD", "End date should not be less than start date.", (value, testContext) => {
      return !moment(value).isBefore(testContext.parent.start_date);
    })
    .typeError("Please enter valid end date.")
    .nullable(),
});
