import { Checkbox, CircularProgress, FormControlLabel, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SuiPasswordInput from "components/SuiPasswordInput";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { useFormik } from "formik";
import { loginWithPasswordSchema } from "Validation";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "app/features/AuthSlice";
import { useLoginWithPasswordMutation } from "app/features/api/AuthApi";
import { useState } from "react";
import { setRememberMe } from "app/features/AuthSlice";
import colors from "assets/theme/base/colors";

const LoginWithPass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithPassword] = useLoginWithPasswordMutation();
  const [loginwithPasswordError, setLoginwithPasswordError] = useState("");
  const { remember } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginWithPasswordSchema,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
        password: values.password,
        remember: remember,
      };
      try {
        const response = await loginWithPassword(body).unwrap();
        const authResponse = response.data.admin_2fa_auth;
        const admin_id = response.data.admin_id;
        if (authResponse === "1") {
          navigate(`/otpverify/${admin_id}`);
        } else {
          if (response.status === 200) {
            localStorage.setItem("auth_token_admin", response.data.token);
            localStorage.setItem("tokenExpiryTime_admin", response.data.token_expire_at);
            localStorage.setItem("loggedInTime_admin", new Date());
            localStorage.setItem("refresh_token_admin", response.data.refresh_token);
            localStorage.setItem("isRemember_admin", remember);
            sessionStorage.setItem("isRemember_admin", remember);

            dispatch(setCredentials({ token_admin: response.data.token, isAuthenticated: true }));
            navigate("/dashboard");
          }
        }
      } catch (error) {
        setLoginwithPasswordError(error.data.message);
      }
    },
  });
  const handleSetRememberMe = () => {
    dispatch(setRememberMe(!remember));
  };
  return (
    <>
      <SuiTypography
        component="h1"
        variant="formHeading"
        sx={{ lineHeight: "44px" }}
        fontSize={{ xs: 16, md: 18, xxl: 22 }}
      >
        Sign in
      </SuiTypography>
      <SuiTypography
        component="p"
        textColor="text2"
        variant="formSubHeading"
        mb={4}
        fontSize={{ xs: 12, md: 14, xxl: 20 }}
      >
        Fill up below details to sign in
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={3}>
          <SuiTypography
            component="p"
            variant="formSubHeading"
            mb={1.5}
            sx={{
              color: `${colors.text.focus} !important`,
            }}
          >
            Email ID
          </SuiTypography>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="email"
            placeholder="Email ID"
            id="email"
            name="email"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiTypography
            component="p"
            variant="formSubHeading"
            mb={1.5}
            sx={{
              color: `${colors.text.focus} !important`,
            }}
          >
            Password
          </SuiTypography>
          <SuiPasswordInput
            name="password"
            value={formik.values.password}
            getValue={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
        </SuiBox>
        <Grid item sx={{ pb: 1 }}>
          {Object.keys(formik.errors)?.length === 0 && loginwithPasswordError && (
            <ErrorMessageComponent
              touched={loginwithPasswordError}
              error={loginwithPasswordError}
            />
          )}
        </Grid>
        <SuiBox display="flex" alignItems="center">
          <FormControlLabel
            value="end"
            control={
              <Checkbox color="secondary" checked={remember} onChange={handleSetRememberMe} />
            }
            label="Remember me"
            sx={{
              m: 0,
              "& .MuiTypography-root": {
                color: "#848484",
                fontWeight: 400,
              },
            }}
          />
          <SuiTypography
            customClass="cursor-pointer"
            ml="auto"
            component={Link}
            to="/forgot-password"
            variant="caption"
            textColor="info"
            fontWeight="medium"
            tabIndex="3"
          >
            Forgot Password?
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2} mb={0.5}>
        <SuiButton
          tabIndex={4}
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          buttonColor="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" sx={{ color: "blue" }} />
          ) : (
            "Sign in"
          )}
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default LoginWithPass;
