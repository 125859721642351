import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";

const { transparent, secondary, text2 } = colors;

export default {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
      height: pxToRem(25),
      padding: `${pxToRem(8)} ${pxToRem(25)} ${pxToRem(8)} ${pxToRem(12)} !important`,
      borderRadius: pxToRem(10),
      flexGrow: 1,
      "&.Mui-disabled": {
        backgroundColor: secondary.main,
        color: text2.main,
        WebkitTextFillColor: text2.main,
        cursor: "not-allowed",
      },
      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },

    icon: {
      width: "1.5em",
      height: "1.5em",
      right: "3px",
      top: "calc(50% - 0.75em)",
    },
  },
};
