import {
  Box,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from "@mui/material";
import rgba from "../../assets/theme/functions/rgba";
import colors from "../../assets/theme/base/colors";
import boxShadow from "../../assets/theme/functions/boxShadow";
import { useCallback, useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import SuiButton from "components/SuiButton";
import SuiDialog from "components/SuiDialog";
import setImg from "../../assets/images/logout-dialog-image.png";
import { useGetByIdDIdsMutation } from "app/features/api/AuthApi";
import { formatDateNew } from "helper";
import CommonSpinner from "components/CommonSpinner";
import { useFormik } from "formik";
import { useSupplierDropListMutation } from "app/features/api/AuthApi";
import { debounce } from "lodash";
import { useRangeHolderDropListMutation } from "app/features/api/AuthApi";
import { EditDidsSchema } from "Validation";
import { useAddIdDIdsMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";

const DidInventoryDetails = () => {
  const navigate = useNavigate();
  // const [numberStatus] = useState("");
  const [supplierListVal, setSupplierListVal] = useState();
  const [searchTextRange, setSearchTextRange] = useState("");
  const [rangeListVal, setRangeListVal] = useState();

  const [openModal, setOpenModal] = useState(false);
  const [supplierDropList] = useSupplierDropListMutation();
  const [searchText, setSearchText] = useState("");
  const [rangeHolderDropList] = useRangeHolderDropListMutation();
  const location = useLocation();
  const [listData, setListData] = useState("");

  const { pathname } = location;
  const checkPath = pathname.includes("edit-did-inventory");
  const id = localStorage.getItem("did_inventory_id");

  const SupplierLists = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");

      try {
        const response = await supplierDropList(params).unwrap();
        setSupplierListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  const headerData = [
    { id: "1", width: "auto", align: "left", label: "DID Number" },
    { id: "6", width: "auto", align: "left", label: "Country" },
    { id: "2", width: "auto", align: "left", label: "Area Code" },
    { id: "3", width: "auto", align: "left", label: "Range Info" },
    { id: "4", width: "auto", align: "center", label: "IS Golden Number" },
    { id: "5", width: "auto", align: "center", label: "Is Fuse 2 Allocation" },
  ];

  const [getByIdDIds, { isLoading: isLoading }] = useGetByIdDIdsMutation();
  const getByIdDidsFuncc = async () => {
    try {
      const resp = await getByIdDIds(id).unwrap();
      setListData(resp.data);

      formik.setFieldValue(
        "did_inventory_is_golden_number",
        resp.data.did_inventory_is_golden_number === "1"
      );
      formik.setFieldValue(
        "did_inventory_is_fuse2_number",
        resp.data.did_inventory_is_fuse2_number === "1"
      );
      formik.setFieldValue("did_supplier_id1", resp.data?.did_suppliers?.did_supplier_id);

      const transformedRoles = {
        id: resp.data.did_suppliers.did_supplier_id,
        name: resp.data.did_suppliers.did_supplier_name,
      };

      formik.setFieldValue("did_supplier_id", transformedRoles);
      formik.setFieldValue(
        "did_range_holder_id1",
        resp.data.did_range_holders?.did_range_holder_id
      );
      const transformedHolder = {
        id: resp.data.did_range_holders.did_range_holder_id,
        name: resp.data.did_range_holders.did_range_holder_name,
      };
      formik.setFieldValue("did_range_holder_id", transformedHolder);
    } catch (error) {
      console.log(error);
    }
  };

  const RangeList = useCallback(
    debounce(async (value) => {
      setSearchTextRange(value?.target?.value || "");

      try {
        const response = await rangeHolderDropList().unwrap();
        setRangeListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    getByIdDidsFuncc();
    SupplierLists();
    RangeList();
  }, []);
  useEffect(() => {
    if (searchText.length > 0) {
      SupplierLists(searchText);
    } else {
      setSupplierListVal([]);
    }
  }, [searchText, SupplierLists]);
  useEffect(() => {
    if (searchTextRange.length > 0) {
      RangeList();
    } else {
      setRangeListVal([]);
    }
  }, [searchTextRange, RangeList]);
  const suiSnackbar = useSuiSnackbar();
  const [AddIdDIds] = useAddIdDIdsMutation();
  const formik = useFormik({
    initialValues: {
      did_inventory_is_golden_number: "",
      did_inventory_is_fuse2_number: "",
      did_supplier_id: "",
      did_range_holder_id: "",
      did_inventory_action: null,
      did_supplier_id1: "",
      did_range_holder_id1: "",
      did_inventory_unassign: null,
    },
    validationSchema: EditDidsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        did_inventory_is_golden_number: values.did_inventory_is_golden_number ? "1" : "0",
        did_inventory_is_fuse2_number: values.did_inventory_is_fuse2_number ? "1" : "0",
        did_supplier_id: values.did_supplier_id.id,
        did_range_holder_id: values.did_range_holder_id.id,
        did_inventory_action: values.did_inventory_action ? values.did_inventory_action : null,
        did_inventory_unassign:
          listData?.did_inventory_number_status === "Dormant"
            ? null
            : values.did_inventory_unassign === true
            ? "1"
            : "0",
        id,
      };
      try {
        const resp = await AddIdDIds(body).unwrap();
        suiSnackbar(resp.message, "success");
        navigate("/did-inventory");
      } catch (error) {
        suiSnackbar(error.data.message, "error");
        console.log(error);
      }
    },
  });
  console.log(formik.values, "values formik ");
  const disabled = true;
  const rows = (
    <TableRow key={listData.did_inventory_number}>
      <TableCell>{listData.did_inventory_number}</TableCell>
      <TableCell>{listData.country_id != null ? listData.countries?.country_name : "-"}</TableCell>

      <TableCell>{listData?.did_area_codes?.did_area_code_caption}</TableCell>
      <TableCell>
        {listData.did_inventory_range_start}-{listData.did_inventory_range_end}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={formik.values.did_inventory_is_golden_number}
          name="did_inventory_is_golden_number"
          color="secondary"
          sx={{
            opacity: checkPath ? 1 : 0.5,
            pointerEvents: disabled && checkPath ? "auto" : "none",
          }}
          onChange={formik.handleChange}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={formik.values.did_inventory_is_fuse2_number}
          name="did_inventory_is_fuse2_number"
          color="secondary"
          onChange={formik.handleChange}
          sx={{
            opacity: checkPath ? 1 : 0.5,
            pointerEvents: disabled && checkPath ? "auto" : "none",
          }}
        />
      </TableCell>
    </TableRow>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleBackClick = () => {
    navigate("/did-inventory");
  };

  const supplierData = supplierListVal?.map((dropval) => ({
    id: dropval.did_supplier_id,
    name: dropval.did_supplier_name,
  }));
  const rangeData = rangeListVal?.map((dropval) => ({
    id: dropval.did_range_holder_id,
    name: dropval.did_range_holder_name,
  }));
  console.log(listData, "listData");
  return (
    <>
      <SuiBox>
        <Grid container pt={{ xs: 2, md: 3, xl: 4 }} rowSpacing={{ xs: 3, lg: 5 }}>
          <Grid item xs={12}>
            {isLoading ? (
              <CommonSpinner size="100px" color={colors.primary.main} />
            ) : (
              <Grid container spacing={2}>
                {/* Detail Section */}
                <Grid item xs={12} mb={2}>
                  <Box
                    mx="auto"
                    borderRadius={5}
                    bgcolor="#fff"
                    boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                  >
                    <Box
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <SuiTypography sx={{ fontSize: "18px", color: "#2B2B2B", fontWeight: "600" }}>
                        <ArrowBackIcon
                          onClick={handleBackClick}
                          sx={{
                            color: "#55DED0",
                            fontSize: "20px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                          }}
                        />{" "}
                        {checkPath ? "Edit" : "View"} {listData?.did_inventory_number}
                      </SuiTypography>
                    </Box>
                    <Grid item xs={12}>
                      <SuiTypography
                        textColor={
                          listData?.did_inventory_number_status == "Dormant"
                            ? "success"
                            : listData?.did_inventory_number_status == "Exported"
                            ? "error"
                            : "text2"
                        }
                        variant="p"
                        fontWeight="medium"
                        sx={{ fontSize: "18px" }}
                      >
                        {/* Dormant */}
                        {listData?.did_inventory_number_status}
                      </SuiTypography>
                    </Grid>

                    {/* Number Section */}
                    <Grid item xs={12}>
                      <Box
                        className="didTableSet"
                        borderRadius={2}
                        bgcolor="#fff"
                        boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                        mt={3}
                        borderColor={rgba(colors.text.focus, 0.3)}
                        sx={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                        }}
                      >
                        <EnhancedTable
                          tableMinWidth={750}
                          tablehead={headerData}
                          rowsData={[rows]}
                        />
                      </Box>
                    </Grid>

                    {/* Additional Info Section */}
                    <Grid container mt={4} spacing={2}>
                      {/* if edit is enabled for Dormant */}
                      {checkPath &&
                        (listData?.did_inventory_number_status === "Dormant" ||
                          listData?.did_inventory_number_status === "On Hold") && (
                          <>
                            <Grid item xs={12} md={6}>
                              <FormLabel htmlFor="price" required>
                                Supplier Name
                              </FormLabel>
                              <AutocompleteSingleSelect
                                id="supplier_select"
                                value={formik.values.did_supplier_id}
                                setValue={(value) => {
                                  formik.setFieldValue("did_supplier_id", value);
                                  formik.setFieldValue("did_supplier_id1", value.name);
                                }}
                                noOptionsText="No options"
                                placeholder="Select Supplier Name"
                                isLoading={false}
                                disabled={false}
                                options={supplierData}
                                formik={formik}
                              />
                              {formik.errors.did_supplier_id1 && formik.touched.did_supplier_id && (
                                <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                  {formik.errors.did_supplier_id1}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel htmlFor="price" required>
                                Range Holder Name
                              </FormLabel>
                              <AutocompleteSingleSelect
                                id="range_holder_select"
                                value={formik.values.did_range_holder_id}
                                setValue={(value) => {
                                  formik.setFieldValue("did_range_holder_id", value);
                                  formik.setFieldValue("did_range_holder_id1", value.name);
                                }}
                                noOptionsText="No options"
                                placeholder="Select Range Holder Name"
                                isLoading={false}
                                disabled={false}
                                options={rangeData}
                                formik={formik}
                              />
                              {formik.errors.did_range_holder_id1 &&
                                formik.touched.did_range_holder_id && (
                                  <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                    {formik.errors.did_range_holder_id1}
                                  </div>
                                )}
                            </Grid>
                          </>
                        )}

                      {/* if edit is enabled for Dormant */}
                      {!checkPath && (
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Supplier Name:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {listData?.did_suppliers?.did_supplier_name}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Range Holder Name:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {listData?.did_range_holders?.did_range_holder_name}{" "}
                            </SuiTypography>
                          </Box>
                          {listData?.did_inventory_number_status == "Exported" && (
                            <>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Exported on:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {formatDateNew(listData?.did_inventory_exported_on)}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Exported By:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {listData?.admins == null
                                    ? "Self"
                                    : `${listData?.admins?.admin_name} ${listData?.admins?.admin_last_name}`}
                                </SuiTypography>
                              </Box>
                            </>
                          )}
                        </Grid>
                      )}
                      {/* other details if listData?.did_inventory_number_status ==Exported */}
                      {listData?.did_inventory_number_status == "Exported" && (
                        <>
                          <Grid item xs={12} mt={2} mb={2}>
                            <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                              Other Details
                            </SuiTypography>
                          </Grid>
                          <Grid item sx={12} md={6} xl={4}>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Account Number:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.customers?.cus_account_number}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Company Name:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {/* {listData?.customers?.cus_firstname}{" "}
                                {listData?.customers?.cus_lastname} */}
                                {listData?.customers?.cus_company_name}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Account Type:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.customers?.cus_account_type == "0"
                                  ? "Postpaid"
                                  : "Prepaid"}
                              </SuiTypography>
                            </Box>
                          </Grid>
                          <Grid item sx={12} md={6} xl={4}>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Provision to SRE:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData.did_inventory_provision_to_sre == 0 ? "No" : "Yes"}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Rate Override:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                £ {listData.did_inventory_rate_override}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                SIP Server Name:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.did_inventory_sip_server_name == null
                                  ? "-"
                                  : listData?.did_inventory_sip_server_name}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Port in Fees:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                £ {listData?.did_inventory_porting_fee_amount}
                              </SuiTypography>
                            </Box>
                          </Grid>
                        </>
                      )}
                      {/* listData?.did_inventory_request_status == "Waiting For Payment" && */}
                      {listData?.did_inventory_number_status == "On Hold" && (
                        <>
                          <Grid item xs={12} mt={2} mb={2}>
                            <SuiTypography
                              sx={{
                                display: "flex",
                                direction: "row",
                                gap: "10px",
                              }}
                              variant="body2"
                              textColor="dark"
                              fontWeight="semiBold"
                            >
                              Other Details
                              {checkPath &&
                                listData?.did_inventory_request_status == "Waiting For Payment" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      checked={formik.values.did_inventory_unassign}
                                      name="did_inventory_unassign"
                                      color="secondary"
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "did_inventory_unassign",
                                          e.target.checked
                                        );
                                        {
                                          formik.values.did_inventory_unassign == true
                                            ? setOpenModal(false)
                                            : setOpenModal(true);
                                        }
                                      }}
                                    />
                                    <SuiTypography
                                      variant="span"
                                      textColor="info"
                                      fontWeight="semiBold"
                                      sx={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      Unassign this DID
                                    </SuiTypography>
                                  </div>
                                )}
                              {/* When Edit is enabled */}
                            </SuiTypography>
                          </Grid>
                          <Grid item sx={12} md={6} xl={6}>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Account Number:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.customers?.cus_account_number}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Company Name:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {/* {listData?.customers?.cus_firstname}{" "}
                                {listData?.customers?.cus_lastname} */}
                                {listData?.customers?.cus_company_name}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Account Type:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.customers?.cus_account_type == "1"
                                  ? "Prepaid"
                                  : "Postpaid"}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Provision to SRE:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData.did_inventory_provision_to_sre == 0 ? "No" : "Yes"}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Rate Override:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                £ {listData.did_inventory_rate_override}
                              </SuiTypography>
                            </Box>
                            {listData?.did_inventory_request_status == "Requested For Removal" &&
                              checkPath && (
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Status:
                                  </SuiTypography>

                                  <SuiTypography variant="body2" textColor="success">
                                    {listData?.did_inventory_request_status}
                                  </SuiTypography>
                                </Box>
                              )}

                            {/* When Edit is enabled */}
                            {checkPath &&
                              listData?.did_inventory_number_status == "On Hold" &&
                              listData?.did_inventory_request_status != "Waiting For Payment" &&
                              listData?.did_inventory_request_status == "Requested For Removal" && (
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Take Action:
                                  </SuiTypography>
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      sx={{
                                        alignItems: "center",
                                        rowGap: 1.5,
                                        "&>.MuiFormControlLabel-root": {
                                          marginRight: 1,
                                        },
                                      }}
                                      id="range"
                                      // defaultValue="Accept"
                                      name="radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value="Accept"
                                        control={<Radio />}
                                        name="did_inventory_action"
                                        onChange={() =>
                                          formik.setFieldValue("did_inventory_action", "accept")
                                        }
                                        label="Accept"
                                      />
                                      <FormControlLabel
                                        value="Reject"
                                        control={<Radio />}
                                        name="did_inventory_action"
                                        onChange={() =>
                                          formik.setFieldValue("did_inventory_action", "reject")
                                        }
                                        label="Reject"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Box>
                              )}
                          </Grid>
                          <Grid item sx={12} md={6} xl={5}>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                SIP Server Name:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.did_inventory_sip_server_name == null
                                  ? "-"
                                  : listData?.did_inventory_sip_server_name}{" "}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Port in Fees:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                £ {listData?.did_inventory_porting_fee_amount}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Is Ported:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.did_inventory_is_ported_in == "1" ? "Yes" : "No"}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Effective Date:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {listData?.did_inventory_exported_on == null
                                  ? "-"
                                  : formatDateNew(listData?.did_inventory_exported_on)}
                              </SuiTypography>
                            </Box>
                            {listData?.did_inventory_request_status == "Requested For Removal" &&
                              !checkPath && (
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Status:
                                  </SuiTypography>

                                  <SuiTypography variant="body2" textColor="success">
                                    {listData?.did_inventory_request_status}
                                  </SuiTypography>
                                </Box>
                              )}
                            {listData?.did_inventory_request_status != "Requested For Removal" && (
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Status:
                                </SuiTypography>

                                <SuiTypography variant="body2" textColor="success">
                                  {listData?.did_inventory_request_status}
                                </SuiTypography>
                              </Box>
                            )}
                            {/* When Edit is enabled */}
                          </Grid>
                        </>
                      )}
                      {/* other details if listData?.did_inventory_number_status ==on hold  */}
                      {/* When Edit is enabled */}
                      {checkPath && (
                        <Grid item xs={12}>
                          <Grid container pt={4} columnSpacing={2} rowSpacing={1}>
                            <Grid item>
                              <SuiButton
                                size="small"
                                component={Link}
                                to="/did-inventory"
                                variant="outlined"
                                buttonColor="dark"
                                sx={{ px: "1rem", py: "0.4rem" }}
                              >
                                Cancel
                              </SuiButton>
                            </Grid>
                            <Grid item>
                              <SuiButton
                                type="submit"
                                size="small"
                                buttonColor="primary"
                                sx={{ px: "1rem" }}
                                onClick={formik.handleSubmit}
                                // disabled={!formik.dirty}
                              >
                                Update
                              </SuiButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {/* When Edit is enabled */}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </SuiBox>
      <SuiDialog
        open={openModal}
        onClose={() => {
          formik.setFieldValue("did_inventory_unassign", false);
          setOpenModal(false);
        }}
        haveCloseIcon
        maxWidth="xs"
      >
        <DialogContent
          sx={{
            fontWeight: "semiBold",
            textAlign: "center",
          }}
        >
          <Box mb={4} textAlign="center">
            <img src={setImg} alt="Image" width="100" />
          </Box>
          Are you sure you want to unassign
          <br />
          this DID from the customer?
          <SuiTypography
            fontWeight="regular"
            sx={{
              mt: 2,
              fontSize: "12px !important",
            }}
          >
            Unassigning the DID will mark it as Dormant again.
          </SuiTypography>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() =>
                      setOpenModal(false) || formik.setFieldValue("did_inventory_unassign", false)
                    }
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    No
                  </SuiButton>
                </Grid>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={() => setOpenModal(false)}
                  >
                    Yes
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
    </>
  );
};

export default DidInventoryDetails;
