import { Avatar, Box, Grid, Skeleton, TableCell, TableRow } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import boxShadow from "assets/theme/functions/boxShadow";
import { useNavigate } from "react-router-dom";

import EmailIcon from "../../assets/images/email.png";
import CallIcon from "../../assets/images/call.png";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { useViewPartnerInfoMutation } from "app/features/api/AuthApi";
import { useEffect, useState } from "react";
import { useProductListInfoMutation } from "app/features/api/AuthApi";
import { stringAvatar } from "helper";
import colors from "assets/theme/base/colors";
import SuiButton from "components/SuiButton";
import { getFileExtension } from "helper";

const ViewPartner = () => {
  const id = localStorage.getItem("partner_id");
  const [ViewPartnerInfo, { isLoading: isLoading }] = useViewPartnerInfoMutation();
  const [productList, setProductList] = useState();

  const [partnerInfo, setPartnerInfo] = useState();
  console.log(partnerInfo);
  const partnerDetails = async (id) => {
    try {
      const response = await ViewPartnerInfo(id).unwrap();
      setPartnerInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    partnerDetails(id);
    GetProductListData();
  }, []);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/partner-management");
  };
  const pagesTableHeader = [
    {
      id: "1",
      align: "left",
      width: "auto",
      label: "Product name",
    },
    {
      id: "2",
      align: "left",
      width: "auto",
      label: "Product Categroy",
    },
    {
      id: "3",
      align: "left",
      width: "auto",
      label: "Default Price",
    },
    {
      id: "4",
      align: "left",
      width: "auto",
      label: "Partner’S Price",
    },
  ];
  const [ProductListInfo] = useProductListInfoMutation();
  const handleDownload = async (path) => {
    try {
      const response = await fetch(path, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const filePath = partnerInfo?.partner_logo_img_path;
      const fileExtension = getFileExtension(filePath);
      link.setAttribute("download", `partner_logo.${fileExtension}`);
      // link.setAttribute("download", "partner_logo.jpg");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const GetProductListData = async () => {
    const params = {
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  const rows = productList?.map((item) => (
    <TableRow key={1}>
      <TableCell>{item?.prod_master_name}</TableCell>
      <TableCell>{item?.product_categories?.prod_category_name}</TableCell>
      <TableCell>£{item?.prod_master_price}</TableCell>
      <TableCell>£{item?.partner_pricing?.[0]?.partner_price ?? 0.0}</TableCell>
    </TableRow>
  ));
  return (
    <>
      <SuiBox>
        <Grid container pt={{ xs: 2, md: 3, xl: 4 }} rowSpacing={{ xs: 3, lg: 5 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Detail Section */}
              <Grid item xs={12} mb={2}>
                <Box
                  mx="auto"
                  borderRadius={5}
                  bgcolor="#fff"
                  boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                >
                  <Box py={{ xs: 3, lg: 3 }} px={{ xs: 4, lg: 4 }}>
                    <Box
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <SuiTypography
                        sx={{
                          fontSize: "18px",
                          color: "#2B2B2B",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <ArrowBackIcon
                          onClick={handleBackClick}
                          sx={{
                            color: "#55DED0",
                            fontSize: "20px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                          }}
                        />
                        View {partnerInfo?.partner_account_number}
                        {isLoading || !partnerInfo ? (
                          <Skeleton variant="text" width={120} height={50} />
                        ) : (
                          <SuiTypography
                            fontSize="12px"
                            width="auto"
                            px={1.5}
                            py={0.8}
                            borderRadius={2}
                            fontWeight="semiBold"
                            textColor={
                              partnerInfo.partner_status == "1"
                                ? "text"
                                : partnerInfo.partner_status == "2"
                                ? "white"
                                : partnerInfo.partner_status == "3"
                                ? "white"
                                : "white"
                            }
                            bgcolor={
                              partnerInfo.partner_status == "1"
                                ? `${colors.primary.main}`
                                : partnerInfo.partner_status == "2"
                                ? `${colors.dark.main}`
                                : partnerInfo.partner_status == "3"
                                ? `${colors.error.main}`
                                : `${colors.text2.main}`
                            }
                          >
                            {partnerInfo.partner_status == "1"
                              ? "Active"
                              : partnerInfo.partner_status == "2"
                              ? "Restricted"
                              : partnerInfo.partner_status == "3"
                              ? "Blocked"
                              : "Inactive"}
                            {/* {partnerInfo.partner_status} */}
                          </SuiTypography>
                        )}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "20px",
                      }}
                    >
                      {isLoading ? (
                        <Skeleton variant="circular" width={100} height={100} />
                      ) : partnerInfo?.partner_logo_img_path === "" ||
                        partnerInfo?.partner_logo_img_path === null ||
                        partnerInfo?.partner_logo_img_path === "null" ? (
                        <Avatar
                          sx={{ width: "100px", height: "100px" }}
                          alt="Partner Avatar"
                          {...stringAvatar(partnerInfo?.partner_name, "", "100px", "40px")}
                        />
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              background: "#F2F4FF",
                              borderRadius: "50%",
                              padding: "5px",
                              width: "100px",
                              height: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL + partnerInfo?.partner_logo_img_path
                              }
                              alt="IMG"
                              width="90"
                              height="90"
                              className="radius-set"
                            />
                          </Box>
                          {partnerInfo?.partner_logo_img_path?.length > 1 && (
                            <SuiButton
                              variant="outlined"
                              buttonColor="info"
                              customClass="linkSet"
                              sx={{
                                margin: "10px auto 0",
                              }}
                              onClick={() =>
                                handleDownload(
                                  `${process.env.REACT_APP_BASE_URL}${partnerInfo?.partner_logo_img_path}`
                                )
                              }
                            >
                              Download
                            </SuiButton>
                          )}
                        </Box>
                      )}

                      <Box>
                        <SuiTypography
                          varient="h2"
                          fontWeight="medium"
                          fontSize={{ xs: 18, lg: 22, xl: 22, xxl: 28 }}
                        >
                          {partnerInfo?.partner_name}
                        </SuiTypography>
                        <SuiTypography varient="p" fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}>
                          {" "}
                          {partnerInfo?.partner_account_number}
                        </SuiTypography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "8px",
                            flexWrap: "wrap",
                          }}
                        >
                          {isLoading ? (
                            <Skeleton variant="text" width={150} height={20} />
                          ) : (
                            partnerInfo?.partner_email && (
                              <a
                                href={`mailto:${partnerInfo?.partner_email}`}
                                style={{
                                  textDecoration: "none",
                                  color: "#2B2B2B",
                                  wordBreak: "break-all",
                                }}
                              >
                                <img src={EmailIcon} alt="Email Icon" width="15" />{" "}
                                {partnerInfo?.partner_email}
                              </a>
                            )
                          )}
                          {isLoading ? (
                            <Skeleton variant="text" width={150} height={20} />
                          ) : (
                            partnerInfo?.partner_phone_number && (
                              <>
                                {" | "}
                                <a
                                  href={`tel:${partnerInfo?.partner_phone_number}`}
                                  style={{ textDecoration: "none", color: "#2B2B2B" }}
                                >
                                  <img src={CallIcon} alt="Phone Icon" width="15" />{" "}
                                  {partnerInfo?.partner_phone_number}
                                </a>
                              </>
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* address details :: BEGIN */}
                  <Box
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                    sx={{ borderTop: "3px solid #F2F4FF" }}
                  >
                    <SuiTypography
                      varient="h3"
                      mb={1.5}
                      fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 22 }}
                      fontWeight="medium"
                    >
                      Address Details
                    </SuiTypography>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Address Line 1:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.partner_company_address_1 !== ""
                          ? partnerInfo?.partner_company_address_1
                          : "-"}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Address Line 2:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.partner_company_address_2 === "" ||
                        partnerInfo?.partner_company_address_2 === null
                          ? "-"
                          : partnerInfo?.partner_company_address_2}
                      </SuiTypography>
                    </Box>
                    <Box
                      mb={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Country:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.countries !== "" ? partnerInfo?.countries.country_name : "-"}
                      </SuiTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Postal Code:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B" }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.partner_postal_code !== ""
                          ? partnerInfo?.partner_postal_code
                          : "-"}
                      </SuiTypography>
                    </Box>
                  </Box>
                  {/* address details :: END */}
                  {/* email details :: BEGIN */}
                  <Box
                    py={{ xs: 3, lg: 3 }}
                    px={{ xs: 4, lg: 4 }}
                    sx={{ borderTop: "3px solid #F2F4FF" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mx: 1,
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-banks"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Billing Email ID:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B", ml: 1 }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.partner_billing_email}
                      </SuiTypography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mx: 1,
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-banks"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Rate Scheme:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B", ml: 2 }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {" "}
                        {partnerInfo?.rate_schemes === null
                          ? "-"
                          : partnerInfo?.rate_schemes?.rate_scheme_name}
                      </SuiTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mx: 1,
                      }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#848484 !important" }}
                        customClass="width-set-banks"
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        Provider Name:
                      </SuiTypography>
                      <SuiTypography
                        varient="h3"
                        fontWeight="normal"
                        sx={{ color: "#2B2B2B", ml: 1 }}
                        fontSize={{ xs: 14, lg: 14, xl: 16, xxl: 18 }}
                      >
                        {partnerInfo?.providers === null
                          ? "fuse 2 communication"
                          : partnerInfo?.providers?.provider_name}
                      </SuiTypography>
                    </Box>
                  </Box>
                  {/* email details :: END */}
                  {/* Product Price Details :: BEGIN */}
                  {partnerInfo?.partner_is_whitelable_reseller == !0 && (
                    <Box
                      py={{ xs: 3, lg: 3 }}
                      px={{ xs: 4, lg: 4 }}
                      sx={{ borderTop: "3px solid #F2F4FF" }}
                    >
                      <SuiTypography
                        varient="h3"
                        fontWeight="medium"
                        fontSize={{ xs: 16, lg: 16, xl: 18, xxl: 22 }}
                      >
                        Product Price Details
                      </SuiTypography>
                      <Grid
                        className="didTableSet"
                        my={2}
                        sx={{
                          border: "1px solid #ddd",
                          borderRadius: "10px",
                          boxShadow: "1px 2px 0px 0px #D9DBE7",
                        }}
                      >
                        <EnhancedTable
                          tableMinWidth={540}
                          tablehead={pagesTableHeader}
                          rowsData={rows}
                        />
                      </Grid>
                    </Box>
                  )}
                  {/* Product Price Details :: END */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

export default ViewPartner;
