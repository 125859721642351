import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Grid,
  FormLabel,
  FormControlLabel,
  Switch,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDropDownList from "components/SuiDropDownList";
import SuiPasswordInput from "components/SuiPasswordInput";
import boxShadow from "assets/theme/functions/boxShadow";
import rgba from "assets/theme/functions/rgba";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import colors from "assets/theme/base/colors";
import NumberInput from "components/SuiInput/NumberInput";
import { AddPartnerVal } from "Validation";
import { useAddPartnerMutation } from "app/features/api/AuthApi";
import { AddPartnerValEdit } from "Validation";
import {
  useProductListInfoMutation,
  useViewPartnerInfoMutation,
  useEditPartnerMutation,
  useGetAllCountryMutation,
  useGetActiveProviderMutation,
} from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import ProfileUploadUI from "components/SuiFileUploadInput/ProfileUploadUI";
import companyLogo from "../../assets/images/dashboard/company-logo.svg";
import { debounce } from "lodash";
import { useGetActiveRateSchemeMutation } from "app/features/api/AuthApi";
import { useGetGlobalProviderMutation } from "app/features/api/AuthApi";
import { getFileExtension } from "helper";

const AddPartner = () => {
  const id = localStorage.getItem("partner_id");
  const [error, setError] = useState("");
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [AddPartner] = useAddPartnerMutation();
  const [productList, setProductList] = useState();
  const suiSnackbar = useSuiSnackbar();
  const [EditPartner] = useEditPartnerMutation();
  const [defaultProviderdata, setDefaultProviderdata] = useState();
  const navigate = useNavigate();
  const [Price, setPrices] = useState([]);
  const priceFormik = useFormik({
    initialValues: {
      prices: [],
    },
    onSubmit: () => {},
  });
  const [ProductListInfo] = useProductListInfoMutation();
  const [ViewPartnerInfo] = useViewPartnerInfoMutation();
  const headerData = [
    { id: "1", width: "auto", align: "left", label: "Product Name" },
    { id: "2", width: "auto", align: "left", label: "Product Category" },
    { id: "3", width: "auto", align: "left", label: "Default Price" },
    { id: "4", width: "300", align: "left", label: "Partner's Price" },
  ];
  const filteredData = priceFormik.values.prices
    ?.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.partner_price_id === "") {
        delete updatedItem.partner_price_id;
      }
      updatedItem.partner_price =
        updatedItem.partner_price !== "" && updatedItem.partner_price !== 0
          ? Number(updatedItem.partner_price)
          : updatedItem.partner_price;

      return updatedItem;
    })
    .filter((item) => item.partner_price !== "" && item.partner_price !== 0);
  // const handlePriceChange = (index, value) => {
  //   const updatedPrices = [...prices];
  //   updatedPrices[index].partner_price = Number(value);
  //   // updatedPrices[index].prod_master_id;
  //   setPrices(updatedPrices);
  //   priceFormik.setFieldValue(`prices[${index}].partner_price`, Number(value));
  //   priceFormik.setFieldValue(
  //     `prices[${index}].prod_master_id`,
  //     updatedPrices[index].prod_master_id
  //   );
  // };
  const formik = useFormik({
    initialValues: {
      partner_name: "",
      email_id: "",
      phone_no: "",
      status: 1,
      add_line_1: "",
      add_line_2: "",
      passcode: "",
      billing_email: "",
      password: "",
      check_white: false,
      id: "",
      file: "",
      Country: "",
      country_id: "",
      rateScheme_id: "",
      rateScheme_id1: "",
      provider_id: "",
      provider_id1: "",
    },
    validationSchema: id ? AddPartnerValEdit : AddPartnerVal,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      if (id) {
        if (values.partner_name != tabInfoVal.partner_name) {
          formData.append("partner_name", values.partner_name);
        }
        if (values.email_id != tabInfoVal?.partner_email) {
          formData.append("partner_email", values.email_id);
        }
        if (values.phone_no != tabInfoVal?.partner_phone_number) {
          formData.append("partner_phone_number", values.phone_no);
        }
        if (values.add_line_1 != tabInfoVal?.partner_company_address_1) {
          formData.append("partner_company_address_1", values.add_line_1);
        }
        if (values.add_line_2 != tabInfoVal?.partner_company_address_2) {
          formData.append(
            "partner_company_address_2",
            values.add_line_2 === null ? "" : values.add_line_2
          );
        }
        if (values.Country.id != tabInfoVal?.countries?.country_id) {
          formData.append("country_id", values.Country.id);
        }
        if (values.rateScheme_id.id != tabInfoVal?.rate_schemes?.rate_scheme_id) {
          formData.append("rate_scheme_id", values.rateScheme_id.id);
        }
        if (values.passcode != tabInfoVal?.partner_postal_code) {
          formData.append("partner_postal_code", values.passcode);
        }
        if (values.billing_email != tabInfoVal?.partner_billing_email) {
          formData.append("partner_billing_email", values.billing_email);
        }
        {
          values.password?.length > 1 && formData.append("partner_password", values.password);
        }
        if (
          (values.check_white != tabInfoVal?.partner_is_whitelable_reseller) == 1 ? true : false
        ) {
          formData.append(
            "partner_is_whitelable_reseller",
            values.check_white === true ? "1" : "0"
          );
        }
        if (values.status != tabInfoVal.partner_status) {
          formData.append("partner_status", String(values.status));
        }
        if (values.file) {
          formData.append("image", values.file);
          // id && formData.append("partner_logo_img_path", values.file);
        }
        if (previewUrl != null) {
          formData.append("image", previewUrlImg);
        }
        if (values.file == "" && previewUrl == null) {
          id && formData.append("partner_logo_img_path", values.file);
        }
        // if (previewUrl != null) {
        //   formData.append("partner_logo_img_path", previewUrlImg);
        // }
        if (values.check_white == true) {
          formData.append("partner_product_prices", JSON.stringify(filteredData));
          formData.append("provider_id", values.provider_id.id);
        } else {
          formData.append("provider_id", defaultProviderdata.id);
        }
      } else {
        formData.append("partner_name", values.partner_name);
        formData.append("partner_email", values.email_id);
        formData.append("partner_phone_number", values.phone_no);
        formData.append("partner_company_address_1", values.add_line_1);
        formData.append("rate_scheme_id", values.rateScheme_id.id);
        formData.append(
          "partner_company_address_2",
          values.add_line_2 === null ? "" : values.add_line_2
        );
        formData.append("country_id", values.Country.id);
        formData.append("partner_postal_code", values.passcode);
        formData.append("partner_billing_email", values.billing_email);
        {
          values.password?.length > 1 && formData.append("partner_password", values.password);
        }
        formData.append("partner_is_whitelable_reseller", values.check_white === true ? "1" : "0");
        formData.append("partner_status", String(values.status));
        formData.append("image", values.file);
        {
          id && formData.append("partner_logo_img_path", values.file);
        }
        if (values.check_white == true) {
          formData.append("provider_id", values.provider_id.id);
          formData.append("partner_product_prices", JSON.stringify(filteredData));
        } else {
          formData.append("provider_id", defaultProviderdata.id);
        }
      }
      try {
        if (id) {
          formData.id = id;
          let response = await EditPartner(formData).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/partner-management");
            setError("");
          }
        } else {
          let response = await AddPartner(formData).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            navigate("/partner-management");
            suiSnackbar(response.message, "success");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.data.errorMessages) {
          const issues = error.data.errorMessages.issues;
          if (issues) {
            issues.map((item) => {
              suiSnackbar(item.message, "error");
            });
          }
        } else {
          suiSnackbar(error.data.message, "error");
        }
      }
    },
  });
  const [uploadedFile, setUploadedFile] = useState(null); // State to store the uploaded file
  const [previewUrl, setPreviewUrl] = useState(null); // State to store preview UR
  const [previewUrlImg, setPreviewUrlImg] = useState(null); // State to store preview URL

  console.log(previewUrl, "previewUrl");
  const handleFileChange = (file) => {
    setUploadedFile(file);
    setPreviewUrl(URL.createObjectURL(file)); // Update preview for the single uploaded file
    formik.setFieldValue("file", file);
  };
  const removeFileNamefn = () => {
    setUploadedFile(null);
    setPreviewUrl(null);
    formik.setFieldValue("file", "");
    formik.setFieldValue("partner_logo_img_path", "");
  };
  const [countryList, setCountryList] = useState();
  const [GlobalProvierList, setGlobalProvierList] = useState();
  const [GetAllCountry] = useGetAllCountryMutation();
  const [GetGlobalProvider] = useGetGlobalProviderMutation();
  const getCountry = async () => {
    try {
      const response = await GetAllCountry().unwrap();
      setCountryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getProviderID = async () => {
    try {
      const response = await GetGlobalProvider().unwrap();
      setGlobalProvierList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const options = countryList?.map((item) => ({
    id: item.country_id,
    name: item.country_name,
  }));
  const generatePassword = () => {
    // Define character sets
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const specialChars = '!@#$%^&*()_+{}:"<>?-=[];,./';
    const numberChars = "0123456789";

    const getRandomChar = (charSet) => {
      const randomIndex = Math.floor(Math.random() * charSet.length);
      return charSet[randomIndex];
    };
    const randomUppercase = getRandomChar(uppercaseChars);
    const randomLowercase = getRandomChar(lowercaseChars);
    const randomSpecial = getRandomChar(specialChars);
    const randomNumber = getRandomChar(numberChars);
    let password = randomUppercase + randomLowercase + randomSpecial + randomNumber;
    const allChars = uppercaseChars + lowercaseChars + specialChars + numberChars;
    for (let i = password.length; i < 8; i++) {
      const randomChar = getRandomChar(allChars);
      password += randomChar;
    }
    const passwordArray = password.split("");
    for (let i = passwordArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
    }
    password = passwordArray.join("");
    formik.setFieldValue("password", password);
  };
  const handleDownload = async (path) => {
    try {
      const response = await fetch(path, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const filePath = tabInfoVal?.partner_logo_img_path;
      const fileExtension = getFileExtension(filePath);
      link.setAttribute("download", `partner_logo.${fileExtension}`);
      // link.setAttribute("download", "partner_logo.png");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    if (!formik.values.password && !id) {
      generatePassword();
    }
    if (!id) {
      ActiveRateScheme();
      ActiveProvider();
      getProviderID();
    }
  }, []);
  const [ActiveRateSchemeData] = useGetActiveRateSchemeMutation();
  const [rateSchemeDataList, setRateSchemeData] = useState();
  const [searchRateSchemeText, setRateschemeSearchText] = useState();
  const ActiveRateScheme = async () => {
    try {
      const body = {
        rate_scheme_type: "partner",
      };
      const response = await ActiveRateSchemeData(body).unwrap();
      const defaultScheme = response.data.find(
        (scheme) => scheme.rate_scheme_is_default_partner === "1"
      );
      if (defaultScheme) {
        const transformedRateScheme = {
          id: defaultScheme.rate_scheme_id,
          name: defaultScheme.rate_scheme_name,
        };
        formik.setFieldValue("rateScheme_id", transformedRateScheme);
        formik.setFieldValue("rateScheme_id1", defaultScheme.rate_scheme_name);
      } else {
        formik.setFieldValue("rateScheme_id", null);
        formik.setFieldValue("rateScheme_id1", "");
      }
      setRateSchemeData(response.data);
    } catch (error) {
      console.error("Error fetching rate scheme details:", error);
    }
  };
  const ActiveRateSchemeFind = useCallback(
    debounce(async (value) => {
      const params = value
        ? { partner_name: value.target.value, rate_scheme_type: "partner" }
        : { rate_scheme_type: "partner" };
      setRateschemeSearchText(value?.target?.value || "");
      try {
        const response = await ActiveRateSchemeData(params).unwrap();
        setRateSchemeData(response.data);
      } catch (error) {
        console.error("Error fetching rate scheme details:", error);
      }
    }, 1000),
    []
  );
  const rateSchemeData = rateSchemeDataList?.map((dropval) => ({
    id: dropval.rate_scheme_id,
    name: dropval.rate_scheme_name,
  }));
  const [ActiveProviderList] = useGetActiveProviderMutation();
  const [providerData, setProviderData] = useState();
  const [searchProviderText, setSearchProviderText] = useState();
  const ActiveProvider = async () => {
    try {
      const response = await ActiveProviderList().unwrap();

      setProviderData(response.data);
    } catch (error) {
      console.error("Error fetching providers details:", error);
    }
  };

  useEffect(() => {
    if (providerData) {
      const defaultProvider = providerData.find(
        (item) =>
          item.provider_id == GlobalProvierList?.global_config_json_value?.default_postpay_provider
      );
      if (defaultProvider) {
        const transformProvider = {
          id: defaultProvider.provider_id,
          name: defaultProvider.provider_name,
        };
        setDefaultProviderdata(transformProvider);
      }

      if (defaultProvider) {
        const transformProvider = {
          id: defaultProvider.provider_id,
          name: defaultProvider.provider_name,
        };
        setDefaultProviderdata(transformProvider);
        if (!formik.values.check_white) {
          formik.setFieldValue("provider_id1", defaultProvider.provider_id);
          formik.setFieldValue("provider_id", transformProvider);
        }
      }
    }
  }, [GlobalProvierList]);
  const ActiveSearchProvider = useCallback(
    debounce(async (value) => {
      const params = value ? { partner_name: value?.target?.value } : {};
      setSearchProviderText(value?.target?.value || "");
      try {
        const response = await ActiveProviderList(params).unwrap();
        setProviderData(response.data);
      } catch (error) {
        console.error("Error fetching providers details:", error);
      }
    }, 1000),
    []
  );
  const ProviderData = providerData?.map((dropval) => ({
    id: dropval.provider_id,
    name: dropval.provider_name,
  }));
  useEffect(() => {
    if (formik.values.check_white && searchProviderText !== "") {
      ActiveSearchProvider();
    }
    if (searchRateSchemeText !== "") {
      ActiveRateSchemeFind();
    }
  }, [searchProviderText, formik.values.check_white, searchRateSchemeText]);
  const [tabInfoVal, setTabInfoVal] = useState();
  const tabInfo = async (id) => {
    try {
      const response = await ViewPartnerInfo(id).unwrap();
      setTabInfoVal(response.data);
      setPreviewUrl(
        response.data.partner_logo_img_path === "" ||
          response.data.partner_logo_img_path === null ||
          response.data.partner_logo_img_path === "null"
          ? null
          : process.env.REACT_APP_BASE_URL + response.data.partner_logo_img_path
      );
      setPreviewUrlImg(response.data.partner_logo_img_path);
      // formik.setFieldValue(
      //   "file",
      //   response.data.partner_logo_img_path === "" || response.data.partner_logo_img_path === null
      //     ? null
      //     : response.data.partner_logo_img_path
      // );
      formik.setFieldValue("partner_name", response.data.partner_name);
      formik.setFieldValue("id", response.data.partner_account_number);
      formik.setFieldValue("email_id", response.data.partner_email);
      formik.setFieldValue("status", response.data.partner_status);
      formik.setFieldValue("phone_no", response.data.partner_phone_number);
      formik.setFieldValue("add_line_1", response.data.partner_company_address_1);
      formik.setFieldValue(
        "add_line_2",
        response.data.partner_company_address_2 === "null"
          ? ""
          : response.data.partner_company_address_2
      );
      formik.setFieldValue("passcode", response.data.partner_postal_code);
      formik.setFieldValue("billing_email", response.data.partner_billing_email);
      formik.setFieldValue("country_id", response.data.countries.country_name);
      const transformedCountry = {
        id: response.data.countries.country_id,
        name: response.data.countries.country_name,
      };
      formik.setFieldValue("Country", transformedCountry);
      formik.setFieldValue(
        "check_white",
        response.data.partner_is_whitelable_reseller == "1" ? true : false
      );
      if (response.data.partner_is_whitelable_reseller == "1") {
        setSwitchOn(true);
      }
      if (response.data.providers) {
        formik.setFieldValue("provider_id1", response.data.providers.provider_name);
        const transformedProvider = {
          id: response.data.providers.provider_id,
          name: response.data.providers.provider_name,
        };
        formik.setFieldValue("provider_id", transformedProvider);
      } else {
        ActiveProvider();
      }
      if (response.data.rate_schemes != null) {
        formik.setFieldValue("rateScheme_id1", response.data.rate_schemes.rate_scheme_name);
        const transformedProvider = {
          id: response.data.rate_schemes.rate_scheme_id,
          name: response.data.rate_schemes.rate_scheme_name,
        };
        formik.setFieldValue("rateScheme_id", transformedProvider);
      } else {
        ActiveRateScheme();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
    if (formik.values.check_white) {
      formik.setFieldValue("check_white", false);
    } else if (!formik.values.check_white) {
      formik.setFieldValue("check_white", true);
    }
  };
  const GetProductListData = async () => {
    try {
      const response = await ProductListInfo().unwrap();
      setProductList(response.data);
      const updatedPrices = response.data.map((product) => ({
        prod_master_id: product.prod_master_id,
      }));
      response.data.forEach((product, index) => {
        priceFormik.setFieldValue(`prices[${index}].prod_master_id`, product.prod_master_id);
        priceFormik.setFieldValue(`prices[${index}].partner_price`, ""); // Initialize empty price
      });
      setPrices(updatedPrices);
      console.log(Price);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  const GetProductListDataById = async () => {
    const params = {
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      setProductList(response.data);
      const updatedPrices = response.data.map((product) => ({
        prod_master_id: product.prod_master_id,

        partner_price:
          product.partner_pricing.length > 0 ? product.partner_pricing[0].partner_price : "",
        partner_price_id:
          product.partner_pricing.length > 0 ? product.partner_pricing[0].partner_price_id : "",
      }));
      priceFormik.setFieldValue(`prices`, updatedPrices);

      setPrices(updatedPrices);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  const rows = productList?.map((item, index) => (
    <TableRow key={1}>
      <TableCell>{item?.prod_master_name}</TableCell>
      <TableCell>{item?.product_categories?.prod_category_name}</TableCell>
      <TableCell>£{item?.prod_master_price}</TableCell>
      <TableCell>
        <div key={index}>
          <NumberInput
            name={`prices[${index}].partner_price`}
            placeholder="Enter Rate"
            step={1}
            value={priceFormik.values.prices[index]?.partner_price || ""}
            onChange={priceFormik.handleChange}
            // onChange={(e) => handlePriceChange(index, e.target.value)}
            onBlur={priceFormik.handleBlur}
            setValue={priceFormik.setFieldValue}
          />
        </div>
      </TableCell>
    </TableRow>
  ));
  useEffect(() => {
    if (id) {
      GetProductListDataById();
      tabInfo(id);
    }
    if (!id) {
      GetProductListData();
    }
    getCountry();
  }, [id]);

  console.log(formik.values);

  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Basic Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Partner Name (Company Name)
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Partner Name"
              id="partner_name"
              name="partner_name"
              value={formik.values.partner_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.partner_name}
              error={formik.errors.partner_name}
            />
          </Grid>
          {id && (
            <Grid item xs={12} lg={6}>
              <FormLabel htmlFor="role_name" required>
                Partner Id
              </FormLabel>
              <SuiInput
                disabled
                inputSettungs={{ autoComplete: "off" }}
                type="text"
                placeholder="Enter Id"
                id="id"
                name="id"
                value={formik.values.id}
                onChange={formik.handleChange}
              />
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Email Id
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Email Id"
              id="email_id"
              name="email_id"
              value={formik.values.email_id}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.email_id}
              error={formik.errors.email_id}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Phone Number
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Phone Number"
              id="phone_no"
              name="phone_no"
              value={formik.values.phone_no}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.phone_no}
              error={formik.errors.phone_no}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="status" required>
              Status
            </FormLabel>
            <SuiDropDownList
              data={[
                { id: "0", label: "Inactive" },
                { id: "1", label: "Active" },
                { id: "2", label: "Restricted" },
                { id: "3", label: "Blocked" },
              ]}
              select={{
                key: "id",
                valueKey: "id",
                labelName: "label",
                label: "Status",
                name: "status",
                val: formik.values.status,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent touched={formik.touched.status} error={formik.errors.status} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="rateScheme" required>
              Rate Scheme
            </FormLabel>
            <AutocompleteSingleSelect
              id="rateScheme"
              value={formik.values.rateScheme_id || null}
              setValue={(value) => {
                formik.setFieldValue("rateScheme_id", value);
                formik.setFieldValue("rateScheme_id1", value.name);
              }}
              noOptionsText={"No options"}
              placeholder="Select Rate Scheme"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              isLoading={false}
              disabled={false}
              options={rateSchemeData}
              formik={formik}
              onInputChange={ActiveRateSchemeFind}
            />
            {formik.errors.rateScheme_id1 && formik.touched.rateScheme_id && (
              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                {formik.errors.rateScheme_id1}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Address Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Address Line 1
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Address Line 1"
              id="add_line_1"
              name="add_line_1"
              value={formik.values.add_line_1}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.add_line_1}
              error={formik.errors.add_line_1}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">Address Line 2</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Address Line 2"
              id="add_line_2"
              name="add_line_2"
              value={formik.values.add_line_2}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.add_line_2}
              error={formik.errors.add_line_2}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="company_address" required>
              Country
            </FormLabel>
            <AutocompleteSingleSelect
              id="Country"
              value={formik.values.Country}
              setValue={(value) => {
                formik.setFieldValue("Country", value);
                formik.setFieldValue("country_id", value.name);
              }}
              noOptionsText="No options"
              placeholder="Select Country"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              isLoading={false}
              disabled={false}
              options={options}
              formik={formik}
            />
            {formik.errors.country_id && formik.touched.Country && (
              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>{formik.errors.country_id}</div>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Postal Code
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Postal Code"
              id="passcode"
              name="passcode"
              value={formik.values.passcode}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.passcode}
              error={formik.errors.passcode}
            />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Billing Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Billing Email ID
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Billing Email ID"
              id="billing_email"
              name="billing_email"
              value={formik.values.billing_email}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.billing_email}
              error={formik.errors.billing_email}
            />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Credential Details"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Username (Email Id)
            </FormLabel>
            <SuiInput
              disabled
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter User Name"
              id="email_id"
              name="email_id"
              value={formik.values.email_id}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required={id ? false : true}>
              {id ? "Password" : "Password (Auto Generated)"}
            </FormLabel>
            <SuiPasswordInput
              index={1}
              attr={{ name: "password", placeholder: "Password" }}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              getValue={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.password}
              error={formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {"Reseller Information"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControlLabel
              name="cus_network_status"
              // value={formik.values.cus_network_status}
              onChange={formik.handleChange}
              control={
                <Switch
                  checked={isSwitchOn}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                  color="primary"
                  sx={{ ml: 2 }}
                  disabled={tabInfoVal?.partner_is_whitelable_reseller == 1}
                />
              }
              label="Is Whitelabel Reseller"
              labelPlacement="start"
            />
          </Grid>
          {formik.values.check_white && (
            <Grid item xs={12} lg={6}>
              <FormLabel htmlFor="account_status" required>
                Provider
              </FormLabel>
              <AutocompleteSingleSelect
                id="provider"
                value={formik.values.provider_id}
                setValue={(value) => {
                  formik.setFieldValue("provider_id", value);
                  formik.setFieldValue("provider_id1", value.name);
                }}
                noOptionsText={"No options"}
                placeholder="Select Provider"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                isLoading={false}
                disabled={true}
                options={ProviderData}
                formik={formik}
                onInputChange={ActiveSearchProvider}
              />
              <ErrorMessageComponent
                touched={formik.touched.provider_id1}
                error={formik.errors.provider_id1}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {formik.values.check_white && (
              <>
                <Box
                  className="didTableSet"
                  borderRadius={2}
                  bgcolor="#fff"
                  boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                  mt={3}
                  borderColor={rgba(colors.text.focus, 0.3)}
                  sx={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <EnhancedTable tableMinWidth={750} tablehead={headerData} rowsData={rows} />
                </Box>
                <Grid item xs={12} mt={3}>
                  <SuiTypography
                    fontWeight="normal"
                    fontSize={16}
                    sx={{
                      color: `${colors.text.focus} !important`,
                    }}
                  >
                    {"Company Logo"}
                  </SuiTypography>
                </Grid>
                <Grid mt={2} item xs={12} lg={6}>
                  <Box
                    className="company-image-upload"
                    mt={2}
                    mb={1.5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#E9E9E9",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={previewUrl === null ? companyLogo : previewUrl}
                        className={
                          previewUrl === null ? "company-preview-set" : "comapny-image-set"
                        }
                        alt="Company Logo"
                      />
                    </Box>
                    <Box>
                      <ProfileUploadUI
                        name="file"
                        id="file"
                        label={
                          !uploadedFile ? "Upload Logo" : "Remove" // Display file name if uploaded
                        }
                        accept=".png, .jpg, .jpeg, .svg"
                        getFile={handleFileChange}
                        removeFileName={removeFileNamefn}
                        type="false"
                        tabInfoVal={tabInfoVal?.partner_logo_img_path}
                        formik={formik}
                      />
                    </Box>
                  </Box>
                  {id && previewUrl !== null && (
                    <SuiButton
                      variant="outlined"
                      buttonColor="info"
                      customClass="linkSet"
                      onClick={() =>
                        handleDownload(
                          `${process.env.REACT_APP_BASE_URL}${tabInfoVal?.partner_logo_img_path}`
                        )
                      }
                    >
                      Download
                    </SuiButton>
                  )}
                  <ErrorMessageComponent touched={formik.touched.file} error={formik.errors.file} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/partner-management"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  onClick={async () => {
                    if (formik.values.check_white) {
                      const isPriceFormValid = await priceFormik.validateForm();
                      if (isPriceFormValid && priceFormik.dirty) {
                        priceFormik.handleSubmit();
                      }
                    }
                  }}
                  disabled={!formik.dirty}
                >
                  {id ? "Update" : "Save"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AddPartner;
