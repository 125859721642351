import { makeStyles } from "@mui/styles";

export default makeStyles(
  ({ palette, boxShadows, functions, transitions, breakpoints, typography }) => {
    const { dark, white, text } = palette;
    const { navbarBoxShadow } = boxShadows;
    const { pxToRem } = functions;
    const { size } = typography;

    return {
      navbar: {
        boxShadow: navbarBoxShadow,
        backgroundColor: white.main,

        color: ({ transparentNavbar, light }) => {
          let color;

          if (light) {
            color = white.main;
          } else if (transparentNavbar) {
            color = text.main;
          } else {
            color = dark.main;
          }

          return color;
        },
        top: ({ absolute }) => (absolute ? 0 : pxToRem(0)),
        minHeight: pxToRem(75),
        display: "grid",
        alignItems: "center",
        paddingTop: pxToRem(8),
        paddingBottom: pxToRem(8),
        paddingRight: ({ absolute }) => (absolute ? pxToRem(8) : 0),
        paddingLeft: ({ absolute }) => (absolute ? pxToRem(16) : 0),

        "& > *": {
          transition: transitions.create("all", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },

        "& .MuiToolbar-root": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: `${pxToRem(4)} ${pxToRem(0)}`,

          [breakpoints.up("sm")]: {
            minHeight: "auto",
          },
        },
      },
      sidenav_logo: {
        width: 110,
        marginRight: "1rem",
        paddingLeft: "10px",
        [breakpoints.up("xl")]: {
          marginRight: "138px",
        },
        [breakpoints.down("xl")]: {
          paddingLeft: "0px",
        },
      },
      navbar_container: {
        flexDirection: "row",
        alignItems: "flex-start",
        paddingTop: pxToRem(4),
        paddingBottom: pxToRem(4),

        [breakpoints.up("md")]: {
          flexDirection: "row",
          alignItems: "center",
          paddingTop: "0",
          paddingBottom: "0",
        },
      },

      navbar_row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        [breakpoints.up("md")]: {
          justifyContent: ({ isMini }) => (isMini ? "space-between" : "stretch"),
          width: ({ isMini }) => (isMini ? "100%" : "max-content"),
        },

        [breakpoints.up("xl")]: {
          justifyContent: "stretch !important",
          width: "max-content !important",
        },
      },
      navbar_section_desktop: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .userName": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textDecoration: "none",
          fontWeight: 600,
          textTransform: "capitalize",
        },
      },
      avatar_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      },

      navbar_icon_button: {
        padding: `0 ${pxToRem(6)}`,

        "& .material-icons, .material-icons-round": {
          fontSize: `${size.regular} !important`,
        },

        "& .MuiTypography-root": {
          display: "none",

          [breakpoints.up("sm")]: {
            display: "inline-block",
            lineHeight: 1.2,
            marginLeft: pxToRem(4),
          },
        },
      },

      navbar_mobile_menu: {
        display: "inline-block",
        padding: "6px 5px 0",

        [breakpoints.up("xl")]: {
          display: "none",
        },
      },

      navbar_input: {
        "& .material-icons, .material-icons-round": {
          color: text.main,
        },
      },
      notificationIconset: {
        "&[aria-expanded='true']": {
          background: "#55DED0",
        },
      },
      notificationMenu: {
        overflow: "visible",
        marginTop: 1.5 * 8,
        padding: 0,
        maxHeight: "100%",
        marginLeft: "24px",
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 33,
          width: 10,
          height: 10,
          backgroundColor: "#fff",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
        "& .MuiButtonBase-root": {
          position: "relative",
          margin: "0",
          padding: "10px 10px",
          display: "flex",
        },
        "& a.MuiButtonBase-root": {
          marginBottom: "0px",
          "&[aria-current='page']": {
            color: dark.main,
            "& svg": {
              fill: dark.main,
            },
            "&::before": {
              content: '""',
              position: "absolute",
              width: "5px",
              height: "100%",
              top: 0,
              left: -8,
              backgroundImage:
                "linear-gradient(180deg, rgba(21,108,218,1) 0%, rgba(66,38,159,1) 100%)",
              borderRadius: 10,
            },
          },
        },
      },
    };
  }
);
