import { makeStyles } from "@mui/styles";
import objImg from "assets/images/bg-obj.png";
export default makeStyles(({ functions, borders, breakpoints }) => {
  const { pxToRem } = functions;
  const { borderRadius } = borders;

  return {
    pageLayout: {
      padding: `${pxToRem(16)} ${pxToRem(28)}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "stretch",
      position: "relative",
      background: `url(${objImg}) no-repeat left top`,
      backgroundSize: "100% 100%",
    },
    coverLayout: {
      margin: 0,
    },
    logo: {
      maxWidth: pxToRem(120),
    },
    coverLayout_formBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    coverLayout_item: {
      width: "100%",
      maxWidth: pxToRem(400),
      borderRadius: pxToRem(10),
      zIndex: 1,
      backgroundColor: "#fff",
      padding: `${pxToRem(28)} ${pxToRem(16)}`,
    },
    imageBox: {
      width: "100%",
      height: "100%",
      [breakpoints.down("md")]: {
        position: "absolute",
        padding: pxToRem(10),
        zIndex: 0,
      },
    },
    coverLayout_imageBox: {
      height: "100%",
      overflow: "hidden",
      borderRadius: borderRadius.xl,
    },

    coverLayout_image: {
      backgroundImage: ({ image }) => `url(${image})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100%",
      [breakpoints.down("md")]: {
        backgroundPosition: "top center",
      },
    },
  };
});
