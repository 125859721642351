import React, { useEffect, useState } from "react";
import { Card, Grid, FormLabel } from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDropDownList from "components/SuiDropDownList";
import { addProductCategory } from "Validation";
import { useUpdateProductCategoryMutation } from "app/features/api/AuthApi";
import { useGetProductCategoryByIdMutation } from "app/features/api/AuthApi";
import { useAddProductCategoryMutation } from "app/features/api/AuthApi";

const AddProductCategory = () => {
  const id = localStorage.getItem("prod_category_id");
  const [error, setError] = useState("");
  const [addProductCategoryData] = useAddProductCategoryMutation();
  const [ProductCatagoryByID] = useGetProductCategoryByIdMutation();
  const suiSnackbar = useSuiSnackbar();
  const [updateProductCategory] = useUpdateProductCategoryMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      prod_category_name: "",
      prod_category_status: "1",
    },
    validationSchema: addProductCategory,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("values:::::", values);
      const body = {
        prod_category_name: values.prod_category_name,
        prod_category_status: values.prod_category_status,
      };
      try {
        if (id) {
          body.id = id;
          console.log(body);
          let response = await updateProductCategory(body).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/product-category");
            setError("");
          }
        } else {
          // Add new category if no ID is present
          let response = await addProductCategoryData(body).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            navigate("/product-category");
            suiSnackbar(response.message, "success");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  const getProductCategoryDetail = async () => {
    try {
      const response = await ProductCatagoryByID(id).unwrap();
      const { prod_category_name, prod_category_status } = response.data;
      formik.setValues({
        prod_category_name,
        prod_category_status,
      });
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getProductCategoryDetail();
    }
  }, [id]);

  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {id ? "Edit Product Category" : "New Product Category"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Category name
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Category Name"
              id="prod_category_name"
              name="prod_category_name"
              value={formik.values.prod_category_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.prod_category_name}
              error={formik.errors.prod_category_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="status" required>
              Status
            </FormLabel>
            <SuiDropDownList
              data={[
                { id: "1", label: "Active" },
                { id: "0", label: "Inactive" },
              ]}
              select={{
                key: "id",
                valueKey: "id",
                labelName: "label",
                label: "Status",
                name: "prod_category_status",
                val: formik.values.prod_category_status,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.prod_category_status}
              error={formik.errors.prod_category_status}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/product-category"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  disabled={!formik.dirty}
                >
                  {id ? "Update" : "Add"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AddProductCategory;
