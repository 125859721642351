import {
  Grid,
  Card,
  TableCell,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  DialogContent,
  // Checkbox,
  DialogTitle,
  Box,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useEffect } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDialog from "components/SuiDialog";
import setImg from "../../assets/images/logout-dialog-image.png";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import DeleteIcon from "Container/Icons/DeleteIcon";
import EditIcon from "Container/Icons/EditIcon";
import SuiDropDownList from "components/SuiDropDownList";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import EyeIcon from "Container/Icons/EyeIcon";
import PriceShow from "Container/Icons/PriceShowIcon";
import { useGetPartnerMutation } from "app/features/api/AuthApi";
import { useProductListInfoMutation } from "app/features/api/AuthApi";
import { useActivePartnerListMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { appendZero } from "helper";
import { format } from "date-fns";
import { usePartnerDataExportMutation } from "app/features/api/AuthApi";
import { useAssignCustomerPartnerMutation } from "app/features/api/AuthApi";
import { formattedDates } from "helper";
import { useAssignPartnerMutation } from "app/features/api/AuthApi";
import { useDeletePartnerMutation } from "app/features/api/AuthApi";
import { partnerAssign } from "Validation";

const PartnerManagement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagePrice, setCurrentPagePrice] = useState(1);
  const [currentPageAssign, setCurrentPageAssign] = useState(1);
  const [roleInfo, setRoleInfo] = useState("");
  const [GetPartner, { isLoading }] = useGetPartnerMutation();
  const navigateSearch = useNavigateSearch();
  const [count, setCount] = useState(0);
  const [countPrice, setCountPrice] = useState(0);
  const [countAssign, setCountAssign] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [perPagePrice, setPerPagePrice] = useState(10);
  const [perPageAssign, setPerPageAssign] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [productList, setProductList] = useState();
  const [productId, setProductId] = useState();
  const [assignPartnerModal, setAssignPartnerModal] = useState(false);
  const [confiemDeleteModal, setConfirmDeleteModal] = useState(false);
  const [AssignCustomerPartner, { isLoading: AssignLoad }] = useAssignCustomerPartnerMutation();
  const [deleteID, setDeleteID] = useState("");
  const [deleteByID, setDeleteByID] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/partner-management", { page: page });
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/partner-management", { page: 1 });
  };
  const handlePageChangePrice = (event, page) => {
    setCurrentPagePrice(page);
    // navigateSearch("/partner-management", { page: page });
  };
  const handlePerPageChangePrice = (event) => {
    setPerPagePrice(event.target.value);
    setCurrentPagePrice(1);
    // navigateSearch("/partner-management", { page: 1 });
  };
  const handlePageChangeAssign = (event, page) => {
    setCurrentPageAssign(page);
  };
  const handlePerPageChangeAssign = (event) => {
    setPerPageAssign(event.target.value);
    setCurrentPageAssign(1);
  };
  const deleteModal = (id) => {
    setDeleteID(id);
    setDeleteByID(id.partner_id);
    if (id?.partner_no_of_cus == 0) {
      setConfirmDeleteModal(true);
    } else {
      setOpenModal(true);
    }
  };
  const ProductInfoCard = (id) => {
    setOpenProduct(true);
    setProductId(id);
    // partner_id
  };
  const openAssignPartnerModal = () => {
    setOpenModal(false);
    setAssignPartnerModal(true);
  };
  const [ActivePartnerList] = useActivePartnerListMutation();
  const [partnerData, setPartnerData] = useState();
  const [partnerCustData, setPartnerCustData] = useState();
  const [searchText, setSearchText] = useState();
  const ActivePartner = async (value) => {
    const params = { partner_name: value?.target.value };
    setSearchText(value?.target.value);
    setTimeout(async () => {
      try {
        const response = await ActivePartnerList(params).unwrap();
        setPartnerData(response.data);
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };
  const ActiveCustomerPartner = async (perpage, page, id) => {
    const param = {
      results_per_page: perpage,
      page: page,
      id,
    };
    try {
      const response = await AssignCustomerPartner(param).unwrap();
      setPartnerCustData(response.data.rows);
      setCountAssign(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const CateData = partnerData?.map((dropval) => ({
    id: dropval.partner_id,
    name: `${dropval.partner_name} (${dropval.partner_account_number})`,
  }));
  useEffect(() => {
    if (searchText) {
      ActivePartner();
    }
  }, [searchText]);
  const partnerTableHead = [
    { id: "1", align: "left", width: "auto", label: "Account Number" },
    { id: "2", align: "left", width: "auto", label: "Company Name" },
    { id: "3", align: "left", width: "140", label: "Created ON" },
    { id: "4", align: "left", width: "auto", label: "Phone Number" },
    { id: "5", align: "left", width: "auto", label: "Email iD" },
  ];
  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "Partner ID" },
    { id: "2", align: "left", width: "150", label: "PARTNER Name" },
    { id: "3", align: "left", width: "140", label: "Mobile" },
    { id: "4", align: "left", width: "auto", label: "Email" },
    { id: "5", align: "left", width: "auto", label: "Rate Scheme" },
    { id: "6", align: "left", width: "auto", label: "Is Whitelabel reseller" },
    { id: "7", align: "left", width: "auto", label: "Number of Customers" },
    { id: "8", align: "left", width: "auto", label: "Status" },
    {
      id: "8",
      align: "right",
      width: "auto",
      label: "Action",
    },
  ];

  // Edit partner
  const editRedirect = (item, type) => {
    localStorage.setItem("partner_id", item);
    {
      type === "view" ? navigateSearch("view-partner") : navigateSearch("edit-partner");
    }
    navigateSearch(``);
  };
  const formik = useFormik({
    initialValues: {
      status: "",
      email: "",
      id: "",
      partner_name: "",
      white: "",
      phone_no: "",
    },

    // enableReinitialize: true,
    onSubmit: () => {
      setCurrentPage(1);
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.status = "";
      formik.values.email = "";
      formik.values.id = "";
      formik.values.partner_name = "";
      formik.values.white = "";
      formik.values.phone_no = "";
      setCurrentPage(1);
      showData(perPage, 1);
    },
  });
  const [AssignPartner] = useAssignPartnerMutation();
  const DeleteDID = useFormik({
    initialValues: {
      partner_id: "",
      partner_id1: "",
    },
    onSubmit: async (values) => {
      const body = {
        partner_id: values.partner_id.id,
        deleteByID,
      };
      try {
        const response = await AssignPartner(body).unwrap();
        console.log(response.data);
        suiSnackbar(response.message, "success");
        setConfirmDeleteModal(false);
        setAssignPartnerModal(false);
        showData(perPage, 1);
      } catch (error) {
        suiSnackbar(error.data.message, "error");
        setConfirmDeleteModal(false);
        setAssignPartnerModal(false);
        console.log(error);
      }
    },
    onReset: () => {
      DeleteDID.values.partner_id = "";
      DeleteDID.values.partner_id1 = "";
    },
  });

  const AssignDID = useFormik({
    initialValues: {
      partner_id: "",
      partner_id1: "",
    },
    validationSchema: partnerAssign,
    onSubmit: async () => {
      setConfirmDeleteModal(true);
      setAssignPartnerModal(false);
      AssignDID.resetForm();
    },
    onReset: () => {
      formik.values.partner_id = "";
      formik.values.partner_id1 = "";
    },
  });

  let showData = async (perPage, currentPage) => {
    navigateSearch("/partner-management", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.id) {
      urlParamss.partner_account_number = formik.values.id;
    }
    if (formik.values.partner_name) {
      urlParamss.partner_name = formik.values.partner_name;
    }
    if (formik.values.email) {
      urlParamss.partner_email = formik.values.email?.trim();
    }

    if (formik.values.phone_no) {
      urlParamss.partner_phone_number = formik.values.phone_no;
    }
    if (formik.values.white) {
      urlParamss.partner_is_whitelable_reseller = formik.values.white;
    }
    if (formik.values.status) {
      urlParamss.partner_status = formik.values.status;
    }

    try {
      const response = await GetPartner(urlParamss).unwrap();
      setRoleInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setRoleInfo(null);
      setCount(null);
    }
  };
  const [ProductListInfo, { isLoading: isPricing }] = useProductListInfoMutation();
  const [DeletePartner] = useDeletePartnerMutation();
  const DeletePartnerInfo = async () => {
    try {
      const response = await DeletePartner(deleteID?.partner_id).unwrap();
      suiSnackbar(response.message, "success");
      setConfirmDeleteModal(false);
      showData(perPage, 1);
    } catch (error) {
      suiSnackbar(error.data.message, "error");
      setConfirmDeleteModal(false);
    }
  };
  const headerData = [
    { id: "1", width: "auto", align: "left", label: "Product Name" },
    { id: "2", width: "auto", align: "left", label: "Product Category" },
    { id: "3", width: "auto", align: "left", label: "Default Price" },
    { id: "4", width: "auto", align: "left", label: "Partner's Price" },
  ];

  const GetProductListData = async (perPagePrice, currentPagePrice, id) => {
    const params = {
      is_pagination: true,
      results_per_page: perPagePrice,
      page: currentPagePrice,
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      setProductList(response.data.rows);
      setCountPrice(response.data.count);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  useEffect(() => {
    if (productId?.partner_id) {
      GetProductListData(perPagePrice, currentPagePrice, productId?.partner_id);
    }
  }, [perPagePrice, currentPagePrice, productId?.partner_id]);
  useEffect(() => {
    if (deleteByID) {
      ActiveCustomerPartner(perPageAssign, currentPageAssign, deleteByID);
    }
  }, [perPageAssign, assignPartnerModal, currentPageAssign, productId?.partner_id]);
  useEffect(() => {
    if (assignPartnerModal) {
      ActiveCustomerPartner(perPageAssign, currentPageAssign, deleteByID);
      ActivePartner();
    }
  }, [assignPartnerModal]);

  useEffect(() => {
    showData(perPage, currentPage);

    localStorage.removeItem("partner_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);
  const partnerRows = partnerCustData?.map((item) => (
    <>
      <TableRow>
        <TableCell>{item.cus_account_number}</TableCell>
        <TableCell>{item.cus_company_name}</TableCell>
        <TableCell>{formattedDates(item.createdAt)}</TableCell>
        <TableCell>{item.cus_phone_number}</TableCell>

        <TableCell>{item.cus_email}</TableCell>
      </TableRow>
    </>
  ));
  const rows =
    roleInfo &&
    roleInfo?.map((item) => {
      return (
        <>
          <TableRow key={item.id}>
            <TableCell>{item?.partner_account_number}</TableCell>
            <TableCell>
              <div className="w-180" title={item?.partner_name}>
                {item?.partner_name}
              </div>
            </TableCell>
            <TableCell>{item?.partner_phone_number}</TableCell>
            <TableCell>
              <div className="w-180" title={item?.partner_email}>
                {item?.partner_email}
              </div>
            </TableCell>
            <TableCell>
              {item?.rate_schemes === null ? "-" : item?.rate_schemes?.rate_scheme_name}
            </TableCell>
            <TableCell>{item?.partner_is_whitelable_reseller == 0 ? "No" : "Yes"}</TableCell>
            <TableCell>{item?.partner_no_of_cus}</TableCell>
            <TableCell>
              {item.partner_status == "1" ? (
                <SuiTypography fontSize={14} sx={{ color: "#55DED0 !important" }}>
                  Active
                </SuiTypography>
              ) : item.partner_status === "2" ? (
                <SuiTypography fontSize={14} textColor="dark">
                  Restricted
                </SuiTypography>
              ) : item.partner_status === "3" ? (
                <SuiTypography fontSize={14} textColor="error">
                  Blocked
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} textColor="text2">
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            <TableCell sx={{ textAlign: "-webkit-right" }} align="right">
              <Box sx={{ width: "115px" }}>
                {item.partner_is_whitelable_reseller == 1 && (
                  <IconButton onClick={() => ProductInfoCard(item)}>
                    <PriceShow />
                  </IconButton>
                )}
                <IconButton
                  className="viewIcon"
                  onClick={() => editRedirect(item.partner_id, "view")}
                >
                  {<EyeIcon />}
                </IconButton>
                {permissionInfo.partner.UPDATE && !permissionInfo.partner.DELETE && (
                  <IconButton onClick={() => editRedirect(item.partner_id)}>
                    {<EditIcon />}
                  </IconButton>
                )}
                {!permissionInfo.partner.UPDATE && permissionInfo.partner.DELETE && (
                  <IconButton sx={{ display: "none" }} onClick={() => deleteModal(item)}>
                    {<DeleteIcon />}
                  </IconButton>
                )}
                {permissionInfo.partner.UPDATE &&
                  permissionInfo.partner.DELETE &&
                  item.prod_category_name !== "Super Admin" && (
                    <>
                      <IconButton onClick={() => editRedirect(item.partner_id)}>
                        {<EditIcon />}
                      </IconButton>
                      <IconButton sx={{ display: "none" }} onClick={() => deleteModal(item)}>
                        {<DeleteIcon />}
                      </IconButton>
                    </>
                  )}
              </Box>
            </TableCell>
          </TableRow>
        </>
      );
    });
  const [downloadData, { isLoading: isExport }] = usePartnerDataExportMutation();
  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.id) {
      urlParamss.partner_account_number = formik.values.id;
    }
    if (formik.values.partner_name) {
      urlParamss.partner_name = formik.values.partner_name;
    }
    if (formik.values.email) {
      urlParamss.partner_email = formik.values.email?.trim();
    }

    if (formik.values.phone_no) {
      urlParamss.partner_phone_number = formik.values.phone_no;
    }
    if (formik.values.white) {
      urlParamss.partner_is_whitelable_reseller = formik.values.white;
    }
    if (formik.values.status) {
      urlParamss.partner_status = formik.values.status;
    }
    try {
      const response =
        Object.keys(urlParamss)?.length > 0
          ? await downloadData(urlParamss).unwrap()
          : await downloadData().unwrap();
      if (response.status === 200) {
        return response?.data;
      } else {
        return null;
      }
    } catch (error) {
      suiSnackbar(error?.data?.message, "error");
      return null;
    }
  };
  const onDownloadFn = async () => {
    const response = await downloadDetails();
    if (!response) {
      return;
    }
    const downloadData = response?.map((item, i) => {
      return {
        "Sr.no": appendZero(i + 1),
        "PARTNER ID": item?.partner_account_number,
        "PARTNER NAME": item?.partner_name,
        MOBILE: item?.partner_phone_number,
        EMAIL: item?.partner_email,
        "RATE SCHEME": item?.rate_schemes === null ? "-" : item?.rate_schemes?.rate_scheme_name,
        "IS WHITELABEL RESELLER": item?.partner_is_whitelable_reseller === "1" ? "Yes" : "No",
        "NUMBER OF CUSTOMER": item?.partner_no_of_cus,
        STATUS:
          item?.partner_status === "1"
            ? "Active"
            : item?.partner_status === "2"
            ? "Restricted"
            : item?.partner_status === "3"
            ? "Blocked"
            : "Inactive",
      };
    });

    const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
    const csvFormat = [
      csvHeaders && csvHeaders.join(","),
      ...(downloadData || []).map(
        (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvFormat], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Partner List(${format(new Date(), "dd/MM/yyyy | hh:mm a")}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const rowss = productList?.map((item) => (
    <TableRow key={1}>
      <TableCell>{item?.prod_master_name}</TableCell>
      <TableCell>{item?.product_categories?.prod_category_name}</TableCell>
      <TableCell>£{item?.prod_master_price}</TableCell>
      <TableCell>£{item?.partner_pricing?.[0]?.partner_price ?? 0}</TableCell>
    </TableRow>
  ));
  // console.log(deleteID?.partner_no_of_cus, "deleteID?.partner_no_of_cus");
  return (
    <>
      <SuiDialog
        maxWidth="xs"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setDeleteID("");
        }}
        haveCloseIcon
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Delete Partner</DialogTitle>
        <DialogContent
          dividers
          sx={{
            fontWeight: "500",
          }}
        >
          There are {deleteID?.partner_no_of_cus} Customers assigned under this partner. To
          effectively delete the partner profile, please reassign them.
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"start"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={openAssignPartnerModal}
                  >
                    Continue
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setOpenModal(false);
                      setDeleteID("");
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      <SuiDialog
        maxWidth="lg"
        open={openProduct}
        onClose={() => {
          setOpenProduct(false);
          setDeleteID("");
          setCurrentPagePrice(1);
          setPerPagePrice(10);
        }}
        haveCloseIcon
      >
        <div
          style={{
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <DialogTitle sx={{ fontWeight: 600, p: 0 }}>Product Pricing of Partner</DialogTitle>
          {productId?.partner_is_whitelable_reseller == "1" && permissionInfo.partner.UPDATE && (
            <SuiButton
              size="small"
              sx={{ px: "1rem" }}
              buttonColor="info"
              onClick={() => editRedirect(productId?.partner_id)}
            >
              {"Edit Partner's Price"}
            </SuiButton>
          )}
        </div>
        <DialogContent
          dividers
          sx={{
            fontWeight: "500",
          }}
        >
          <Grid
            className="didTableSet"
            sx={{
              border: "1px solid #ddd",
              borderRadius: "10px",
            }}
          >
            <EnhancedTable
              tableMinWidth={750}
              tablehead={headerData}
              isLoading={isPricing}
              rowsData={rowss}
            />
            {!isLoading && countPrice > 0 && (
              <Grid
                container
                py={3}
                spacing={1.5}
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "center", md: "flex-end" }}
                alignItems="center"
              >
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <span>Show</span>
                    </Grid>
                    <Grid item>
                      <Select
                        value={perPagePrice}
                        onChange={handlePerPageChangePrice}
                        variant="standard"
                        sx={{ minWidth: 100 }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item>
                      <span>out of {countPrice} Results</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <CommonPagination
                    mt={0}
                    totalData={countPrice}
                    pageSize={perPagePrice}
                    currentPage={Number(currentPagePrice)}
                    onPageChange={handlePageChangePrice}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </SuiDialog>
      {/* assign partner modal :: BEGIN */}
      <SuiDialog
        open={assignPartnerModal}
        onClose={() => {
          setAssignPartnerModal(false);
          setDeleteID("");
          setPerPageAssign(10);
          setCurrentPageAssign(1);
          AssignDID.setFieldValue("partner_id", "");
          AssignDID.setFieldValue("partner_id1", "");
        }}
        haveCloseIcon
        maxWidth="lg"
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Assign Partner</DialogTitle>
        <DialogContent
          dividers
          sx={{
            fontWeight: "500",
          }}
        >
          <Grid container component="form" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="partner_name" required>
                Partner Name
              </FormLabel>
              <AutocompleteSingleSelect
                id="partnerName"
                value={AssignDID.values.partner_id}
                setValue={(value) => {
                  AssignDID.setFieldValue("partner_id", value);
                  AssignDID.setFieldValue("partner_id1", value.name);
                  DeleteDID.setFieldValue("partner_id", value);
                  DeleteDID.setFieldValue("partner_id1", value.name);
                }}
                noOptionsText="No options"
                placeholder="Select"
                onInputChange={ActivePartner}
                isLoading={false}
                disabled={false}
                options={CateData}
                formik={formik}
              />
              {AssignDID.errors.partner_id1 && AssignDID.touched.partner_id && (
                <div style={{ color: "#fc3c3c", fontSize: "0.9em", fontWeight: "400" }}>
                  {AssignDID.errors.partner_id1}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12}>
              <SuiTypography
                fontWeight="normal"
                fontSize="12px"
                my={2}
                sx={{ color: "#848484 !important" }}
              >
                List of Customer assigned to this partner
              </SuiTypography>
            </Grid>
            <Grid
              item
              xs={12}
              className="didTableSet"
              sx={{
                border: "1px solid #ddd",
                borderRadius: "10px",
              }}
            >
              <EnhancedTable
                tableMinWidth={540}
                tablehead={partnerTableHead}
                rowsData={partnerRows}
                isLoading={AssignLoad}
              />
            </Grid>
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPageAssign}
                      onChange={handlePerPageChangeAssign}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {countAssign} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={countAssign}
                  pageSize={perPageAssign}
                  currentPage={Number(currentPageAssign)}
                  onPageChange={handlePageChangeAssign}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"start"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setAssignPartnerModal(false);
                      setDeleteID("");
                      AssignDID.handleReset();
                      setCurrentPageAssign(1);
                      setPerPageAssign(10);
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={() => {
                      AssignDID.submitForm();
                    }}
                  >
                    Next
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      {/* assign partner modal :: END */}
      {/* final delete confirmation modal :: BEGIN */}
      <SuiDialog
        open={confiemDeleteModal}
        onClose={() => {
          setConfirmDeleteModal(false);
          setDeleteID("");
        }}
        haveCloseIcon
      >
        <DialogContent
          sx={{
            fontWeight: "semiBold",
            textAlign: "center",
          }}
        >
          <Box mb={4} textAlign="center">
            <img src={setImg} alt="Image" width="100" />
          </Box>
          {deleteID?.partner_no_of_cus > 0 ? (
            <>
              <SuiTypography fontWeight="bold" textColor="text">
                Are you sure you want to assign the customer(s)
                <br />
                to the selected partner and delete the old partner?
              </SuiTypography>
              <SuiTypography
                fontWeight="regular"
                mt={1}
                mb={2}
                sx={{ fontSize: "14px", color: "#848484 !important" }}
              >
                Assigning a new partner may affect the current pricing and <br /> branding details
                of the customer(s).
              </SuiTypography>
            </>
          ) : (
            <SuiTypography fontWeight="bold" textColor="text">
              Are you sure you want to delete <br /> the Partner?
            </SuiTypography>
          )}
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setConfirmDeleteModal(false);
                      setDeleteID("");
                      setCurrentPageAssign(1);
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={() => {
                      deleteID?.partner_no_of_cus == 0
                        ? DeletePartnerInfo()
                        : DeleteDID.submitForm();
                    }}
                  >
                    Proceed to Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      {/* final delete confirmation modal :: END */}
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Partner Id"
                  id="id"
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Partner Name"
                  id="partner_name"
                  name="partner_name"
                  value={formik.values.partner_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Email Id"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Phone number"
                  id="phone_no"
                  name="phone_no"
                  value={formik.values.phone_no}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Yes" },
                    { id: "0", label: "No" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Is Whitelabel reseller",
                    name: "white",
                    val: formik.values.white,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "0", label: "Inactive" },
                    { id: "1", label: "Active" },
                    { id: "2", label: "Restricted" },
                    { id: "3", label: "Blocked" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "status",
                    val: formik.values.status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                    formik.values.prod_category_name = "";
                    formik.values.prod_category_status = "";
                    setPerPage(10);
                    navigateSearch("/partner-management", { page: 1 });
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>

      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Partners">
            <SuiButton
              size="small"
              buttonColor="accous"
              sx={{
                fontWeight: "500",
                px: "12px",
                ml: 2,
                color: "#848484 !important",
              }}
              onClick={onDownloadFn}
              disabled={isExport}
            >
              {isExport ? <CircularProgress size={20} color="info" /> : "Export"}
            </SuiButton>
            {permissionInfo?.partner?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"create-partner"}
              >
                Add Partner
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={pagesTableHeader}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default PartnerManagement;
