import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import { Select, MenuItem, TextField, InputAdornment } from "@mui/material";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SuiBox from "components/SuiBox";
import { countryCodes } from "helper";

const useStyles = makeStyles(() => ({
  searchBar: {
    position: "sticky",
    paddingTop: "8px",
    paddingBottom: "8px",
    top: "-8px",
    left: 0,
    background: "#fff",
    zIndex: "999",
  },
}));
const SuiCountryCodeInput = ({ codeValue, countryCode, customClass, type, disabled }) => {
  const classes = useStyles();
  const [countries, setCountries] = React.useState(countryCodes);
  const [searchContryCode, setSearchContryCode] = React.useState("");
  return React.useMemo(
    () => (
      <Select
        id={type === "businessnumber" ? "cus_business_country_code" : "cus_phone_country_code"}
        disabled={disabled}
        name={type === "businessnumber" ? "cus_business_country_code" : "cus_phone_country_code"}
        fullWidth
        value={codeValue}
        SelectDisplayProps={{ className: customClass }}
        renderValue={() => codeValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        inputProps={{ required: true }}
        onChange={countryCode}
        sx={{
          "&> .MuiSelect-select": {
            color: codeValue !== "" ? "inherit" : "#AAAEB1 !important",
            border: "none !important",
            borderRadius: "0 !important",
          },
        }}
      >
        <SuiBox
          component="li"
          customClass={classes.searchBar}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <TextField
            sx={{
              bgcolor: "#F4F5F5",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": { bgcolor: "transparent !important" },
            }}
            value={searchContryCode}
            onChange={(e) => {
              setSearchContryCode(e.target.value);
              const list = countryCodes.filter((contry) => {
                if (e.target.value.startsWith("+")) {
                  return contry.countryCallingCode
                    .toLocaleLowerCase()
                    .startsWith(e.target.value.toLocaleLowerCase());
                }
                return contry.countryName
                  .toLocaleLowerCase()
                  .startsWith(e.target.value.toLocaleLowerCase());
              });
              setCountries([...list]);
            }}
            size="small"
            fullWidth
            name="searchLanguage"
            id="search-language"
            type="search"
            placeholder="Search"
            variant="outlined"
            autoComplete="off"
            InputProps={{
              inputProps: {
                sx: { border: "none !important", borderRadius: 0 },
              },
              startAdornment: (
                <InputAdornment position="start" sx={{ ml: 1 }}>
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
          />
        </SuiBox>
        {countries.length > 0 ? (
          countries.map((country) => {
            const countryWithCode = `${country.countryName} ${country.countryCallingCode}`;
            return (
              <MenuItem key={country.countryShortCode} value={countryWithCode}>
                {countryWithCode}
              </MenuItem>
            );
          })
        ) : (
          <SuiBox component="li" sx={{ textAlign: "center", py: 3 }}>
            No Country Found.
          </SuiBox>
        )}
        <MenuItem key={codeValue} sx={{ display: "none" }} value={codeValue}>
          {codeValue}
        </MenuItem>
      </Select>
    ),
    [codeValue, countries, searchContryCode]
  );
};

SuiCountryCodeInput.propTypes = {
  codeValue: PropTypes.string,
  countryCode: PropTypes.func,
  customClass: PropTypes.any,
};

export default SuiCountryCodeInput;
