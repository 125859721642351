import { Box, Card, Grid, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";

import PropTypes, { object } from "prop-types";
import SuiBox from "components/SuiBox";
import styles from "./styles";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SuiTypography from "components/SuiTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CollapseFormLayout = ({ routes, children }) => {
  const classes = styles();
  const loc = useLocation();
  const collapseName = `/${loc.pathname.slice(1)}`;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const checkVal = loc.pathname.includes("view");
  const navigate = useNavigate();
  const { accnt_num } = useParams();
  const handleBackClick = () => {
    navigate("/customer");
  };

  return (
    <Card className={classes.card_Box}>
      {checkVal && (
        <Box
          mb={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <SuiTypography sx={{ fontSize: "18px", color: "#2B2B2B", fontWeight: "600" }}>
            <ArrowBackIcon
              onClick={handleBackClick}
              sx={{
                color: "#55DED0",
                fontSize: "20px",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
            />
            View Details of {accnt_num}
          </SuiTypography>
        </Box>
      )}

      <Grid container>
        <Grid item xs={12} md={4} lg={3.5}>
          <Card className={classes.side_card_Box}>
            <Tabs
              orientation={mobileView ? "horizontal" : "vertical"}
              variant="scrollable"
              scrollButtons={mobileView}
              value={collapseName}
            >
              {routes.map(({ to, name }) => {
                return <Tab component={Link} key={to} label={name} to={to} value={to} />;
              })}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} lg={8.5}>
          <SuiBox className={classes.form_box}>{children}</SuiBox>
        </Grid>
      </Grid>
    </Card>
  );
};

CollapseFormLayout.propTypes = {
  routs: PropTypes.arrayOf(object),
  children: PropTypes.any,
};

export default CollapseFormLayout;
