import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setLocalStorageData } from "hooks/useLocalStorage";
import { getLocalStorageData } from "hooks/useLocalStorage";
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    const token = await getLocalStorageData("auth_token_admin");
    headers.set("Public-Key", "1d5ee304-5de4-4490-8cfa-4b23a2bb5058");
    headers.set("x-appversion", "web");
    headers.set("x-platform", "web");
    if (token) {
      headers.set("authorization", `${token}`);
    }
    return headers;
  },
});
let lastActivityTime = localStorage.getItem("lastActivityTime_admin");
const trackUserActivity = () => {
  lastActivityTime = new Date().getTime();
  localStorage.setItem("lastActivityTime_admin", lastActivityTime); // Save the last activity time
};
document.addEventListener("mousemove", trackUserActivity);
document.addEventListener("click", trackUserActivity);
document.addEventListener("keydown", trackUserActivity);
window.addEventListener("DOMContentLoaded", trackUserActivity);
let refreshing = false;
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const remember = JSON.parse(localStorage.getItem("isRemember_admin"));
  const token = localStorage.getItem("auth_token_admin") || "";
  if (token) {
    if (result?.error?.status === 403 || (!remember && result?.error?.status === 401)) {
      localStorage.clear();
      redirectToLogin();
      return;
    }
  }
  if (result?.error?.status === 401 || result?.error?.status === 403) {
    localStorage.clear();
    redirectToLogin();
    return;
  }

  if (remember) {
    const loggedInTime = new Date(localStorage.getItem("loggedInTime_admin")).getTime();
    const tokenExpireat = localStorage.getItem("tokenExpiryTime_admin") * 1000;
    const refreshBefore = 15 * 60 * 1000;
    // const refreshBefore = 15 * 1000;
    const shouldRefreshToken = loggedInTime
      ? loggedInTime + (tokenExpireat - refreshBefore) < new Date().getTime()
      : false;
    if (shouldRefreshToken && !refreshing) {
      refreshing = true;
      const refreshResult = await baseQuery(
        {
          url: "admin/refreshtoken",
          method: "POST",
          body: {
            refresh_token: localStorage.getItem("refresh_token_admin"),
          },
        },
        api,
        extraOptions
      );
      if (refreshResult?.data.status === 200) {
        await setLocalStorageData("auth_token_admin", refreshResult?.data.data.token);
        await setLocalStorageData(
          "tokenExpiryTime_admin",
          refreshResult?.data.data.token_expire_at
        );
        await setLocalStorageData("loggedInTime_admin", new Date());
        result = await baseQuery(args, api, extraOptions);
      }
      refreshing = false;
    }
  } else {
    const tokenExpireat = localStorage.getItem("tokenExpiryTime_admin") * 1000;
    const currentTime = new Date().getTime();
    const activityTimeout = 15 * 60 * 1000;
    const lastActivity = lastActivityTime ? parseInt(lastActivityTime) : null;
    const loggedInTime = new Date(localStorage.getItem("loggedInTime_admin")).getTime();
    const hasActivity = lastActivity && currentTime - lastActivity < activityTimeout;
    const shouldRefreshToken = loggedInTime
      ? loggedInTime + (tokenExpireat - activityTimeout) < currentTime
      : false;
    const isTokenExpired = tokenExpireat < currentTime;
    if (!hasActivity && !isTokenExpired) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      if (shouldRefreshToken && !refreshing) {
        refreshing = true;
        const refreshResult = await baseQuery(
          {
            url: "admin/refreshtoken",
            method: "POST",
            body: {
              refresh_token: localStorage.getItem("refresh_token_admin"),
            },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data.status === 200) {
          await setLocalStorageData("auth_token_admin", refreshResult?.data.data.token);
          await setLocalStorageData(
            "tokenExpiryTime_admin",
            refreshResult?.data.data.token_expire_at
          );
          await setLocalStorageData("loggedInTime_admin", new Date());
          result = await baseQuery(args, api, extraOptions);
        }
        refreshing = false;
      }
    }
  }
  return result;
};
function redirectToLogin() {
  window.location.href = process.env.REACT_APP_BASE_FRONT_URL;
}

export const baseAPIService = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["COMMON_GET"],
  endpoints: () => ({}),
});
