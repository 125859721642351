import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { accountStatus } from "./dropdownArrays";

const AccountStatusDropdown = ({ name, value, setValue, disabled }) => {
  return (
    <SuiDropDownList
      disabled={disabled}
      data={accountStatus}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Select Account Status",
        name: name,
        val: value,
        fn: setValue,
      }}
    />
  );
};

AccountStatusDropdown.defaultProps = {
  name: "account_status",
};

AccountStatusDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default AccountStatusDropdown;
