import { Card, Grid, FormLabel, FormControlLabel, Switch, Box } from "@mui/material";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
// import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SuiPhoneNo from "components/SuiPhoneNo";
import { getCountryCode } from "helper";
import { getCountryShortCode } from "helper";
import { isValidPhoneNumber } from "react-phone-number-input";
import AutoCompleteSingleAddress from "components/SuiAutocomplete/AutoCompleteSingleAddress";
import AutoCompleteSingleCountry from "components/SuiAutocomplete/AutocompletecountrySelect";
import SuiTypography from "components/SuiTypography";
import AccountTypeDropdown from "components/SuiDropDownList/AccountTypeDropdown";
import SuiPasswordInput from "components/SuiPasswordInput";
import AccountStatusDropdown from "components/SuiDropDownList/AccountStatusDropdown";
import { useCreateCustomerMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { createCustomerSchema } from "Validation";
import DayOfPlan from "components/SuiAutocomplete/autoCompleteDayofplan";
import DayOfPlanSubCatory from "components/SuiAutocomplete/autoCompleteSubCatory";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useActivePartnerListMutation } from "app/features/api/AuthApi";
import { debounce } from "lodash";
import { useGetActiveRateSchemeMutation } from "app/features/api/AuthApi";
import { useGetActiveProviderMutation } from "app/features/api/AuthApi";
import { useGetGlobalProviderMutation } from "app/features/api/AuthApi";

const CreateCustomer = () => {
  const [mobileInValid, setMobileInValid] = useState(false);
  const [businessMobileInValid, setBusinessMobileInValid] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const suiSnackbar = useSuiSnackbar();
  const [createCustomer] = useCreateCustomerMutation();
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [flag, setFlag] = useState(false);

  const emailPattern = /(?=^.{10,254}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i;

  const initialValues = {
    cus_first_name: "",
    partner_id: "",
    partner_id1: "",
    rateScheme_id: "",
    rateScheme_id1: "",
    cus_last_name: "",
    cus_email: "",
    cus_phone_country_code: "+44",
    cus_phone_number: "",
    cus_business_country_code: "+44",
    cus_business_number: "",
    cus_day_of_time: "",
    cus_billing_email: "",
    cus_company_name: "",
    cus_country: "",
    cus_city: "",
    cus_postal_code: "",
    username: "",
    password: "",
    cus_account_type: "",
    cus_account_status: "",
    cus_network_status: "0",
    cus_2fa_auth: "",
    cus_alter_email: "",
    cus_address_line_1: "",
    cus_address_line_2: "",
    cus_address_line_3: "",
    cus_address_line_4: "",
    cus_time_of_day_sub_category: "",
    provider_id: "",
    provider_id1: "",
  };
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!emailPattern.test(values.cus_alter_email) && values.cus_2fa_auth == true) {
        errors.cus_alter_email = "Invalid Alternate Email ID.";
      }
      if (values.cus_alter_email == null && values.cus_2fa_auth == true) {
        errors.cus_alter_email = "Alternate Email ID cannot be blank.";
      }
      if (values.cus_alter_email == "" && values.cus_2fa_auth == true) {
        errors.cus_alter_email = "Alternate Email ID cannot be blank.";
      }

      return errors;
    },
    validationSchema: createCustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        cus_firstname: capitalizeFirstLetter(values.cus_first_name.trim()),
        cus_lastname: capitalizeFirstLetter(values.cus_last_name.trim()),
        cus_email: values.cus_email,
        cus_phone_country_code: getCountryCode(values.cus_phone_country_code),
        cus_business_country_code: getCountryCode(values.cus_business_country_code),
        cus_billing_email: values.cus_billing_email,
        cus_time_of_day_category: Number(values.cus_day_of_time),
        cus_time_of_day_sub_category: Number(values.cus_time_of_day_sub_category),
        cus_company_name: values.cus_company_name,
        cus_address_line_1: values.cus_address_line_1,
        cus_address_line_2: values.cus_address_line_2,
        cus_address_line_3: values.cus_address_line_3,
        cus_address_line_4: values.cus_address_line_4,
        cus_postal_code: values.cus_postal_code,
        cus_password: values.password,
        cus_phone_number: values.cus_phone_number,
        cus_business_number: values.cus_business_number,
        cus_country: values.cus_country,
        partner_id: values.partner_id.id,
        cus_account_type: values.cus_account_type == "post_paid" ? "0" : "1",
        rate_scheme_id: values.rateScheme_id.id,
        provider_id: values.provider_id.id,
        cus_status:
          values?.cus_account_status == "inactive"
            ? "0"
            : values?.cus_account_status == "active"
            ? "1"
            : values?.cus_account_status == "blocked"
            ? "3"
            : values?.cus_account_status == "restricted"
            ? "2"
            : "unknown",
        cus_network_status: values.cus_network_status,
        cus_2fa_auth: values.cus_2fa_auth ? "1" : "0",
        cus_city: values.cus_city,
      };

      if (formik.values.cus_2fa_auth) {
        body.cus_alter_email = values.cus_alter_email == null ? "" : values.cus_alter_email;
      }

      if (!mobileInValid && !businessMobileInValid) {
        try {
          const response = await createCustomer(body).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            suiSnackbar(response.message, "success");
            navigate("/customer");
            setError("");
          }
        } catch (error) {
          if (error?.data?.errorMessages?.issues) {
            console.log(error);
            const fieldErrors = error.data.errorMessages.issues;
            console.log(fieldErrors);
            if (fieldErrors) {
              fieldErrors.map((item) => {
                suiSnackbar(item.message, "error");
              });
            }
          } else {
            suiSnackbar(error?.data?.errorMessages || "An error occurred", "error");
          }
        }
      }
      formik.setSubmitting(false);
    },
  });
  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
    if (formik.values.cus_network_status == 1) {
      formik.setFieldValue("cus_network_status", "0");
    } else if (formik.values.cus_network_status == 0) {
      formik.setFieldValue("cus_network_status", "1");
    }
  };
  const handlePostPaidSelection = () => {};
  useEffect(() => {
    formik.resetForm();
    ActivePartner();
    ActiveRateScheme();
    ActiveProvider();
  }, []);
  useEffect(() => {
    if (String(getCountryCode(formik.values.cus_phone_country_code)) == "+44") {
      if (
        formik.values.cus_phone_number?.length < 10 ||
        formik.values.cus_phone_number?.length > 11
      ) {
        setMobileInValid("Please enter valid mobile number.");
      } else {
        setMobileInValid(false);
      }
    } else {
      if (
        !isValidPhoneNumber(
          formik.values.cus_phone_number,
          getCountryShortCode(getCountryCode(formik.values.cus_phone_country_code))
        )
      ) {
        setMobileInValid("Please enter valid mobile number.");
      } else {
        setMobileInValid(false);
      }
    }
    if (String(getCountryCode(formik.values.cus_business_country_code)) == "+44") {
      if (
        formik.values.cus_business_number?.length < 10 ||
        formik.values.cus_business_number?.length > 11
      ) {
        setBusinessMobileInValid("Please enter valid business number.");
      } else {
        setBusinessMobileInValid(false);
      }
    } else {
      if (
        !isValidPhoneNumber(
          formik.values.cus_business_number,
          getCountryShortCode(getCountryCode(formik.values.cus_business_country_code))
        )
      ) {
        setBusinessMobileInValid("Please enter valid mobile number.");
      } else {
        setBusinessMobileInValid(false);
      }
    }
  }, [
    getCountryCode(formik.values.cus_phone_country_code),
    getCountryCode(formik.values.cus_business_country_code),
  ]);
  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const [ActivePartnerList] = useActivePartnerListMutation();
  const [partnerData, setPartnerData] = useState();
  const [searchText, setSearchText] = useState();
  const ActivePartner = useCallback(
    debounce(async () => {
      try {
        const response = await ActivePartnerList().unwrap();
        setPartnerData(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 1000),
    []
  );
  const ActiveSearchPartner = useCallback(
    debounce(async (value) => {
      const params = value ? { partner_name: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");
      try {
        const response = await ActivePartnerList(params).unwrap();
        setPartnerData(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 1000),
    []
  );
  const CateData = partnerData?.map((dropval) => ({
    id: dropval.partner_id,
    name: dropval.partner_name,
  }));
  useEffect(() => {
    if (searchText !== "") {
      console.log(searchText);
      ActiveSearchPartner();
    }
    if (formik.values.partner_id) {
      formik.setFieldValue("cus_account_type", "post_paid");
    } else {
      formik.setFieldValue("cus_account_type", "");
    }
  }, [formik.values.partner_id, searchText]);

  const [ActiveRateSchemeData] = useGetActiveRateSchemeMutation();
  const [rateSchemeDataList, setRateSchemeData] = useState();
  const [searchRateSchemeText, setRateschemeSearchText] = useState();
  const ActiveRateScheme = async () => {
    try {
      const body = {
        rate_scheme_type: "customer",
      };
      const response = await ActiveRateSchemeData(body).unwrap();
      const defaultScheme = response.data.find(
        (scheme) => scheme.rate_scheme_is_default_cus === "1"
      );
      if (defaultScheme) {
        const transformedRateScheme = {
          id: defaultScheme.rate_scheme_id,
          name: defaultScheme.rate_scheme_name,
        };
        formik.setFieldValue("rateScheme_id", transformedRateScheme);
        formik.setFieldValue("rateScheme_id1", transformedRateScheme.name);
      } else {
        formik.setFieldValue("rateScheme_id", null);
        formik.setFieldValue("rateScheme_id1", "");
      }
      setRateSchemeData(response.data);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };
  const ActiveRateSchemeFind = useCallback(
    debounce(async (value) => {
      const params = value
        ? { partner_name: value.target.value, rate_scheme_type: "customer" }
        : { rate_scheme_type: "customer" };
      setRateschemeSearchText(value?.target?.value || "");
      try {
        const response = await ActiveRateSchemeData(params).unwrap();
        setRateSchemeData(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 1000),
    []
  );
  const rateSchemeData = rateSchemeDataList?.map((dropval) => ({
    id: dropval.rate_scheme_id,
    name: dropval.rate_scheme_name,
  }));
  useEffect(() => {
    if (searchRateSchemeText !== "") {
      ActiveRateSchemeFind();
    }
    if (formik.values.partner_id) {
      formik.setFieldValue("cus_account_type", "post_paid");
    } else {
      formik.setFieldValue("cus_account_type", "");
    }
  }, [formik.values.partner_id, searchRateSchemeText]);
  const [ActiveProviderList] = useGetActiveProviderMutation();
  const [providerData, setProviderData] = useState();
  const [searchProviderText, setSearchProviderText] = useState();
  const ActiveProvider = async () => {
    try {
      const response = await ActiveProviderList().unwrap();
      setProviderData(response.data);
    } catch (error) {
      console.error("Error fetching providers details:", error);
    }
  };
  const ActiveSearchProvider = useCallback(
    debounce(async (value) => {
      const params = value ? { partner_name: value?.target?.value } : {};
      setSearchProviderText(value?.target?.value || "");
      try {
        const response = await ActiveProviderList(params).unwrap();
        setProviderData(response.data);
      } catch (error) {
        console.error("Error fetching providers details:", error);
      }
    }, 1000),
    []
  );
  const ProviderData = providerData?.map((dropval) => ({
    id: dropval.provider_id,
    name: dropval.provider_name,
  }));
  useEffect(() => {
    getProviderID();
  }, []);
  useEffect(() => {
    if (searchProviderText !== "") {
      ActiveSearchProvider();
    }
  }, [searchProviderText]);
  const [GetGlobalProvider] = useGetGlobalProviderMutation();
  const [GlobalProvierList, setGlobalProvierList] = useState();
  const getProviderID = async () => {
    try {
      const response = await GetGlobalProvider().unwrap();
      setGlobalProvierList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [defaultProviderdata, setDefaultProviderdata] = useState();
  console.log(defaultProviderdata, "defaultProviderdata");

  console.log(formik.errors, "Checking the errors");
  useEffect(() => {
    if (providerData) {
      const defaultProvider = providerData.find(
        (item) =>
          item.provider_id == GlobalProvierList?.global_config_json_value?.default_postpay_provider
      );
      if (defaultProvider) {
        const transformProvider = {
          id: defaultProvider.provider_id,
          name: defaultProvider.provider_name,
        };
        setDefaultProviderdata(transformProvider);
      }

      if (defaultProvider) {
        const transformProvider = {
          id: defaultProvider.provider_id,
          name: defaultProvider.provider_name,
        };
        setDefaultProviderdata(transformProvider);
        if (!formik.values.check_white) {
          formik.setFieldValue("provider_id1", defaultProvider.provider_id);
          formik.setFieldValue("provider_id", transformProvider);
        }
      }
    }
  }, [GlobalProvierList]);
  return (
    <Card sx={{ my: 3, p: 2 }}>
      <Grid component={"form"} container spacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Partner Details
          </SuiTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormLabel htmlFor="account_status">Partner Name</FormLabel>
          <AutocompleteSingleSelect
            id="Supplier"
            value={formik.values.partner_id}
            setValue={(value) => {
              formik.setFieldValue("partner_id", value);
              formik.setFieldValue("partner_id1", value.name);
            }}
            noOptionsText={"No options"}
            placeholder="Select"
            isLoading={false}
            disabled={false}
            options={CateData}
            formik={formik}
            onInputChange={ActiveSearchPartner}
          />
        </Grid>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Basic Details
          </SuiTypography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_first_name" required>
            First Name
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="text"
            placeholder="First Name"
            id="cus_first_name"
            name="cus_first_name"
            value={formik.values.cus_first_name}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_first_name}
            error={formik.errors.cus_first_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_last_name" required>
            Last Name
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="text"
            placeholder="Last Name"
            id="cus_last_name"
            name="cus_last_name"
            value={formik.values.cus_last_name}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_last_name}
            error={formik.errors.cus_last_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="email_id" required>
            Email ID
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            inputProps={{
              autoComplete: "off",
              required: true,
            }}
            type="email"
            placeholder=" Email ID"
            id="cus_email"
            name="cus_email"
            value={formik.values.cus_email}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_email}
            error={formik.errors.cus_email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_phone_number" required>
            Mobile Number
          </FormLabel>
          <SuiPhoneNo
            countryCode={formik.handleChange}
            onBlur={formik.handleBlur}
            mobileNum={(e) => {
              formik.handleChange(e);
              if (getCountryCode(formik.values.cus_phone_country_code) === "+44") {
                if (String(e.target.value)?.length < 10 || String(e.target.value)?.length > 11) {
                  setMobileInValid("Please enter valid mobile number.");
                } else {
                  setMobileInValid(false);
                }
              } else {
                if (
                  !isValidPhoneNumber(
                    e.target.value,
                    getCountryShortCode(getCountryCode(formik.values.cus_phone_country_code))
                  )
                ) {
                  setMobileInValid("Please enter valid mobile number.");
                } else {
                  setMobileInValid(false);
                }
              }
            }}
            codeValue={getCountryCode(formik.values.cus_phone_country_code)}
            phoneValue={formik.values.cus_phone_number}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_phone_number}
            error={formik.errors.cus_phone_number || mobileInValid}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_business_number" required>
            Business Number
          </FormLabel>
          <SuiPhoneNo
            countryCode={formik.handleChange}
            type="businessnumber"
            onBlur={formik.handleBlur}
            mobileNum={(e) => {
              formik.handleChange(e);
              if (getCountryCode(formik.values.cus_business_country_code) === "+44") {
                if (String(e.target.value)?.length < 10 || String(e.target.value)?.length > 11) {
                  setBusinessMobileInValid("Please enter valid business number.");
                } else {
                  setBusinessMobileInValid(false);
                }
              } else {
                if (
                  !isValidPhoneNumber(
                    e.target.value,
                    getCountryShortCode(getCountryCode(formik.values.cus_business_country_code))
                  )
                ) {
                  setBusinessMobileInValid("Please enter valid business number.");
                } else {
                  setBusinessMobileInValid(false);
                }
              }
            }}
            codeValue={getCountryCode(formik.values.cus_business_country_code)}
            phoneValue={formik.values.cus_business_number}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_business_number}
            error={formik.errors.cus_business_number || businessMobileInValid}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="account_status" required>
            Provider
          </FormLabel>
          <AutocompleteSingleSelect
            id="provider"
            value={formik.values.provider_id}
            setValue={(value) => {
              formik.setFieldValue("provider_id", value);
              formik.setFieldValue("provider_id1", value.name);
            }}
            noOptionsText={"No options"}
            placeholder="Select Provider"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            isLoading={false}
            disabled={false}
            options={ProviderData}
            formik={formik}
            onInputChange={ActiveSearchProvider}
          />
          {formik.errors.provider_id1 && formik.touched.provider_id && (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>{formik.errors.provider_id1}</div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="time-day-category" required>
            Time of Day Category
          </FormLabel>
          <DayOfPlan
            id="cus_day_of_time"
            idKey="cus_day_of_time"
            labelKey="cus_day_of_time"
            placeholder="Select country"
            noOptionsText="No country available"
            onBlur={formik.handleBlur}
            value={formik.values.cus_day_of_time}
            setValue={(value) => formik.setFieldValue("cus_day_of_time", value)}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_day_of_time}
            error={formik.errors.cus_day_of_time}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="time_of_day" required>
            Time of Day
          </FormLabel>
          <DayOfPlanSubCatory
            formik={formik}
            id="cus_time_of_day_sub_category"
            idKey="cus_time_of_day_sub_category"
            labelKey="cus_time_of_day_sub_category"
            placeholder="Select country"
            noOptionsText="No country available"
            // options={options}
            onBlur={formik.handleBlur}
            value={formik.values.cus_time_of_day_sub_category}
            setValue={(value) => formik.setFieldValue("cus_time_of_day_sub_category", value)}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_time_of_day_sub_category}
            error={formik.errors.cus_time_of_day_sub_category}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="email_id" required>
            Billing Email ID
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            inputProps={{
              autoComplete: "off",
              required: true,
            }}
            type="email"
            placeholder="Billing Email ID"
            id="cus_billing_email"
            name="cus_billing_email"
            value={formik.values.cus_billing_email}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_billing_email}
            error={formik.errors.cus_billing_email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="rate_scheme" required>
            Rate Scheme
          </FormLabel>
          <AutocompleteSingleSelect
            id="rateScheme"
            value={formik.values.rateScheme_id || null}
            setValue={(value) => {
              formik.setFieldValue("rateScheme_id", value);
              formik.setFieldValue("rateScheme_id1", value.name);
            }}
            noOptionsText={"No options"}
            placeholder="Select Rate Scheme"
            isLoading={false}
            disabled={false}
            options={rateSchemeData}
            formik={formik}
            onInputChange={ActiveRateSchemeFind}
          />
          <ErrorMessageComponent
            touched={formik.touched.rateScheme_id1}
            error={formik.errors.rateScheme_id1}
          />
          {formik.errors.rateScheme_id1 && formik.touched.rateScheme_id && (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
              {formik.errors.rateScheme_id1}
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Company Details
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor=" Company Name" required>
            Company Name
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="tel"
            placeholder="Company Name"
            id="cus_company_name"
            name="cus_company_name"
            value={formik.values.cus_company_name}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_company_name}
            error={formik.errors.cus_company_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="company_address" required>
            Country
          </FormLabel>
          <AutoCompleteSingleCountry
            id="cus_country"
            idKey="cus_country"
            labelKey="cus_country"
            placeholder="Select country"
            noOptionsText="No country available"
            onBlur={formik.handleBlur}
            value={formik.values.cus_country}
            setValue={(value) =>
              formik.setFieldValue("cus_country", value) && value == ""
                ? formik.setFieldValue("cus_address_line_1", "")
                : formik.values.cus_address_line_1
            }
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_country}
            error={formik.errors.cus_country}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel htmlFor="cus_address" required>
            Company Address Line 1
          </FormLabel>
          <AutoCompleteSingleAddress
            formik={formik}
            id="cus_address_line_1"
            idKey="cus_address_line_1"
            labelKey="cus_address_line_1"
            placeholder="Select country"
            noOptionsText="No country available"
            name="cus_address_line_1"
            // options={options}
            onBlur={formik.handleBlur}
            // isLoading={isLoading} // loading state
            value={formik.values.cus_address_line_1}
            setValue={(value) => {
              formik.setFieldValue(
                "cus_address_line_1",
                value.fullAddress?.split(",")[0],
                formik.setFieldValue("cus_address_line_2", value.fullAddress?.split(",")[1]),
                formik.setFieldValue("cus_address_line_3", value.fullAddress?.split(",")[2]),
                formik.setFieldValue("cus_address_line_4", value.fullAddress?.split(",")[3]),
                formik.setFieldValue("cus_postal_code", value.postcode),
                setFlag(!!value.summaryline)
              );
            }}
          />
          {formik.errors.cus_address_line_1 && formik.touched.cus_address_line_1 ? (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
              {formik.errors.cus_address_line_1}
            </div>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_address" required>
            Company Address Line 2
          </FormLabel>
          <SuiInput
            type="text"
            size="medium"
            name="cus_address_line_2"
            id="cus_address_line_2"
            placeholder="Company Address Line 2"
            inputProps={{
              disabled: flag,
              style: { textTransform: "none" },
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cus_address_line_2}
          />

          <ErrorMessageComponent
            touched={formik.touched.cus_address_line_2}
            error={formik.errors.cus_address_line_2}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_address">Company Address Line 3</FormLabel>
          <SuiInput
            type="text"
            size="medium"
            name="cus_address_line_3"
            id="cus_address_line_3"
            placeholder="Company Address Line 3"
            inputProps={{
              disabled: flag,
              style: { textTransform: "none" },
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cus_address_line_3}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_address_line_3}
            error={formik.errors.cus_address_line_3}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_address">Company Address Line 4</FormLabel>
          <SuiInput
            type="text"
            size="medium"
            name="cus_address_line_4"
            id="cus_address_line_4"
            placeholder="Company Address Line 4"
            inputProps={{
              disabled: flag,
              style: { textTransform: "none" },
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cus_address_line_4}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_address_line_4}
            error={formik.errors.cus_address_line_4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="cus_postal_code" required>
            Postal Code
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off" }}
            inputProps={{
              readOnly: flag,
            }}
            type="text"
            placeholder="Postal Code"
            id="cus_postal_code"
            name="cus_postal_code"
            value={formik.values.cus_postal_code}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_postal_code}
            error={formik.errors.cus_postal_code}
          />
        </Grid>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Login Credentials
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="username" required>
            Username
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            inputProps={{
              autoComplete: "off",
              required: true,
            }}
            disabled
            type="email"
            placeholder="Username"
            id="cus_email"
            name="cus_email"
            value={formik.values.cus_email}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_email}
            error={formik.errors.cus_email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="password" required>
            Password
          </FormLabel>
          <SuiPasswordInput
            index={1}
            attr={{ name: "password", placeholder: "Password" }}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            getValue={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.password} error={formik.errors.password} />
        </Grid>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            Other Details
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="account_status" required>
            Account Type
          </FormLabel>
          <AccountTypeDropdown
            name="cus_account_type"
            value={formik.values.cus_account_type}
            setValue={formik.handleChange}
            disabled={formik.values.cus_account_type === "post_paid" && formik.values.partner_id}
            handlePostPaidSelection={handlePostPaidSelection}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_account_type}
            error={formik.errors.cus_account_type}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel htmlFor="account_status" required>
            Account Status
          </FormLabel>
          <AccountStatusDropdown
            name="cus_account_status"
            value={formik.values.cus_account_status}
            setValue={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.cus_account_status}
            error={formik.errors.cus_account_status}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", flexDirection: "row" }}
          alignSelf={"flex-end"}
        >
          <FormControlLabel
            name="cus_network_status"
            value={formik.values.cus_network_status}
            onChange={formik.handleChange}
            control={
              <Switch
                checked={isSwitchOn}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
                color="primary"
                sx={{ ml: 2 }}
              />
            }
            label="Network Blocked"
            labelPlacement="start"
          />
          <Grid item xs={12} md={6}>
            <FormControlLabel
              sx={{ ml: "20px" }}
              id="cus_2fa_auth"
              name="cus_2fa_auth"
              value={formik.values.cus_2fa_auth}
              control={
                <Switch checked={formik.values.cus_2fa_auth} color="primary" sx={{ ml: 2 }} />
              }
              label="Two-Factor Authentication"
              onChange={formik.handleChange}
              labelPlacement="start"
            />
            <ErrorMessageComponent
              touched={formik.touched.cus_2fa_auth}
              error={formik.errors.cus_2fa_auth}
            />
          </Grid>
        </Grid>
        {formik.values.cus_2fa_auth && (
          <>
            <Box width="100%" />
            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="cus_alter_email" required>
                Alternate Email ID
              </FormLabel>
              <SuiInput
                inputSettungs={{ autoComplete: "off", required: true }}
                type="tel"
                id="cus_alter_email"
                name="cus_alter_email"
                value={formik.values.cus_alter_email}
                onChange={formik.handleChange}
                placeholder="Alternate Email ID"
              />
              {formik.touched.cus_alter_email && formik.errors.cus_alter_email ? (
                <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                  {formik.errors.cus_alter_email}
                </div>
              ) : null}
              <Grid item sx={{ pb: 1 }}>
                {/* {Object.keys(formik.errors)?.length === 0 && error && (
                <ErrorMessageComponent touched={error} error={error} />
              )} */}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item sx={{ pb: 1, pt: 1 }}>
        {/* {Object.keys(formik.errors)?.length === 0 && error && ( */}
        <ErrorMessageComponent touched={error} error={error} />
        {/* )} */}
      </Grid>
      <Grid item xs={12}>
        <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
          <Grid item>
            <SuiButton
              size="small"
              component={Link}
              to="/customer"
              variant="outlined"
              buttonColor="dark"
              sx={{ px: "1rem", py: "0.4rem" }}
            >
              Cancel
            </SuiButton>
          </Grid>
          <Grid item>
            <SuiButton
              type="submit"
              size="small"
              buttonColor="primary"
              sx={{ px: "1rem" }}
              onClick={formik.handleSubmit}
              disabled={!formik.dirty}
            >
              Add
            </SuiButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateCustomer;
