import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDropDownList from "components/SuiDropDownList";
import { useUpdateProductMasterMutation } from "app/features/api/AuthApi";
import { useGetProductMasterByIdMutation } from "app/features/api/AuthApi";
import { useAddProductMasterMutation } from "app/features/api/AuthApi";
import NumberInput from "components/SuiInput/NumberInput";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useActiveCategoryListMutation } from "app/features/api/AuthApi";
import { AddProductMasterVal } from "Validation";

const AddProductMaster = () => {
  const id = localStorage.getItem("prod_master_id");
  const [error, setError] = useState("");
  const [addProductMaster] = useAddProductMasterMutation();
  const [ProductMasterByID] = useGetProductMasterByIdMutation();
  const suiSnackbar = useSuiSnackbar();
  const [updateProductMaster] = useUpdateProductMasterMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      prod_master_name: "",
      prod_master_sku: "",
      prod_category_id: "",
      prod_master_billing_period: "",
      prod_master_status: "1",
      prod_master_price: "",
      prod_category_id1: "",
    },
    validationSchema: AddProductMasterVal,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        prod_master_name: values.prod_master_name,
        prod_master_sku: values.prod_master_sku,
        prod_category_id: values.prod_category_id.id,
        prod_master_billing_period: values.prod_master_billing_period,
        prod_master_status: values.prod_master_status,
        prod_master_price: Number(values.prod_master_price),
        id,
      };
      try {
        if (id) {
          body.id = id;
          let response = await updateProductMaster(body).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/product-master");
            setError("");
          }
        } else {
          let response = await addProductMaster(body).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            navigate("/product-master");
            suiSnackbar(response.message, "success");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        const issues = error.data.errorMessages.issues;
        if (error.data.errorMessages.issues) {
          issues.map((item) => {
            suiSnackbar(item.message, "error");
          });
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  const getProductMasterDetails = async () => {
    try {
      const response = await ProductMasterByID(id).unwrap();
      const {
        prod_master_name,
        prod_master_sku,
        prod_category_id,
        prod_master_billing_period,
        prod_master_status,
        product_categories,
        prod_master_price,
      } = response.data;
      formik.setValues({
        prod_master_name,
        prod_master_sku,
        prod_master_billing_period,
        prod_master_status,
        prod_master_price,
      });
      const transformedCoCategory = {
        id: prod_category_id,
        name: product_categories?.prod_category_name,
      };
      formik.setFieldValue("prod_category_id", transformedCoCategory);
      formik.setFieldValue("prod_category_id1", prod_category_id);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  const [ActiveCategoryList] = useActiveCategoryListMutation();
  const [CategoryData, setCategoryData] = useState();
  const ActiveCategory = async () => {
    try {
      const response = await ActiveCategoryList().unwrap();
      setCategoryData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const CateData = CategoryData?.map((dropval) => ({
    id: dropval.prod_category_id,
    name: dropval.prod_category_name,
  }));
  useEffect(() => {
    if (id) {
      getProductMasterDetails();
    }
    ActiveCategory();
  }, [id]);

  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {id ? "Edit Product Master" : "New Product Master"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Product name
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Product Name"
              id="prod_master_name"
              name="prod_master_name"
              value={formik.values.prod_master_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.prod_master_name}
              error={formik.errors.prod_master_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              SKU
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter SKU"
              id="prod_master_sku"
              name="prod_master_sku"
              value={formik.values.prod_master_sku}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.prod_master_sku}
              error={formik.errors.prod_master_sku}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="status" required>
              Category
            </FormLabel>
            <AutocompleteSingleSelect
              id="Supplier"
              value={formik.values.prod_category_id}
              setValue={(value) => {
                formik.setFieldValue("prod_category_id", value);
                formik.setFieldValue("prod_category_id1", value.name);
              }}
              noOptionsText="No options"
              placeholder="Select"
              isLoading={false}
              disabled={false}
              options={CateData}
              formik={formik}
            />
            {formik.errors.prod_category_id1 && formik.touched.prod_category_id && (
              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                {formik.errors.prod_category_id1}
              </div>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="status" required>
              Status
            </FormLabel>
            <SuiDropDownList
              data={[
                { id: "1", label: "Active" },
                { id: "0", label: "Inactive" },
              ]}
              select={{
                key: "id",
                valueKey: "id",
                labelName: "label",
                label: "Status",
                name: "prod_master_status",
                val: formik.values.prod_master_status,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.prod_master_status}
              error={formik.errors.prod_master_status}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel htmlFor="status" required>
              Billing Period
            </FormLabel>
            <FormControl>
              <RadioGroup
                row
                sx={{
                  alignItems: "center",
                  rowGap: 1.5,
                  "&>.MuiFormControlLabel-root": {
                    marginRight: 1,
                  },
                }}
                name="prod_master_billing_period"
                value={formik.values.prod_master_billing_period} // Set the value from formik
                onChange={(event) =>
                  formik.setFieldValue("prod_master_billing_period", event.target.value)
                } // Update value on change
              >
                <FormControlLabel value="One Time" control={<Radio />} label="One Time" />
                <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
              </RadioGroup>
            </FormControl>
            <ErrorMessageComponent
              touched={formik.touched.prod_master_billing_period}
              error={formik.errors.prod_master_billing_period}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FormLabel htmlFor="status" required>
              Default Price
            </FormLabel>
            <NumberInput
              name="prod_master_price"
              placeholder="Select"
              step={1}
              value={formik.values.prod_master_price}
              setValue={formik.setFieldValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessageComponent
              touched={formik.touched.prod_master_price}
              error={formik.errors.prod_master_price}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/product-master"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  // disabled={!formik.dirty}
                >
                  {id ? "Update" : "Add"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AddProductMaster;
