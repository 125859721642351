import {
  Grid,
  Card,
  TableCell,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  DialogContent,
  // Checkbox,
  DialogTitle,
  Box,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useEffect } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDialog from "components/SuiDialog";
import setImg from "../../assets/images/logout-dialog-image.png";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import EditIcon from "Container/Icons/EditIcon";
import SuiDropDownList from "components/SuiDropDownList";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { useProductListInfoMutation } from "app/features/api/AuthApi";
import { useActivePartnerListMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { appendZero } from "helper";
import { format } from "date-fns";
// import { useAssignCustomerPartnerMutation } from "app/features/api/AuthApi";
import { formattedDates } from "helper";
import { useAssignPartnerMutation } from "app/features/api/AuthApi";
import { useDeletePartnerMutation } from "app/features/api/AuthApi";
import { partnerAssign } from "Validation";
import EyeIcon from "Container/Icons/EyeIcon";
import { useProviderListMutation } from "app/features/api/AuthApi";
import { useDownloadProviderMutation } from "app/features/api/AuthApi";
import { useGetAllCountryMutation } from "app/features/api/AuthApi";

const Provider = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagePrice] = useState(1);
  const [currentPageAssign, setCurrentPageAssign] = useState(1);
  const [roleInfo, setRoleInfo] = useState("");
  const [GetProvider, { isLoading }] = useProviderListMutation();
  const navigateSearch = useNavigateSearch();
  const [count, setCount] = useState(0);
  const [setCountPrice] = useState(0);
  const [countAssign] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [perPagePrice] = useState(10);
  const [perPageAssign, setPerPageAssign] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [setProductList] = useState();
  const [productId] = useState();
  const [assignPartnerModal, setAssignPartnerModal] = useState(false);
  const [confiemDeleteModal, setConfirmDeleteModal] = useState(false);
  //   const [AssignCustomerPartner, { isLoading: AssignLoad }] = useAssignCustomerPartnerMutation();
  const [deleteID, setDeleteID] = useState("");
  const [deleteByID] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/providers", { page: page });
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/providers", { page: 1 });
  };

  const handlePageChangeAssign = (event, page) => {
    setCurrentPageAssign(page);
  };
  const handlePerPageChangeAssign = (event) => {
    setPerPageAssign(event.target.value);
    setCurrentPageAssign(1);
  };

  const openAssignPartnerModal = () => {
    setOpenModal(false);
    setAssignPartnerModal(true);
  };
  const [ActivePartnerList] = useActivePartnerListMutation();
  const [partnerData, setPartnerData] = useState();
  const [partnerCustData] = useState();
  const [searchText, setSearchText] = useState();
  const ActivePartner = async (value) => {
    const params = { partner_name: value?.target.value };
    setSearchText(value?.target.value);
    setTimeout(async () => {
      try {
        const response = await ActivePartnerList(params).unwrap();
        setPartnerData(response.data);
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const CateData = partnerData?.map((dropval) => ({
    id: dropval.partner_id,
    name: `${dropval.partner_name} (${dropval.partner_account_number})`,
  }));
  useEffect(() => {
    if (searchText == "1%") {
      ActivePartner();
    }
  }, [searchText]);

  const partnerTableHead = [
    { id: "1", align: "left", width: "auto", label: "Account Number" },
    { id: "2", align: "left", width: "auto", label: "Company Name" },
    { id: "3", align: "left", width: "140", label: "Created ON" },
    { id: "4", align: "left", width: "auto", label: "Phone Number" },
    { id: "5", align: "left", width: "auto", label: "Email iD" },
  ];
  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "Provider name" },
    { id: "2", align: "left", width: "150", label: "phone number" },
    { id: "3", align: "left", width: "140", label: "Email" },
    { id: "4", align: "left", width: "auto", label: "Country" },
    { id: "5", align: "left", width: "auto", label: "Website" },
    { id: "6", align: "left", width: "auto", label: "Status" },
    {
      id: "8",
      align: "right",
      width: "auto",
      label: "Action",
    },
  ];

  // Edit partner
  const editRedirect = (item, type) => {
    localStorage.setItem("partner_id", item);
    {
      type === "view" ? navigateSearch("view-provider") : navigateSearch("edit-provider");
    }
    navigateSearch(``);
  };
  const formik = useFormik({
    initialValues: {
      status: "",
      email: "",
      provider_name: "",
      Country: "",
      phone_no: "",
    },
    onSubmit: () => {
      setCurrentPage(1);
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.provider_name = "";
      formik.values.phone_no = "";
      formik.values.email = "";
      formik.values.Country = "";
      formik.values.status = "";
      setCurrentPage(1);
      showData(perPage, 1);
    },
  });
  const [AssignPartner] = useAssignPartnerMutation();
  const DeleteDID = useFormik({
    initialValues: {
      partner_id: "",
      partner_id1: "",
    },
    onSubmit: async (values) => {
      const body = {
        partner_id: values.partner_id.id,
        deleteByID,
      };
      try {
        const response = await AssignPartner(body).unwrap();
        suiSnackbar(response.message, "success");
        setConfirmDeleteModal(false);
        setAssignPartnerModal(false);
        showData(perPage, 1);
      } catch (error) {
        suiSnackbar(error.data.message, "error");
        setConfirmDeleteModal(false);
        setAssignPartnerModal(false);
        console.log(error);
      }
    },
    onReset: () => {
      DeleteDID.values.partner_id = "";
      DeleteDID.values.partner_id1 = "";
    },
  });

  const AssignDID = useFormik({
    initialValues: {
      partner_id: "",
      partner_id1: "",
    },
    validationSchema: partnerAssign,
    onSubmit: async () => {
      setConfirmDeleteModal(true);
      setAssignPartnerModal(false);
      AssignDID.resetForm();
    },
    onReset: () => {
      formik.values.partner_id = "";
      formik.values.partner_id1 = "";
    },
  });

  let showData = async (perPage, currentPage) => {
    navigateSearch("/providers", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };

    if (formik.values.provider_name) {
      urlParamss.provider_name = formik.values.provider_name?.trim();
    }
    if (formik.values.email) {
      urlParamss.provider_email = formik.values.email?.trim();
    }

    if (formik.values.phone_no) {
      urlParamss.provider_phone_number = formik.values.phone_no;
    }
    if (formik.values.Country) {
      urlParamss.country_id = formik.values.Country.id;
    }
    if (formik.values.status) {
      urlParamss.provider_status = formik.values.status;
    }

    try {
      const response = await GetProvider(urlParamss).unwrap();
      setRoleInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setRoleInfo(null);
      setCount(null);
    }
  };
  const [ProductListInfo] = useProductListInfoMutation();
  const [DeletePartner] = useDeletePartnerMutation();
  const DeletePartnerInfo = async () => {
    try {
      const response = await DeletePartner(deleteID?.partner_id).unwrap();
      suiSnackbar(response.message, "success");
      setConfirmDeleteModal(false);
      showData(perPage, 1);
    } catch (error) {
      suiSnackbar(error.data.message, "error");
      setConfirmDeleteModal(false);
    }
  };

  const GetProductListData = async (perPagePrice, currentPagePrice, id) => {
    const params = {
      is_pagination: true,
      results_per_page: perPagePrice,
      page: currentPagePrice,
      partner_id: id,
    };
    try {
      const response = await ProductListInfo(params).unwrap();
      setProductList(response.data.rows);
      setCountPrice(response.data.count);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  useEffect(() => {
    if (productId?.partner_id == 1) {
      GetProductListData(perPagePrice, currentPagePrice, productId?.partner_id);
    }
  }, [perPagePrice, currentPagePrice, productId?.partner_id]);
  useEffect(() => {
    if (!deleteByID) {
      //   ActiveCustomerPartner(perPageAssign, currentPageAssign, deleteByID);
    }
  }, [perPageAssign, assignPartnerModal, currentPageAssign, productId?.partner_id]);
  useEffect(() => {
    if (assignPartnerModal == "1%") {
      //   ActiveCustomerPartner(perPageAssign, currentPageAssign, deleteByID);/
      ActivePartner();
    }
  }, [assignPartnerModal]);
  const [GetAllCountry] = useGetAllCountryMutation();
  const [countryList, setCountryList] = useState();
  const getCountry = async () => {
    try {
      const response = await GetAllCountry().unwrap();
      setCountryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountry();
    showData(perPage, currentPage);

    localStorage.removeItem("partner_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);
  const CountryOption = countryList?.map((item) => ({
    id: item.country_id,
    name: item.country_name,
  }));
  const partnerRows = partnerCustData?.map((item) => (
    <>
      <TableRow>
        <TableCell>{item.cus_account_number}</TableCell>
        <TableCell>{item.cus_company_name}</TableCell>
        <TableCell>{formattedDates(item.createdAt)}</TableCell>
        <TableCell>{item.cus_phone_number}</TableCell>

        <TableCell>{item.cus_email}</TableCell>
      </TableRow>
    </>
  ));
  const rows =
    roleInfo &&
    roleInfo?.map((item) => {
      return (
        <>
          <TableRow key={item.id}>
            <TableCell>{item?.provider_name}</TableCell>
            <TableCell>
              <div title={item?.provider_phone_number}>
                {item?.provider_phone_number == "" ? "-" : item?.provider_phone_number}
              </div>
            </TableCell>
            <TableCell>{item?.provider_email == "" ? "-" : item?.provider_email}</TableCell>
            <TableCell>{item?.countries == null ? "-" : item?.countries?.country_name}</TableCell>
            <TableCell>
              {item?.provider_website_link == "" ? "-" : item?.provider_website_link}
            </TableCell>
            <TableCell>
              {item.provider_status == "1" ? (
                <SuiTypography fontSize={14} sx={{ color: "#55DED0 !important" }}>
                  Active
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            <TableCell sx={{ textAlign: "-webkit-right" }} align="right">
              <Box sx={{ width: "115px" }}>
                <IconButton
                  className="viewIcon"
                  onClick={() => editRedirect(item.provider_id, "view")}
                >
                  {<EyeIcon />}
                </IconButton>
                {permissionInfo?.provider?.UPDATE && (
                  <IconButton onClick={() => editRedirect(item.provider_id)}>
                    {<EditIcon />}
                  </IconButton>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </>
      );
    });
  const [downloadProvider, { isLoading: isExport }] = useDownloadProviderMutation();
  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.id) {
      urlParamss.partner_account_number = formik.values.id;
    }
    if (formik.values.provider_name) {
      urlParamss.provider_name = formik.values.provider_name;
    }
    if (formik.values.email) {
      urlParamss.partner_email = formik.values.email?.trim();
    }

    if (formik.values.phone_no) {
      urlParamss.partner_phone_number = formik.values.phone_no;
    }
    if (formik.values.white) {
      urlParamss.partner_is_whitelable_reseller = formik.values.white;
    }
    if (formik.values.status) {
      urlParamss.partner_status = formik.values.status;
    }
    try {
      const response =
        Object.keys(urlParamss)?.length > 0
          ? await downloadProvider(urlParamss).unwrap()
          : await downloadProvider().unwrap();
      if (response.status === 200) {
        return response?.data;
      } else {
        return null;
      }
    } catch (error) {
      suiSnackbar(error?.data?.message, "error");
      return null;
    }
  };
  const onDownloadFn = async () => {
    const response = await downloadDetails();
    if (!response) {
      return;
    }
    const downloadData = response?.map((item, i) => {
      return {
        "Sr.no": appendZero(i + 1),
        "PROVIDER NAME": item?.provider_name == "" ? "-" : item?.provider_name,
        "PHONE NUMBER": item?.provider_phone_number == "" ? "-" : item?.provider_phone_number,
        EMAIL: item?.provider_email == "" ? "-" : item?.provider_email,
        COUNTRY: item?.countries == null ? "-" : item.countries.country_name,
        WEBSITE: item?.provider_website_link == "" ? "-" : item?.provider_website_link,
        STATUS: item?.provider_status === "1" ? "Active" : "Inactive",
      };
    });

    const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
    const csvFormat = [
      csvHeaders && csvHeaders.join(","),
      ...(downloadData || []).map(
        (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvFormat], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Provider List(${format(new Date(), "dd/MM/yyyy | hh:mm a")}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];

  return (
    <>
      <SuiDialog
        maxWidth="xs"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setDeleteID("");
        }}
        haveCloseIcon
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Delete Partner</DialogTitle>
        <DialogContent
          dividers
          sx={{
            fontWeight: "500",
          }}
        >
          There are {deleteID?.partner_no_of_cus} Customers assigned under this partner. To
          effectively delete the partner profile, please reassign them.
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"start"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={openAssignPartnerModal}
                  >
                    Continue
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setOpenModal(false);
                      setDeleteID("");
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      {/* assign partner modal :: BEGIN */}
      <SuiDialog
        open={assignPartnerModal}
        onClose={() => {
          setAssignPartnerModal(false);
          setDeleteID("");
          setPerPageAssign(10);
          setCurrentPageAssign(1);
          AssignDID.setFieldValue("partner_id", "");
          AssignDID.setFieldValue("partner_id1", "");
        }}
        haveCloseIcon
        maxWidth="lg"
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Assign Partner</DialogTitle>
        <DialogContent
          dividers
          sx={{
            fontWeight: "500",
          }}
        >
          <Grid container component="form" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="partner_name" required>
                Partner Name
              </FormLabel>
              <AutocompleteSingleSelect
                id="partnerName"
                value={AssignDID.values.partner_id}
                setValue={(value) => {
                  AssignDID.setFieldValue("partner_id", value);
                  AssignDID.setFieldValue("partner_id1", value.name);
                  DeleteDID.setFieldValue("partner_id", value);
                  DeleteDID.setFieldValue("partner_id1", value.name);
                }}
                noOptionsText="No options"
                placeholder="Select"
                onInputChange={ActivePartner}
                isLoading={false}
                disabled={false}
                options={CateData}
                formik={formik}
              />
              {AssignDID.errors.partner_id1 && AssignDID.touched.partner_id && (
                <div style={{ color: "#fc3c3c", fontSize: "0.9em", fontWeight: "400" }}>
                  {AssignDID.errors.partner_id1}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12}>
              <SuiTypography
                fontWeight="normal"
                fontSize="12px"
                my={2}
                sx={{ color: "#848484 !important" }}
              >
                List of Customer assigned to this partner
              </SuiTypography>
            </Grid>
            <Grid
              item
              xs={12}
              className="didTableSet"
              sx={{
                border: "1px solid #ddd",
                borderRadius: "10px",
              }}
            >
              <EnhancedTable
                tableMinWidth={540}
                tablehead={partnerTableHead}
                rowsData={partnerRows}
                // isLoading={AssignLoad}
              />
            </Grid>
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPageAssign}
                      onChange={handlePerPageChangeAssign}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {countAssign} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={countAssign}
                  pageSize={perPageAssign}
                  currentPage={Number(currentPageAssign)}
                  onPageChange={handlePageChangeAssign}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"start"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setAssignPartnerModal(false);
                      setDeleteID("");
                      AssignDID.handleReset();
                      setCurrentPageAssign(1);
                      setPerPageAssign(10);
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={() => {
                      AssignDID.submitForm();
                    }}
                  >
                    Next
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      {/* assign partner modal :: END */}
      {/* final delete confirmation modal :: BEGIN */}
      <SuiDialog
        open={confiemDeleteModal}
        onClose={() => {
          setConfirmDeleteModal(false);
          setDeleteID("");
        }}
        haveCloseIcon
      >
        <DialogContent
          sx={{
            fontWeight: "semiBold",
            textAlign: "center",
          }}
        >
          <Box mb={4} textAlign="center">
            <img src={setImg} alt="Image" width="100" />
          </Box>
          {deleteID?.partner_no_of_cus > 0 ? (
            <>
              <SuiTypography fontWeight="bold" textColor="text">
                Are you sure you want to assign the customer(s)
                <br />
                to the selected partner and delete the old partner?
              </SuiTypography>
              <SuiTypography
                fontWeight="regular"
                mt={1}
                mb={2}
                sx={{ fontSize: "14px", color: "#848484 !important" }}
              >
                Assigning a new partner may affect the current pricing and <br /> branding details
                of the customer(s).
              </SuiTypography>
            </>
          ) : (
            <SuiTypography fontWeight="bold" textColor="text">
              Are you sure you want to delete <br /> the Partner?
            </SuiTypography>
          )}
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => {
                      setConfirmDeleteModal(false);
                      setDeleteID("");
                      setCurrentPageAssign(1);
                    }}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs="auto" sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={() => {
                      deleteID?.partner_no_of_cus == 0
                        ? DeletePartnerInfo()
                        : DeleteDID.submitForm();
                    }}
                  >
                    Proceed to Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      {/* final delete confirmation modal :: END */}
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Provider Name"
                  id="provider_name"
                  name="provider_name"
                  value={formik.values.provider_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Phone Number"
                  id="phone_no"
                  name="phone_no"
                  value={formik.values.phone_no}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Email Id"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="Country"
                  value={formik.values.Country}
                  setValue={(value) => {
                    formik.setFieldValue("Country", value);
                    formik.setFieldValue("Country1", value.name);
                  }}
                  noOptionsText="No options"
                  placeholder="Country"
                  isLoading={false}
                  disabled={false}
                  options={CountryOption}
                  formik={formik}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiDropDownList
                  data={[
                    { id: "1", label: "Active" },
                    { id: "0", label: "Inactive" },
                  ]}
                  select={{
                    key: "id",
                    valueKey: "id",
                    labelName: "label",
                    label: "Status",
                    name: "status",
                    val: formik.values.status,
                    fn: formik.handleChange,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                    formik.values.prod_category_name = "";
                    formik.values.prod_category_status = "";
                    setPerPage(10);
                    navigateSearch("/providers", { page: 1 });
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>

      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Providers">
            <SuiButton
              size="small"
              buttonColor="accous"
              sx={{
                fontWeight: "500",
                px: "12px",
                ml: 2,
                color: "#848484 !important",
              }}
              onClick={onDownloadFn}
              disabled={isExport}
            >
              {isExport ? <CircularProgress size={20} color="info" /> : "Export"}
            </SuiButton>
            {permissionInfo?.provider?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"add-provider"}
              >
                Add Provider
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={pagesTableHeader}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default Provider;
