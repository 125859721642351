import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const ProfileUploadUI = ({
  id,
  accept,
  required = false,
  fileValue,
  getFile,
  removeFileName,
  tabInfoVal,
}) => {
  const [fileName, setFileName] = useState("");
  const suiSnackbar = useSuiSnackbar();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (fileValue) {
      setFileName(fileValue);
    } else if (tabInfoVal !== "" || tabInfoVal !== null || tabInfoVal !== "null") {
      setFileName(tabInfoVal);
    } else {
      setFileName("");
    }
  }, [fileValue, tabInfoVal]);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setFileName(tabInfoVal || "");
      return;
    }
    const maxFileSize = 25 * 1024 * 1024;

    if (file.size > maxFileSize) {
      setFileName("");
      suiSnackbar("Maximum file size must be 25MB.", "error");
      return;
    }

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];

    if (allowedTypes.includes(file.type)) {
      setFileName(file.name);
      getFile(file);
    } else {
      setFileName("");
      suiSnackbar("Unsupported file type. Please upload a PNG, JPG, JPEG, or SVG file.", "error");
    }
  };

  const removeFile = () => {
    setFileName("");
    removeFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        {!fileName ? (
          <Grid item sx={{ flexGrow: 1 }}>
            <SuiBox display="flex" title="No file chosen">
              <label htmlFor={id} style={{ cursor: "pointer" }}>
                <SuiTypography
                  textColor="info"
                  sx={{
                    fontWeight: 500,
                    textDecoration: "underline !important",
                    cursor: "pointer",
                  }}
                  fontSize={14}
                >
                  Upload
                </SuiTypography>
              </label>
            </SuiBox>
            <SuiBox>
              <SuiTypography textColor="text2" fontSize={{ xs: 12, md: 12, xl: 14 }} pt={1}>
                Maximum file size must be 25MB.
              </SuiTypography>
              <SuiTypography textColor="text2" fontSize={{ xs: 12, md: 12, xl: 14 }} pt={0}>
                Logo formats can be .PNG, .JPEG, .JPG or .SVG.
              </SuiTypography>
            </SuiBox>
          </Grid>
        ) : (
          <Grid item>
            <SuiBox
              position="relative"
              borderRadius="5px"
              display="inline-block"
              sx={{
                bgcolor: "transparent !important",
                color: "#C02B0A !important",
                textDecoration: "underline !important",
                cursor: "pointer",
                fontWeight: "500",
              }}
              title={fileName}
              onClick={removeFile}
            >
              Remove
            </SuiBox>
            <SuiBox>
              <SuiTypography textColor="text2" fontSize={{ xs: 12, md: 12, xl: 14 }} pt={1}>
                Maximum file size must be 25MB.
              </SuiTypography>
              <SuiTypography textColor="text2" fontSize={{ xs: 12, md: 12, xl: 14 }} pt={0}>
                Logo formats can be .PNG, .JPEG, .JPG or .SVG.
              </SuiTypography>
            </SuiBox>
          </Grid>
        )}
      </Grid>
      <SuiInput
        id={id}
        type="file"
        inputRef={fileInputRef}
        inputProps={{
          sx: { display: "none" },
          hidden: true,
          required: required,
          accept: accept,
        }}
        onChange={handleFileUpload}
      />
    </>
  );
};

export default ProfileUploadUI;
